import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { passwordValidationRules } from '../../constants';

export class PasswordValidators {
  public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl<string>): Record<string, true> | null => {
      if (!control.value) {
        // if control is empty return no error.
        return null;
      }

      // test the value of the control against the regexp supplied.
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter.
      return valid ? null : error;
    };
  }

  /**
   * Custom validator for the password strength rules. It iterates through all rules defined in
   * the {@see passwordValidationRules} and keeps track of how many passed validation.
   * @param {number} [minValidRules] Optional parameter to specify what is the minimum number of rules
   * that need to pass validation to validate the field/password strength. It defaults to minimum 3 rules.
   * If the number of minimum rules to match is greater than the size of the rules set, the minimum rules
   * to match is set to the size of the rules set.
   * @returns {ValidatorFn} The validator function that validates the password strength.
   */
  public static passwordStrengthValidator(minValidRules: number = 3): ValidatorFn {
    return (control: AbstractControl<string>): Record<'passwordStrength', true> | null => {
      const input = control.value;

      if (!input) {
        return null;
      }

      const numRulesInSet = passwordValidationRules.length;
      const minValid = minValidRules > numRulesInSet ? numRulesInSet : minValidRules;
      let numValid = 0;

      passwordValidationRules.forEach((rule: RegExp): void => {
        if (rule.test(input)) {
          numValid += 1;
        }
      });

      return numValid < minValid ? { passwordStrength: true } : null;
    };
  }
}
