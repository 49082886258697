/**
 The route to the help desk form. If the route configuration changes {@see GlobalRoutingModule}
 in `src/global/global-routing.module`, {@see helpDeskRoute} constant in `src/constants/app.ts`
 must be updated as well to match the new route!!!
 */
export const helpDeskRoute = [ '/global', 'help' ] as const;
export const shorthelpDeskRoute = [ '/global', 'short-help' ] as const;
export const resetPasswordRoute = [ '/login', 'forgot-password' ] as const;
export const joinTodayUrlRoute = [ '/signup', 'search' ] as const;
export const forgotUsernameRoute = [ '/login', 'forgot-username' ] as const;
export const benefitSelectionRoute = [ '/login', 'benefit-selection' ] as const;
export const sessionTimeoutMinutes = 600000;
export const sessionWarningMinutes = 300000;
export const tokenDuration = 900000 as const; // this is 15 min in ms
export const tokenBuffer = 300000 as const; // this is 5 min in ms

/**
 * The BE login URL which redirects to the appropriate login page (LOB vs BHL2.0/Angular)
 */
export const loginUrl = 'account/login' as const;

export const bhTitle = '| Bright Horizons®' as const;
