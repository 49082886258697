import { ActionReducer, createFeature, createReducer, on } from '@ngrx/store';

import { Content, ContentPages, HttpError, IContentState } from '../../models';
import { ContentActions } from './content.actions';
import { initialState } from './initial-content';

/**
 * The `content` (app global) reducer.
 */
export const contentReducer: ActionReducer<IContentState> = createReducer(
  initialState,
  on(
    ContentActions.loadPageContentPattern,
    (state: IContentState, { page }: { page: ContentPages }): IContentState => ({
      ...state,
      [page]: {
        // this is not based on user provided input, but mapping in the app, safe to suppress
        // eslint-disable-next-line security/detect-object-injection
        ...state[page],
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    ContentActions.loadPageContentPatternSuccess,
    (state: IContentState, { content, page }: { content: Content, page: ContentPages }): IContentState => ({
      ...state,
      [page]: {
        // this is not based on user provided input, but mapping in the app, safe to suppress
        // eslint-disable-next-line security/detect-object-injection
        ...state[page],
        loading: false,
        loaded: true,
        content: {
          // eslint-disable-next-line security/detect-object-injection
          ...state[page].content,
          ...content,
        },
      },
    }),
  ),
  on(
    ContentActions.loadPageContentPatternFailure,
    (state: IContentState, { error, page }: { error: string | HttpError; page: ContentPages }): IContentState => ({
      ...state,
      [page]: {
        // this is not based on user provided input, but mapping in the app, safe to suppress
        // eslint-disable-next-line security/detect-object-injection
        ...state[page],
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    ContentActions.getPageContentPattern,
    (state: IContentState, { page }: { page: ContentPages }): IContentState => ({
      ...state,
      [page]: {
        // this is not based on user provided input, but mapping in the app, safe to suppress
        // eslint-disable-next-line security/detect-object-injection
        ...state[page],
        loading: false,
        loaded: true,
      },
    }),
  ),
  on(
    ContentActions.getLogoUrl,
    (state: IContentState): IContentState => ({
      ...state,
      logoUrl: {
        ...state.logoUrl,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    ContentActions.fetchLogoUrlSuccess,
    (state: IContentState, { path }: { path: string | null }): IContentState => ({
      ...state,
      logoUrl: {
        ...state.logoUrl,
        loaded: true,
        loading: false,
        path,
      },
    }),
  ),
  on(
    ContentActions.fetchLogoUrlFailure,
    (state: IContentState, { error }: { error: string | HttpError }): IContentState => ({
      ...state,
      logoUrl: {
        ...state.logoUrl,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    ContentActions.clearFetchLogoUrl,
    (state: IContentState): IContentState => ({
      ...state,
      logoUrl: initialState.logoUrl,
    }),
  ),
);

/**
 * The `content` (app global) reducer feature creator that will generate
 * metadata and selectors for us.
 */
export const contentFeatureReducer = createFeature({
  name: 'content',
  reducer: contentReducer,
});

/**
 * Name export all the generated selectors by the feature reducer creator.
 */
export const {
  name,
  selectContentState,
  selectEmployeeSearchPage,
  selectEligibilityPage,
  selectMagicLinkPage,
  selectPersonalInfoPage,
  selectCreateAccountPage,
  selectSignupConfirmationPage,
  selectHelpDeskPage,
  selectFranchiseLocatorPage,
  selectFullServiceUniqueIdPage,
  selectFederatedSplitScreenPage,
  selectFullServiceEmployerSelectionPage,
  selectMagicLinkExpiredPage,
  selectUserAlreadyRegisteredPage,
  selectFranchiseConfirmIdentityPage,
  selectSignupMFAPage,
  selectLogoUrl,
  selectSignupMFAOTPPage,
  selectStandardLoginPage,
  selectForgotUsernamePage,
  selectResetPasswordPage,
  selectLoginMFAPage,
  selectPasswordUpdatedPage,
  selectChangePasswordPage,
  selectSignupOverlayPopupPage,
  selectBenefitSelectionPage,
} = contentFeatureReducer;
