import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { SharedService } from '../../shared.service';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { LocalStorageKey } from 'src/app/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-field-level-error',
  templateUrl: './field-level-error.component.html',
  styleUrls: [ './field-level-error.component.scss' ],
})
export class FieldLevelErrorComponent implements OnChanges {
  /**
   * An id to identify the error message to be used for any aria-describedby attributes.
   * It defaults to `error-input-empty`, but that default should not be relied upon unless
   * there is ONLY one field level element on the page, and it matches the error state (`required`).
   */
  @Input() public id: string = 'error-input-empty';
  @Input() public signUpErrorGA4: boolean = false;
  @Input() public formErrorGA4: string = "";
  /**
   * The error text to display to the end user.
   */
  @Input() public message: string | undefined;
  constructor(
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
  ) {
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['signUpErrorGA4'] && changes['signUpErrorGA4'].currentValue && this.message) {
      this.pushGA4SignUpErrorData(this.message.trim().replace(/[^ -~]+/g, ''));
    }
    if (changes['formErrorGA4'] && changes['formErrorGA4'].currentValue && this.message) {
      switch(this.formErrorGA4){
        case 'Contact Helpdesk': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Contact Helpdesk');
          break;
        case 'Support': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Support');
          break;
        case 'Forgot Username': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Forgot Username');
          break;
        case 'Forgot Password': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Forgot Password');
          break;
        case 'resetPassword': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Reset Password');
          break;
        case 'createUsernamePassword': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Create Username Password');
          break;
        case 'registrationStep2': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Sign Up');
          break;
        case 'signup': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Sign Up');
          break;
        case 'login': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Login');
          break;        
        case 'Sign Up': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Sign Up');
          break;
        case 'Reset Password': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Reset Password');
          break;
        case 'Create Username Password': this.pushGA4FormErrorData(this.message.trim().replace(/[^ -~]+/g, '', ), 'Create Username Password');
          break;
        default:
          throw new Error('No case matched for form_error Ga4 event.');
      }
    }
  }
  public pushGA4SignUpErrorData(errorMessage: string): void {
    if (errorMessage) {
      const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
      this.sharedService.GA4pushEvent('sign_up_error', {
        // eslint-disable-next-line camelcase
        benefit_id: benefitIdFromLocalStorage,
        // eslint-disable-next-line camelcase
        benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage),
        // eslint-disable-next-line camelcase
        error_message: errorMessage,
      });
    }
  }
  public pushGA4FormErrorData(errorMessage: string, formName: string): void {
    if (errorMessage) {
      this.sharedService.GA4pushEvent('form_error', {
          /* eslint camelcase: ["error", {allow: ["form_name","error_message"]}]*/
          form_name: formName,
          error_message: errorMessage,
      });
    }
  }
}
