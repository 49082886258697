import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Stepper component to indicate the progress through the flow. It has
 * two `Input` parameters to handle the display state and number of steps.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-stepper',
  templateUrl: './bh-stepper.component.html',
  styleUrls: [ './bh-stepper.component.scss' ],
})
export class BhStepperComponent {
  /**
   * Sets the current step in the flow. Defaults to 1. To mark the last
   * step completed, you need to pass `totalSteps + 1` to this property.
   * For example, if we have total of 3 steps, to mark the last step
   * completed, we would pass 4 to `currentStep`.
   */
  @Input() public currentStep: number = 1;

  /**
   * Sets the total number of steps in the flow. Defaults to 3.
   */
  @Input() public totalSteps: number = 3;

  /**
   * An array to generate the number of steps in the stepper based on the `totalSteps`.
   */
  public steps: Array<number>;

  /**
   * The component `constructor`
   */
  constructor() {
    this.steps = Array(this.totalSteps)
      .fill(1)
      .map((x, i: number): number => i);
  }

  /**
   * A track by function for the ngFor loop to optimize performance.
   * @param {number} index The index of the current element.
   * @returns {number} The unique identifier for this element.
   */
  public trackByFn(index: number): number {
    return index;
  }
}
