<mat-dialog-content id="autofocus-target" class="bh-dialog--timeout-warning">
  <div *ngIf="(content | async)?.loaded">
    <div class="signup-confirmation"
      *ngIf="{ page: (content | async)?.content, account: (accountContent | async)?.content } as pageContent">

      <div class="success-icon">
        <img [src]="'assets/success-icon.svg'" [alt]="'Success'" />
      </div>
      <h1 class="bh--heading" id="dialog-title">
        {{ pageContent.page?.BHL_Overlaypopup_Label_Header }}
      </h1>
      <p id="dialog-message" class="explore-message" style="margin-bottom: 2rem;white-space:pre-wrap;">
        {{pageContent.page?.BHL_Overlaypopup_Label_Content1! | bhlineBreak}}
      </p>

      <ng-container>
        <button (click)="buildBenefitsURL()" type="button" data-atm-button-continue="continue-button"
          class="bh-button--primary">
          {{ pageContent.account?.BHL_EmployerSearch_Button_Continue }}
        </button>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>