import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, firstValueFrom, take } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  BhQueryParams,
  EventName,
  HttpError,
  IAppJwt,
  IBenefitLogo,
  IEmployeeDetailsState,
  ITrackEventPayload,
  LocalStorageKey,
} from '../../models';
import { selectApiToken, selectBenefitId, selectBhPropertiesURL } from '../config/config.reducers';
import { selectEmployeeDetails } from '../signup/signup.reducers';
import { SharedService } from 'src/app/shared/shared.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
// Declare Optanon as a global variable
declare var Optanon: any;
interface CustomWindow extends Window {
  OnetrustActiveGroups?: string[];
}
/**
 * Service to send events to AppInsights and other logging services.
 */
@Injectable({
  providedIn: 'root',
})

export class LoggerService {
  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
  ) { }

  /**
   * Send errors to the appropriate service.
   * @param {string} message The exception that happened.
   * @param {string | null} stack The stack trace for the error.
   * @param {EventName} eventName The event name/error that occurred. Defaults to FE exception/error.
   * @param {Error | HttpError} errorObj The actual error object, so we can pass it to the backed when appropriate.
   * @param {string | undefined} subCategory The subCategory that occurred. based  on the category.
   */
  public sendError(
    message: string,
    stack: string | null,
    eventName: EventName = EventName.SIGNUP_TECHNICAL_ERROR_FE,
    errorObj?: Error | HttpError,
    subCategory?: string | undefined,
  ): void {
    if (environment.debugLogs) {
      console.error('message:', message, 'stack:', stack);
    }

    this.sendTrackEvent(message, stack, eventName, errorObj, subCategory);
  }

  /**
   * Send any errors/exceptions to the custom BE AppInsights endpoint.
   * @param {string} message The exception that happened.
   * @param {string | null} stack The stack trace for the error.
   * @param {EventName} eventName The event name/error that occurred. Defaults to FE exception/error.
   * @param {Error | HttpError} errorObj The actual error object, so we can pass it to the backed when appropriate.
   * @param {string | undefined} subCategory The event name/error that occurred. based  on the subcategory.
   */
  public sendTrackEvent(
    message: string,
    stack: string | null,
    eventName: EventName = EventName.SIGNUP_TECHNICAL_ERROR_FE,
    errorObj?: Error | HttpError,
    eventCategory?: string,
  ): void {
    let hasC0002PerfCookieSelected : boolean = false;
    const defaultView = window as CustomWindow;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (defaultView && defaultView.OnetrustActiveGroups) {
      hasC0002PerfCookieSelected = defaultView.OnetrustActiveGroups.includes('C0002');
    }

    // need to check if the exception came from this endpoint and not report it and go into a loop!!!
    if (hasC0002PerfCookieSelected && !message.includes('v1/Admin/TrackEvent')) {
      this.store.select(selectBenefitId)
        .pipe(
          combineLatestWith(
            this.store.select(selectBhPropertiesURL),
            this.store.select(selectApiToken),
            this.store.select(selectEmployeeDetails),
          ),
          take(1),
        )
        .subscribe(([benefit, params, token, details]: [IBenefitLogo, BhQueryParams, IAppJwt | null, IEmployeeDetailsState | null]): void => {
          const payload: ITrackEventPayload = {
            eventName,
            message: JSON.stringify({
              message,
              ...stack !== null && { stack },
              ...(errorObj?.name === 'HttpErrorResponse' && (errorObj as HttpError).error) && { beResponse: (errorObj as HttpError).error },
            }),
            benefitId: benefit.id,
            url: window.location.href,
            ...params.clientguid && { clientGuid: params.clientguid },
            userInfoId: token?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string,
            username: details?.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string,
            eventCategory,
          };

          // Pendo Tracking
          if (environment.pendoEnable) {
            this.sharedService.TrackPendoEvent(eventName, benefit.id, eventCategory ?? '', message);
          }

          // Convert to a Promise because Observables are lazy in nature and will not fire until there is a subscription.
          // However, this is a fire-and-forget scenario, and we do not need to subscribe/wait for a response.
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          firstValueFrom(this.http.post<boolean>(`${environment.apiVersionUri.v1}/Admin/TrackEvent`, payload)).catch(() => { });
        });
    }
  }
}
