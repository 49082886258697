import { Injectable } from '@angular/core';

import { visitorUniqueId, unknownAccountId } from '../../constants';
import {
  BenefitId,
  BhQueryParams,
  Identity,
  IdentityMetadata,
  IEmployeeDetailsState,
  IPersonInfo,
  ISelectedEmployerState,
  IValidateUserStatus,
  Pendo,
} from '../../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  private readonly globalWindow: Window;

  /**
   * A property to hold an instance of the Pendo Client, so we can initialize Pendo
   * with the selected or passed in the URL client GUID.
   * @private
   */
  private pendoClient: Pendo | undefined;

  public set pendo(pendo: Pendo | undefined) {
    this.pendoClient = pendo;
  }

  public get pendo(): Pendo | undefined {
    return this.pendoClient;
  }

  constructor() {
    this.globalWindow = window;
  }

  private getClient(): Pendo {
    return (this.globalWindow as Window & { pendo: Pendo }).pendo;
  }

  /**
   * Initialize the Pendo script with a default account ID.
   */
  public initPendo(benefitId: BenefitId): void {
    this.pendo = this.getClient();
    this.pendoClient?.initialize({
      // user information is stored under `visitor` (like user's email, userName, zip, etc.)
      visitor: {
        id: visitorUniqueId, // Required if user is logged in
        benefitid: benefitId as string,

        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      // client information is stored under `account` (like clientGuid, clientName, etc.)
      account: {
        // eslint-disable-next-line line-comment-position,no-inline-comments
        id: unknownAccountId, // Highly recommended
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }

  /**
   * Update the Pendo configuration once we retrieve the client GUID.
   * @param {object} info The information we would like to update Pendo with like clientGuid,
   * clientName, user's email, user's full name, benefit id etc.
   */
  public updateMetadata(
    info: {
      benefitId: BenefitId,
      params: BhQueryParams,
      employer?: ISelectedEmployerState,
      employee?: IEmployeeDetailsState,
      loggedInUser?: IValidateUserStatus,
      personalInfo?: IPersonInfo,
    },
  ): void {
    if (this.pendoClient) {
      const isLoggedIn: boolean = Boolean(info.loggedInUser?.userInfoId ?? info.personalInfo?.userInfoID);
      const metadata: Identity = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        visitor: {
          id: info.loggedInUser?.userInfoId ?? info.personalInfo?.userInfoID ?? visitorUniqueId,
          ...info.params as any,
          benefitid: info.benefitId,
          // eslint-disable-next-line max-len
          ...(info.employee?.email || info.loggedInUser?.primaryEmail || info.personalInfo?.primaryEmail) && { email: info.employee?.email ?? info.loggedInUser?.primaryEmail ?? info.personalInfo?.primaryEmail },
          // eslint-disable-next-line max-len
          ...(info.employee?.country || info.loggedInUser?.country || info.personalInfo?.country) && { country: info.employee?.country ?? info.loggedInUser?.country ?? info.personalInfo?.country },
          // eslint-disable-next-line max-len
          ...(info.employee?.zipCode || info.loggedInUser?.postalCode || info.personalInfo?.postalCode) && { zipcode: info.employee?.zipCode ?? info.loggedInUser?.postalCode ?? info.personalInfo?.postalCode },
          // eslint-disable-next-line camelcase,@typescript-eslint/restrict-template-expressions
          ...(info.employee?.firstName || info.employee?.lastname) && { full_name: `${info.employee.firstName} ${info.employee.lastname}` },
          ...info.loggedInUser?.userInfoId && { eligibilityID: info.loggedInUser.eligibilityID },
          ...(info.loggedInUser?.userName || info.personalInfo?.userName) && { userName: info.loggedInUser?.userName ?? info.personalInfo?.userName },
          ...info.loggedInUser?.userTypeID && { userTypeID: info.loggedInUser.userTypeID },
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,max-len,@typescript-eslint/no-unsafe-member-access
          // ...(info.loggedInUser?.clientRegistration?.nsn || (info.personalInfo as any)?.nsn) && { nsn: info.loggedInUser?.clientRegistration?.nsn ?? (info.personalInfo as any)?.nsn },
        },
        account: {
          // eslint-disable-next-line max-len
          id: info.employer?.clientGuid ?? info.params.clientguid ?? info.loggedInUser?.clientGuid ?? info.personalInfo?.crmClientId ?? unknownAccountId,
          // eslint-disable-next-line max-len
          ...(info.employer?.clientName || info.loggedInUser?.clientName || info.personalInfo?.clientName) && { clientname: info.employer?.clientName ?? info.loggedInUser?.clientName ?? info.personalInfo?.clientName },
          ...(info.employer?.clientId || info.loggedInUser?.clientId) && { clientid: info.employer?.clientId ?? info.loggedInUser?.clientId },
          ...info.params.origclientguid && { origclientguid: info.params.origclientguid },
        },
      };

      if (isLoggedIn) {
        // grab info from the /employee (login) endpoint response if available, otherwise grab from personal info endpoint response
        if (info.loggedInUser) {
          metadata.visitor = {
            ...metadata.visitor,
            // eslint-disable-next-line camelcase,@typescript-eslint/restrict-template-expressions
            ...(info.loggedInUser.firstName || info.loggedInUser.lastName) && { full_name: `${info.loggedInUser.firstName} ${info.loggedInUser.lastName}` },
          };
        } else {
          // grab info from the personal info endpoint response when employee response is not available
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          metadata.visitor = {
            ...metadata.visitor,
            // eslint-disable-next-line camelcase,@typescript-eslint/restrict-template-expressions
            ...(info.personalInfo?.firstName || info.personalInfo?.lastName) && { full_name: `${info.personalInfo.firstName} ${info.personalInfo.lastName}` },
          };
        }
      }

      // remove the access token we received from the query params, so we don't send it to Pendo
      // we need to remove it here after we spread the query params in the metadata object which creates a new object reference to
      // avoid removing the access token from app state. if we remove it from the passed params object, it will delete it from the
      // app state as well (bug 324885)
      if (metadata.account?.['accesstoken']) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete (metadata.account as any).accesstoken;
      }

      if (metadata.visitor?.['accesstoken']) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete (metadata.visitor as any).accesstoken;
      }

      // given we add all query params to the `visitor` object, we should remove the ones that are also provided in the `account`
      // object to avoid duplication of data as well as proper reporting to Pendo that aligns with the direction/separation BH
      // plans to go/standardize on. Thus, remove any key/data from the `visitor` object we added to the `account` one to ensure
      // there are no duplicates between the 'visitor` (user's) object and the `account` (client's) one.
      Object.keys(metadata.account as IdentityMetadata).forEach((value: string): void => {
        if (value !== 'id') {
          // eslint-disable-next-line security/detect-object-injection
          delete metadata.visitor?.[value];
        }
      });

      // also remove the client guid from the visitor object because it is assigned to the `id` key of the `account` object and
      // the above loop will not pick it up and remove
      if (metadata.visitor?.['clientguid']) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete metadata.visitor['clientguid'];
      }

      // Prepend all fields with `bhl_` to easily identify them in Pendo dashboard (and a conversation with Meena)
      Object.keys(metadata).forEach((key: string): void => {
        const identityMetadata: IdentityMetadata = metadata[key as keyof Identity] as IdentityMetadata;

        Object.keys(identityMetadata).forEach((value: string): void => {
          // we must NOT rename the `id` field! It is a mandatory field by Pendo
          if (value !== 'id') {
            // eslint-disable-next-line security/detect-object-injection
            identityMetadata[`bhl_${value}`] = identityMetadata[value] as string | number | boolean | Array<string>;
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete,security/detect-object-injection
            delete identityMetadata[value];
          }
        });
      });

      this.pendoClient.updateOptions(metadata);
    }
  }


  /**
  * Track Pendo event by eventname.
  * @param {object} info The information we would like   to update Pendo with like clientGuid,
  * clientName, user's email, user's full name, benefit id etc.
  */
  public TrackEvent(
    info: {
      eventName: string,
      eventCategory: string,
      trackDescription?: string,
      benefitId: string,
      params: BhQueryParams,
      employer?: ISelectedEmployerState,
      employee?: IEmployeeDetailsState,
      loggedInUser?: IValidateUserStatus,
      personalInfo?: IPersonInfo,
    },
  ): void {
    if (this.pendoClient && typeof this.pendoClient.track === 'function' && environment.pendoEnable) {
      // const isLoggedIn: boolean = Boolean(info.loggedInUser?.userInfoId ?? info.personalInfo?.userInfoID);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.pendoClient.track(info.eventCategory,
        {
          visitorId: info.loggedInUser?.userInfoId ?? info.personalInfo?.userInfoID ?? visitorUniqueId,
          eventCategory: info.eventName,
          benefitid: info.benefitId,
          message: info.trackDescription as string,
          // eslint-disable-next-line max-len
          accountId: info.employer?.clientGuid.toLowerCase() ?? info.params.clientguid?.toLowerCase() ?? info.loggedInUser?.clientGuid.toLowerCase() ?? info.personalInfo?.crmClientId?.toLowerCase() ?? '',
          ...info.loggedInUser?.userInfoId && { eligibilityID: info.loggedInUser.eligibilityID },
        });
    }
  }
}
