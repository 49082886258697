/**
 * The number of auto retries in case of an error response from the backend.
 */
export const retries: number = 0;

/**
 The route to the global error page where we navigate in certain situations like token endpoint failure,
 JS exception. If the route configuration changes {@see GlobalRoutingModule} in `src/global/global-routing.module`,
 {@see errorPageRoute} constant in `src/constants/error.ts` must be updated as well to match the new route!!!
 */
export const errorPageRoute = [ '/global', 'error' ] as const;
