import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../../shared/shared.module';
import { AlreadyRegisteredComponent } from './components/already-registered/already-registered.component';
import { MagicLinkExpiredComponent } from './components/magic-link-expired/magic-link-expired.component';
import { MfaSignupComponent } from './components/mfa-signup/mfa-signup.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { GlobalRoutingModule } from './global-routing.module';
import { ErrorComponent } from './components/error/error.component';
import { HelpComponent } from './components/help/help.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { HelpGuard } from './guards/help.guard';
import { MfaGuard } from './guards/mfa.guard';
import { globalFeatureReducer } from './store/global.reducers';
import { GlobalEffects } from './store/global.effects';
import { GlobalService } from './global.service';
import { SystemOutageErrorComponent } from './components/system-outage-error/system-outage-error.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { PasswordUpdatedComponent } from './components/password-updated/password-updated.component';
import { FederatedaccountNotFoundComponent } from './components/federatedaccount-not-found/federatedaccount-not-found.component';
import { FedaccountnotfoundGuard } from './guards/fedaccountnotfound.guard';
import { ExtremlyHighriskUserComponent } from './components/extremly-highrisk-user/extremly-highrisk-user.component';
import { ShortHelpComponent } from './components/short-help/short-help.component';
import { ConfirmationPageShortComponent } from './components/confirmation-page-short/confirmation-page-short.component';
import { ConfirmationMessageComponent } from './components/confirmation-message/confirmation-message.component';

@NgModule({
  declarations: [
    AlreadyRegisteredComponent,
    ConfirmationMessageComponent,
    ConfirmationPageComponent,
    ConfirmationPageShortComponent,
    ErrorComponent,
    ExtremlyHighriskUserComponent,
    FederatedaccountNotFoundComponent,
    ForgetPasswordComponent,
    HelpComponent,
    MagicLinkExpiredComponent,
    MaintenancePageComponent,
    MfaSignupComponent,
    PageNotFoundComponent,
    PasswordUpdatedComponent,
    ResetPasswordComponent,
    SessionExpiredComponent,
    ShortHelpComponent,
    SystemOutageErrorComponent,
    VerifyOtpComponent,
  ],
  imports: [
    GlobalRoutingModule,
    SharedModule,
    StoreModule.forFeature(globalFeatureReducer),
    EffectsModule.forFeature([ GlobalEffects ]),
  ],
  exports: [
    MaintenancePageComponent,
  ],
  providers: [
    FedaccountnotfoundGuard,
    GlobalService,
    HelpGuard,
    MfaGuard,
  ],
})
export class GlobalModule { }
