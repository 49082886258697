import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, Observable, take, tap } from 'rxjs';


import { errorPageRoute } from '../../../constants';
import { ConfigActions } from '../../../core/config/config.actions';
import { selectBenefitId, selectBhPropertiesURL } from '../../../core/config/config.reducers';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { SignupActions } from '../../../core/signup/signup.actions';
import { selectBenefitInfo, selectSelectedEmployer } from '../../../core/signup/signup.reducers';
import { benefitLogo, BhQueryParams, IBenefitDetailsState, IBenefitLogo, ISelectedEmployerState, LocalStorageKey } from '../../../models';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class FedaccountnotfoundGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {}

  public canActivate(): true | UrlTree | Observable<boolean> {
    let clientGuid: string | null | undefined;
    let isClientGuidExists: boolean = false;
    let benefitId: string = '';

    this.store.select(selectSelectedEmployer)
      .pipe(
        take(1),
        combineLatestWith(
          this.store.select(selectBhPropertiesURL),
          this.store.select(selectBenefitId),
        ),
      )
      .subscribe(([ empInfo, params, benefitLogoInfo ]: [ ISelectedEmployerState | null, BhQueryParams, IBenefitLogo ]): void => {
        const guidFromLocalStorage: string | null = this.localStorageService.getItem(LocalStorageKey.clientGuid);

        clientGuid = empInfo?.clientGuid ?? params.clientguid ?? guidFromLocalStorage;
        isClientGuidExists = Boolean(clientGuid);
        benefitId = benefitLogoInfo.id;

        // If benefitId is already in store, save it in local storage
        if (benefitId in benefitLogo) {
          this.localStorageService.saveItem(LocalStorageKey.benefitId, benefitId);
        }
      });

    if (isClientGuidExists as boolean) {
      // if the client guid was retrieved for anywhere - state, localStorage - let's make sure we save it
      // to both the state AND localStorage
      this.store.dispatch(ConfigActions.bhPropertiesUrl({
        bhPropertiesURL: { clientguid: clientGuid },
      }));
      this.localStorageService.saveItem(LocalStorageKey.clientGuid, clientGuid as string);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else {
      return this.router.parseUrl('/signup/search');
    }

    if (clientGuid) {
      this.store.dispatch(SignupActions.getBenefit());

      return this.store.select(selectBenefitInfo)
        .pipe(
          filter((state: IBenefitDetailsState): boolean => state.loaded || state.error !== null),
          take(1),
          tap((state: IBenefitDetailsState): void => {
            if (state.loaded) {
              this.store.dispatch(SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientGuid: state.benefit?.crmClientId as string,
                  clientName: state.benefit?.clientName as string,
                } as ISelectedEmployerState,
              }));
            } else if (state.error) {
              this.router.navigate([ ...errorPageRoute ]);
            }
          }),
          map((state: IBenefitDetailsState): boolean => state.loaded),
        );
    }

    return true;
  }
}


export const fedaccountnotfoundGuard: CanActivateFn = (): true | UrlTree | Observable<boolean> => inject(FedaccountnotfoundGuard).canActivate();
