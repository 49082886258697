/* eslint-disable camelcase */
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { throwIfAlreadyLoaded } from '../utils';
import { ApiAuthService } from './auth/api-auth.service';
import { ApiTokenInterceptor } from './auth/http.interceptor';
import { handleAppInit } from './config/app-init';
import { ConfigEffects } from './config/config.effects';
import { ContentEffects } from './content/content.effects';
import { CredentialsEffects } from './credentials/credentials.effects';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { ServerErrorInterceptor } from './error-handler/server-error.interceptor';
import { SignupEffects } from './signup/signup.effects';
import { CustomSerializer, reducers, metaReducers } from './store/reducer';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LocalStorageService } from './local-storage/local-storage.service';

const globalSettings: RecaptchaSettings = { siteKey: environment.google.reCaptcha.siteKey, size: 'invisible' };

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    HttpClientModule,
    RecaptchaModule,
    RouterModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false, // need to set it to `false` because we need to pass a `File`, which is not serializable (help desk form)
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot([
      ConfigEffects,
      ContentEffects,
      CredentialsEffects,
      SignupEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomSerializer,
    }),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25, name: 'BH Login 2.0' }),
    SharedModule,
    GoogleTagManagerModule.forRoot({
      id: environment.google.gtm.gtmId,
      ...!environment.google.gtm.prodContainer && { gtm_auth: environment.google.gtm.gtmAuth },
      ...!environment.google.gtm.prodContainer && { gtm_preview: environment.google.gtm.gtmPreview },
      ...!environment.google.gtm.prodContainer && { gtm_cookies_win: environment.google.gtm.gtmCookieWin },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: handleAppInit,
      deps: [
        Store,
        ApiAuthService,
        Router,
        LocalStorageService,
      ],
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
    { provide: RECAPTCHA_SETTINGS, useValue: globalSettings },
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
