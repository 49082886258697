import { BenefitId, IConfigState, ISharedState } from './config';

export enum ContentPages {
  employeeSearchPage = 'employeeSearchPage',
  eligibilityPage = 'eligibilityPage',
  magicLinkPage = 'magicLinkPage',
  personalInfoPage = 'personalInfoPage',
  createAccountPage = 'createAccountPage',
  signupConfirmationPage = 'signupConfirmationPage',
  franchiseLocatorPage = 'franchiseLocatorPage',
  fullServiceUniqueIdPage = 'fullServiceUniqueIdPage',
  fullServiceEmployerSelectionPage = 'fullServiceEmployerSelectionPage',
  helpDeskPage = 'helpDeskPage',
  magicLinkExpiredPage = 'magicLinkExpiredPage',
  userAlreadyRegisteredPage = 'userAlreadyRegisteredPage',
  federatedSplitScreenPage = 'federatedSplitScreenPage',
  franchiseConfirmIdentityPage = 'franchiseConfirmIdentityPage',
  signupMFAPage = 'signupMFAPage',
  signupMFAOTPPage = 'signupMFAOTPPage',
  standardLoginPage = 'standardLoginPage',
  forgotUsernamePage = 'forgotUsernamePage',
  resetPasswordPage = 'resetPasswordPage',
  loginMFAPage = 'loginMFAPage',
  passwordUpdatedPage = 'passwordUpdatedPage',
  changePasswordPage = 'changePasswordPage',
  signupOverlayPopupPage = 'signupOverlayPopupPage',
  benefitSelectionPage = 'benefitSelectionPage',
}

export enum ContentPrefix {
  employeeSearchPage = 'BHL_EmployerSearch_*',
  eligibilityPage = 'BHL_Eligibility_*',
  magicLinkPage = 'BHL_MagicLink_*',
  personalInfoPage = 'BHL_AcctCrt1_*',
  createAccountPage = 'BHL_AcctCrt2_*',
  signupConfirmationPage = 'BHL_Confirmation_*',
  franchiseLocatorPage = 'BHL_FranchiseLocation_*',
  fullServiceUniqueIdPage = 'BHL_FSUniqueID_*',
  fullServiceEmployerSelectionPage = 'BHL_FSEmployer_*',
  helpDeskPage = 'BHL_LoggedOutSupportForm*',
  magicLinkExpiredPage = 'BHL_MagicLinkExpired_*',
  userAlreadyRegisteredPage = 'BHL_AcctExist_*',
  federatedSplitScreenPage = 'BHL_SplitScreen_*',
  franchiseConfirmIdentityPage = 'BHL_FranchiseEmployerInfo_*',
  signupMFAPage = 'BHL_MFAContact_*',
  signupMFAOTPPage = 'BHL_MFAOTP_*',
  standardLoginPage = 'BHL_StandardLogin_*',
  forgotUsernamePage = 'BHL_ForgotUN*',
  resetPasswordPage = 'BHL_ResetPW*',
  loginMFAPage = 'BHL_MFALogin_*',
  passwordUpdatedPage = 'BHL_MobilePWReset_*',
  changePasswordPage = 'BHL_ChangePW_*',
  signupOverlayPopupPage = 'BHL_Overlaypopup_*',
  benefitSelectionPage = 'BHL_BenefitSelection_*',
}

export type Content = Record<string, string>;

export interface IContent<T> extends ISharedState {
  content: T;
}

export interface IEmployeeSearchContent {
  BHL_EmployerSearch_Label_PageHeader: string;
  BHL_EmployerSearch_Label_Header: string;
  BHL_EmployerSearch_Label_Employer: string;
  BHL_EmployerSearch_Button_Continue: string;
  BHL_EmployerSearch_Button_Confirm: string;
  BHL_EmployerSearch_Button_Previous: string;
  BHL_EmployerSearch_Field_Placeholder: string;
  BHL_EmployerSearch_Label_Login_Button: string;
  BHL_EmployerSearch_Label_Login: string;
  BHL_EmployerSearch_Label_Questions: string;
  BHL_EmployerSearch_Label_Questions_HelpDesk: string;
  BHL_EmployerSearch_Label_ChangeEmployer: string;
  BHL_EmployerSearch_Link_ChangeEmployer: string;
  BHL_EmployerSearch_Error_NoEmployerSelected: string;
  BHL_EmployerSearch_Error_FakeClient: string;
  BHL_EmployerSearch_Error_ManualClient: string;
  BHL_EmployerSearch_Error_InvalidClientBenefit: string;
}

export interface IMagicLinkContent {
  BHL_MagicLink_Label_Header: string;
  BHL_MagicLink_Label_Content1: string;
  BHL_MagicLink_Label_Content2: string;
  BHL_MagicLink_Label_Link_Again: string;
  BHL_MagicLink_Label_Link_Toast: string;
}

export interface IEligibilityContent {
  BHL_Eligibility_Label_Header: string;
  BHL_Eligibility_Label_Required: string;
  BHL_Eligibility_Label_UniqueIDEmail: string;
  BHL_Eligibility_Label_UniqueIDWorkIDPlaceholder: string;
  BHL_Eligibility_Label_UniqueIDWorkID: string;
  BHL_Eligibility_Label_UniqueIDWorkIDEmail: string;
  BHL_Eligibility_Label_UniqueIDWorkIDLastName: string;
  BHL_Eligibility_Label_UniqueIDAlternateEmail: string;
  BHL_Eligibility_Label_AlternateEmailCheckBox: string;
  BHL_Eligibility_Label_AlternateEmailToolTip: string;
  BHL_Eligibility_Label_Passcode: string;
  BHL_Eligibility_Label_PasscodeContent: string;
  BHL_Eligibility_Label_PasscodePlaceholder: string;
  BHL_Eligibility_Error_PasscodeEmail: string;
  BHL_Eligibility_Error_EmailInvalidFormat: string;
  BHL_Eligibility_Error_EmailExisting: string;
  BHL_Eligibility_Error_AccountNotFound_Title: string;
  BHL_Eligibility_Error_AccountNotFound_Body: string;
  BHL_Eligibility_Error_IncorrectBenefit_Title: string;
  BHL_Eligibility_Error_IncorrectBenefit_Body: string;
  BHL_Eligibility_Error_FederatedAccountNotFound_Body: string;
  BHL_Eligibility_Error_IDInvalidFormat: string;
  BHL_Eligibility_Error_AccountExisting_Title: string;
  BHL_Eligibility_Error_LN_Invalid: string;
  BHL_Eligibility_Error_AccountExisting_Body: string;
  BHL_Eligibility_Error_BlankPasscode: string;
  BHL_Eligibility_Error_InvalidPasscode_Title: string;
  BHL_Eligibility_Error_InvalidPasscode_Body: string;
  BHL_Eligibility_Error_InvalidGeneric: string;
  BHL_Eligibility_Error_InvalidUniqueIDLength: string;
}

export interface IPersonalInfoContent {
  BHL_AcctCrt1_Label_Header: string;
  BHL_AcctCrt1_Label_FN: string;
  BHL_AcctCrt1_Label_FNPlaceholder: string;
  BHL_AcctCrt1_Label_LN: string;
  BHL_AcctCrt1_Label_LNPlaceholder: string;
  BHL_AcctCrt1_Label_Email: string;
  BHL_AcctCrt1_Label_EmailPlaceholder: string;
  BHL_AcctCrt1_Label_CTRY: string;
  BHL_AcctCrt1_Label_CTRYUSA: string;
  BHL_AcctCrt1_Label_CTRYCND: string;
  BHL_AcctCrt1_Label_CTRYIRE: string;
  BHL_AcctCrt1_Label_CTRYUK: string;
  BHL_AcctCrt1_Label_CTRYIND: string;
  BHL_AcctCrt1_Label_CTRYOther: string;
  BHL_AcctCrt1_Label_Zip_Opt1: string;
  BHL_AcctCrt1_Label_Zip_Opt2: string;
  BHL_AcctCrt1_Error_FN: string;
  BHL_AcctCrt1_Error_FN_Invalid: string;
  BHL_AcctCrt1_Error_LN: string;
  BHL_AcctCrt1_Error_LN_Invalid: string;  
  BHL_AcctCrt1_Error_Email: string;
  BHL_AcctCrt1_Error_CTRY: string;
  BHL_AcctCrt1_Error_ZIP: string;
  BHL_AcctCrt1_Error_ZIP_Invalid: string;
  BHL_AcctCrt1_Error_Privacy: string;
  BHL_AcctCrt1_Label_EmailPlaceholder_ShortHelp: string;
}

export interface ICreateAccountContent {
  BHL_AcctCrt2_Label_Header: string;
  BHL_AcctCrt2_Label_SubHeader: string;
  BHL_AcctCrt2_Label_UN: string;
  BHL_AcctCrt2_Label_UNPlaceholder: string;
  BHL_AcctCrt2_Label_PW: string;
  BHL_AcctCrt2_Label_PWPlaceholder: string;
  BHL_AcctCrt2_Label_PW2: string;
  BHL_AcctCrt2_Label_PW2Placeholder: string;
  BHL_AcctCrt2_Error_UNRequired: string;
  BHL_AcctCrt2_Error_ExistUN: string;
  BHL_AcctCrt2_Error_UNAllowed: string;
  BHL_AcctCrt2_Error_PW_Validation1_Tile: string;
  BHL_AcctCrt2_Error_PW_Validation1_Rules: string;
  BHL_AcctCrt2_Error_PW_Validation2_Tile: string;
  BHL_AcctCrt2_Error_PW_Validation2_Rules: string;
  BHL_AcctCrt2_Error_PWConfirm: string;
  BHL_AcctCrt2_Error_WeakPW: string;
  BHL_AcctCrt2_Error_PWRequired: string,
  BHL_AcctCrt2_Error_PWRulesMissing: string,
  BHL_AcctCrt2_Error_PW2Required: string,
  BHL_AcctCrt2_BH_PrivacyTerms: string;
  BHL_AcctCrt2_reCAPTCHA: string;
  BHL_AcctCrt2_reCAPTCHA_Error_Toast: string;
}

export interface IPageContentPayload {
  keys: Array<ContentPrefix>;
  culture: IConfigState['locale'];
  clientGuid: string | null;
  benefitId: BenefitId;
}

export interface ISignupConfirmationContent {
  BHL_Confirmation_Label_Header: string;
  BHL_Confirmation_Label_Content1: string;
}

export interface ISignupOverlayContent {
  BHL_Overlaypopup_Label_Header: string;
  BHL_Overlaypopup_Label_Content1: string;
}

export interface IFranchiseLocatorContent {
  BHL_FranchiseLocation_Label_Header: string;
  BHL_FranchiseLocation_Label_Content1: string;
  BHL_FranchiseLocation_Label_ZipCityState: string;
  BHL_FranchiseLocation_Label_ZipCityState_Placeholder: string;
  BHL_FranchiseLocation_Error_IncorrectFormat: string;
  BHL_FranchiseLocation_Error_MissZIP: string;
  BHL_FranchiseLocation_Error_NoLocation_Title: string;
  BHL_FranchiseLocation_Error_NoLocation_Body: string;
  BHL_FranchiseLocation_Error_LocationNotFound_Title: string;
  BHL_FranchiseLocation_Error_LocationNotFound_Body: string;
}

export interface IFullServiceUniqueIdContent {
  BHL_FSUniqueID_Label_Header: string;
  BHL_FSUniqueID_Label_Content1: string;
  BHL_FSUniqueID_Label_WorkEmail: string;
  BHL_FSUniqueID_Label_NoEmail: string;
  BHL_FSUniqueID_Label_Content2: string;
  BHL_FSUniqueID_Label_WorkID: string;
  BHL_FSUniqueID_Label_NoID: string;
  BHL_FSUniqueID_Error_Email: string;
}
export interface IFullServiceEmployerSelectionContent {
  BHL_FSEmployer_Label_Header: string;
  BHL_FSEmployer_Label_Employed: string;
  BHL_FSEmployer_Label_Employed_Description: string,
  BHL_FSEmployer_Label_NOEmployed: string;
  BHL_FSEmployer_Error_Employed: string;
}

export interface IHelpDeskContent {
  BHL_LoggedOutSupportForm_Label_Header: string;
  BHL_LoggedOutSupportFormFIC_Label_Content1: string;
  BHL_LoggedOutSupportForm_Label_Content: string;
  BHL_LoggedOutSupportForm_Label_Content3: string;
  BHL_LoggedOutSupportForm_Label_Help: string;
  BHL_LoggedOutSupportForm_Label_Services: string;
  BHL_LoggedOutSupportForm_Label_Emp: string;
  BHL_LoggedOutSupportForm_Label_FN: string;
  BHL_LoggedOutSupportForm_Label_FNPlaceholder: string;
  BHL_LoggedOutSupportForm_Label_LN: string;
  BHL_LoggedOutSupportForm_Label_LNPlaceholder: string;
  BHL_LoggedOutSupportForm_Label_Email: string;
  BHL_LoggedOutSupportForm_Label_Phone: string;
  BHL_LoggedOutSupportForm_Label_EmpID: string;
  BHL_LoggedOutSupportFormFIC_Label_Center: string;
  BHL_LoggedOutSupportForm_Label_City: string;
  BHL_LoggedOutSupportForm_Label_State: string;
  BHL_LoggedOutSupportForm_Label_Help2: string;
  BHL_LoggedOutSupportForm_Label_Content2: string;
  BHL_LoggedOutSupportForm_Label_ServicesB: string;
  BHL_LoggedOutSupportForm_Label_ServicesBH: string;
  BHL_LoggedOutSupportForm_Label_ServicesBHC: string;
  BHL_LoggedOutSupportForm_Label_ServicesChild: string;
  BHL_LoggedOutSupportForm_Label_ServicesCC: string;
  BHL_LoggedOutSupportForm_Label_ServicesEdA: string;
  BHL_LoggedOutSupportForm_Label_ServicesFIC: string;
  BHL_LoggedOutSupportForm_Label_ServicesMBD: string;
  BHL_LoggedOutSupportForm_Label_ServicesBenefits: string;
  BHL_LoggedOutSupportForm_Label_ServicesSitter: string;
  BHL_LoggedOutSupportForm_Label_ServicesWFS: string;
  BHL_LoggedOutSupportForm_Label_ServicesOther: string;
  BHL_LoggedOutSupportForm_Label_HelpLogin: string;
  BHL_LoggedOutSupportForm_Label_HelpReg: string;
  BHL_LoggedOutSupportForm_Label_HelpEmp: string;
  BHL_LoggedOutSupportForm_Label_HelpTech: string;
  BHL_LoggedOutSupportForm_Error_Help: string;
  BHL_LoggedOutSupportForm_Error_Services: string;
  BHL_LoggedOutSupportForm_Error_Emp: string;
  BHL_LoggedOutSupportForm_Error_FN: string;
  BHL_LoggedOutSupportForm_Error_FN_Invalid: string;
  BHL_LoggedOutSupportForm_Error_LN: string;
  BHL_LoggedOutSupportForm_Error_LN_Invalid: string;  
  BHL_LoggedOutSupportForm_Error_Email: string;
  BHL_LoggedOutSupportForm_Error_Phone: string;
  BHL_LoggedOutSupportForm_Error_Help2: string;
  BHL_LoggedOutSupportFormFIC_Error_Center: string;
  BHL_LoggedOutSupportFormConfirm_Label_Header: string;
  BHL_LoggedOutSupportFormConfirm_Label_Content1: string;
  BHL_LoggedOutSupportForm_Label_EmpoyerPlaceholder: string;
  BHL_LoggedOutSupportForm_Label_PhonePlaceholder: string;
  BHL_LoggedOutSupportForm_Button_Send: string;
  BHL_LoggedOutSupportForm_Button_Remove: string;
  BHL_LoggedOutSupportForm_Button_Attach: string;
  BHL_LoggedOutSupportForm_Privacy: string;
  BHL_LoggedOutSupportForm_Label_Header_ShortHelp: string;
  BHL_LoggedOutSupportFormFIC_Label_Content1_ShortHelp: string;
  BHL_LoggedOutSupportForm_Label_Help2_ShortHelp: string;
  BHL_LoggedOutSupportForm_Label_checkbox_ShortHelp: string;
  BHL_LoggedOutSupportFormConfirm_Label_Header_ShortHelp: string;
  BHL_LoggedOutSupportFormConfirm_Label_Content1_ShortHelp: string;
}
export interface IMagicLinkExpiredContent {
  BHL_MagicLinkExpired_Label_Header: string;
  BHL_MagicLinkExpired_Label_Content1: string;
  BHL_MagicLinkExpired_Button_Resend: string;
  BHL_MagicLinkExpired_Label_Link_Toast: string;
}

export interface IUserAlreadyRegisteredContent {
  BHL_AcctExist_Header: string;
  BHL_AcctExist_Header_SSO_OSR: string;
  BHL_AcctExist_Content: string;
  BHL_AcctExist_Content_SSO_OSR: string;
  BHL_AcctExist_Button_Login: string;
}

export interface IFederatedSplitScreenContent {
  BHL_SplitScreen_Label_Header: string,
  BHL_SplitScreen_Label_Content: string,
  BHL_SplitScreen_Button_EmpLogin: string,
  BHL_SplitScreen_Button_NonEmpLogin: string,
  BHL_SplitScreen_Label_Disclaimer: string,
}

export interface IFranchiseConfirmIdentityContent {
  BHL_FranchiseEmployerInfo_Label_Header: string;
  BHL_FranchiseEmployerInfo_Label_Content1: string;
  BHL_FranchiseEmployerInfo_Label_DOB: string;
  BHL_FranchiseEmployerInfo_Label_SSN: string;
  BHL_FranchiseEmployerInfo_Label_Start: string;
  BHL_FranchiseEmployerInfo_Label_Position: string;
  BHL_FranchiseEmployerInfo_Label_Hours: string;
  BHL_FranchiseEmployerInfo_Label_Gender: string;
  BHL_FranchiseEmployerInfo_Label_Ethnicity: string;
  BHL_FranchiseEmployerInfo_Label_PositionCrew: string;
  BHL_FranchiseEmployerInfo_Label_PositioncrewT: string;
  BHL_FranchiseEmployerInfo_Label_PositionFloor: string;
  BHL_FranchiseEmployerInfo_Label_PositionPrimMain: string;
  BHL_FranchiseEmployerInfo_Label_PositionBackMain: string;
  BHL_FranchiseEmployerInfo_Label_PositionTrainee: string;
  BHL_FranchiseEmployerInfo_Label_PositionGenMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionResMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionAsstMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionFAsstMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionSAsstMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionDepMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionSwing: string;
  BHL_FranchiseEmployerInfo_Label_PositionShiftMan: string;
  BHL_FranchiseEmployerInfo_Label_PositionOffice: string;
  BHL_FranchiseEmployerInfo_Label_HoursLess: string;
  BHL_FranchiseEmployerInfo_Label_Hours15: string;
  BHL_FranchiseEmployerInfo_Label_Hours30: string;
  BHL_FranchiseEmployerInfo_Label_GenderM: string;
  BHL_FranchiseEmployerInfo_Label_GenderF: string;
  BHL_FranchiseEmployerInfo_Label_GenderU: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 1']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 2']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 3']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 4']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 5']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 6']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 7']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 8']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 9']: string;
  ['BHL_FranchiseEmployerInfo_Label_Ethinicity 10']: string;
  BHL_FranchiseEmployerInfo_Error_DOB: string;
  BHL_FranchiseEmployerInfo_Error_FutureDOB: string,
  BHL_FranchiseEmployerInfo_Error_Before1900DOB: string;
  BHL_FranchiseEmployerInfo_Error_SSN: string;
  BHL_FranchiseEmployerInfo_Error_SSN_Pattern: string;
  BHL_FranchiseEmployerInfo_Error_Start: string;
  BHL_FranchiseEmployerInfo_Error_Before1900Start: string;
  BHL_FranchiseEmployerInfo_Error_Position: string;
  BHL_FranchiseEmployerInfo_Error_Hours: string;
  BHL_FranchiseEmployerInfo_Error_Phone: string;
  BHL_FranchiseEmployerInfo_Error_ExistingAccount_Title: string;
  BHL_FranchiseEmployerInfo_Error_ExistingAccount_Body: string;

  // Allow dynamic lookup to map an Account Question to relevant content
  [key: string]: string;
}
export interface ISignupMFAContent {
  BHL_MFAContact_Error_MissPhone: string,
  BHL_MFAContact_Error_MissCTRY: string,
  BHL_MFAContact_Error_InvalidEmail: string,
  BHL_MFAContact_Error_Email: string,
  BHL_MFAContact_Error_PhoneCount: string,
  BHL_MFAContact_Label_CTRY: string,
  BHL_MFAContact_Label_CTRYCND: string,
  BHL_MFAContact_Label_CTRYIND: string,
  BHL_MFAContact_Label_CTRYIRE: string,
  BHL_MFAContact_Label_CTRYOther: string,
  BHL_MFAContact_Label_CTRYUK: string,
  BHL_MFAContact_Label_CTRYUSA: string,
  BHL_MFAContact_Label_Content1: string,
  BHL_MFAContact_Label_Email: string,
  BHL_MFAContact_Label_Header: string,
  BHL_MFAContact_Label_Mobile: string,
  BHL_MFAContact_Label_EmailPlaceholder: string,
  BHL_MFAContact_Label_NumberPlaceholder: string,
  BHL_MFAContact_Label_AddEmail: string,
  BHL_MFAContact_Label_AddNumber: string,
  BHL_MFAContact_Label_SkipNow: string,
  BHL_MFAContact_Label_RemoveEmail: string,
  BHL_MFAContact_Label_RemoveNumber: string
}

export interface IStandardLoginPageContent {
  BHL_StandardLogin_Label_Header: string,
  BHL_StandardLogin_Label_UN: string,
  BHL_StandardLogin_Label_PW: string,
  BHL_StandardLogin_Error_UNRequired: string,
  BHL_StandardLogin_Error_AccountLocked_Title: string,
  BHL_StandardLogin_Error_AccountLocked: string,
  BHL_StandardLogin_Error_AccountNewLocked: string,
  BHL_StandardLogin_Error_AccountNotFound_Title: string,
  BHL_StandardLogin_Error_AccountNotFound: string,
  BHL_StandardLogin_Error_AccountNewNotFound_Title: string,
  BHL_StandardLogin_Error_AccountNewNotFound: string,
  BHL_StandardLogin_Label_RememberMe: string,
  BHL_StandardLogin_Label_ContinueButton: string,
}

/**
 * Interface to represent the url to the selected client's logo.
 */
export interface IClientLogoUrl extends ISharedState {
  path: string | null;
}

export interface ISignupMFAOTPContent {
  BHL_MFAOTP_Error_MissOTP: string,
  BHL_MFAOTP_Error_IncorrectOTP_Title: string,
  BHL_MFAOTP_Error_IncorrectOTP_Body: string,
  BHL_MFAOTP_Label_Content1: string,
  BHL_MFAOTP_Label_Content2: string,
  BHL_MFAOTP_Label_Content3: string,
  BHL_MFAOTP_Label_Content4: string,
  BHL_MFAOTP_Label_Content5: string,
  BHL_MFAOTP_Label_Header: string,
  BHL_MFAOTP_Label_OTP: string,
  BHL_MFAOTP_Label_Content6: string
}

export interface IForgotUsernameContent {
  BHL_ForgotUN_Error_Email: string,
  BHL_ForgotUN_Label_Email: string,
  BHL_ForgotUN_Header2: string,
  BHL_ForgotUN_Header: string,
  BHL_ForgotUNConfirm_Header2: string,
  BHL_ForgotUNConfirm_Header: string,
  BHL_ForgotUN_Placeholder_Email: string,
  BHL_ForgotUN_Button_Send: string,
  BHL_ForgotUNConfirm_NewHeader: string,
  BHL_ForgotUNConfirm_NewHeader2: string,
}

export interface IResetPasswordContent {
  BHL_ResetPW_Label_Email: string,
  BHL_ResetPW_Header2: string,
  BHL_ResetPW_Header: string,
  BHL_ResetPWConfirm_Header2: string,
  BHL_ResetPWConfirm_Header: string,
  BHL_ResetPW_Button_Send: string,
  BHL_ResetPWConfirm_NewHeader: string,
  BHL_ResetPWConfirm_NewHeader2: string,
}
export interface ILoginMFAContent {
  BHL_MFALogin_Label_Email: string,
  BHL_MFALogin_Label_Mobile: string,
  BHL_MFALogin_Label_Header: string,
  BHL_MFALogin_Label_Header2: string,
  BHL_MFALogin_Error_ExtremeRiskError_Title: string,
  BHL_MFALogin_Error_ExtremeRiskError_Desc: string,
}

export interface IPassswordUpdatedContent {
  BHL_MobilePWReset_MBD_Header: string,
  BHL_MobilePWReset_MBD_Body: string,
}

export interface IChangePassswordContent {
  BHL_ChangePW_Header: string,
  BHL_ChangePW_CurrentPW: string,
  BHL_ChangePW_PHText_CurrentPW: string,
  BHL_ChangePW_NewPW: string,
  BHL_ChangePW_PHText_NewPW: string,
  BHL_ChangePW_ConfirmPW: string,
  BHL_ChangePW_PHText_ConfirmPW: string,
  BHL_ChangePW_UpdateButton: string,
  BHL_ChangePW_ErrorUsedPW: string,
  BHL_ChangePW_ErrorStrength: string,
}

export interface IBenefitSelectionContent {
  BHL_BenefitSelection_Label_PageHeader: string,
  BHL_BenefitSelection_Header: string,
  BHL_BenefitSelection_BackUpCare_Header: string,
  BHL_BenefitSelection_BackUpCare_Summary: string,
  BHL_BenefitSelection_CollegeCoach_Header: string,
  BHL_BenefitSelection_CollegeCoach_Summary: string,
  BHL_BenefitSelection_EdAssist_Header: string,
  BHL_BenefitSelection_EdAssist_Summary: string,
}

export interface IContentState {
  [ContentPages.employeeSearchPage]: IContent<IEmployeeSearchContent>;
  [ContentPages.eligibilityPage]: IContent<IEligibilityContent>;
  [ContentPages.magicLinkPage]: IContent<IMagicLinkContent>;
  [ContentPages.personalInfoPage]: IContent<IPersonalInfoContent>;
  [ContentPages.createAccountPage]: IContent<ICreateAccountContent>;
  [ContentPages.signupConfirmationPage]: IContent<ISignupConfirmationContent>;
  [ContentPages.helpDeskPage]: IContent<IHelpDeskContent>;
  [ContentPages.franchiseLocatorPage]: IContent<IFranchiseLocatorContent>;
  [ContentPages.fullServiceUniqueIdPage]: IContent<IFullServiceUniqueIdContent>;
  [ContentPages.fullServiceEmployerSelectionPage]: IContent<IFullServiceEmployerSelectionContent>;
  [ContentPages.magicLinkExpiredPage]: IContent<IMagicLinkExpiredContent>;
  [ContentPages.userAlreadyRegisteredPage]: IContent<IUserAlreadyRegisteredContent>;
  [ContentPages.federatedSplitScreenPage]: IContent<IFederatedSplitScreenContent>;
  [ContentPages.franchiseConfirmIdentityPage]: IContent<IFranchiseConfirmIdentityContent>;
  [ContentPages.passwordUpdatedPage]: IContent<IPassswordUpdatedContent>;
  [ContentPages.signupMFAPage]: IContent<ISignupMFAContent>;
  logoUrl: IClientLogoUrl;
  [ContentPages.signupMFAOTPPage]: IContent<ISignupMFAOTPContent>;
  [ContentPages.standardLoginPage]: IContent<IStandardLoginPageContent>;
  [ContentPages.forgotUsernamePage]: IContent<IForgotUsernameContent>;
  [ContentPages.resetPasswordPage]: IContent<IResetPasswordContent>;
  [ContentPages.loginMFAPage]: IContent<ILoginMFAContent>;
  [ContentPages.changePasswordPage]: IContent<IChangePassswordContent>;
  [ContentPages.signupOverlayPopupPage]: IContent<ISignupOverlayContent>;
  [ContentPages.benefitSelectionPage]: IContent<IBenefitSelectionContent>;
}
