import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-field-message',
  templateUrl: './field-message.component.html',
  styleUrls: [ './field-message.component.scss' ],
})
export class FieldMessageComponent {
  /**
   * A flag to determine whether to show success (checkmark) or failure (x) icon
   * in front of the {@see message}. Defaults to false (failure/x icon).
   */
  @Input() public success: boolean = false;

  /**
   * The text to display to the end user.
   */
  @Input() public message: string | undefined;
}
