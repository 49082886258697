<ng-container *ngIf="maintenance; else mainContent">
<bh-maintenance-page></bh-maintenance-page>
</ng-container>

<ng-template #mainContent>
  <bh-header></bh-header>
  <main>
    <article>
      <router-outlet></router-outlet>
    </article>
  </main>
  <bh-footer></bh-footer>
  <div *ngIf="showSpinner | async" class="overlay"></div>
  <mat-spinner [diameter]="spinnerDiameter" *ngIf="showSpinner | async"></mat-spinner>
</ng-template>

