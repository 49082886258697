<ng-container *ngIf="(accountContent | async)?.loaded">
    <article
      *ngIf="{ account: (accountContent | async)?.content, required: (requiredContent | async)?.content } as accountContent"
      class="mat-typography">
      <img
        [src]="'assets/exclamation.svg'"
        [alt]="''"
      />
      <h1 class="bh-heading bh-heading--1">{{ accountContent.required?.BHL_Eligibility_Error_AccountNotFound_Title }}</h1>
      <p [innerHTML]="eligibilityFederatedAccountNotFoundbody"></p>
      <bh-already-have-account 
        [content]="accountContent.account"
        [showLogin]="false">
      </bh-already-have-account>
    </article>
  
</ng-container>
  