import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Store } from '@ngrx/store';
import { selectBhPropertiesURL } from '../core/config/config.reducers';
import { selectEmployeeDetails, selectSelectedEmployer } from '../core/signup/signup.reducers';
import {
  Subject,
  combineLatestWith,
  takeUntil,
} from 'rxjs';
import {
  BenefitName,
  BhQueryParams,
  IEmployeeDetailsState,
  IPersonInfo,
  ISelectedEmployerState,
  IValidateUserStatus,
} from '../models';
import { selectValidateUserStatus } from '../core/credentials/credentials.selectors';
import { selectPersonInfoStatus } from '../core/signup/signup.selectors';
import { PendoService } from '../core/pendo/pendo.service';
import { environment } from 'src/environments/environment';
import * as FullStory from '@fullstory/browser';
import { ApiAuthService } from '../core/auth/api-auth.service';
declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

// Declare Optanon as a global variable
declare var Optanon: any;
interface CustomWindow extends Window {
  OnetrustActiveGroups?: string[];
}
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  /**
  * Subject to pass to the RxJS `takeUntil` to properly unsubscribe when we destroy
  * the component.
  * @private
  */
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private readonly store: Store,
    private readonly pendoService: PendoService,
    private readonly authService: ApiAuthService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || [];
  }
  public GA4pushEvent(event: string, eventData: Record<string, any>): void {
    const data = { event, ...eventData };
    window.dataLayer.push(data);
  }

  public getBenefitNameByBenefitId(benefitId: string): BenefitName {
    switch (benefitId) {
      case '0':
        return BenefitName.Default;
      case '-1':
        return BenefitName.MyBH;
      case '1':
        return BenefitName.CollegeCoach;
      case '2':
        return BenefitName.EdAssist;
      case '3':
        return BenefitName.BUC;
      case '4':
        return BenefitName.Community;
      case '5':
        return BenefitName.FIC;
      case '6':
        return BenefitName.SLP;
      case '7':
        return BenefitName.TPM;
      case '8':
        return BenefitName.OW;
      case '9':
        return BenefitName.Crisiscare;
      case '10':
        return BenefitName.CCSS;
      case '11':
        return BenefitName.WFS;
      case '12':
        return BenefitName.WFS_IRL;
      case '13':
        return BenefitName.ElderCare;
      case '14':
        return BenefitName.SpecialNeeds;
      case '15':
        return BenefitName.Thrive;
      case '16':
        return BenefitName.SC;
      case '17':
        return BenefitName.CCR;
      case '18':
        return BenefitName.EFS;
      case '19':
        return BenefitName.EPTB;
      case '20':
        return BenefitName.GPS;
      case '21':
        return BenefitName.SFBH;
      default:
        return BenefitName.Default;
    }
  }
  // eslint-disable-next-line max-params
  public exploreBenefits(url: string, accessToken: string, userInfoId: string, flowType: string, userName: string, isOTPEnabled: string): void {
    const myform = this.document.createElement('form');
    myform.method = 'POST';
    myform.action = url.toString();

    const mapInput = this.document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'accessToken';
    mapInput.value = accessToken;
    myform.appendChild(mapInput);

    const mapInput1 = this.document.createElement('input');
    mapInput1.type = 'hidden';
    mapInput1.name = 'userInfoId';
    mapInput1.value = userInfoId;
    myform.appendChild(mapInput1);

    const mapInput2 = this.document.createElement('input');
    mapInput2.type = 'hidden';
    mapInput2.name = 'flowType';
    mapInput2.value = flowType;
    myform.appendChild(mapInput2);

    if (isOTPEnabled === 'true') {
      const mapInput3 = this.document.createElement('input');
      mapInput3.type = 'hidden';
      mapInput3.name = 'encryptUn';
      const encryptUN = `${userName}//${new Date(Date.now() + (environment.userNameExpiry * 60000)).toISOString()}`;
      const encJsonUser = this.authService.encrypt(JSON.stringify(encryptUN));
      mapInput3.value = encJsonUser;
      myform.appendChild(mapInput3);

      const mapInput4 = this.document.createElement('input');
      mapInput4.name = 'isOTPEnabled';
      mapInput4.value = isOTPEnabled;
      myform.appendChild(mapInput4);
    }


    this.document.body.appendChild(myform);
    myform.submit();
  }

  /**
  * Pendo Tracking
  */
  public TrackPendoEvent(eventName: string, benefitId: string, eventCategory: string, trackDescription?: string): void {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(
          this.store.select(selectSelectedEmployer),
          this.store.select(selectEmployeeDetails),
          this.store.select(selectValidateUserStatus),
          this.store.select(selectPersonInfoStatus),
        ),
      )
      // eslint-disable-next-line max-len
      .subscribe(([ params, employer, employee, loggedInUser, personalInfo ]: [BhQueryParams, ISelectedEmployerState | null, IEmployeeDetailsState | null, IValidateUserStatus | null, IPersonInfo | null]): void => {
        this.pendoService.TrackEvent({
          eventName,
          eventCategory,
          trackDescription,
          benefitId,
          params,
          ...employer !== null && { employer },
          ...employee !== null && { employee },
          ...loggedInUser !== null && { loggedInUser },
          ...personalInfo !== null && { personalInfo },
        });
      });
  }

  /**
  * fullstory Log
  */
  public logFullStory(uId?: string, displayName?: string): void {
    let hasC0002PerfCookieSelected: boolean = false;
    const defaultView = window as CustomWindow;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (defaultView && defaultView.OnetrustActiveGroups) {
      hasC0002PerfCookieSelected = defaultView.OnetrustActiveGroups.includes('C0002');
    }

    if (hasC0002PerfCookieSelected && environment.fullstoryEnable && (typeof uId !== 'undefined' && uId)) {
      if (!FullStory.isInitialized()) {
        FullStory.init({ orgId: environment.fullStoryOrgId });
      }

      if (FullStory.isInitialized()) {
        FullStory.identify(uId, {
          displayName: typeof displayName !== 'undefined' && displayName ? displayName : uId,
        });
      }
    }
  }

  /**
  * fullstory Log
  */
  public loginlogFullStory(uId?: string, name?: string, clientGuid?: string, clientName?: string, country?: string): void {
    let hasC0002PerfCookieSelected: boolean = false;
    const defaultView = window as CustomWindow;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (defaultView && defaultView.OnetrustActiveGroups) {
      hasC0002PerfCookieSelected = defaultView.OnetrustActiveGroups.includes('C0002');
    }
    if (hasC0002PerfCookieSelected && environment.fullstoryEnable && uId) {
      if (!FullStory.isInitialized()) {
        FullStory.init({ orgId: environment.fullStoryOrgId });
      }

      if (FullStory.isInitialized()) {
        FullStory.identify(uId, {
          displayName: name,
          UserUniqueID: uId,
          UserFullName: name,
          ClientUniqueID: clientGuid,
          ClientName: clientName,
          HomeCountry: country,
        });
      }
    }
  }
}
