import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatestWith, take, takeUntil } from 'rxjs';
import { BhQueryParams, ContentPages, EventName, IAppJwt, IContentState, ICreateRegistrationStatus, LocalStorageKey, RegistrationSubCategory, SourceId } from 'src/app/models';
import { selectEmployeeSearchPage, selectSignupOverlayPopupPage } from 'src/app/core/content/content.reducers';
import { ContentActions } from 'src/app/core/content/content.actions';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { ConfigService } from 'src/app/core/config/config.service';
import { SharedService } from '../../shared.service';
import { selectApiToken, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { selectCreateRegistrationDetails } from 'src/app/core/signup/signup.selectors';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-overlay-popup',
  templateUrl: './overlay-popup.component.html',
  styleUrls: [ './overlay-popup.component.scss' ],
})
export class OverlayPopupComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the page content.
   */
  public readonly content: Observable<IContentState[ContentPages.signupOverlayPopupPage]> = this.store.select(selectSignupOverlayPopupPage);

  /**
   * A property to hold the built URL that includes any query params we need to pass along.
   */
  // public benefitsUrl: URL;

  // eslint-disable-next-line max-params
  constructor(
    public dialogRef: MatDialogRef<OverlayPopupComponent>,
    private readonly store: Store,
    private readonly config: ConfigService,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedService: SharedService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialog: MatDialog,
    private readonly elRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    // fetch page content
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.signupOverlayPopupPage }));
  }

  public ngOnInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.handleOutsideClick();
      }
    });
  }
  public handleOutsideClick(): void {
    const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
    this.sharedService.TrackPendoEvent(EventName.REGISTRATION,
      benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_CLICKED_OUT);
  }


  // public ngOnInit(): void {

  //   this.dialogRef.afterClosed().subscribe((result) => {
  //     if (!result) {
  //       // User clicked outside the dialog
  //       // eslint-disable-next-line no-alert
  //       alert('Clicked out of  box');
  //       this.handleOutsideClick();
  //     }
  //   });
  // }

  // public handleOutsideClick(): void {
  //   // Add your logic here to handle the outside click
  //   // eslint-disable-next-line no-alert
  //   alert('testing outside click');
  //   const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
  //   this.sharedService.TrackPendoEvent(RegistrationSubCategory.OVERLAY_CLICKED_OUT, benefitIdFromLocalStorage as string, EventName.REGISTRATION);
  // }

  // build the benefits URL
  public buildBenefitsURL(): void {
    this.store.select(selectApiToken)
      .pipe(
        combineLatestWith(
          this.store.select(selectCreateRegistrationDetails),
          this.store.select(selectBhPropertiesURL),
        ),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ apiToken, userInfo, params ]: [IAppJwt | null, ICreateRegistrationStatus | null, BhQueryParams]): void => {
        const sourceId: string | undefined = params.sourceid?.toLowerCase();
        const goToLogin: '' | null | undefined | boolean = sourceId && (sourceId !== SourceId['fic-registration']);
        const url: URL = this.config.buildExternalUrl(goToLogin ? undefined : environment.exploreBenefitsUrl);
        if (userInfo) {
          this.sharedService.loginlogFullStory(userInfo.workEmail,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            userInfo.firstName as string + userInfo.lastName,
            userInfo.clientGuid, '', userInfo.country as string);
        }

        const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
        this.sharedService.TrackPendoEvent(EventName.REGISTRATION,
          benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_CONTINUE_BTN_CLICK);
        if (goToLogin) {
          this.document.location.href = url.href;
        } else {
          this.sharedService.exploreBenefits(url.toString(), apiToken?.userAccessToken ?? apiToken?.token as string,
            userInfo?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string, '', '', 'false');
        }
      });

    // Clear localStorage, since there's no longer any need for it
    const clientGuid = this.localStorageService.getItem(LocalStorageKey.clientGuid) as string;
    this.localStorageService.clearAll();
    this.localStorageService.saveItem(LocalStorageKey.clientGuid,clientGuid );
    this.dialog.closeAll();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
