import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-page-level-error',
  templateUrl: './page-level-error.component.html',
  styleUrls: [ './page-level-error.component.scss' ],
})
export class PageLevelErrorComponent {
  /**
   * Unique id for the rendered element.
   * This id should be referenced in the `aria-describedby` attribute on the relevant form field.
   */
  @Input() public id: string;
  @Input() public errorHeader: string = 'Error';
  @Input() public errorBody: Array<string> = [ 'An error occurred.' ];

  /**
   * A track by function for the ngFor loop to optimize performance.
   * @param {number} index The index of the current element.
   * @returns {number} The unique identifier for this element.
   */
  public trackByFn(index: number): number {
    return index;
  }
}
