import { convertDateToYYYYMMDD } from '../utils';

/**
 * Helper function to convert questionType from Account Questions API into a more useful format
 * @param {number} typeNum the number to map to its question type
 * @returns {string} the question type framed in terms of what type of form field it requires
 */
export const getQuestionType = (typeNum: number): string => {
  switch (typeNum) {
    case 881840001:
      return 'select';
    case 881840003:
      return 'date';
    case 881840000:
    case 881840002:
    case 881840004:
    default:
      return 'text';
  }
};

export interface IFranchiseAccountQuestion {
  accountQuestionId: number;
  clientId: number;
  displayName: string;
  questionType: number;
  questionOptions: Record<string, string> | null;
  eligibilityField: string;
  isRequired: boolean;
  isHashingRequired: boolean;
  maxLength: number | null;
  regularExpression: string | null;
  customValidation: null; // ???
  sequence: number;
  crmclientId: string;
  isDob: boolean;
  isSsn: boolean;
  isEditable: boolean;
}

export interface IEditFranchiseAccountQuestion extends IFranchiseAccountQuestion {
  isEditRegistration: true;
  value: string | null;
}

export interface IFranchiseAccountAnswer {
  displayName: string;
  eligibilityField: string;
  isHashingRequired: boolean;
  value: string;
  isDob: boolean;
  isSsn: boolean;
  isEditable: boolean;
  isRequired: boolean;
}

/**
 * Maps a franchise account question object to an "answer" object
 * @param {IFranchiseAccountQuestion} question the question object (i.e., from the Account Questions API)
 * @param {string | Date} value the user-provided "answer" to the question
 * @returns {IFranchiseAccountAnswer} an object in the format expected by the Create Registration API
 */
export const mapFranchiseAccountQuestionToAnswer = (
  question: IFranchiseAccountQuestion,
  value: string | Date,
): IFranchiseAccountAnswer => ({
  displayName: question.displayName,
  eligibilityField: question.eligibilityField,
  isHashingRequired: question.isHashingRequired,
  isDob: question.isDob,
  isSsn: question.isSsn,
  isEditable: question.isEditable,
  isRequired: question.isRequired,
  value: value instanceof Date ? convertDateToYYYYMMDD(value) : value,
});

interface IErrorMessageContentKeyMap {
  required?: string;
  pattern?: string;
}

export enum FranchiseAccountQuestionEligibilityField {
  BH_DOB = 'BH_DOB',
  BH_SSN = 'BH_SSN',
  BH_Registration1 = 'BH_Registration1',
  BH_Registration2 = 'BH_Registration2',
  BH_Registration3 = 'BH_Registration3',
  BH_Registration6 = 'BH_Registration6',
}

/**
 * A map to translate a question's eligibilityField (from AccountQuestions API)
 * into a content key that points to the corresponding error message (from Content API)
 */
export const franchiseAccountQuestionErrorMap: Map<FranchiseAccountQuestionEligibilityField, IErrorMessageContentKeyMap> =
  new Map<FranchiseAccountQuestionEligibilityField, IErrorMessageContentKeyMap>(
    [
      [
        FranchiseAccountQuestionEligibilityField.BH_DOB,
        {
          required: 'BHL_FranchiseEmployerInfo_Error_DOB',
          pattern: 'BHL_FranchiseEmployerInfo_Error_FutureDOB',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_DOB,
        {
          pattern: 'BHL_FranchiseEmployerInfo_Error_Before1900DOB',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_SSN,
        {
          required: 'BHL_FranchiseEmployerInfo_Error_SSN',
          pattern: 'BHL_FranchiseEmployerInfo_Error_SSN_Pattern',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_Registration1,
        {
          required: 'BHL_FranchiseEmployerInfo_Error_Start',
          pattern: 'BHL_FranchiseEmployerInfo_Error_Before1900Start',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_Registration2,
        {
          required: 'BHL_FranchiseEmployerInfo_Error_Position',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_Registration3,
        {
          required: 'BHL_FranchiseEmployerInfo_Error_Hours',
        },
      ],
      [
        FranchiseAccountQuestionEligibilityField.BH_Registration6,
        {
          pattern: 'BHL_FranchiseEmployerInfo_Error_Phone',
        },
      ],
    ],
  );
