import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  HttpError, IEmployer, IHelpDeskPayload, IHelpDeskStatus, IMasterListStatus,
} from '../../../models';

export const GlobalActions = createActionGroup({
  'source': 'Global',
  'events': {
    'Load Employers': props<{ input: string }>(),
    'Load Employers Success': props<{ list: Array<IEmployer> }>(),
    'Load Employers Failure': props<{ error: string | HttpError }>(),
    'Clear Employers': emptyProps(),

    'Load Support Helps': emptyProps(),
    'Load Support Helps Success': props<{ list: Array<IMasterListStatus> }>(),
    'Load Support Helps Failure': props<{ error: string | HttpError }>(),
    'Clear Support Helps': emptyProps(),

    'Load Support Services': emptyProps(),
    'Load Support Services Success': props<{ list: Array<IMasterListStatus> }>(),
    'Load Support Services Failure': props<{ error: string | HttpError }>(),
    'Clear Support Services': emptyProps(),

    'Help Desk Support': props<{ payload: IHelpDeskPayload }>(),
    'Help Desk Support Success': props<{ helpDeskSupportStatus: IHelpDeskStatus }>(),
    'Help Desk Support Failure': props<{ error: string | HttpError }>(),

    'Load State': emptyProps(),
    'Load State Success': props<{ list: Array<IMasterListStatus> }>(),
    'Load State Failure': props<{ error: string | HttpError }>(),
    'Clear State': emptyProps(),
  },
});
