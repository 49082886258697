import { Injectable } from '@angular/core';
import { LocalStorageKey } from 'src/app/models';
import { ApiAuthService } from '../auth/api-auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private readonly encryptLocalStorage: boolean = environment.encryptLocalStorage;

  constructor(
    private readonly authService: ApiAuthService,
  ) {
  }

  public saveItem(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  public saveEncryptedItem(key: LocalStorageKey, value: string): void {
    if(this.encryptLocalStorage) {
      const encryptedValue = this.authService.encrypt(value);
      localStorage.setItem(key, encryptedValue);
    }
    else {
      localStorage.setItem(key, value);
    }
  }

  public getItem(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
  }

  public getDecryptedItem(key: LocalStorageKey): string | null {
    if(this.encryptLocalStorage) {
      const encryptedValue = localStorage.getItem(key);
      if (encryptedValue) {
        return this.authService.decrypt(encryptedValue);
      }
      return null;  
    }
    else {
      return localStorage.getItem(key);
    }
  }

  public removeItem(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }

  public clearAll(): void {
    localStorage.clear();
  }
}
