import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bhlineBreak',
})

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class LineBreakPipePipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    return value.replace(/(?:\r\n|\r|\\n)/g, '\n');
  }
}
