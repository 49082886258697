<div>
  <div class="block border">
    <div class="wrapper-progressbar">
      <ul class="progressbar">
        <li
          *ngFor="let step of steps; let i = index; trackBy: trackByFn"
          [ngClass]="{
            'completed': currentStep > i + 1,
            'active': currentStep === i + 1
          }"
        ></li>
      </ul>
    </div>
  </div>
</div>
