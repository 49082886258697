/**
 * Enum to store all the SourceID GUIDs, so we can determine user type - Broker vs OSR for example.
 * The GUIDs are the same across environments and will be passed in the `SourceID` query param. The
 * app is set up to set all query param values to lowercase (in the NgRx store, see app-init.ts),
 * thus, we are defining these in lowercase here in the enum. If a new GUID is added or an existing
 * one is updated, ensure either it is lowercase here or wherever it is/will be used, is converted
 * to lowercase for proper comparison.
 */
export enum SourceId {
  'broker-liaison' = 'daedb5ae-6134-41a3-ab7a-2aae1f5cad27',
  'forgot-password' = 'f5c0d9a9-51e8-4fda-a2fc-43f1d6a48170',
  'reset-password' = 'ead797f7-9b95-47b9-9f67-c21070754ca7',
  'osr' = '08064dac-c5c4-43d4-afae-6a0565d343ec',
  'buc-authorized' = 'aef408d7-a6c0-4a9d-b915-dd91a2e2542e',
  'cc-care-recipients' = 'd31de4c5-441b-4f59-99b3-13ee181ea668',
  'federated-non-employees' = '0d145208-c5b2-45d9-8bc0-9f11768e4d7a',
  'operator-owner' = '5f5420f2-2d1c-450f-8224-66825ced1e97',
  'cohs' = 'fd786cf3-3ede-4a77-be71-ca1371d2d6c9',
  'fic-contact-center' = '53a371c2-2fd1-441a-af28-99705e5548e0', // when registration for FS/FIC has been done by a customer center rep (a.k.a. non-manual FIC registration
  'fic-registration' = '9ca1edeb-9e40-49a4-ad2c-defe0b08cc80', // when registration for FS/FIC has been done manually by a user via the Angular app (a.k.a. manual FIC registration)
  'enable-lastNameFlow' = '2FE06812-E6E1-E711-AE89-005056991898',
}
