import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { Observable, Subject, combineLatestWith, filter, map, take, takeUntil } from 'rxjs';
import { selectLoginMFAPage } from 'src/app/core/content/content.reducers';
import { BhQueryParams, ContentPages, IBenefitLogo, IContentState, ILoginMFAContent } from 'src/app/models';
import { Store } from '@ngrx/store';
import { ContentActions } from 'src/app/core/content/content.actions';
import { selectBenefitId, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { helpDeskRoute } from 'src/app/constants';
import { Router, UrlTree } from '@angular/router';
import { DOCUMENT, LocationStrategy } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-app-extremly-highrisk-user',
  templateUrl: './extremly-highrisk-user.component.html',
  styleUrls: [ './extremly-highrisk-user.component.scss' ],
})

export class ExtremlyHighriskUserComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly locationStrategy: LocationStrategy,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.loginMFAPage }));
  }

  public extremeHighRiskErrorBodyTemplate: string = '';
  private helpdeskUrl: string = '';
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public readonly loginMFAContent: Observable<IContentState[ContentPages.loginMFAPage]> = this.store.select(selectLoginMFAPage);

  private urlWithQueryParams(route: readonly [string, string], benefitId: string, params: BhQueryParams): string {
    const urlTree: UrlTree = this.router.createUrlTree([ ...route ], {
      queryParams: {
        ...benefitId !== '0' && { benefitId },
        ...params, sourceurl: this.document.location.href,
      },
    });

    return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }


  public ngOnInit(): void {
    this.store
      .select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(this.store.select(selectBenefitId)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ params, benefit ]: [BhQueryParams, IBenefitLogo]): void => {
        this.helpdeskUrl = this.urlWithQueryParams(helpDeskRoute, benefit.id, params);
      });

    this.loginMFAContent
      .pipe(
        filter((loginMFAContent: IContentState[ContentPages.loginMFAPage]): boolean => loginMFAContent.loaded),
        map((loginMFAContent: IContentState[ContentPages.loginMFAPage]): ILoginMFAContent => loginMFAContent.content),
        take(1),
      )
      .subscribe((loginMFAContent: ILoginMFAContent): void => {
        this.extremeHighRiskErrorBodyTemplate = loginMFAContent.BHL_MFALogin_Error_ExtremeRiskError_Desc;
        this.extremeHighRiskErrorBodyTemplate = this.extremeHighRiskErrorBodyTemplate.replace('{{ helpDeskUrl }}', this.helpdeskUrl);
      });
  }
}
