import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

import { environment } from '../environments/environment';
import { diameter, mobileBreakpoint, sessionTimeoutMinutes, sessionWarningMinutes, tokenBuffer } from './constants';
import { ConfigActions } from './core/config/config.actions';
import { selectApiToken, selectApiTokenRefresh, selectBenefitId, selectBhPropertiesURL, selectLoading } from './core/config/config.reducers';
import { selectValidateUserStatus } from './core/credentials/credentials.selectors';
import { PendoService } from './core/pendo/pendo.service';
import { selectEmployeeDetails, selectSelectedEmployer } from './core/signup/signup.reducers';
import { selectPersonInfoStatus } from './core/signup/signup.selectors';
import {
  BenefitId,
  BhQueryParams,
  IApiTokenRefreshState,
  IAppJwt,
  IBenefitLogo,
  IEmployeeDetailsState,
  IPersonInfo,
  ISelectedEmployerState,
  IValidateUserStatus,
} from './models';
import { ContentService } from './core/content/content.service';
import { TimeoutPopupComponent } from './shared/components/timeout-popup/timeout-popup.component';
import { SharedService } from 'src/app/shared/shared.service';

/**
 * The application main/global component.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Subject to pass to the RxJS `takeUntil` to properly unsubscribe when we destroy
   * the component.
   * @private
   */
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  private benefitId: BenefitId | undefined;

  public maintenance = environment.maintenanceMode;

  /**
   * An observable to listen to the loading property in the store to show/hide the
   * loading spinner on the page.
   */
  public readonly showSpinner: Observable<boolean> = this.store.select(selectLoading);

  /**
   * The size of the spinner in pixels.
   */
  public readonly spinnerDiameter: number = diameter;
  public userActivity: NodeJS.Timeout;
  public warningActivity: NodeJS.Timeout;
  public userInactive = new Subject<undefined>();
  public sessionExpired = new Subject<undefined>();

  /**
   * The component `constructor`
   * @param {Store} store The NgRx store instance.
   * @param {BreakpointObserver} bpObserver The Angular Material BreakPoint observer instance.
   * @param {GoogleTagManagerService} gtmService The Google Tag Manager service instance.
   * @param {PendoService} pendoService The Pendo service instance.
   * @param userActivityService
   * @param dialog
   * @param router
   */
  // eslint-disable-next-line max-params
  constructor(
    private readonly store: Store,
    private readonly bpObserver: BreakpointObserver,
    private readonly gtmService: GoogleTagManagerService,
    private readonly pendoService: PendoService,
    private readonly userActivityService: ContentService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly sharedService: SharedService,
  ) {
    let previousPageURL = '';

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        previousPageURL = location.href;
      }

      if (val instanceof NavigationEnd) {
        this.sharedService.GA4pushEvent('page_view', {
          // eslint-disable-next-line camelcase
          page_location: location.href,
          // eslint-disable-next-line camelcase
          page_referrer: previousPageURL,
        });
      }
    });

    this.setTimeout();
    this.userInactive
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(() => {
        this.userActivityService.openDialog(TimeoutPopupComponent);
        this.setTimeoutWarning();
      });

    this.sessionExpired
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(() => {
        this.dialog.closeAll();
        this.userActivityService.onYesClick
          .pipe(take(1))
          .subscribe(
            (response: boolean | null) => {
              if (response || response === null) {
                this.store.dispatch(ConfigActions.clearClientToken());
                this.store.dispatch(ConfigActions.clearAppAuthToken());
                clearTimeout(this.warningActivity);
                clearTimeout(this.userActivity);
                this.router.navigate([ '/global', 'session-expired' ]);
              }
            },
          );
      });
  }

  // after 10 mins inactive
  public setTimeout(): void {
    // while the user is active, reset the clock
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
    }, sessionTimeoutMinutes);

    // while the user is active, check if the token will be expiring soon and if so, request a new one
    // to keep the session active and allow the app to make API calls to prevent 401s
    this.store.select(selectApiToken)
      .pipe(take(1),
        combineLatestWith(this.store.select(selectApiTokenRefresh)),
        take(1))
      .subscribe(([ response, newApiToken ]: [IAppJwt | null, IApiTokenRefreshState]): void => {
        if (response && !newApiToken.loading && (Date.now() + tokenBuffer) > response.expiresAt) {
          this.store.dispatch(ConfigActions.fetchClientToken());
        }
      });
  }

  // display a warning modal (currentlyY) 5mins before the session expires to notify the user and
  // bring their attention back to the app
  public setTimeoutWarning(): void {
    this.warningActivity = setTimeout(() => {
      this.sessionExpired.next(undefined);
    }, sessionWarningMinutes);
  }

  @HostListener('click', [ '$event' ])
  @HostListener('tap', [ '$event' ])
  @HostListener('mousedown', [ '$event' ])
  @HostListener('mousemove', [ '$event' ])
  @HostListener('keypress', [ '$event' ])
  public refreshUserState(): void {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  /**
   * Angular's `OnInit` lifecycle hook where we handle any initialization logic like
   * subscribing to the browser width, so we can properly update the store.
   */
  public ngOnInit(): void {
    this.bpObserver.observe([ `(max-width: ${mobileBreakpoint}px)` ])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((value: BreakpointState): void => {
        this.store.dispatch(ConfigActions.displayType({ displayType: value.matches ? 'mobile' : 'desktop' }));
      });

    const secureAuthStr = JSON.stringify(secureAuth.fingerprint.getAllResults());
    this.store.dispatch(ConfigActions.setSecureAuthInfo({ jsonDfp: secureAuthStr }));

    this.initTrackingServices();
  }

  /**
   * Initialize any tracking services like GTM, Pendo, etc.
   * @private
   */
  private initTrackingServices(): void {
    if (environment.enableCookieBanner) {
      this.store.select(selectBenefitId)
        .pipe(
          take(1),
        )
        .subscribe((benefit: IBenefitLogo): void => {
          this.benefitId = benefit.id;
        });
      if (environment.enableGtm) {
        this.gtmService.addGtmToDom().catch((error) => {
          if (environment.debugLogs) {
            console.error('An error occurred GTM:', error);
          }
          
          //Update Cache Version Timestamp for new cache version.
          if(environment.apiHost === "CacheVersion6Aug2024-01")
            {
              console.error('Cache Version error');
            }
        });
      }
      this.initPendo();
    }
  }

  /**
   * Initialize the Pendo script with a default account ID.
   * @private
   */
  private initPendo(): void {
    // initialize Pendo
    this.pendoService.initPendo(this.benefitId as BenefitId);

    // after initialization, wait for the first router navigation to complete before checking the store for
    // any data (like personal info for logged-in users on the help desk, etc.) and initializing the subscription
    // to update the Pendo metadata whenever we get new data (no need to update Pendo on every route navigation,
    // because Pendo automatically sends the latest metadata on URL change)
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((evt: Event): evt is NavigationEnd => evt instanceof NavigationEnd),
        take(1),
      )
      .subscribe((): void => {
        this.initPendoMetadataUpdates();
      });
  }

  private initPendoMetadataUpdates(): void {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(
          this.store.select(selectSelectedEmployer),
          this.store.select(selectEmployeeDetails),
          this.store.select(selectValidateUserStatus),
          this.store.select(selectPersonInfoStatus),
        ),
      )
      .subscribe(this.buildPendoMetadata);
  }

  // eslint-disable-next-line max-len
  private readonly buildPendoMetadata = ([ params, employer, employee, loggedInUser, personalInfo ]: [BhQueryParams, ISelectedEmployerState | null, IEmployeeDetailsState | null, IValidateUserStatus | null, IPersonInfo | null]): void => {
    this.pendoService.updateMetadata({
      benefitId: this.benefitId as BenefitId,
      params,
      ...employer !== null && { employer },
      ...employee !== null && { employee },
      ...loggedInUser !== null && { loggedInUser },
      ...personalInfo !== null && { personalInfo },
    });

    if (loggedInUser && environment.fullstoryEnable) {
      this.sharedService.loginlogFullStory(loggedInUser.userName,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        loggedInUser.firstName as string + loggedInUser.lastName,
        loggedInUser.clientGuid, loggedInUser.clientName, loggedInUser.country as string);
    }
  };

  /**
   * Angular's `OnDestroy` lifecycle hook where we handle any cleanup logic before we destroy
   * the component like unsubscribing from any Observables.
   */
  public ngOnDestroy(): void {
    clearTimeout(this.userActivity);
    clearTimeout(this.warningActivity);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
