<header class="bh-header" role="banner">
  <mat-grid-list cols="5" rowHeight="fit" gutterSize="0">
    <mat-grid-tile class="bh-grid-list--left bh-logo" colspan="4" *ngIf="(benefitId | async) as benefitIdInfo">
      <img
        class="bh-logo__img"
        [src]="benefitIdInfo.logoUrl"
        [alt]="benefitIdInfo.altText"
      />
     </mat-grid-tile>
    <mat-grid-tile
      *ngIf="displayHelpButton"
      class="bh-grid-list--right"
      [ngSwitch]="(displayType | async)"
      colspan="1"
    >
      <ng-container *ngSwitchCase="'desktop'" [ngTemplateOutlet]="desktopHelp"></ng-container>
      <ng-container *ngSwitchCase="'mobile'" [ngTemplateOutlet]="mobileHelp"></ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="desktopHelp"></ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</header>

<ng-template #desktopHelp>
  <a
    class="bh-button--tertiary" tabindex="0" role="link"
    [href]=""   
    aria-label="Help (opens in new tab)"
    target="_blank"
    rel="noopener noreferrer"
    (click)="onHelpButtonClick()"
  >
    Help
  </a>
</ng-template>

<ng-template #mobileHelp>
  <a
    class="bh--help"
    [href]=""    
    aria-label="Help (opens in new tab)"
    target="_blank"
    rel="noopener noreferrer"
    (click)="onHelpButtonClick()"
  >
    ?
  </a>
</ng-template>
