export const bhQueryParamsSet = [

  /**
   * Used to determine the LOB - e.g., MyBH, Backup Care, Full Service, College Coach,, etc. {@see BenefitId} `enum`.
   */
  'benefitid', // BenefitId enum
  'targetid', // BenefitId enum, sent whenever benefitid is not (for security reasons)
  'fstargetid', // number
  'redirecturl', // string
  'clientguid', // string
  'clientid', // number
  'origclientguid', // string
  'UserInfoId', // string
  'uid', // string
  'sourceurl', // string
  'clientname', // string
  /**
   * Used to call the retrieve magic link details endpoint when a user lands on the application from a magic link in an email.
   */
  'requestid', // string
  'usertype', // enum (0, 1?)
  'preregistration', // boolean
  'issplitmerge', // boolean

  /**
   * Used to determine if we need to show the OTP (one-time password) screen for MFA users during the registration flow or skip it. If `true`, we skip.
   */
  'ishardstop', // boolean
  'isemployee', // boolean
  'ismobile', // boolean
  'isfederated', // boolean
  'mode', // number
  'uniqueid', // string

  /**
   * Used to determine user type/flow - e.g., Eda non sso client continue with non federated flow.
   */
  'isnonsso', // boolean

  /**
   * This is the user's access token that is passed to use for CC MFA login validation. The backend will redirect directly to the MFA page and
   * provide it to us, so we can pass this value to explore benefits after the user logs in. CC users will NOT log in using our login page, so
   * the BE will pass it to us. We will also use this param to identify we are in the "login" flow vs "signup" to display the right content on
   * the MFA page.
   */
  'accesstoken', // string

  /**
   * Used to determine user type/flow - e.g., OSR, COHS, Broker/Liaison, Reset/Forgot Password, etc. {@see SourceId} `enum`.
   */
  'sourceid', // SourceId enum

  /**
   * Used to identify unique franchise location for Franchise flow
   */
  'nsn', // string

  /**
   * Encrypted userInfoId, for use in Edit Franchise flow
   */
  'userinfoid', // string
  'said', // string (this is the userInfoId in the reset/forgot password flow)

  /**
   * For Edit Franchise flow: if true, skip location step and go straight to account questions
   */
  'editprofile', // boolean

  /**
   * Google Tag Manager tags that are passed by email campaigns.
   */
  'utm_source', // string
  'utm_medium', // string
  'utm_campaign', // string
  'utm_term', // string
  'utm_content', // string

  /**
   * Track event from Backend
   */
  'eventname', // string
  'eventcatgry', // string

  /**
   * Alternate Email Changes
   */
  'altemailduplicate', // boolean

  /**
   * forgot Password Otp Flow
   */
  'fgPwdOtpFlow', // boolean

    
  /**
   * from bh.com flow
   */
  'isbh', // boolean
] as const;
