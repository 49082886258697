import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable, take } from 'rxjs';
import { bhTitle } from 'src/app/constants';

import { ContentActions } from 'src/app/core/content/content.actions';
import { selectEmployeeSearchPage, selectForgotUsernamePage, selectHelpDeskPage, selectResetPasswordPage } from 'src/app/core/content/content.reducers';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { selectForgotType } from 'src/app/features/login/store/login.reducers';
import { ContentPages, ForgotType, IConfirmationPageData, IContent, IContentState, IForgotUsernameContent, IHelpDeskContent, IResetPasswordContent, LocalStorageKey } from 'src/app/models';

@Component({
  selector: 'bh-app-confirmation-page-short',
  templateUrl: './confirmation-page-short.component.html',
  styleUrls: [ './confirmation-page-short.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationPageShortComponent implements OnInit {
  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);
  public showAlreadyAccount: boolean = false;
  public contentWithoutHTMLTag: string = '';
  public userConfirmationPageData: IConfirmationPageData = {
    confirmationHeader: '',
    confirmationBody: '',
  };

  constructor(
    private readonly store: Store,
    private readonly getTitle: Title,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.helpDeskPage }));
  }
  public removeTags(str: string): string {
    return str.replace(/(<([^>]+)>)/ig, '');
  }
  public ngOnInit(): void {
    const emailFromLocalStorage: string | null =

            this.localStorageService.getDecryptedItem(LocalStorageKey.email);
    this.store.select(selectForgotType)
      .pipe(
        combineLatestWith(
          this.store.select(selectHelpDeskPage),
          this.store.select(selectForgotUsernamePage),
          this.store.select(selectResetPasswordPage),
        ),
        take(1))
      .subscribe(([ forgotType, helpDeskContent, forgotUNContent, restPWContent ]:
      [ForgotType | null, IContent<IHelpDeskContent>, IContent<IForgotUsernameContent>, IContent<IResetPasswordContent> ]) => {
        if (forgotType === 'username') {
          this.showAlreadyAccount = true;
          this.userConfirmationPageData = {
            confirmationHeader: forgotUNContent.content.BHL_ForgotUNConfirm_Header,
            confirmationBody: forgotUNContent.content.BHL_ForgotUNConfirm_Header2.replace('{{EMAILID}}', emailFromLocalStorage as string),
          };
          this.contentWithoutHTMLTag = this.removeTags(this.userConfirmationPageData.confirmationBody);
          this.getTitle.setTitle(`Forgot Username ${bhTitle}`);
        } else if (forgotType === 'password') {
          this.showAlreadyAccount = true;
          this.userConfirmationPageData = {
            confirmationHeader: restPWContent.content.BHL_ResetPWConfirm_Header,
            confirmationBody: restPWContent.content.BHL_ResetPWConfirm_Header2.replace('{{EMAILID}}', emailFromLocalStorage as string),
          };
          this.contentWithoutHTMLTag = this.removeTags(this.userConfirmationPageData.confirmationBody);
          this.getTitle.setTitle(`Forgot Password ${bhTitle}`);
        } else {
          this.showAlreadyAccount = false;
          this.userConfirmationPageData = {
            confirmationHeader: helpDeskContent.content.BHL_LoggedOutSupportFormConfirm_Label_Header_ShortHelp,
            confirmationBody: helpDeskContent.content.BHL_LoggedOutSupportFormConfirm_Label_Content1_ShortHelp,
          };
          this.contentWithoutHTMLTag = this.removeTags(this.userConfirmationPageData.confirmationBody);
        }
      });
    this.localStorageService.removeItem(LocalStorageKey.email);
  }
}
