/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatestWith, filter, take, takeUntil } from 'rxjs';

import { ConfigActions } from 'src/app/core/config/config.actions';
import { selectApiToken, selectBhPropertiesURL, selectFlowType, selectLoginType } from 'src/app/core/config/config.reducers';
import { ConfigService } from 'src/app/core/config/config.service';
import { ContentActions } from 'src/app/core/content/content.actions';
import { emailPattern, loginUrl, phonePattern } from '../../../../constants';
import { selectEmployeeSearchPage, selectLoginMFAPage, selectSignupMFAPage } from '../../../../core/content/content.reducers';
import { ContentService } from '../../../../core/content/content.service';
import {
  ContactType, ContentPages,
  IContentState,
  IEmployeeDetailsState,
  IMfaContactObject,
  IMfaObjectForRegistration,
  ModeType,
  IMasterListStatus,
  IAppJwt,
  ISendOTPState,
  IMfaVerificationState,
  FlowType,
  ISelectedEmployerState,
  IMFAUserEmailAndMobileState,
  IMFAUserEmailAndMobileStatus,
  LocalStorageKey,
  IClientInfoState,
  IEligibilityFromMagicLinkState,
  LoginType,
  ICreateRegistrationStatus,
  BhQueryParams,
  SourceId,
  EventName,
  RegistrationSubCategory,
  BenefitId,
} from '../../../../models';
import { SignupActions } from '../../../../core/signup/signup.actions';
import {
  selectClientInfoState,
  selectEligibilityFromMagicLink,
  selectEmployeeDetails,
  selectMfaVerificationState,
  selectSelectedEmployer,
  selectSendOTPState,
} from '../../../../core/signup/signup.reducers';
import {
  selectCountryCodesList,
  selectCreateRegistrationDetails,
  selectCreateRegistrationLoading,
  selectMfaVerificationLoading,
  selectSendOTPLoading,
} from '../../../../core/signup/signup.selectors';
import { selectMfaUserEmailAndMobileState } from 'src/app/core/credentials/credentials.reducers';
import { MaskEmailPipe } from 'src/app/shared/pipes/mask-email.pipe';
import { MaskPhoneNumberPipe } from 'src/app/shared/pipes/mask-phone-number.pipe';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { OverlayPopupComponent } from 'src/app/shared/components/overlay-popup/overlay-popup.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-mfa-signup',
  templateUrl: './mfa-signup.component.html',
  styleUrls: ['./mfa-signup.component.scss'],
})

export class MfaSignupComponent implements OnInit, OnDestroy {
  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);
  public mfaForm: FormGroup;
  public emailDetails: FormArray;
  public phoneDetails: FormArray;
  public readonly countries: Observable<Array<IMasterListStatus> | null> = this.store.select(selectCountryCodesList);
  public readonly content: Observable<IContentState[ContentPages.signupMFAPage]> = this.store.select(selectSignupMFAPage);
  public readonly loginContent: Observable<IContentState[ContentPages.loginMFAPage]> = this.store.select(selectLoginMFAPage);
  public submitted = false;
  public selectedContact = { index: '0', type: 'email' };
  public clientName?: string = '';
  private userInfoId: string = '';
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public flowType: string;
  public loginType: string;
  public isLoginFlow: boolean = false;
  public isPasswordLoginFlow: boolean = false;
  public emailIsReadOnly: boolean = false;
  public isMfaUserProfileBlank: boolean = false;
  public unmaskedEmail: string = '';
  public unmaskedAlternateEmail: string = '';
  public unmaskedPhoneNumber: string = '';
  public headerContent: string;
  public selectedTypefromLogin: string;
  private usermfaetails: Array<IMFAUserEmailAndMobileStatus> = [];
  public showMFaForm: boolean = true;
  public alternateEmail: boolean = false;
  public showRemoveIcon: boolean = true;
  public isOtpEnabledFlow?: boolean = false;
  public isMfaEnabled?: boolean = false;
  public isSkipNowEnabled?: boolean = false;
  public signUpTitle: string = 'signup';
  private showOverlayPopup: boolean = false;

  private readonly defaultEmail: FormGroup<{ radioOption: FormControl<string>; email: FormControl<string>; uniqueId: FormControl<number> }> =
    this.createEmailItem();

  // eslint-disable-next-line max-params
  constructor(
    public readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly config: ConfigService,
    private readonly contentService: ContentService,
    private readonly windowLocation: Location,
    private readonly maskEmail: MaskEmailPipe,
    private readonly maskPhoneNumber: MaskPhoneNumberPipe,
    private readonly localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sharedService: SharedService,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.signupMFAPage }));
    this.store.dispatch(SignupActions.loadCountryCodes());

    this.defaultEmail.controls.radioOption.setValue('email');
    this.defaultEmail.controls.email.addValidators(Validators.required);
    const emails: Array<FormGroup<{ radioOption: FormControl<string>; email: FormControl<string>; uniqueId: FormControl<number> }>> =
      [this.defaultEmail];

    const defaultPhoneNumber: FormGroup<{
      radioOption: FormControl<string>;
      phoneNumber: FormControl<string>;
      country: FormControl<string>;
      uniqueId: FormControl<number>
    }> = this.createPhoneItem();

    defaultPhoneNumber.controls.phoneNumber.addValidators([Validators.required, Validators.pattern(phonePattern)]);
    defaultPhoneNumber.controls.country.addValidators(Validators.required);

    const phones: Array<FormGroup<{
      radioOption: FormControl<string>;
      phoneNumber: FormControl<string>;
      country: FormControl<string>;
      uniqueId: FormControl<number>
    }>> = [defaultPhoneNumber];

    this.mfaForm = this.fb.group({
      emailData: this.fb.array(emails),
      phoneData: this.fb.array(phones),
    });

    this.emailDetails = this.mfaForm.get('emailData') as FormArray;
    this.phoneDetails = this.mfaForm.get('phoneData') as FormArray;

    this.store
      .select(selectEmployeeDetails)
      .pipe(take(1))
      .subscribe((empDetails: IEmployeeDetailsState | null): void => {
        this.unmaskedEmail = empDetails?.email as string;
        this.defaultEmail.controls.email.setValue(this.maskEmail.transform(empDetails?.email as string));
        if (empDetails?.email) {
          this.localStorageService.saveEncryptedItem(LocalStorageKey.email, empDetails?.email as string);
          this.defaultEmail.controls.email.disable();
        }
        else {
          const emailFromLocalStorage =  (this.localStorageService.getDecryptedItem(LocalStorageKey.email) || '').toString();
          if(emailFromLocalStorage)
          {
            this.unmaskedEmail = emailFromLocalStorage as string;
            this.defaultEmail.controls.email.setValue(this.maskEmail.transform(emailFromLocalStorage));
            this.defaultEmail.controls.email.disable();
          }
        }
      });

    this.store.select(selectFlowType)
      .pipe(take(1))
      .subscribe((flowType: FlowType): void => {
        this.flowType = flowType;
        this.isLoginFlow = this.flowType === 'login';
      });

    this.store.select(selectLoginType)
      .pipe(take(1))
      .subscribe((loginType: LoginType): void => {
        this.loginType = loginType;
        this.isPasswordLoginFlow = this.loginType === 'password';
      });

    if(!this.isPasswordLoginFlow)
    {
      this.localStorageService.saveEncryptedItem(LocalStorageKey.isLoginFlow, 'true');
    }
    this.store
      .select(selectClientInfoState)
      .pipe(
        filter((state: IClientInfoState): boolean => state.loaded || state.error !== null),
        take(1),
      )
      .subscribe(
        (clientInfo: IClientInfoState | null): void => {
          this.alternateEmail = (clientInfo?.data?.isAlternateEmail) as boolean;
          this.clientName = clientInfo?.data?.clientName as string;
          this.isOtpEnabledFlow = clientInfo?.data?.isLoginOtpEnabled;
          this.isMfaEnabled = clientInfo?.data?.isMfaEnabled;
          this.isSkipNowEnabled = this.isOtpEnabledFlow && !this.isMfaEnabled;
          const isresentotp: string = (this.localStorageService.getDecryptedItem(LocalStorageKey.isresentotp) || '').toString();
          const isAltEmailDuplicate: string = (this.localStorageService.getItem(LocalStorageKey.isAltEmailDuplicate) || '').toString();
          if (this.alternateEmail && !this.isLoginFlow && (isresentotp !== 'true') && (isAltEmailDuplicate !== 'true')) {
            this.store
              .select(selectEligibilityFromMagicLink)
              .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((eligibility: IEligibilityFromMagicLinkState): boolean => eligibility.loaded || eligibility.error !== null),
                take(1),
              )
              .subscribe(
                (eligibility: IEligibilityFromMagicLinkState | null): void => {
                  if (eligibility?.loaded) {
                    // eslint-disable-next-line max-len
                    if (eligibility.eligibilityFromMagicLinkStatus?.alternateEmail?.toLowerCase() !== eligibility.eligibilityFromMagicLinkStatus?.workEmail?.toLowerCase()) {
                      this.populateAlternateEmail(eligibility.eligibilityFromMagicLinkStatus?.alternateEmail as string);
                    }
                  }
                },
              );
          }
        },
      );
  }

  public ngOnInit(): void {
    const isresentotp: string = (this.localStorageService.getDecryptedItem(LocalStorageKey.isresentotp) || '').toString();
    if (isresentotp === 'true') {
      this.showMFaForm = false;
    }

    this.store
      .select(selectCreateRegistrationLoading)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectMfaVerificationLoading)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectSendOTPLoading)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store.select(selectEmployeeDetails)
      .pipe(take(1))
      .subscribe((details: IEmployeeDetailsState | null): void => {
        this.clientName = details?.clientName;
      });

    if (this.isLoginFlow) {
      this.store.select(selectSelectedEmployer)
        .pipe(
          take(1),
        )
        .subscribe((employee: ISelectedEmployerState | null): void => {
          this.clientName = employee?.clientName as string;
        });
      this.showMFaForm = false;
      this.initiateLoginSubs();
    }

    this.store
      .select(selectMfaVerificationState)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mfaStatus: IMfaVerificationState): void => {
        if (mfaStatus.loaded) {
          if (mfaStatus.mfaVerificationStatus?.isHardStop && !this.isOtpEnabledFlow &&
            this.isMfaEnabled) {
            const url: URL = this.config.buildExternalUrl(loginUrl);

            // check if hard stop flag is already in the query params (ignoring letter case)
            // and, if so, remove it to avoid duplicating the param IF it exists with a different casing
            // if it exists with different casing, the `url.searchParams.set` method will not overwrite it!
            for (const key of url.searchParams.keys()) {
              if (key.toLowerCase() === 'ishardstop') {
                url.searchParams.delete('ishardstop');
                break;
              }
            }

            url.searchParams.set('ishardstop', 'true');

            this.store.dispatch(ConfigActions.loading({ loading: false }));
            localStorage.removeItem(LocalStorageKey.userInfoId);
            this.router.navigate(['global', 'extreme-highrisk']);
          } else if (isresentotp === 'true') {
            this.initiateLoginSubs();
          } else {
            this.store.dispatch(SignupActions.sendOtp());
          }
        } else if (mfaStatus.error) {
          this.contentService.showErrorToast();
        }
      });

    this.store
      .select(selectSendOTPState)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((otpStatus: ISendOTPState): void => {
        if (otpStatus.loaded) {
          if (otpStatus.sendOTPStatus) {
            // need to clear the successful response before navigating to avoid
            // unintentional side effects in the verify otp component when we
            // initialize the subscriptions (for resending a new code for example)
            this.store.dispatch(SignupActions.clearSendotp());
            this.localStorageService.saveEncryptedItem(LocalStorageKey.verifyotpstate, 'true');
            this.router.navigate(['/global', 'verify-otp']);
          } else {
            this.contentService.showErrorToast();
          }
        } else if (otpStatus.error) {
          this.contentService.showErrorToast();
        }
      });

    this.store.select(selectApiToken)
      .pipe(take(1))
      .subscribe((apiToken: IAppJwt | null): void => {
        if (apiToken) {
          this.userInfoId = apiToken.userInfoId as string;
          this.isSkipNowEnabled = this.isOtpEnabledFlow && !this.isMfaEnabled;
        }
      });
      this.localStorageService.saveItem(LocalStorageKey.otpLink, window.location.href);
  }

  private initiateLoginSubs(): void {
    this.store
      .select(selectMfaUserEmailAndMobileState)
      .pipe(
        filter((state: IMFAUserEmailAndMobileState): boolean => state.loaded || state.error !== null),
        take(1),
      )
      .subscribe(
        (state: IMFAUserEmailAndMobileState): void => {
          if (state.loaded && state.mfaUserEmailAndMobileStatus.length > 0) {
            const isNonMFAFalseForAll = state.mfaUserEmailAndMobileStatus.every((item) => item.isNonMFA) && this.isPasswordLoginFlow;
            if (isNonMFAFalseForAll) {
              const primaryEmail = state.mfaUserEmailAndMobileStatus[0]?.value;
              this.unmaskedEmail = primaryEmail as string;
              this.defaultEmail.controls.email.setValue(this.maskEmail.transform(primaryEmail as string));
              this.defaultEmail.controls.email.disable();
              if (state.mfaUserEmailAndMobileStatus.length === 2) {
                const alternateEmail = state.mfaUserEmailAndMobileStatus[1]?.value;
                this.populateAlternateEmail(alternateEmail as string);
              }
              this.showMFaForm = true;
              this.cdr.detectChanges();
            }
            if (!isNonMFAFalseForAll) {
              this.usermfaetails = state.mfaUserEmailAndMobileStatus;
              this.isMfaUserProfileBlank = true;
              this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.loginMFAPage }));
              this.emailDetails.removeAt(0);
              this.phoneDetails.removeAt(0);

              let emailIndex: number = -1;
              let phoneIndex: number = -1;

              state.mfaUserEmailAndMobileStatus.forEach((item: IMFAUserEmailAndMobileStatus): void => {
                const isEmail: boolean = item.type === ContactType.email;

                if (isEmail) {
                  emailIndex += 1;
                  this.unmaskedEmail = item.value;
                  this.emailDetails.push(
                    this.fb.group({
                      radioOption: item.isDefault ? 'email' : '',
                      uniqueId: item.userMfamobileEmailId,
                      email: [this.maskEmail.transform(item.value)],
                    }),
                  );
                } else {
                  phoneIndex += 1;
                  this.unmaskedPhoneNumber = item.value;
                  this.phoneDetails.push(
                    this.fb.group({
                      radioOption: item.isDefault ? 'mobile' : '',
                      phoneNumber: [this.maskPhoneNumber.transform(item.value)],
                      country: item.countryCode,
                      uniqueId: item.userMfamobileEmailId,
                    }),
                  );
                }

                if (item.isDefault) {
                  this.selectedTypefromLogin = item.value;
                  this.selectedContact = {
                    index: isEmail ? emailIndex.toString() : phoneIndex.toString(),
                    type: isEmail ? 'email' : 'mobile',
                  };
                }
              });
              this.emailIsReadOnly = true;
              this.showMFaForm = true;
              this.cdr.detectChanges();
            }
          } else if (state.error) {
            this.contentService.showErrorToast();
          }
        });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onContactChange(event: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.selectedContact = { index: event.target.id.substring(0, 1), type: event.target.id.substring(1, 10) };
  }

  public onSend(): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    this.userInfoId = this.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string;
    this.localStorageService.removeItem(LocalStorageKey.isresentotp);
    if (this.mfaForm.valid) {
      const mfaObjectArray: Array<IMfaObjectForRegistration> = [];
      let mfaObject: IMfaObjectForRegistration;
      let defaultContact: string | undefined = '';
      this.mfaForm.value.emailData.forEach((item: IMfaContactObject) => {
        item.isDefault = false;
      });
      this.mfaForm.value.phoneData.forEach((item: IMfaContactObject) => {
        item.isDefault = false;
      });
      switch (this.selectedContact.type) {
        case 'email':
          this.mfaForm.value.emailData[Number(this.selectedContact.index)].isDefault = true;
          break;
        case 'mobile':
          this.mfaForm.value.phoneData[Number(this.selectedContact.index)].isDefault = true;
          break;
        default:
          break;
      }
      this.mfaForm.value.emailData.forEach((item: IMfaContactObject, index: number) => {
        mfaObject = {
          type: ContactType.email,
          value: item.email ?? (index === 0 ? this.unmaskedEmail : this.unmaskedAlternateEmail),
          isDefault: item.isDefault ?? false,
          userInfoId: this.userInfoId,
        };
        if (item.isDefault) {
          defaultContact = item.email ?? (index === 0 ? this.unmaskedEmail : this.unmaskedAlternateEmail);
        }
        mfaObjectArray.push(mfaObject);
      });
      this.mfaForm.value.phoneData.forEach((item: IMfaContactObject) => {
        mfaObject = {
          type: ContactType.mobile,
          countryCode: item.country,
          value: item.phoneNumber ?? this.unmaskedPhoneNumber,
          isDefault: item.isDefault ?? false,
          userInfoId: this.userInfoId,
        };
        if (item.isDefault) {
          defaultContact = item.phoneNumber;
        }
        mfaObjectArray.push(mfaObject);
      });

      this.store.dispatch(SignupActions.employeeDetails({
        employeeDetails: {
          mfaContact: mfaObjectArray,
          defaultContact,
          selectedContactType : this.selectedContact.type,
        } as IEmployeeDetailsState,
      }));

      this.store.dispatch(SignupActions.saveMfa());
      this.localStorageService.saveItem(LocalStorageKey.userInfoId, this.userInfoId);
      const payload = {
        modeType: this.isLoginFlow ? ModeType.Login : ModeType.Registration,
      };

      this.store.dispatch(SignupActions.mfaVerification({
        payload,
      }));
    }
    this.mfaForm.markAllAsTouched();
  }

  public userRequired(index: number): boolean | undefined {
    const emailDetailsValidation = this.emailDetails.controls[Number(index)];

    return emailDetailsValidation?.get('email')?.hasError('required');
  }

  public userPattern(index: number): boolean | undefined {
    const emailDetailsValidation = this.emailDetails.controls[Number(index)];

    return emailDetailsValidation?.get('email')?.hasError('pattern');
  }

  public emailTouched(index: number): boolean | undefined {
    const emailDetailsValidation = this.emailDetails.controls[Number(index)];

    return emailDetailsValidation?.get('email')?.touched;
  }
  public emailDirty(index: number): boolean | undefined {
    const emailDetailsValidation = this.emailDetails.controls[Number(index)];

    return emailDetailsValidation?.get('email')?.dirty;
  }
  public emailInvalid(index: number): boolean | undefined {
    const emailDetailsValidation = this.emailDetails.controls[Number(index)];

    return emailDetailsValidation?.get('email')?.invalid;
  }
  public countryRequired(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('country')?.hasError('required');
  }
  public countryTouched(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('country')?.touched;
  }
  public countryDirty(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('country')?.dirty;
  }
  public countryInvalid(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('country')?.invalid;
  }
  public phoneRequired(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('phoneNumber')?.hasError('required');
  }
  public phoneTouched(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('phoneNumber')?.touched;
  }
  public phoneDirty(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('phoneNumber')?.dirty;
  }

  public phoneInvalid(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('phoneNumber')?.invalid;
  }
  public minLengthValid(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    return phoneDetailsValidation?.get('phoneNumber')?.hasError('pattern');
  }

  public phoneNoValue(index: number): boolean | undefined {
    const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];

    const phoneNumberValidate = phoneDetailsValidation?.get('phoneNumber')?.value as string;

    return phoneNumberValidate.toString().length > 9 || phoneNumberValidate.toString().length <= 9;
  }
  public addContactRow(typeOfContact: string): void {
    switch (typeOfContact) {
      case 'email':
        if (this.emailDetails.length < 3) {
          this.emailDetails.push(this.fb.nonNullable.group({
            radioOption: '',
            email: ['', [Validators.required, Validators.pattern(emailPattern)]],
            uniqueId: Math.random(),
          }));
        }
        break;
      case 'mobile':
      default:
        if (this.phoneDetails.length < 3) {
          this.phoneDetails.push(this.fb.nonNullable.group({
            radioOption: '',
            phoneNumber: ['', [Validators.required, Validators.pattern(phonePattern)]],
            country: ['', Validators.required],
            uniqueId: Math.random(),
          }));
        }
        break;
    }
  }

  public removeContactRow(typeOfContact: string, index: number): void {
    switch (typeOfContact) {
      case 'email':
        if (index > 0) {
          const emailDetailsValidation = this.emailDetails.controls[Number(index)];
          if (emailDetailsValidation?.get('radioOption')?.value === 'email') {
            this.emailDetails.controls[0]?.get('radioOption')?.setValue('email');
          }
          this.emailDetails.removeAt(index);
        }
        break;
      case 'mobile':
      default:
        if (index > 0) {
          const phoneDetailsValidation = this.phoneDetails.controls[Number(index)];
          if (phoneDetailsValidation?.get('radioOption')?.value === 'mobile') {
            this.emailDetails.controls[0]?.get('radioOption')?.setValue('email');
          }
          this.phoneDetails.removeAt(index);
        }
        break;
    }
  }

  public createEmailItem(): FormGroup<{ radioOption: FormControl<string>, email: FormControl<string>; uniqueId: FormControl<number> }> {
    return this.fb.nonNullable.group({
      radioOption: '',
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      uniqueId: Math.random(), // this field's sole purpose is to uniquely identify the rendered input for the trackBy function
    });
  }

  public createPhoneItem(): FormGroup<{
    radioOption: FormControl<string>;
    phoneNumber: FormControl<string>; country: FormControl<string>; uniqueId: FormControl<number>
  }> {
    return this.fb.nonNullable.group({
      radioOption: '',
      phoneNumber: ['', [Validators.required, Validators.pattern(phonePattern)]],
      country: ['', Validators.required],
      uniqueId: Math.random(),
    });
  }

  public readonly email = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackByContactFn(index: number, fg: any): number {
    // the type definition of the TrackByFunction (`AbstractControl`) does not match with the actual
    // supplied item, which is a `FormGroup<{ email: string; uniqueId: number }>`, thus, we need to
    // use the `any` type :(
    return fg.controls.uniqueId.value as number;
  }

  public trackByFn(index: number, emp: IMasterListStatus): string | undefined {
    return emp.objId;
  }

  /**
   * Handles interaction with Previous button.
   */
  public onPrevious(): void {
    const isForgotUnFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotUnFlow) || '').toString();
    const isForgotPwFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotPwFlow) || '').toString();
    const userNavigationState = (this.localStorageService.getItem(LocalStorageKey.userNavigationState) || '').toString();
    const isLoginNewRedirectStop = (this.localStorageService.getItem(LocalStorageKey.isLoginNewRedirectStop) || '').toString();
    const isNewPwLoginFlow = (this.localStorageService.getItem(LocalStorageKey.isNewPwLoginFlow) || '').toString();
    const benefitID = (this.localStorageService.getItem(LocalStorageKey.benefitId) || '').toString();

    this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);
    if(isForgotUnFlow === 'true')
    {
      this.router.navigate([ '/login', 'forgot-username' ]);
      this.localStorageService.removeItem(LocalStorageKey.isForgotUnFlow);
      return;
    }
    if(isForgotPwFlow === 'true')
    {
      this.router.navigate([ '/login', 'forgot-password' ]);
      this.localStorageService.removeItem(LocalStorageKey.isForgotPwFlow);
      return;
    }
    if(userNavigationState === 'true')
    {
      this.router.navigate([ 'signup', 'create-account' ]);
      this.localStorageService.removeItem(LocalStorageKey.userNavigationState);
      return;
    }
    if(isLoginNewRedirectStop === 'true')
    {
      this.router.navigate([ '/login' ]);
      this.localStorageService.removeItem(LocalStorageKey.isLoginNewRedirectStop);
      return;
    }
    if(isNewPwLoginFlow === 'true')
    {
      this.router.navigate([ '/login', 'password' ]);
      this.localStorageService.removeItem(LocalStorageKey.isNewPwLoginFlow);
      return;
    }
    if(benefitID === BenefitId.FIC.toString() || benefitID === BenefitId.BUC.toString() || benefitID === BenefitId.CollegeCoach.toString())
    {
      this.document.location.href = this.config.buildExternalUrl(loginUrl).href;
      return;
    }

    this.windowLocation.back();
    // this.localStorageService.removeItem(LocalStorageKey.clientGuid);
    this.store.dispatch(ConfigActions.bhPropertiesUrl({ bhPropertiesURL: { clientguid: null } }));
  }

  public onContinue(): void {
    this.localStorageService.removeItem(LocalStorageKey.isresentotp);
    this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);
    let defaultContact: string | undefined = '';
    let changedSelectedType: string | undefined = '';
    let uniqueId: number;
    let mfaObject: IMfaObjectForRegistration;
    const mfaObjectArray: Array<IMfaObjectForRegistration> = [];

    switch (this.selectedContact.type) {
      case 'email':
        changedSelectedType = this.mfaForm.value.emailData[Number(this.selectedContact.index)].email as string;
        uniqueId = this.mfaForm.value.emailData[Number(this.selectedContact.index)].uniqueId as number;
        break;
      case 'mobile':
      default:
        changedSelectedType = this.mfaForm.value.phoneData[Number(this.selectedContact.index)].phoneNumber as string;
        uniqueId = this.mfaForm.value.phoneData[Number(this.selectedContact.index)].uniqueId as number;
        break;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    defaultContact = changedSelectedType ?? this.selectedTypefromLogin;

    this.usermfaetails.forEach((item: IMFAUserEmailAndMobileStatus) => {
      mfaObject = {
        type: item.type,
        value: item.value,
        userMobileEmailId: item.userMfamobileEmailId,
        countryCode: item.countryCode as string,
        isDefault: item.userMfamobileEmailId === uniqueId,
        userInfoId: item.userInfoId,
      };
      if (item.userMfamobileEmailId === uniqueId) {
        defaultContact = item.value;
      }
      mfaObjectArray.push(mfaObject);
    });

    this.store.dispatch(SignupActions.employeeDetails({
      employeeDetails: {
        mfaContact: mfaObjectArray,
        defaultContact,
        selectedContactType : this.selectedContact.type,
      } as IEmployeeDetailsState,
    }));


    this.store.dispatch(SignupActions.saveMfa());
    this.store.dispatch(SignupActions.sendOtp());
  }

  public populateAlternateEmail(alternateEmail: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (alternateEmail !== null && alternateEmail.trim() !== '') {
      const alternateEmailObj: FormGroup<{ radioOption: FormControl<string>; email: FormControl<string>; uniqueId: FormControl<number> }> =
        this.createEmailItem();
      this.showRemoveIcon = false;
      alternateEmailObj.controls.email.addValidators(Validators.required);
      this.emailDetails.push(alternateEmailObj);
      this.unmaskedAlternateEmail = alternateEmail;
      alternateEmailObj.controls.email.setValue(this.maskEmail.transform(alternateEmail));
      alternateEmailObj.controls.email.disable();
      if(!this.isLoginFlow) 
      {
        this.cdr.detectChanges();
      }
    }
  }

  public SkipNowMfaOtp(): void {
    this.store.select(selectApiToken)
      .pipe(
        combineLatestWith(
          this.store.select(selectCreateRegistrationDetails),
          this.store.select(selectBhPropertiesURL),
        ),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([apiToken, userInfo, params]: [IAppJwt | null, ICreateRegistrationStatus | null, BhQueryParams]): void => {
        const sourceId: string | undefined = params.sourceid?.toLowerCase();
        const goToLogin: '' | null | undefined | boolean = sourceId && (sourceId !== SourceId['fic-registration']);
        const url: URL = this.config.buildExternalUrl(goToLogin ? undefined : environment.exploreBenefitsUrl);
        if (userInfo) {
          this.sharedService.loginlogFullStory(userInfo.workEmail,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            userInfo.firstName as string + userInfo.lastName,
            userInfo.clientGuid, '', userInfo.country as string);
        }
        if (goToLogin) {
          this.document.location.href = url.href;
        } else {
          if (this.isLoginFlow) {
            const userName = userInfo?.username ?? this.localStorageService.getItem(LocalStorageKey.uname) as string;
            this.sharedService.exploreBenefits(url.toString(), apiToken?.userAccessToken ?? apiToken?.token as string, userInfo?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) ?? apiToken?.userInfoId as string, 'login', userName, this.isOtpEnabledFlow ? 'true' : 'false');
          }
          else {
            const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
            this.sharedService.TrackPendoEvent(EventName.LOGIN,
              benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_DISPLAY);
              this.showOverlayPopup = true;
            this.contentService.openOverlayDialog(OverlayPopupComponent).subscribe((data) => {
              this.store.dispatch(ConfigActions.loading({ loading: true }));
            });
          }

        }
      });

      // Clear localStorage, since there's no longer any need for it
      const clientGuid = this.localStorageService.getItem(LocalStorageKey.clientGuid) as string;
      const userId = this.localStorageService.getItem(LocalStorageKey.userInfoId) as string;
      this.localStorageService.clearAll();
      if(this.showOverlayPopup)
      {
        this.localStorageService.saveItem(LocalStorageKey.userInfoId, userId ); 
      }
      this.localStorageService.saveItem(LocalStorageKey.clientGuid,clientGuid );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
