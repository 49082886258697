<mat-dialog-content class="bh-dialog--timeout-warning">
  <img
    [src]="'assets/Vector.svg'"
    [alt]="''"
  />
  <h1 class="bh--heading" id="dialog-title">Need more time?</h1>
  <p class="bh-heading bh-heading--3" id="dialog-message">
    Your session will expire in 5 minutes. If you need more time, please click
    “Yes”. Otherwise you will be logged out.
  </p>
  <div class="bh-button-pair bh-button-pair--equal">
    <button
      class="bh-button--tertiary"
      (click)="onNo()"
      type="button"
      data-atm-button-no="no-button"
    >No</button>
    <button
      class="bh-button--primary"
      (click)="onYes()"
      type="button"
      data-atm-button-yes="yes-button"
      id="autofocus-target"
    >Yes</button>
  </div>
</mat-dialog-content>
