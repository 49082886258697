<ng-container *ngIf="(loginMFAContent | async)?.loaded">
    <article
      *ngIf="{ loginMFA: (loginMFAContent | async)?.content } as loginMFAContent"
      class="mat-typography"
    >
      <img
        [src]="'assets/exclamation.svg'"
        [alt]="''"
      />
      <h1 class="bh-heading bh-heading--1"> 
        <label       
        [innerHTML]="loginMFAContent?.loginMFA?.BHL_MFALogin_Error_ExtremeRiskError_Title"
      ></label></h1>
      <p class="field-content field-content--description-font">
        <label        
        [innerHTML]="this.extremeHighRiskErrorBodyTemplate"
      ></label>    
      </p>
      <a target="_blank"></a>
    </article>
  </ng-container>