import { IEnvironment } from '../app/models';

/**
 * The environment variables for the Test environment
 */
export const environment: IEnvironment = {
  production: true,
  trace: false,
  apiHost: 'https://apibhltest.bhdts.com/api',
  apiVersionUri: {
    v1: '/v1',
  },
  apiAuthHost: 'https://apibhltest.bhdts.com/api/v1',
  versionDate: 'f2bdf219be0445ed9cb6c1391125c99e',
  cacheBustingVersion: '6980d09f3072290e4b09eb2afe387b273bae81b7af67c2e30d24db9be68e72f3',
  microsoft: {
    maps: {
      key: 'AqWwxSD3WAZJQmxpT3ZfrH0MtEczD4qB0Y-u4BgkC4alVFNRAQajO_tXp9Wp-plb',
      mapControlUrl: 'https://sdk.virtualearth.net/api/maps/mapcontrol',
      restApiUrl: 'https://dev.virtualearth.net/REST/v1',
    },
  },
  google: {
    gtm: {
      prodContainer: false,
      gtmId: 'GTM-T5MTQWP',
      gtmAuth: 'WL1n8D71oXQ387hMzkQYvg',
      gtmPreview: 'env-546',
      gtmCookieWin: 'x',
    },
    reCaptcha: {
      siteKey: '6LeonLsaAAAAAMa4Tsm_UviZBqPIOYdVtt4PGd1o',
      secretKey: '6LeonLsaAAAAALCkbwLBVdePMRYCXLuCecNxN6xT',
      enable: false,
    },
  },
  pendoKey: '672bb382-89e6-484c-6825-cb518fd863d2',
  oneTrustKey: 'd3929886-0192-4a9b-966c-b1feb101b6eb-test',
  federatedUrl: '/Account/LoginFDC',
  exploreBenefitsUrl: '/account/ExploreBenefits/',
  benefitsUrl: 'https://perf.brighthorizons.com',
  enableCookieBanner: true,
  enableGtm: true,
  versionNumber: '1203199320052021',
  baseHref: '/spa',
  debugLogs: true,
  fullstoryEnable: true,
  fullStoryOrgId: 'o-1Y93GA-na1',
  pendoEnable: true,
  OverlayPopupTimeout: 10000,
  userNameExpiry: 2,
  invalidMaximumAttempt: 3,
  cookieExpiry: 1826,
  EnableBH: true,
  encryptLocalStorage: true,
  maintenanceMode: false, // Added this flag to display the maintenance page
};
