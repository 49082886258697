import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, Observable, take, tap } from 'rxjs';

import { errorPageRoute } from '../../constants';
import { ConfigActions } from '../config/config.actions';
import { selectApiToken, selectBhPropertiesURL } from '../config/config.reducers';
import { ContentActions } from '../content/content.actions';
import { LoggerService } from '../logger-service/logger.service';
import { SignupActions } from '../signup/signup.actions';
import {
  selectEmailActivityState,
  selectFetchEligibilityOsrDetailsState,
  selectFetchRegistrationById,
  selectPersonInfoState,
  selectRetrieveFICDetailsState,
} from '../signup/signup.reducers';
import {
  BhQueryParams,
  ContentPages,
  EventName,
  IAppJwt,
  IEmailActivityState,
  IEmployeeDetailsState,
  IFetchEligibilityOsrDetailsState,
  IFetchEligibilityOsrDetailsStatus,
  IFetchRegistrationByIdState,
  IPersonInfoState,
  IRetrieveFICDetailsState,
  IRetrieveFICDetailsStatus,
  ISelectedEmployerState,
  MagicLinkSubCategory,
  RegistrationSubCategory,
  SourceId,
} from '../../models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SetupPasswordGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly loggerService: LoggerService,
  ) { }

  public canActivate(): Observable<boolean> | boolean {
    let sourceId: SourceId | null | undefined;
    let requestId: string | undefined;
    let saUniqueID: string;
    let isFederatedNonEmployee: boolean = false;
    let fgPwdOtpFlow: boolean = false;

    this.store.dispatch(ConfigActions.loading({ loading: true }));
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        sourceId = params.sourceid?.toLowerCase() as SourceId | undefined;
        requestId = params.requestid as string | undefined;
        saUniqueID = params.userinfoid ?? params.said as string;
        isFederatedNonEmployee = params.sourceid?.toLowerCase() === SourceId['federated-non-employees'];
        fgPwdOtpFlow = params.fgPwdOtpFlow?.toLowerCase() === 'true';

        if (!isFederatedNonEmployee) {
          if (saUniqueID) {
            this.store.dispatch(ConfigActions.loading({ loading: true }));
            this.store.dispatch(SignupActions.loadPersonInfo({ input: saUniqueID }));

            this.store.select(selectPersonInfoState)
              .pipe(
                filter((personalInfoState: IPersonInfoState): boolean => personalInfoState.loaded || personalInfoState.error !== null),
                take(1),
                // eslint-disable-next-line @ngrx/avoid-mapping-selectors
                map((): true => true),
              );
          }
        }
      });

    // Adding this for Otp forgot password flow
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (fgPwdOtpFlow) {
      return true;
    }

    switch (sourceId) {
      case SourceId.osr:
        return this.handleOsrLink(requestId);
      case SourceId['fic-registration']:
        return this.handleFicManualReg(requestId);
      case SourceId['cc-care-recipients']:
      case SourceId['federated-non-employees']:
        return this.handleCC(requestId);
      case SourceId['broker-liaison']:
      case SourceId['fic-contact-center']:
      case SourceId.cohs:
      case SourceId['operator-owner']:
      case SourceId['reset-password']:
      case SourceId['forgot-password']:
        return this.handleEmailActivity(sourceId, requestId);
      default:
        return true;
    }
  }

  private handleMissingRequestId(serviceName: string): false {
    this.router.navigate([ ...errorPageRoute ]);

    // this.loggerService.sendTrackEvent(`Missing RequestId for ${serviceName}`, null, EventName.VERIFYEMPLOYER_FAILED);
    if (environment.debugLogs) {
      console.error(`Missing RequestId for ${serviceName}`);
    }

    return false;
  }

  private handleOsrLink(requestId: string | undefined): Observable<boolean> | boolean {
    if (requestId) {
      this.store.dispatch(SignupActions.fetchEligbilityOsrDetails());

      return this.store
        .select(selectFetchEligibilityOsrDetailsState)
        .pipe(
          filter((osrDetails: IFetchEligibilityOsrDetailsState): boolean => osrDetails.loaded || osrDetails.error !== null),
          take(1),
          tap((osrDetails: IFetchEligibilityOsrDetailsState): void => {
            if (osrDetails.error) {
              this.router.navigate([ ...errorPageRoute ]);
            } else {
              const data: IFetchEligibilityOsrDetailsStatus = osrDetails.data as IFetchEligibilityOsrDetailsStatus;

              // store the details in the appropriate state slices so when we dispatch the action to verify if this user exists already,
              // the effect has all the data it needs
              this.store.dispatch(ConfigActions.bhPropertiesUrl({
                bhPropertiesURL: {
                  clientguid: data.clientGuid,
                },
              }));
              this.store.dispatch(SignupActions.employeeDetails({
                employeeDetails: {
                  email: data.workEmail,
                  clientGuid: data.clientGuid,
                } as IEmployeeDetailsState,
              }));
              this.store.dispatch(SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientName: data.clientName as string,
                  clientGuid: data.clientGuid,
                  clientId: data.clientId as number,
                } as ISelectedEmployerState,
              }));
              this.store.dispatch(SignupActions.fetchregistrationbyid({ id: data.eligibilityUniqueValue ?? data.workEmail }));
            }
          }),
          combineLatestWith(
            this.store.select(selectFetchRegistrationById),
          ),
          // eslint-disable-next-line max-len
          filter(([ , verify ]: [IFetchEligibilityOsrDetailsState, IFetchRegistrationByIdState]): boolean => verify.loaded || verify.error !== null),
          take(1),
          tap(([ osrDetails, verify ]: [IFetchEligibilityOsrDetailsState, IFetchRegistrationByIdState]): void => {
            if (verify.loaded) {
              // if data came back, it means the user is already registered, and we should navigate to the already registered page
              this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.userAlreadyRegisteredPage }));
              this.loggerService.sendTrackEvent('Account Exists', JSON.stringify(verify.fetchRegistrationByIdStatus), EventName.OSR_REGISTRATION, undefined, RegistrationSubCategory.ALREADY_REGISTERED);
              this.router.navigate([ '/global', 'already-registered' ]);
            } else if (typeof verify.error !== 'string' && verify.error?.status !== 404) {
              // if a non-404 error occurred, redirect to the error page, otherwise, just continue with the flow
              this.router.navigate([ ...errorPageRoute ]);
            } else {
              const data: IFetchEligibilityOsrDetailsStatus = osrDetails.data as IFetchEligibilityOsrDetailsStatus;
              const requestExpiry: Date = new Date(`${data.requestExpiry as string}Z`);

              // check the `requestExpiry` and act accordingly
              if (requestExpiry < new Date()) {
                this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.magicLinkExpiredPage }));
                this.loggerService.sendTrackEvent('The OSR Magic Link has Expired', JSON.stringify(osrDetails.data), EventName.OSR_REGISTRATION, undefined, MagicLinkSubCategory.EMAIL_EXPIRED);
                this.router.navigate([ '/global', 'expired' ]);
              }
            }
          }),
          map(([ osrDetails ]: [IFetchEligibilityOsrDetailsState, IFetchRegistrationByIdState]): boolean => osrDetails.loaded),
        );
    }

    return this.handleMissingRequestId('OSR');
  }

  private handleFicManualReg(requestId: string | undefined): Observable<boolean> | boolean {
    if (requestId) {
      this.store.dispatch(SignupActions.retrieveFicDetails());

      return this.store
        .select(selectRetrieveFICDetailsState)
        .pipe(
          filter((ficMagicLinkDetails: IRetrieveFICDetailsState): boolean => ficMagicLinkDetails.loaded || ficMagicLinkDetails.error !== null),
          take(1),
          tap((ficMagicLinkDetails: IRetrieveFICDetailsState): void => {
            if (ficMagicLinkDetails.error) {
              this.router.navigate([ ...errorPageRoute ]);
            } else {
              this.store.dispatch(ConfigActions.bhPropertiesUrl({
                bhPropertiesURL: {
                  clientguid: ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.clientGuid,
                },
              }));

              this.store.dispatch(SignupActions.employeeDetails({
                employeeDetails: {
                  email: ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.workEmail ?? null,
                  clientName: ficMagicLinkDetails.retrieveFICDetailsStatus?.clientName,
                  uniqueId: ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.eligibilityUniqueValue,
                } as IEmployeeDetailsState,
              }));
              this.store.dispatch(SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientName: ficMagicLinkDetails.retrieveFICDetailsStatus?.clientName as string,
                  clientGuid: ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.clientGuid as string,
                  clientId: 0 as number,
                } as ISelectedEmployerState,
              }));
              this.store.dispatch(SignupActions.fetchregistrationbyid({
                id: (ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.eligibilityUniqueValue &&
                  // eslint-disable-next-line max-len
                  ficMagicLinkDetails.retrieveFICDetailsStatus.registrationData.eligibilityUniqueValue.length > 0 ? ficMagicLinkDetails.retrieveFICDetailsStatus.registrationData.eligibilityUniqueValue : ficMagicLinkDetails.retrieveFICDetailsStatus?.registrationData?.workEmail) as string,
              }));
            }
          }),
          combineLatestWith(
            this.store.select(selectFetchRegistrationById),
          ),
          filter(([ , verify ]: [IRetrieveFICDetailsState, IFetchRegistrationByIdState]): boolean => verify.loaded || verify.error !== null),
          take(1),
          tap(([ ficMagicLinkDetails, verify ]: [IRetrieveFICDetailsState, IFetchRegistrationByIdState]): void => {
            if (verify.loaded) {
              // if data came back, it means the user is already registered, and we should navigate to the already registered page
              this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.userAlreadyRegisteredPage }));
              this.loggerService.sendTrackEvent('Account Exists', JSON.stringify(verify.fetchRegistrationByIdStatus), EventName.FIC_REGISTRATION, undefined, RegistrationSubCategory.ALREADY_REGISTERED);
              this.router.navigate([ '/global', 'already-registered' ]);
            } else if (typeof verify.error !== 'string' && verify.error?.status !== 404) {
              // if a non-404 error occurred, redirect to the error page, otherwise, just continue with the flow
              this.router.navigate([ ...errorPageRoute ]);
            } else {
              // check the `requestExpiry` and act accordingly
              // eslint-disable-next-line max-len
              const requestExpiry: Date = new Date(`${(ficMagicLinkDetails.retrieveFICDetailsStatus as IRetrieveFICDetailsStatus).requestExpiry as string}Z`);

              if (requestExpiry < new Date()) {
                const info: IRetrieveFICDetailsStatus = ficMagicLinkDetails.retrieveFICDetailsStatus as IRetrieveFICDetailsStatus;

                // store the details in the appropriate state slices so when we click the Resend magic link on
                // the expired page, the effect has all the data it needs
                this.store.dispatch(SignupActions.employeeDetails({
                  employeeDetails: {
                    firstName: info.registrationData?.firstName,
                    lastname: info.registrationData?.lastName,
                    email: info.registrationData?.workEmail,
                    clientGuid: info.registrationData?.clientGuid as string,
                    country: info.registrationData?.country,
                    zipCode: info.registrationData?.postalCode,
                  } as IEmployeeDetailsState,
                }));
                this.store.dispatch(SignupActions.ficClientDetails({
                  ficClientDetails: {
                    isEmployer: !info.registrationData?.isNotEmployed,
                    clientName: info.clientName,
                    nonClientName: info.registrationData?.nonClientEmployerName,
                    eligibilityUniqueValue: info.registrationData?.eligibilityUniqueValue,
                  },
                }));
                this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.magicLinkExpiredPage }));
                this.loggerService.sendTrackEvent('The FIC Manual Registration Magic Link has Expired', JSON.stringify(ficMagicLinkDetails.retrieveFICDetailsStatus), EventName.FIC_REGISTRATION, undefined, MagicLinkSubCategory.EMAIL_EXPIRED);
                this.router.navigate([ '/global', 'expired' ]);
              }
            }
          }),
          // eslint-disable-next-line @ngrx/avoid-mapping-selectors
          map(([ ficMagicLinkDetails ]: [IRetrieveFICDetailsState, IFetchRegistrationByIdState]): boolean => ficMagicLinkDetails.loaded),
        );
    }

    return this.handleMissingRequestId('FIC Manual Registration');
  }

  private handleCC(requestId: string | undefined): Observable<boolean> | boolean {
    if (requestId) {
      this.store.dispatch(SignupActions.loadPersonInfo({ input: requestId }));

      return this.store
        .select(selectPersonInfoState)
        .pipe(
          filter((personInfo: IPersonInfoState): boolean => personInfo.loaded || personInfo.error !== null),
          take(1),
          tap((personInfo: IPersonInfoState): void => {
            if (personInfo.error) {
              this.router.navigate([ ...errorPageRoute ]);
            }
          }),
          // eslint-disable-next-line @ngrx/avoid-mapping-selectors
          map((personInfo: IPersonInfoState): boolean => personInfo.loaded),
        );
    }

    return this.handleMissingRequestId('College Coach');
  }

  private handleEmailActivity(sourceId: SourceId, requestId: string | undefined): Observable<boolean> | boolean {
    const serviceName: Partial<{ [key in SourceId]: string }> = {
      [SourceId['broker-liaison']]: 'CL/Broker',
      [SourceId['fic-contact-center']]: 'FIC Contact Center',
      [SourceId.cohs]: 'COHS',
      [SourceId['operator-owner']]: 'Operator Owner',
      [SourceId['reset-password']]: 'Reset Password',
      [SourceId['forgot-password']]: 'Forgot Password',
    };

    if (requestId) {
      this.store.dispatch(SignupActions.loadEmailActivity({ input: requestId }));

      return this.store
        .select(selectEmailActivityState)
        .pipe(
          filter((emailActivity: IEmailActivityState): boolean => emailActivity.loaded || emailActivity.error !== null),
          take(1),
          combineLatestWith(
            this.store.select(selectBhPropertiesURL),
          ),
          tap(([ emailActivity, params ]: [IEmailActivityState, BhQueryParams]): void => {
            if (emailActivity.error) {
              this.router.navigate([ ...errorPageRoute ]);
            } else {
              // check if the user came from a non CL/Broker link and if so, check the `requestExpiry` and act accordingly
              if (emailActivity.data?.requestExpiry) {
                const requestExpiry: Date = new Date(`${emailActivity.data.requestExpiry}Z`);

                if (requestExpiry < new Date()) {
                  this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.magicLinkExpiredPage }));
                  // eslint-disable-next-line security/detect-object-injection
                  this.loggerService.sendTrackEvent(`The ${serviceName[sourceId] as string} Magic Link has Expired`, JSON.stringify(emailActivity.data), EventName.MAGIC_LINK, undefined, MagicLinkSubCategory.EMAIL_EXPIRED);

                  this.router.navigate([ '/global', 'expired' ]);
                }
              }

              if (!params.clientguid || params.clientguid.length < 1) {
                this.store.dispatch(ConfigActions.bhPropertiesUrl({
                  bhPropertiesURL: {
                    clientguid: emailActivity.data?.personResponse?.crmClientId,
                  },
                }));
              }

              this.store.select(selectApiToken)
                .pipe(
                  take(1),
                )
                .subscribe((tokenInfo: IAppJwt | null): void => {
                  this.store.dispatch(ConfigActions.saveAppAuthToken({
                    tokenInfo: {
                      ...tokenInfo,
                      userInfoId: emailActivity.data?.userInfoId as string,
                      token: tokenInfo?.token as string,
                      expiresAt: tokenInfo?.expiresAt as number,
                    },
                  }));
                });
            }
          }),
          // eslint-disable-next-line @ngrx/avoid-mapping-selectors
          map(([ emailActivity ]: [IEmailActivityState, BhQueryParams]): boolean => emailActivity.loaded),
        );
    }

    // eslint-disable-next-line security/detect-object-injection
    return this.handleMissingRequestId(serviceName[sourceId] as string);
  }
}

export const setupPasswordGuard: CanActivateFn = (): Observable<boolean> | boolean => inject(SetupPasswordGuard).canActivate();
