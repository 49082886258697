import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { setupPasswordGuard } from '../../core/guards/setup-password.guard';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { SetupPasswordComponent } from '../../shared/components/setup-password/setup-password.component';
import { AlreadyRegisteredComponent } from './components/already-registered/already-registered.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { ErrorComponent } from './components/error/error.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HelpComponent } from './components/help/help.component';
import { MagicLinkExpiredComponent } from './components/magic-link-expired/magic-link-expired.component';
import { MfaSignupComponent } from './components/mfa-signup/mfa-signup.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { SystemOutageErrorComponent } from './components/system-outage-error/system-outage-error.component';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { helpGuard } from './guards/help.guard';
import { mfaGuard } from './guards/mfa.guard';
import { PasswordUpdatedComponent } from './components/password-updated/password-updated.component';
import { FederatedaccountNotFoundComponent } from './components/federatedaccount-not-found/federatedaccount-not-found.component';
import { fedaccountnotfoundGuard } from './guards/fedaccountnotfound.guard';
import { ExtremlyHighriskUserComponent } from './components/extremly-highrisk-user/extremly-highrisk-user.component';
import { ShortHelpComponent } from './components/short-help/short-help.component';
import { ConfirmationPageShortComponent } from './components/confirmation-page-short/confirmation-page-short.component';
import { ConfirmationMessageComponent } from './components/confirmation-message/confirmation-message.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'help',
    pathMatch: 'full',
  },
  {
    /**
     * The route to the help desk form. If the route configuration changes {@see GlobalRoutingModule}
     * in `src/global/global-routing.module`, {@see helpDeskRoute} constant in `src/constants/app.ts`
     * must be updated as well to match the new route!!!
     */
    path: 'help',
    title: 'Support',
    component: HelpComponent,
    canActivate: [ helpGuard ],
  },
  {
    path: 'short-help',
    title: 'Support',
    component: ShortHelpComponent,
  },
  {
    path: 'forget-password',
    title: 'Forgot Password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'reset-password',
    title: 'Set Password',
    component: SetupPasswordComponent,
    canActivate: [ setupPasswordGuard ],
  },
  {
    path: 'confirmation',
    title: 'Support',
    component: ConfirmationPageComponent,
  },
  {
    path: 'confirmation-short',
    title: 'Support',
    component: ConfirmationPageShortComponent,
  },
  {
    path: 'confirmation-message',
    title: 'Support',
    component: ConfirmationMessageComponent,
  },
  {
    /**
     The route to the global error page where we navigate in certain situations like token endpoint failure,
     JS exception. If the route configuration changes {@see GlobalRoutingModule} in `src/global/global-routing.module`,
     {@see errorPageRoute} constant in `src/constants/error.ts` must be updated as well to match the new route!!!
     */
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'outage',
    component: SystemOutageErrorComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'federatedaccount-not-found',
    title: 'Federated Account Not Found',
    component: FederatedaccountNotFoundComponent,
    canActivate: [ fedaccountnotfoundGuard ],
  },
  {
    path: 'session-expired',
    component: SessionExpiredComponent,
  },
  {
    path: 'mfa',
    title: 'Multi Factor Authentication',
    component: MfaSignupComponent,
    canActivate: [ mfaGuard ],
  },
  {
    path: 'verify-otp',
    title: 'One Time Passcode',
    component: VerifyOtpComponent,
  },
  {
    path: 'already-registered',
    title: 'Already Registered',
    component: AlreadyRegisteredComponent,
  },
  {
    path: 'expired',
    title: 'Email Verification',
    component: MagicLinkExpiredComponent,
  },
  {
    path: 'maintenance',
    title: 'Scheduled Maintenance',
    component: MaintenancePageComponent,
  },
  {
    path: 'password-updated',
    title: 'Password Updated',
    component: PasswordUpdatedComponent,
  },
  {
    path: 'extreme-highrisk',
    title: 'High Risk Activity Detected',
    component: ExtremlyHighriskUserComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class GlobalRoutingModule { }
