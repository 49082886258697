import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  IApiResponse,
  IBenefitDetails,
  ICreateRegistrationPayload,
  ICreateRegistrationStatus,
  IEligibilityFromMagicLinkStatus,
  IEligibilityPayload,
  IEmployer,
  IFetchRegistrationByIdPayload,
  IFetchRegistrationByIdStatus,
  IMagicLinkEmailPayload,
  IPasscodePayload,
  IRegistrationStatus,
  ISendMagicLinkStatus,
  ISendOTPpayload,
  IMfaVerificationPayload,
  IMfaVerificationStatus,
  IFranchiseLocation,
  IFranchiseAccountQuestion,
  IValidateOTPpayload,
  IFetchFederatedDetailsPayload,
  IFetchFederatedDetailsStatus,
  ISendEmailVerifyLinkPayload,
  ISaveMfaPayload,
  IRetrieveFICDetailsStatus,
  IEmailActivity,
  ICreateUNPwdPayload,
  ICreateUNPwdStatus,
  IFetchEligibilityOsrDetailsStatus,
  IFranchiseUniqueValueRequestParams,
  IOsrNewEmailPayload,
  IPersonInfo,
  IClientInfo,
  ISaveEmployeeDetailsStatus,
  ISaveEmployeeDetailsPayload,
  IFetchUserIdentityStatus,
  IFetchUserPayload,
  IAccountLockPayload,
  IEmailVerify,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(private readonly httpService: HttpClient) { }

  public getRegistrationInfo(
    empSearchId: string,
  ): Observable<IApiResponse<IRegistrationStatus>> {
    return this.httpService.get<IApiResponse<IRegistrationStatus>>(
      `${environment.apiVersionUri.v1}/Client/${empSearchId}/Registration`,
    );
  }

  public getEmployersList(
    searchInput: string,
  ): Observable<IApiResponse<Array<IEmployer>>> {
    return this.httpService.post<IApiResponse<Array<IEmployer>>>(
      `${environment.apiVersionUri.v1}/Client/ClientList`,
      `"${searchInput}"`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public verifyEligibility(
    payload: IEligibilityPayload,
  ): Observable<IApiResponse<string>> {
    // The response will be encrypted for this endpoint, hence, the type is `string`,
    // and the effect has the responsibility to decrypt it
    return this.httpService.post<IApiResponse<string>>(
      `${environment.apiVersionUri.v1}/Registration/EligibilityByBenefitEnc`,
      payload,
    );
  }

  public sendEmailVerify(
    payload: IMagicLinkEmailPayload,
  ): Observable<IApiResponse<ISendMagicLinkStatus>> {
    return this.httpService.post<IApiResponse<ISendMagicLinkStatus>>(
      `${environment.apiVersionUri.v1}/Registration/SendEmailVerifyLink`,
      payload,
    );
  }

  public retrieveEligibilityFromMagicLink(
    requestId: string,
  ): Observable<IApiResponse<IEligibilityFromMagicLinkStatus>> {
    return this.httpService.get<IApiResponse<IEligibilityFromMagicLinkStatus>>(
      `${environment.apiVersionUri.v1}/Person/MagicLinkEmailDetails/${requestId}`,
    );
  }

  public getBenefitDetails(
    clientGuid: string,
    benefitId: string,
  ): Observable<IApiResponse<IBenefitDetails>> {
    return this.httpService.get<IApiResponse<IBenefitDetails>>(
      `${environment.apiVersionUri.v1}/Client/${clientGuid}/Benefit/${benefitId}`,
    );
  }

  public getClientBenefitDetails(
    clientGuid: string,
  ): Observable<IApiResponse<Array<IBenefitDetails>>> {
    return this.httpService.get<IApiResponse<Array<IBenefitDetails>>>(
      `${environment.apiVersionUri.v1}/Client/${clientGuid}/Benefits`,
    );
  }

  public getValidatePostalCode(
    postalCode: string,
    country: string,
  ): Observable<IApiResponse<boolean>> {
    return this.httpService.get<IApiResponse<boolean>>(
      `${environment.apiVersionUri.v1}/Registration/ValidatePostalCode/${postalCode}/${country}`,
    );
  }

  public createRegistration(
    payload: ICreateRegistrationPayload,
  ): Observable<IApiResponse<ICreateRegistrationStatus>> {
    return this.httpService.post<IApiResponse<ICreateRegistrationStatus>>(
      `${environment.apiVersionUri.v1}/Registration`, payload);
  }

  public sendFICEmailVerifyLink(
    payload: ISendEmailVerifyLinkPayload): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(
      `${environment.apiVersionUri.v1}/Registration/SendFICEmailVerifyLink`, payload);
  }

  public fetchRegistrationByID(
    payload: IFetchRegistrationByIdPayload,
  ): Observable<IApiResponse<IFetchRegistrationByIdStatus>> {
    return this.httpService.post<IApiResponse<IFetchRegistrationByIdStatus>>(
      `${environment.apiVersionUri.v1}/Registration/Verify`, payload);
  }

  public fetchUserByID(user: string, clientguid: string):
  Observable<IApiResponse<IFetchUserIdentityStatus>> {
    const payload: IFetchUserPayload = {
      clientGuid: clientguid,
      username: user,
    };

    return this.httpService.post<IApiResponse<IFetchUserIdentityStatus>>(
      `${environment.apiVersionUri.v1}/Registration/VerifyUser`, payload);
  }

  public fetchFederatedDetails(
    payload: IFetchFederatedDetailsPayload,
  ): Observable<IApiResponse<IFetchFederatedDetailsStatus>> {
    return this.httpService.post<IApiResponse<IFetchFederatedDetailsStatus>>(
      `${environment.apiVersionUri.v1}/Person/FederatedEligibilityRecord`, payload);
  }


  public getCountriesList(): Observable<IApiResponse<object>> {
    return this.httpService.get<IApiResponse<object>>(
      `${environment.apiVersionUri.v1}/Person/MasterListDetail/Country`,
    );
  }

  public getCountriesCodeList(): Observable<IApiResponse<object>> {
    return this.httpService.get<IApiResponse<object>>(
      `${environment.apiVersionUri.v1}/Person/MasterListDetail/CountryCode`,
    );
  }

  public verifyPasscode(payload: IPasscodePayload): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Client/VerifyPasscode`, payload);
  }

  public verifyEmailExists(payload: IEmailVerify): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Registration/VerifyEmail`, payload);
  }

  public mfaSendOTP(payload: ISendOTPpayload,
  ): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(
      `${environment.apiVersionUri.v1}/Person/MFA/SendOtp`, payload);
  }

  public mfaVerification(payload: IMfaVerificationPayload,
  ): Observable<IApiResponse<IMfaVerificationStatus>> {
    return this.httpService.post<IApiResponse<IMfaVerificationStatus>>(
      `${environment.apiVersionUri.v1}/Person/MFA/Verification`, payload);
  }

  public mfaValidateOTP(payload: IValidateOTPpayload,
  ): Observable<IApiResponse<string>> {
    return this.httpService.post<IApiResponse<string>>(
      `${environment.apiVersionUri.v1}/Person/MFA/ValidateOtpEnc`, payload);
  }

  public accountLock(payload: IAccountLockPayload): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(
      `${environment.apiVersionUri.v1}/Person/LockPerson`, payload,
    );
  }

  public saveMfaMobileEmail(payload: ISaveMfaPayload): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(
      `${environment.apiVersionUri.v1}/Person/SaveMfaMobileEmail`, payload);
  }

  public getFranchiseLocations(
    searchInput: string,
    clientGuid: string,
    isEditFlow: boolean,
  ): Observable<IApiResponse<Array<IFranchiseLocation>>> {
    // Restrict results to selected employer (i.e., don't allow switching between McD Franchise & McOpCo) if editing an existing registration
    const clientGrpRequired = isEditFlow ? 'false' : 'true';

    return this.httpService.get<IApiResponse<Array<IFranchiseLocation>>>(
      `${environment.apiVersionUri.v1}/Registration/FranchiseLocationSearch?SearchValue=${searchInput}&clientGuid=${clientGuid}&nsn=null&isClientGrpRequired=${clientGrpRequired}`,
    );
  }

  public getFranchiseAccountQuestions(clientId: number): Observable<IApiResponse<Array<IFranchiseAccountQuestion>>> {
    return this.httpService.get<IApiResponse<Array<IFranchiseAccountQuestion>>>(
      `${environment.apiVersionUri.v1}/Client/${clientId}/AccountQuestionsNew`,
    );
  }

  public getFranchiseEligibilityUniqueValue(params: IFranchiseUniqueValueRequestParams): Observable<IApiResponse<string>> {
    return this.httpService.post<IApiResponse<string>>(
      `${environment.apiVersionUri.v1}/Registration/EligibilityUniqueValue`,
      params,
    );
  }

  public getFICMagicLinkEmailDetails(requestId: string): Observable<IApiResponse<IRetrieveFICDetailsStatus>> {
    return this.httpService.get<IApiResponse<IRetrieveFICDetailsStatus>>(
      `${environment.apiVersionUri.v1}/Person/FICMagicLinkEmailDetails/${requestId}`,
    );
  }

  public getEmailActivity(requestId: string): Observable<IApiResponse<IEmailActivity>> {
    return this.httpService.get<IApiResponse<IEmailActivity>>(`${environment.apiVersionUri.v1}/Person/EmailActivity/${requestId}`);
  }

  public createUNPwd(payload: ICreateUNPwdPayload): Observable<IApiResponse<ICreateUNPwdStatus>> {
    return this.httpService.post<IApiResponse<ICreateUNPwdStatus>>(`${environment.apiVersionUri.v1}/Registration/CreateUserNamePassword`, payload);
  }

  public fetchEligbilityOsrDetails(requestId: string): Observable<IApiResponse<IFetchEligibilityOsrDetailsStatus>> {
    return this.httpService.get<IApiResponse<IFetchEligibilityOsrDetailsStatus>>(`${environment.apiVersionUri.v1}/Person/EligibilityDetails/${requestId}`);
  }

  public triggerNewOsrEmail(payload: IOsrNewEmailPayload): Observable<IApiResponse<boolean>> {
    return this.httpService.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Person/ReCreatePasswordEmail`, payload);
  }

  public getPersonInfo(requestId: string): Observable<IApiResponse<IPersonInfo>> {
    return this.httpService.get<IApiResponse<IPersonInfo>>(`${environment.apiVersionUri.v1}/Person/${requestId}`);
  }

  public getClientInfo(clientId: string): Observable<IApiResponse<IClientInfo>> {
    return this.httpService.get<IApiResponse<IClientInfo>>(`${environment.apiVersionUri.v1}/Client/${clientId}`);
  }

  public saveEmployeeDetails(
    payload: ISaveEmployeeDetailsPayload,
  ): Observable<IApiResponse<ISaveEmployeeDetailsStatus>> {
    return this.httpService.post<IApiResponse<ISaveEmployeeDetailsStatus>>(
      `${environment.apiVersionUri.v1}/Person/Employee`, payload);
  }
}
