/* eslint-disable @typescript-eslint/no-misused-promises */
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { bhQueryParamsSet, errorPageRoute, tokenDuration } from '../../constants';
import {
  BenefitId,
  benefitLogo,
  BhQueryParams,
  ContentPages,
  IApiResponse,
  IAppJwt,
  IAppToken,
  IAsyncAwait,
  LocalStorageKey,
  QueryParamsSet,
} from '../../models';
import { asyncAwaitHandler } from '../../utils';
import { ApiAuthService } from '../auth/api-auth.service';
import { ContentActions } from '../content/content.actions';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ConfigActions } from './config.actions';

/**
 * Handle any app initialization logic necessary to boostrap the app before the app is
 * loaded like grabbing data from the backend, determine flow type, query param values, etc.
 * @param {Store} store The NgRx store instance.
 * @param {ApiAuthService} authService The API Authentication service instance.
 * @param {Router} router
 * @param {LocalStorageService} localStorageService
 * @returns {() => Promise<boolean>} Promise that resolves once we execute all the initialization logic.
 */
export const handleAppInit: (
  store: Store,
  authService: ApiAuthService,
  router: Router,
  localStorageService: LocalStorageService
) => () => Promise<boolean> =
  (
    store: Store,
    authService: ApiAuthService,
    router: Router,
    localStorageService: LocalStorageService,
  ): () => Promise<boolean> => (): Promise<boolean> => new Promise(async (resolve): Promise<void> => {
    const paramsSet: Set<QueryParamsSet> = new Set<QueryParamsSet>(bhQueryParamsSet);
    const url: URL = new URL(window.location.href);
    const bhPropertiesURL: BhQueryParams = {};

    // If benefit id is already in local storage, save in store so the rest of the app can access it
    const benefitIdFromLocalStorage = localStorageService.getItem(LocalStorageKey.benefitId);
    if (benefitIdFromLocalStorage !== null) {
      store.dispatch(ConfigActions.benefitId({
        // eslint-disable-next-line max-len
        benefitId: benefitIdFromLocalStorage && benefitIdFromLocalStorage in benefitLogo ? benefitLogo[benefitIdFromLocalStorage as BenefitId] : benefitLogo[BenefitId.Default],
      }));
    }

    url.searchParams.forEach((value: string, param: string): void => {
      const key: string = param.toLowerCase();

      if (paramsSet.has(key as QueryParamsSet)) {
        if (key === 'benefitid' || key === 'targetid') {
          // if the query param value is in the list of valid IDs, replace BenefitId.Default with the retrieved ID
          store.dispatch(ConfigActions.benefitId({
            benefitId: value && value in benefitLogo ? benefitLogo[value as BenefitId] : benefitLogo[BenefitId.Default],
          }));

          if(key === 'benefitid')
          {
           localStorageService.saveItem(LocalStorageKey.benefitId, value);
          }
          // bhPropertiesURL[key as QueryParamsSet] = value;
        } else if (value) {
          // the list of params is defined by the app, not a user input, safe to suppress the below warning
          // eslint-disable-next-line security/detect-object-injection
          bhPropertiesURL[key as QueryParamsSet] = value;
        }
      }
    });

    store.dispatch(ConfigActions.setAppOrigin({ origin: url.origin }));
    store.dispatch(ConfigActions.bhPropertiesUrl({ bhPropertiesURL }));

    if (bhPropertiesURL.clientguid) {
      store.dispatch(ConfigActions.clientGuidFromQuery({ isFromQuery: true }));
    }

    // if we are going to the outage page, skip any backend calls!
    if (!url.pathname.toLowerCase().includes('global/outage')) {
      const { response, error }: IAsyncAwait<IApiResponse<IAppToken> | null> = await asyncAwaitHandler(authService.authenticateApp());

      if (response?.data.accessToken === null || error) {
        router.navigate([ ...errorPageRoute ]);
      } else {
        const tokenInfo: IAppJwt = {
          token: response?.data.accessToken as string,
          userInfoId: response?.data.userInfoId as unknown as string,
          expiresAt: Date.now() + tokenDuration,
        };

        // store the token in the store, so it can be attached to each API call by the interceptor
        store.dispatch(ConfigActions.saveAppAuthToken({ tokenInfo }));

        // grab some globally necessary content like for account already exists section on each page
        store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.employeeSearchPage }));

        // grab the "Required" heading text used on all forms
        store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.eligibilityPage }));

        // grab the "Something went wrong..." message for the error toast for unknown BE errors (like 5xx on form submit for example)
        store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.createAccountPage }));
      }
    }

    resolve(true);
  });
