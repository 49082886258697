/* eslint-disable camelcase */
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import {
  BhQueryParams,
  IEmployeeSearchContent,
  LocalStorageKey,
  RegistrationSubCategory,
} from '../../../../models';
import { selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { SharedService } from 'src/app/shared/shared.service';
import { Router } from '@angular/router';
import { ConfigActions } from 'src/app/core/config/config.actions';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.scss' ],
})
export class ErrorComponent implements OnInit {

  public isContentLoaded: boolean = true;
  public readonly content: Partial<IEmployeeSearchContent> = {
    BHL_EmployerSearch_Label_Login: 'Already have an account?',
    BHL_EmployerSearch_Label_Login_Button: 'Login',
  };

  constructor(
    private readonly location: Location,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store,
    private readonly sharedService: SharedService,
    private readonly router: Router,
  ) { }

  public ngOnInit(): void {
    // initialize the Pendo logic
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        if (params.eventname) {
          if(params.eventcatgry === RegistrationSubCategory.BENEFIT_NOT_SUPPORTED)
          {
            this.isContentLoaded = false;
            this.store.dispatch(ConfigActions.flowType({ flowType: 'login' }));
            this.localStorageService.saveItem(LocalStorageKey.clientGuid, params.clientguid as string);
            this.router.navigate([ '/signup', 'search' ]);
          }
          else
          {
            this.sharedService.TrackPendoEvent(params.eventname, params.benefitid as string, params.eventcatgry as string);
          }
        }
      });
  }

  public handleTryAgain(): void {
    // Remove clientGuid from localStorage to prevent a redirect/error loop
    this.localStorageService.removeItem(LocalStorageKey.clientGuid);

    // we try to navigate to the previous page if possible
    this.location.back();
  }
}
