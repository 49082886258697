<article class="page-not-found">
  <div class="error-icon">
    <img
      [src]="'assets/exclamation.svg'"
      [alt]="'Error'"
    />
  </div>
  <h1 class="bh--heading">Page Not Found</h1>
  <p class="field-content field-content--description-font">
    This page no longer exists or has moved. If you think we should know about
    this, you can report this 404 error to our
    <a
      [href]="''"
      [routerLink]="$any(helpDeskRoute)"
      [queryParams]="queryParams"
      target="_blank"
      >support team</a
    >.
  </p>
  <bh-already-have-account
    [content]="$any(content)"
    [showHelpDesk]="false"
    [showLogin]="false"
  ></bh-already-have-account>
</article>
