/* eslint-disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as FullStory from '@fullstory/browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
// Declare Optanon as a global variable
declare var Optanon: any;
interface CustomWindow extends Window {
  OnetrustActiveGroups?: string[];
}

if (environment.enableCookieBanner && !environment.maintenanceMode) {
  function setOptanonWrapperAttributes() {
    const button = document.querySelector('.ot-floating-button__open');
    if (button) {
      button.setAttribute('aria-label', 'Manage Cookies');
    }
  }
  function observeButtonChanges() {
    const button = document.querySelector('.ot-floating-button__open');
    if (!button) return;
  
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-label') {
          if (button.getAttribute('aria-label') !== 'Manage Cookies') {
            // If aria-label is not 'Manage Cookies', set it to 'Manage Cookies'
            setOptanonWrapperAttributes();
          }
          break;
        }
      }
    });
  
    observer.observe(button, { attributes: true });
  }
  document.addEventListener('DOMContentLoaded', () => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // Check if the added node contains the OneTrust banner
          if (document.querySelector('.ot-floating-button__open')) {
            observer.disconnect(); // Disconnect the observer once the banner is found
            setOptanonWrapperAttributes(); // Call the function to set attributes
            observeButtonChanges(); // Start observing changes in the button
            break;
          }
        }
      }
    });
  observer.observe(document.body, { childList: true, subtree: true });
  // add OneTrust cookie consent script
  const body: HTMLBodyElement = document.getElementsByTagName('body')[0] as HTMLBodyElement;
  const sOneTrust: HTMLScriptElement = document.createElement('script');
  sOneTrust.type = 'text/javascript';
  sOneTrust.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
  sOneTrust.async = true;
  sOneTrust.setAttribute('data-domain-script', environment.oneTrustKey);
  sOneTrust.setAttribute('charset', 'UTF-8');
  sOneTrust.setAttribute('fetchpriority', 'low');
  body.appendChild(sOneTrust);
  // End OneTrust cookie consent script

  let gtmQueryParams: string = '';

  // add test container params (used for the lower environments, we must not pass these in Production!)
  if (!environment.google.gtm.prodContainer) {
    gtmQueryParams = `&gtm_auth=${environment.google.gtm.gtmAuth}&gtm_preview=${environment.google.gtm.gtmPreview}&gtm_cookies_win=${environment.google.gtm.gtmCookieWin}`
  }

  if (environment.enableGtm) {
    // add GTM (Google Tag Manager) noscript iframe (the GTM script is injected in app.component.ts
    // using the `angular-google-tag-manager` package).
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
  <iframe
    src="https://www.googletagmanager.com/ns.html?id=${environment.google.gtm.gtmId}${gtmQueryParams}"
    height="0"
    width="0"
    style="display:none;visibility:hidden"
  ></iframe>
  `;
    body.appendChild(noscript);
  }  
});
}
// add Pendo script
function initializePendo() {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      // @ts-expect-error
      let v; let w; let x; let y; let
        z;
      // @ts-expect-error
      o = p[d] = p[d] || {};
      // @ts-expect-error
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          // @ts-expect-error
          o[m] = o[m] || function () {
            // @ts-expect-error
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
      }
      y = e.createElement(n);
      y.id = 'BHLoginPendo';
      // @ts-expect-error
      y.async = true;
      // @ts-expect-error
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      y.setAttribute('fetchpriority', 'low');
      z = e.getElementsByTagName(n)[0];
      // @ts-expect-error
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(environment.pendoKey);
}

function checkConsentAndInitializePendo() {
  const interval = setInterval(() => {
    if (typeof Optanon !== 'undefined' && Optanon.OnConsentChanged) {
      clearInterval(interval);
      Optanon.OnConsentChanged(() => {
        handleConsentChange();
      });
      handleConsentChange(); // Initial check
    }
  }, 100);
}
function deleteCookie() {
  const cookiesToDelete = [
    'ai_user',
    'ai_session',
    'pendo_identity',
    'pendo_accountId',
    'PendoXSRFToken',
    'PendoXSRFToken_C80',
    'fs_uid',
    'fs_session'
  ];
  cookiesToDelete.forEach(name => {console.log(name);
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });  
};
function handleConsentChange() {
  const defaultView = window as CustomWindow;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (defaultView && defaultView.OnetrustActiveGroups) {
    const hasOneTrustOptInValues = defaultView.OnetrustActiveGroups !== null && defaultView.OnetrustActiveGroups !== undefined;
    if (hasOneTrustOptInValues) {
      const oldOneTrustActiveGroups = localStorage.getItem('OldBHLoginOnetrustActiveGroupsIds');
      const newOneTrustActiveGroups = defaultView.OnetrustActiveGroups.toString();
      if (oldOneTrustActiveGroups && oldOneTrustActiveGroups === newOneTrustActiveGroups) {
        // no action needed
      } else {
        localStorage.setItem('OldBHLoginOnetrustActiveGroupsIds', newOneTrustActiveGroups);
      }

      const pendoScript = document.getElementById('BHLoginPendo');
      const isPendoScriptPresent = pendoScript !== null && pendoScript !== undefined;
      const hasC0002PerfCookieOptedOut = !defaultView.OnetrustActiveGroups.includes('C0002');
      if (hasC0002PerfCookieOptedOut) {
        if (isPendoScriptPresent) {
          pendoScript.remove();
          window.location.reload();
        }
        deleteCookie();      
      } else {
        if (!isPendoScriptPresent) {
          initializePendo();         
        }
        FullStory.init({ orgId: environment.fullStoryOrgId });        
      }
    }
  }
}

  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
  .then(() => {
    checkConsentAndInitializePendo();
  });
