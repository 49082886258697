import { Injectable } from '@angular/core';

/**
 * Custom interface allows us to define a custom callback on the window when loading the Bing Maps Control
 */
export interface ICustomWindow extends Window {
  onMapControlLoad: () => void;
}

function getWindow(): Window & typeof globalThis | ICustomWindow {
  return window;
}

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class WindowRefService {
  public get nativeWindow(): ICustomWindow {
    return getWindow() as ICustomWindow;
  }
}
