import { IFranchiseAccountQuestion } from '../models';

/**
 * ClientGuid associated with McDonald's Franchise
 */
export const mcDonaldsFranchiseClientGuid: string = '67f6b1c9-fd37-ec11-a847-005056991c60';

/**
 * ClientGuid associated with McDonald's Corporate Restaurants, aka McOpCo (also a Franchise client)
 */
export const mcOpCoClientGuid: string = 'a672e6a1-9b0d-eb11-a844-0050569942a3';

/**
 * Determines whether the provided clientGuid identifies one of the McDonald's Franchise clients.
 * Helper function to be used in areas of the app where special behavior is needed for McDonald's Franchise clients.
 * @param {string} clientGuid The clientGuid of the employer to check
 * @returns {boolean} true if the provided clientGuid matches one of the McDonald's Franchise clientGuids, otherwise false
 */
export const isMcDonaldsFranchise = (clientGuid: string): boolean => clientGuid === mcDonaldsFranchiseClientGuid || clientGuid === mcOpCoClientGuid;

/**
 * String to prepend to accountQuestionId to generate a form control name and id for the associated form element
 */
export const franchiseQuestionPrefix: string = 'question';

/**
 * Generates a unique form control name based on account question id
 * @param {IFranchiseAccountQuestion} question An account question data object
 * @returns {string} A unique ID for the form control
 */
export const getFranchiseQuestionFormControlName = (question: IFranchiseAccountQuestion): string => `${franchiseQuestionPrefix}${question.accountQuestionId}`;
