<ng-container *ngIf="(content | async)?.loaded">
  <article class="create-account" *ngIf="{
      page: (content | async)?.content,
      changePass: (changePassContent | async)?.content,
      account: (accountContent | async)?.content,
      required: (requiredContent | async)?.content,
      privacyerror: (privacyerrorContent | async)?.content
    } as pageContent">
    <h1 class="bh--heading">
      {{ isResetPass ? pageContent.changePass?.BHL_ChangePW_Header : pageContent.page?.BHL_AcctCrt2_Label_Header }}
    </h1>
    <ng-container
      *ngIf="!isBrokerLiaison && !isOperatorOwner && !isCohs && !isResetPass && !isForgotPass && !isFicContactCenter">
      <bh-stepper [currentStep]="2"></bh-stepper>
      <bh-employer-logo [heading]="clientName"></bh-employer-logo>
    </ng-container>
    <p *ngIf="!isResetPass" class="field-content">
      {{ pageContent.page?.BHL_AcctCrt2_Label_SubHeader }}
    </p>
    <form [formGroup]="accCredentialsForm" class="form">
      <p class="required-label">
        <span class="bh--marked">*</span>{{ pageContent.required?.BHL_Eligibility_Label_Required }}
      </p>

      <bh-sr-only-page-level-error [formGrp]="accCredentialsForm"></bh-sr-only-page-level-error>

      <!-- START Username Field -->
      <div
        *ngIf="!isBrokerLiaison && !isOperatorOwner && !isCohs && !isResetPass && !isForgotPass && !isFicContactCenter"
        class="bh-form__field employee-label form__field" [class.bh-form__field--invalid]="
          (username.invalid && (username.dirty || username.touched)) ||
          usernameNotUnique
        ">
        <label for="username" class="bh-form__field__label label-field">
          {{ pageContent.page?.BHL_AcctCrt2_Label_UN
          }}<span class="bh--marked">*</span>
        </label>
        <input id="username" type="text" placeholder="{{ pageContent.page?.BHL_AcctCrt2_Label_UNPlaceholder }}"
          [attr.aria-describedby]="
            (username.errors?.['required'] && username.invalid && (username.dirty || submitted || username.touched) ? ' error-username-input-empty' : '') +
            (usernameNotUnique ? ' error-username-input-duplicate' : '') +
            (username.invalid && username.dirty && username.value !== '' ? ' error-username-input-invalid' : '')
          " formControlName="username" class="bh-form__field__input input-field" matInput maxlength="60"
          data-atm-input-user-name="Enter Username Name" [ngClass]="{
            'is-valid': (username.dirty || submitted) && usernameValid,
            'is-invalid': (username.dirty || submitted) && !usernameValid
          }" />
        <bh-field-level-error
          *ngIf="(username.errors?.['required'] && username.invalid && (username.dirty || submitted || username.touched))"
          [id]="'error-username-input-empty'"
          [message]="pageContent.page?.BHL_AcctCrt2_Error_UNRequired"></bh-field-level-error>

        <bh-field-level-error *ngIf="usernameNotUnique" [id]="'error-username-input-duplicate'"
          [message]="pageContent.page?.BHL_AcctCrt2_Error_ExistUN"></bh-field-level-error>

        <bh-field-level-error [formErrorGA4] = "ga4FormName" *ngIf="username.invalid && username.dirty && username.value !== ''"
          [id]="'error-username-input-invalid'"
          [message]="pageContent.page?.BHL_AcctCrt2_Error_UNAllowed"></bh-field-level-error>
      </div>
      <!-- END Username Field -->

      <!-- START Current Password Field -->
      <div *ngIf="isResetPass" class="bh-form__field employee-label form__field" [class.bh-form__field--invalid]="
          (currentPassword.invalid && (currentPassword.dirty || currentPassword.touched))
        ">
        <label for="currentPassword" class="bh-form__field__label label-field">
          {{ pageContent.changePass?.BHL_ChangePW_CurrentPW }} <span class="bh--marked">*</span>
        </label>
        <div class="passwordeyeicon form__field__with-icon">
          <input id="currentPassword" [type]="showCurrentPassword ? 'text' : 'password'" name="currentPassword" matInput
            placeholder="{{ pageContent.changePass?.BHL_ChangePW_PHText_CurrentPW }}" [attr.aria-describedby]="
              (
                (currentPassword.invalid && (currentPassword.dirty || currentPassword.touched)) &&
                  (currentPassword.errors?.['required'] ? ' error-current-password-input-empty' : '') +
                (weakPassword ? ' error-password-input-weak' : '')
              )
            " formControlName="currentPassword"
            class="bh-form__field__input input-field password input-field--password" autocomplete="current-password"
            data-atm-input-password="Current password" />
          <button [ngClass]="{
              'input-field__icon': true,
              'input-field__icon--eye': showCurrentPassword,
              'input-field__icon--eye-crossed': !showCurrentPassword
            }" (click)="togglePassword('currentPassword')" type="button" role="switch"
            [attr.aria-checked]="showCurrentPassword" aria-label="Show Password"></button>
          <ng-container *ngIf="
              (currentPassword.invalid && (currentPassword.dirty || currentPassword.touched))
            ">
            <bh-field-level-error *ngIf="currentPassword.errors?.['required']"
              [id]="'error-current-password-input-empty'"
              [message]="pageContent.page?.BHL_AcctCrt2_Error_PWRequired"></bh-field-level-error>
          </ng-container>
        </div>
      </div>
      <!-- END Current Password Field -->

      <!-- START Password Field -->
      <div class="bh-form__field employee-label form__field" [class.bh-form__field--invalid]="
          password.invalid && (password.dirty || password.touched)
        ">
        <label for="password" class="bh-form__field__label label-field">
          {{ isResetPass ? pageContent.changePass?.BHL_ChangePW_NewPW : pageContent.page?.BHL_AcctCrt2_Label_PW }}<span
            class="bh--marked">*</span>
        </label>
        <div class="passwordeyeicon form__field__with-icon">
          <input matInput [type]="showPassword ? 'text' : 'password'" name="password" id="password"
            placeholder="{{ isResetPass ? pageContent.changePass?.BHL_ChangePW_PHText_NewPW : pageContent.page?.BHL_AcctCrt2_Label_PWPlaceholder }}"
            formControlName="password" minlength="8" maxlength="68"
            class="bh-form__field__input input-field password input-field--password" autocomplete="new-password"
            data-atm-input-password="Create a password" [attr.aria-describedby]="
              (requiredValid ? 'validation-rules' : '') +
              (password.invalid &&
              (password.dirty || submitted || password.touched) &&
              !requiredValid
                ? ' error-password-input-empty'
                : '') +
              (weakPassword ? ' error-password-input-weak' : '')
            " [ngClass]="{
              'is-valid': (password.dirty || submitted) && passwordValid,
              'is-invalid': (password.dirty || submitted) && !passwordValid
            }" (blur)="handlePasswordFldFocus()" (focusin)="handlePasswordFldFocus(false)" />
          <button [ngClass]="{
              'input-field__icon': true,
              'input-field__icon--eye': showPassword,
              'input-field__icon--eye-crossed': !showPassword
            }" (click)="togglePassword('password')" type="button" role="switch" [attr.aria-checked]="showPassword"
            aria-label="Show Password"></button>
          <bh-field-level-error *ngIf="
              password.invalid &&
              (password.dirty || submitted || password.touched) &&
              !requiredValid
            " [id]="'error-password-input-empty'"
            [message]="pageContent.page?.BHL_AcctCrt2_Error_PWRequired"></bh-field-level-error>

          <bh-field-level-error *ngIf="weakPassword" [id]="'error-password-input-weak'"
            [message]="pageContent.page?.BHL_AcctCrt2_Error_WeakPW"></bh-field-level-error>

          <div class="invalid-feedback">
            <!-- password validation rules -->
            <div id="validation-rules" *ngIf="requiredValid" class="validationrule" aria-live="polite">
              <div class="validationpart1">
                <h3>
                  {{ pageContent.page?.BHL_AcctCrt2_Error_PW_Validation1_Tile }}
                </h3>
                <ng-container *ngIf="
                    pageContent.page?.BHL_AcctCrt2_Error_PW_Validation1_Rules?.split(
                      contentSeparator
                    ) as rules
                  ">
                  <!-- minlength -->
                  <bh-field-message [success]="minLengthValid" [message]="rules[0]"></bh-field-message>
                  <!-- error message to indicate which rule is missing and to be read ONLY! by screen readers and not displayed on the screen -->
                  <p *ngIf="
                      password.invalid &&
                      password.dirty &&
                      !minLengthValid &&
                      isFocusedOnPasswordLost
                    " aria-live="polite" class="sr-only">
                    {{
                    pageContent.page?.BHL_AcctCrt2_Error_PW_Validation1_Tile
                    }}
                    {{ rules[0] }}
                  </p>

                  <!-- password mismatch -->
                  <bh-field-message [success]="unPasswordMatch" [message]="rules[1]"></bh-field-message>
                  <!-- error message to indicate which rule is missing and to be read ONLY! by screen readers and not displayed on the screen -->
                  <p *ngIf="
                      password.invalid &&
                      password.dirty &&
                      !unPasswordMatch &&
                      isFocusedOnPasswordLost
                    " aria-live="polite" class="sr-only">
                    {{
                    pageContent.page?.BHL_AcctCrt2_Error_PW_Validation1_Tile
                    }}
                    {{ rules[1] }}
                  </p>
                </ng-container>
              </div>
              <div class="validationpart2">
                <h3>
                  {{ pageContent.page?.BHL_AcctCrt2_Error_PW_Validation2_Tile }}
                </h3>
                <ng-container *ngIf="
                    pageContent.page?.BHL_AcctCrt2_Error_PW_Validation2_Rules?.split(
                      contentSeparator
                    ) as rules
                  ">
                  <!-- requires uppercase -->
                  <bh-field-message [success]="onlyUpperCase" [message]="rules[0]"></bh-field-message>

                  <!-- requires lowercase -->
                  <bh-field-message [success]="onlyLowerCase" [message]="rules[1]"></bh-field-message>

                  <!-- requires digit -->
                  <bh-field-message [success]="onlyDigits" [message]="rules[2]"></bh-field-message>

                  <!-- requires special characters -->
                  <bh-field-message [success]="onlySpecialChars" [message]="rules[3]"></bh-field-message>

                  <!-- error message to indicate a rule is missing and to be read ONLY! by screen readers and not displayed on the screen!!! -->
                  <p *ngIf="
                      password.invalid &&
                      password.dirty &&
                      isFocusedOnPasswordLost
                    " aria-live="polite" class="sr-only">
                    {{ pageContent.page?.BHL_AcctCrt2_Error_PWRulesMissing }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Password Field -->

      <!-- START Confirm Password Field -->
      <div class="bh-form__field employee-label form__field" [class.bh-form__field--invalid]="
          (confirmPassword.invalid &&
            (confirmPassword.dirty || confirmPassword.touched)) ||
          passwordMismatch
        ">
        <label for="confirmPassword" class="bh-form__field__label label-field">
          {{ isResetPass ? pageContent.changePass?.BHL_ChangePW_ConfirmPW : pageContent.page?.BHL_AcctCrt2_Label_PW2
          }}<span class="bh--marked">*</span>
        </label>
        <div class="passwordeyeicon form__field__with-icon">
          <input id="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'" name="confirmPassword" matInput
            placeholder="{{ isResetPass ? pageContent.changePass?.BHL_ChangePW_PHText_ConfirmPW : pageContent.page?.BHL_AcctCrt2_Label_PW2Placeholder }}"
            [attr.aria-describedby]="
              (
                (confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) || passwordMismatch) &&
                  (
                    (confirmPassword.errors?.['required'] ? ' error-confirm-password-input-empty' : '') +
                    (!confirmPassword.errors?.['required'] && passwordMismatch ? ' error-confirm-password-input-mismatch' : '')
                  )
              )
            " formControlName="confirmPassword"
            class="bh-form__field__input input-field password input-field--password" autocomplete="new-password"
            data-atm-input-password="Confirm your password" />
          <button [ngClass]="{
              'input-field__icon': true,
              'input-field__icon--eye': showConfirmPassword,
              'input-field__icon--eye-crossed': !showConfirmPassword
            }" (click)="togglePassword('confirmPassword')" type="button" role="switch"
            [attr.aria-checked]="showConfirmPassword" aria-label="Show Password"></button>
          <ng-container *ngIf="
              (confirmPassword.invalid &&
                (confirmPassword.dirty || confirmPassword.touched)) ||
              passwordMismatch
            ">
            <bh-field-level-error *ngIf="confirmPassword.errors?.['required']"
              [id]="'error-confirm-password-input-empty'"
              [message]="pageContent.page?.BHL_AcctCrt2_Error_PW2Required"></bh-field-level-error>

            <bh-field-level-error *ngIf="!confirmPassword.errors?.['required'] && passwordMismatch"
              [id]="'error-confirm-password-input-mismatch'"
              [message]="pageContent.page?.BHL_AcctCrt2_Error_PWConfirm"></bh-field-level-error>
          </ng-container>
        </div>
      </div>
      <!-- END Confirm Password Field -->

      <!-- START Privacy Terms -->
      <div class="privacypolicy" [class.has-errors]="
          acceptTerms.invalid && (acceptTerms.dirty || acceptTerms.touched)
        " *ngIf="isAcceptableUseEnable">
        <mat-checkbox [color]="'primary'" id="checkbox" class="checkbox" formControlName="acceptTerms"
          data-atm-input-privacy-policy="Privacy Policy"></mat-checkbox>
        <div class="privacydetails">
          <label for="checkbox" class="details" [innerHTML]="pageContent.page?.BHL_AcctCrt2_BH_PrivacyTerms">
          </label>
          <span class="bh--marked">*</span>
        </div>
      </div>
      <bh-field-level-error 
        *ngIf="acceptTerms.invalid && (acceptTerms.dirty || acceptTerms.touched) && acceptTerms.errors?.['required']"
        [id]="'error-terms-and-conditions-input-empty'"
        [message]="pageContent.privacyerror?.BHL_AcctCrt1_Error_Privacy"></bh-field-level-error>
      <!-- END Privacy Terms -->

      <div [ngClass]="
          (!isOSRenabled && !isBrokerLiaison && !isCollegeCoach && !isFic && !isOperatorOwner && !isCohs && !isResetPass && !isForgotPass && !isFederatedNonEmployee && !isFicContactCenter)
          ? 'bh-button-pair'
          : 'bh-button-single'
        ">
        <button
          *ngIf="!isOSRenabled && !isBrokerLiaison && !isCollegeCoach && !isFic && !isOperatorOwner && !isCohs && !isResetPass && !isForgotPass && !isFederatedNonEmployee && !isFicContactCenter"
          type="button" class="bh-button--secondary" (click)="navigateBack()">
          {{ pageContent.account?.BHL_EmployerSearch_Button_Previous }}
        </button>
        <button type="submit" class="bh-button--primary" (click)="onContinue()">
          {{ isResetPass ? pageContent.changePass?.BHL_ChangePW_UpdateButton :
          pageContent.account?.BHL_EmployerSearch_Button_Confirm }}
        </button>
      </div>
      <div class="privacy-policy" *ngIf="!isCaptchaDisabled && isRecaptchaEnabled">
        <label class="info-details" [innerHTML]="pageContent.page?.BHL_AcctCrt2_reCAPTCHA"></label>
        <re-captcha #captchaRef (resolved)="resolved()" (error)="onError($event)" errorMode="handled"></re-captcha>
      </div>
    </form>
    <div *ngIf="isOSRenabled; else disabled">
      <bh-already-have-account [content]="pageContent.account" [showLogin]="true"
        [showHelpDesk]="false"></bh-already-have-account>
    </div>
    <!-- <ng-template #disabled>
      <bh-already-have-account [content]="pageContent.account"
        [showLogin]="!isResetPass && !isForgotPass"></bh-already-have-account>
    </ng-template> -->
    <ng-template #disabled>
      <bh-already-have-account [content]="pageContent.account"
        [showLogin]="false"></bh-already-have-account>
    </ng-template>
  </article>
</ng-container>
