import { ILegalLink } from '../models';

/**
 * The privacy notice link content.
 */
export const privacyNotice: ILegalLink = {
  url: 'https://www.brighthorizons.com/Privacy',
  label: 'Privacy Notice',
};

/**
 * The cookie notice link content.
 */
export const cookieNotice: ILegalLink = {
  url: 'https://www.brighthorizons.com/Privacy/Cookie-Notice',
  label: 'Cookie Notice',
};

/**
 * The acceptable use policy notice link content.
 */
export const acceptableUsePolicy: ILegalLink = {
  url: 'https://www.brighthorizons.com/Terms/Terms-use',
  label: 'Acceptable Use Policy',
};

/**
 * The trademark link content.
 */
export const trademarks: ILegalLink = {
  url: 'https://www.brighthorizons.com/trademarks',
  label: 'Trademarks',
};
