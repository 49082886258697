import { BenefitId } from './config';

export enum EventName {
  LOB_TECHNICAL_ERROR = 'LOB-TECHNICAL-ERROR',
  SIGNUP_TECHNICAL_ERROR_FE = 'SIGNUP-TECHNICAL-ERROR-FE',
  SIGNUP_TECHNICAL_ERROR_BE = 'SIGNUP-TECHNICAL-ERROR-BE',
  VERIFYEMPLOYER_FAILED = 'VERIFY-EMPLOYER',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  FIC_REGISTRATION = 'FIC-REGISTRATION',
  OSR_REGISTRATION = 'OSR-REGISTRATION',
  MAGIC_LINK = 'MAGIC-LINK',
  EMPLOYER_SEARCH = 'EMPLOYER-SEARCH',
  SIGNUP_BENEFIT_NOT_SUPPORT = 'SIGNUP-BENEFIT-NOT-SUPPORT',
  USER_ACCOUNTSETUP_ERROR = 'USER-ACCOUNTSETUP-ERROR',
  LOGIN_INVALID_OTP = 'LOGIN-INVALID-OTP',
  LOGIN_TECHNICAL_ERROR_FE = 'TECHNICAL-ERROR-FE',
  LOGIN_TECHNICAL_ERROR_BE = 'TECHNICAL-ERROR-BE',
  LOGIN_BENEFIT_NOT_SUPPORT = 'BENEFIT-NOT-SUPPORT',
  RECAPTCHA = 'Recaptcha',
  EVENT_CANCELLED_ERROR = 'EVENT-CANCELLED-ERROR',
  FORGOT_PASSWORD = 'FORGOT-PASSWORD',
  FORGOT_USERNAME = 'FORGOT-USERNAME',
}

export interface ITrackEventPayload {
  eventName: EventName;
  eventCategory?: string;
  url: string;
  message: string;
  benefitId: BenefitId;
  clientGuid?: string;
  userInfoId?: string;
  username?: string;
}


export enum VerifyEmployerSubCategory {
  INVALID_PASSCODE = 'INVALID-PASSCODE',
  BHLOGIN_TECHNICAL_ERROR = 'BHLOGIN-TECHNICAL-ERROR',
  API_VALIDATION_ERROR = 'API-VALIDATION-ERROR',
}

export enum LoginSubCategory {
  LOGIN_INVALID_PASSWORD = 'INVALID-PASSWORD', // for error code 2 during the login flow (from /Employee endpoint response)
  LOGIN_INVALID_USERNAME = 'INVALID-USERNAME', // for error code 1 during the login flow (from /Employee endpoint response)
  LOGIN_INVALID_CLIENT = 'INVALID-CLIENT', // for error code 3 during the login flow (from /Employee endpoint response)
  LOGIN_ACCOUNT_LOCKED = 'ACCOUNT-LOCKED', // for error code 4 during the login flow (from /Employee endpoint response)
  LOGIN_INVALID_OTP = 'INVALID-OTP',
  API_VALIDATION_ERROR = 'API-VALIDATION-ERROR',
  BHLOGIN_TECHNICAL_ERROR_BE = 'BHLOGIN-TECHNICAL-ERROR-BE',
  LOGIN_BENEFIT_NOT_SUPPORT = 'BENEFIT-NOT-SUPPORTED',
  BHLOGIN_TECHNICAL_ERROR_FE = 'BHLOGIN-TECHNICAL-ERROR-FE',
  LOGIN_WITH_OTP_VERIFICATION = 'LOGIN-WITH-OTP-VERIFICATION',
  LOGIN_WITH_PASSWORD_VERIFICATION = 'LOGIN-WITH-PASSWORD-VERIFICATION',
  LOGIN_WITH_OTP_VERIFICATION_SUCCESS = 'LOGIN-WITH-OTP-VERIFICATION-SUCCESS',
  LOGIN_WITH_OTP_VERIFICATION_FAILED = 'LOGIN-WITH-OTP-VERIFICATION-FAILED',
  LOGIN_WITH_OTP_VERIFICATION_LOCKED = 'LOGIN-WITH-OTP-VERIFICATION-LOCKED',
  FORGOT_PASSWORD_OTP_VERIFICATION = 'FORGOT-PASSWORD-OTP-VERIFICATION',
  FORGOT_PASSWORD_EMAIL_VERIFICATION = 'FORGOT-PASSWORD-EMAIL-VERIFICATION',
  FORGOT_USERNAME_OTP_VERIFICATION = 'FORGOT-USERNAME-OTP-VERIFICATION',
  FORGOT_USERNAME_EMAIL_VERIFICATION = 'FORGOT-USERNAME-EMAIL-VERIFICATION',
  FORGOT_USERNAME_OTP_VERIFICATION_SUCCESS = 'FORGOT-USERNAME-OTP-VERIFICATION-SUCCESS',
  FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESS = 'FORGOT-PASSWORD-OTP-VERIFICATION-SUCCESS',
  FORGOT_USERNAME_OTP_VERIFICATION_FAILED = 'FORGOT-USERNAME-OTP-VERIFICATION-FAILED',
  FORGOT_PASSWORD_OTP_VERIFICATION_FAILED = 'FORGOT-PASSWORD-OTP-VERIFICATION-FAILED',
  INVALID_CREDENTIALS = 'INVALID-CREDENTIALS',
  BENEFIT_REGISTRATION_UNAVAILABLE = 'BENEFIT-REGISTRATION-UNAVAILABLE',
}

export enum RegistrationSubCategory {
  ALREADY_REGISTERED = 'ALREADY-REGISTERED',
  BHLOGIN_TECHNICAL_ERROR = 'BHLOGIN-TECHNICAL-ERROR',
  API_VALIDATION_ERROR = 'API-VALIDATION-ERROR',
  ELIGIBILITY_MISMATCH_FEDERATED = 'ELIGIBILITY-MISMATCH-FEDERATED',
  ELIGIBILITY_MISMATCH_ERROR = 'ELIGIBILITY-MISMATCH-ERROR',
  ZIPCODE_COUNTRY_VALIDATION_ERROR = 'ZIPCODE-COUNTRY-VALIDATION-ERROR',
  SIGNUP_TECHNICAL_ERROR_FE = 'SIGNUP-TECHNICAL-ERROR-FE',
  EMPLOYER_NOT_ELIGIBLE = 'EMPLOYER-NOT-ELIGIBLE',
  BENEFIT_NOT_SUPPORTED = 'BENEFIT-NOT-SUPPORTED',
  OVERLAY_DISPLAY = 'CONFIRM OVERLAY-DISPLAY',
  OVERLAY_CLICKED_OUT = 'CONFIRM OVERLAY-CLICKED-OUT',
  OVERLAY_CONTINUE_BTN_CLICK = 'CONFIRM OVERLAY-CONTINUE-BTN-CLICK',
  OVERLAY_TIMEOUT = 'CONFIRM OVERLAY-TIMEOUT',
  UNIQUEID_FIXEDLENGTH_VALIDATION = 'UNIQUE ID FIXED LENGTH VALIDATION',
  BENEFIT_REGISTRATION_UNAVAILABLE = 'BENEFIT-REGISTRATION-UNAVAILABLE',
}

export enum MagicLinkSubCategory {
  ALREADY_REGISTERED = 'ALREADY-REGISTERED',
  EMAIL_EXPIRED = 'EMAIL-EXPIRED',
  BHLOGIN_TECHNICAL_ERROR = 'BHLOGIN-TECHNICAL-ERROR',
  API_VALIDATION_ERROR = 'API-VALIDATION-ERROR',
}

export enum RecaptchaSubCategory {
  RECAPTCHA_SUCCESS = 'SUCCESS',
  RECAPTCHA_FAILURE = 'RECAPTCHA-FAILED',
}
