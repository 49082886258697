import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Content, ContentPages, HttpError } from '../../models';

/**
 * The `content` (app global) action group.
 */
export const ContentActions = createActionGroup({
  source: 'App Content',
  events: {
    'Get Page Content Pattern': props<{ page: ContentPages }>(),
    'Load Page Content Pattern': props<{ page: ContentPages }>(),
    'Load Page Content Pattern Success': props<{ content: Content, page: ContentPages }>(),
    'Load Page Content Pattern Failure': props<{ error: string | HttpError, page: ContentPages }>(),

    'Get Logo Url': emptyProps(),
    'Fetch Logo Url': emptyProps(),
    'Fetch Logo Url Success': props<{ path: string | null }>(),
    'Fetch Logo Url Failure': props<{ error: string | HttpError }>(),
    'Clear Fetch Logo Url ': emptyProps(),
  },
});
