import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to mask a name/string.
 */
@Pipe({
  name: 'maskPhoneNumber',
})
export class MaskPhoneNumberPipe implements PipeTransform {
  /**
   * Transform a phone number (or a string) to mask first six numbers
   * with an asterisk (*).
   * @param {string} value The name/string to mask.
   * @returns {string} The masked name/string.
   */
  public transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    const phoneNumber: Array<string> = value.split('');
    const maskArr: Array<string> = [];
    const initial: number = 0;
    const len: number = 10;
    const slice: number = 4;
    const end: number = len - slice;

    phoneNumber.forEach((item: string, pos: number): void => {
      if (pos >= initial && pos < end) {
        maskArr.push('*');
      } else {
        maskArr.push(item);
      }
    });

    return maskArr.join('');
  }
}
