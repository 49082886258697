/* eslint-disable @angular-eslint/use-injectable-provided-in,arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, take } from 'rxjs';

import {
  IApiResponse,
  HttpError,
  IEmployer,
  IHelpDeskStatus,
  IHelpDeskPayload,
  BhQueryParams,
} from '../../../models';
import { ErrorHandlerService } from '../../../core/error-handler/error-handler.service';
import { GlobalService } from '../global.service';
import { GlobalActions } from './global.actions';
import { getArrayFromObject } from 'src/app/utils';
import { selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { Store } from '@ngrx/store';
@Injectable()
export class GlobalEffects {
  constructor(
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly globalService: GlobalService,
    private readonly errorService: ErrorHandlerService,
  ) { }
  public userInfoId: string | null | undefined = '';
  public bhPropertiesURL: BhQueryParams = {};
  public loadEmployers = createEffect(() => {
    return this.actions.pipe(
      ofType(GlobalActions.loadEmployers),
      concatMap(({ input }: { input: string }) => {
        return this.globalService.getEmployersList(input).pipe(
          map((response: IApiResponse<Array<IEmployer>>) =>
            GlobalActions.loadEmployersSuccess({ list: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              GlobalActions.loadEmployersFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public loadSupportHelps = createEffect(() => {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        this.userInfoId = params?.uid as string;
      });

    const userInfoId: string | null | undefined = this.userInfoId;

    return this.actions.pipe(
      ofType(GlobalActions.loadSupportHelps),
      concatMap(() => {
        return this.globalService.getSupportHelps(userInfoId).pipe(
          map((response: IApiResponse<object>) =>
            GlobalActions.loadSupportHelpsSuccess({ list: getArrayFromObject(response.data) }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              GlobalActions.loadSupportHelpsFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public loadSupportServices = createEffect(() => {
    return this.actions.pipe(
      ofType(GlobalActions.loadSupportServices),
      concatMap(() => {
        return this.globalService.getSupportServices().pipe(
          map((response: IApiResponse<object>) =>
            GlobalActions.loadSupportServicesSuccess({ list: getArrayFromObject(response.data) }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              GlobalActions.loadSupportServicesFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public helpDeskSupport = createEffect(() => {
    let userInfoId: string = '';
    if (this.userInfoId !== null && this.userInfoId !== undefined && this.userInfoId.trim().length > 0) {
      userInfoId = this.userInfoId;
    }

    return this.actions.pipe(
      ofType(GlobalActions.helpDeskSupport),
      concatMap(({ payload }: { payload: IHelpDeskPayload }) => {
        const loadPayload: FormData = new FormData();
        loadPayload.append('userInfoID', userInfoId);

        // build the FormData with the entered info
        Object.keys(payload).forEach((key: string): void => {
          if (key === 'FileAttachments' && payload.FileAttachments) {
            const file: File = payload.FileAttachments;

            loadPayload.append(key, file, file.name);
          } else {
            loadPayload.append(key, payload[key as keyof typeof payload] as string);
          }
        });

        return this.globalService.sendHelp(loadPayload).pipe(
          map((response: IApiResponse<IHelpDeskStatus>) =>
            GlobalActions.helpDeskSupportSuccess({ helpDeskSupportStatus: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              GlobalActions.helpDeskSupportFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public loadState = createEffect(() => {
    return this.actions.pipe(
      ofType(GlobalActions.loadState),
      concatMap(() => {
        return this.globalService.getStateList().pipe(
          map((response: IApiResponse<object>) =>
            GlobalActions.loadStateSuccess({ list: getArrayFromObject(response.data) }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              GlobalActions.loadStateFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });
}
