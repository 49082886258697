import { ActionReducer, createFeature, createReducer, on } from '@ngrx/store';

import {
  BenefitId,
  benefitLogo,
  BhQueryParams,
  ClickType,
  DisplayType,
  FlowType,
  HttpError,
  IAppJwt,
  IAppToken,
  IBenefitLogo,
  IConfigState,
  LoginType,
} from '../../models';
import { ConfigActions } from './config.actions';

/**
 * The initial value for the `config` (app global) app state.
 */
export const initialState: IConfigState = {
  flowType: 'join',
  loading: false,
  benefitId: benefitLogo[BenefitId.Default],
  displayType: 'desktop',
  loginType: 'password',
  apiToken: null,
  bhPropertiesURL: {},
  jsonDfp: null,
  origin: '',
  locale: 'en-US',
  isClientGuidFromQueryParam: false,
  apiTokenRefresh: {
    loaded: false,
    loading: false,
    error: null,
    apiTokenRefresh: null,
  },
  clickType: 'no',
};

/**
 * The `config` (app global) reducer.
 */
export const configReducer: ActionReducer<IConfigState> = createReducer(
  initialState,
  on(
    ConfigActions.flowType,
    (state: IConfigState, { flowType }: { flowType: FlowType }): IConfigState => ({
      ...state,
      flowType,
    }),
  ),
  on(
    ConfigActions.loading,
    (state: IConfigState, { loading }: { loading: boolean }): IConfigState => ({
      ...state,
      loading,
    }),
  ),
  on(
    ConfigActions.benefitId,
    (state: IConfigState, { benefitId }: { benefitId: IBenefitLogo }): IConfigState => ({
      ...state,
      benefitId,
    }),
  ),
  on(
    ConfigActions.displayType,
    (state: IConfigState, { displayType }: { displayType: DisplayType }): IConfigState => ({
      ...state,
      displayType,
    }),
  ),
  on(
    ConfigActions.loginType,
    (state: IConfigState, { loginType }: { loginType: LoginType }): IConfigState => ({
      ...state,
      loginType,
    }),
  ),
  on(
    ConfigActions.saveAppAuthToken,
    (state: IConfigState, { tokenInfo }: { tokenInfo: Partial<IAppJwt> }): IConfigState => ({
      ...state,
      apiToken: {
        ...state.apiToken,
        ...tokenInfo,
      } as IAppJwt,
    }),
  ),
  on(
    ConfigActions.bhPropertiesUrl,
    (state: IConfigState, { bhPropertiesURL }: { bhPropertiesURL: BhQueryParams }): IConfigState => ({
      ...state,
      bhPropertiesURL: {
        ...state.bhPropertiesURL,
        ...bhPropertiesURL,
      },
    }),
  ),
  on(
    ConfigActions.setSecureAuthInfo,
    (state: IConfigState, { jsonDfp }: { jsonDfp: string }): IConfigState => ({
      ...state,
      jsonDfp,
    }),
  ),
  on(
    ConfigActions.setAppOrigin,
    (state: IConfigState, { origin }: { origin: string }): IConfigState => ({
      ...state,
      origin,
    }),
  ),
  on(
    ConfigActions.clearAppAuthToken,
    (state: IConfigState): IConfigState => ({
      ...state,
      apiToken: null,
    }),
  ),
  on(
    ConfigActions.clientGuidFromQuery,
    (state: IConfigState, { isFromQuery }: { isFromQuery: boolean }): IConfigState => ({
      ...state,
      isClientGuidFromQueryParam: isFromQuery,
    }),
  ),
  on(
    ConfigActions.fetchClientToken,
    (state: IConfigState): IConfigState => ({
      ...state,
      apiTokenRefresh: {
        ...state.apiTokenRefresh,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    ConfigActions.getClientTokenSuccess,
    (state: IConfigState, { info }: { info: IAppToken }): IConfigState => ({
      ...state,
      apiTokenRefresh: {
        ...state.apiTokenRefresh,
        loading: false,
        loaded: true,
        apiTokenRefresh: info,
      },
    }),
  ),
  on(
    ConfigActions.getClientTokenFailure,
    (state: IConfigState, { error }: { error: string | HttpError }): IConfigState => ({
      ...state,
      apiTokenRefresh: {
        ...state.apiTokenRefresh,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    ConfigActions.clearClientToken,
    (state: IConfigState): IConfigState => ({
      ...state,
      apiTokenRefresh: {
        apiTokenRefresh: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    ConfigActions.saveTimeoutButtonClick,
    (state: IConfigState, { clickType }: { clickType: ClickType }): IConfigState => ({
      ...state,
      clickType,
    }),
  ),
);

/**
 * The `config` (app global) reducer feature creator that will generate
 * metadata and selectors for us.
 */
export const globalFeatureReducer = createFeature({
  name: 'config',
  reducer: configReducer,
});

/**
 * Name export all the generated selectors by the feature reducer creator.
 */
export const {
  name,
  selectConfigState,
  selectFlowType,
  selectLoading,
  selectBenefitId,
  selectDisplayType,
  selectLoginType,
  selectApiToken,
  selectBhPropertiesURL,
  selectJsonDfp,
  selectOrigin,
  selectLocale,
  selectIsClientGuidFromQueryParam,
  selectApiTokenRefresh,
  selectClickType,
} = globalFeatureReducer;
