<div *ngIf="showLogin">
  <p class="jointodayclass">
    {{
      showJoinToday
        ? content?.BHL_EmployerSearch_Label_Login
        : notRegistered
    }}
      <bh-login-button
      [label]="
        showJoinToday
          ? content?.BHL_EmployerSearch_Label_Login_Button
          : joinToday
      "
    >
    </bh-login-button>
  </p>
</div>
<aside class="bh-already-have-account"> 
  <p *ngIf="showHelpDesk" class="no-bottom-margin">
    {{ content?.BHL_EmployerSearch_Label_Questions }}
  </p>
  <a
    *ngIf="showHelpDesk"
    class="bh-link"
    target="_blank"
    rel="noopener noreferrer"
    [attr.aria-label]="content?.BHL_EmployerSearch_Label_Questions_HelpDesk + ' (opens in new tab)'"
    [href]="''"
    [routerLink]="$any(helpDeskRoute)"
    [queryParams]="queryParams"
    (click)="goToHelpDesk()"
  >
    {{ content?.BHL_EmployerSearch_Label_Questions_HelpDesk }}
  </a>
</aside>
