import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  IApiResponse, IEmployer, IHelpDeskStatus,
} from '../../models';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class GlobalService {
  constructor(private readonly httpService: HttpClient) {}

  public getEmployersList(
    searchInput: string,
  ): Observable<IApiResponse<Array<IEmployer>>> {
    return this.httpService.post<IApiResponse<Array<IEmployer>>>(
      `${environment.apiVersionUri.v1}/Client/ClientList`,
      `"${searchInput}"`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public getSupportHelps(UserInfoId: string | null | undefined): Observable<IApiResponse<object>> {
    if (UserInfoId === null || UserInfoId === undefined || UserInfoId.trim().length === 0) {
      return this.httpService.get<IApiResponse<object>>(
        `${environment.apiVersionUri.v1}/Person/MasterListDetail/MyBHSupportNeedHelp`,
      );
    }

    return this.httpService.get<IApiResponse<object>>(
      `${environment.apiVersionUri.v1}/Person/MasterListDetail/MyBHSessionNeedHelp`,
    );
  }

  public getSupportServices(): Observable<IApiResponse<object>> {
    return this.httpService.get<IApiResponse<object>>(
      `${environment.apiVersionUri.v1}/Person/MasterListDetail/MyBHSupportBHService`,
    );
  }

  public sendHelp(payload: FormData): Observable<IApiResponse<IHelpDeskStatus>> {
    return this.httpService.post<IApiResponse<IHelpDeskStatus>>(
      `${environment.apiVersionUri.v1}/Person/HelpDesk`, payload,
    );
  }

  public getStateList(): Observable<IApiResponse<object>> {
    return this.httpService.get<IApiResponse<object>>(
      `${environment.apiVersionUri.v1}/Person/MasterListDetail/State`,
    );
  }
}
