import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { delay, of, take } from 'rxjs';

import { srMessageDelay } from '../../../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-sr-only-page-level-error',
  templateUrl: './sr-only-page-level-error.component.html',
  styleUrls: [ './sr-only-page-level-error.component.scss' ],
})
export class SrOnlyPageLevelErrorComponent {
  /**
   * An id to identify the error message to be used for any aria-describedby attributes.
   * Defaults to 'page-level-error-visually-hidden' but can be overridden if desired.
   */
  @Input() public id: string = 'page-level-error-visually-hidden';

  /**
   * The content of the error message. In most (if not all) cases, the default should be sufficient.
   */
  @Input() public message: string = 'There are errors on the page, please review them and try again.';

  /**
   * The instance of the `FormGroup` we will be validating and focusing the first field with error.
   */
  @Input() public formGrp: FormGroup | undefined;

  /**
   * Flag to add/remove the screen reader only error message to/from the DOM.
   */
  public validationErrorOnSubmit: boolean = false;

  /**
   * Event Listener for the form submit event and setting the validation error flag as appropriate when a form is submitted.
   */
  @HostListener('body:submit', [ '$event' ])
  public onSubmit(event: SubmitEvent): void {
    // add the sr only error message only if the form is invalid on submit
    if (this.formGrp?.invalid) {
      // get the form object, so we can focus on the first error field
      const form: HTMLFormElement = this.renderer.selectRootElement(event.target, true) as HTMLFormElement;
      this.validationErrorOnSubmit = true;

      // Remove visually hidden error message after a brief delay
      // (This allows it to be detected by a screen reader every time it is re-added to the DOM)
      of(1)
        .pipe(
          delay(srMessageDelay),
          take(1),
        )
        .subscribe(() => {
          this.validationErrorOnSubmit = false;
          this.cdr.detectChanges();
        });

      const firstInvalidField = Object
        .keys(this.formGrp.controls)
        // eslint-disable-next-line security/detect-object-injection
        .find((key: string): boolean => ((this.formGrp as FormGroup).controls[key] as AbstractControl).invalid);

      if (firstInvalidField) {
        const element: Element | null = form.querySelector(`[formcontrolname="${firstInvalidField}"`);
        const nodeName: string | undefined = element?.nodeName.toLowerCase();

        switch (nodeName) {
          case 'mat-checkbox':
            // If it is an Angular Material checkbox element the formControlName is set on the `<mat-checkbox>` component,
            // thus, it will be returned as the invalid element. Therefore, we need to find the first `input` and set the focus
            // on that rather than on the wrapping `<mat-checkbox>` element in order for the screen reader to announce it.
            (element?.querySelector('input[type="checkbox"]') as HTMLInputElement).focus();
            break;
          case 'mat-radio-group':
            // If it is an Angular Material radio element the formControlName is set on the `<mat-radio-group>` component,
            // thus, it will be returned as the invalid element. Therefore, we need to find the first `input` and set the focus
            // on that rather than on the wrapping `<mat-radio-group>` element in order for the screen reader to announce it.
            (element?.querySelector('input[type="radio"]') as HTMLInputElement).focus();
            break;
          default:
            (element as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null)?.focus();
        }
      }
    }
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
  ) { }
}
