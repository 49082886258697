import { createFeature, createReducer, on } from '@ngrx/store';
import {
  IEligibilityStatus,
  HttpError,
  IEmployer,
  ISelectedEmployerState,
  ISignupState,
  IRegistrationStatus,
  ISendMagicLinkStatus,
  IEligibilityFromMagicLinkStatus,
  IBenefitDetails,
  ICreateRegistrationStatus,
  IFetchRegistrationByIdStatus,
  IEmployeeDetailsState,
  IMasterListStatus,
  IMfaVerificationStatus,
  IFranchiseLocation,
  IGeocodedLocation,
  IFranchiseAccountQuestion,
  IFetchFederatedDetailsStatus,
  IFicClientState,
  IRetrieveFICDetailsStatus,
  IEmailActivity,
  ICreateUNPwdStatus,
  IFranchiseAccountAnswer,
  IFetchEligibilityOsrDetailsStatus,
  IPersonInfo,
  IClientInfo,
  ISaveEmployeeDetailsStatus,
  IFetchUserIdentityStatus,
} from '../../models';
import { SignupActions } from './signup.actions';

export const initialState: ISignupState = {
  registration: {
    loading: false,
    loaded: false,
    registrationStatus: null,
    error: null,
  },
  fetchRegistrationById: {
    loading: false,
    loaded: false,
    fetchRegistrationByIdStatus: null,
    error: null,
  },
  fetchRegistrationByalternateId: {
    loading: false,
    loaded: false,
    fetchRegistrationByIdStatus: null,
    error: null,
  },
  fetchUserById: {
    loading: false,
    loaded: false,
    fetchUserByIdStatus: null,
    error: null,
  },
  fetchFederatedDetails: {
    loading: false,
    loaded: false,
    fetchFederatedDetailsStatus: null,
    error: null,
  },
  employers: {
    loading: false,
    loaded: false,
    list: null,
    error: null,
  },
  eligibility: {
    loading: false,
    loaded: false,
    eligibilityStatus: null,
    error: null,
  },
  selectedEmployer: null,
  employeeDetails: null,
  ficClientDetails: null,
  sendMagicLink: {
    loading: false,
    loaded: false,
    magicLinkEmailStatus: null,
    error: null,
  },
  eligibilityFromMagicLink: {
    loading: false,
    loaded: false,
    eligibilityFromMagicLinkStatus: null,
    error: null,
  },
  benefitInfo: {
    loaded: false,
    loading: false,
    error: null,
    benefit: null,
  },
  clientbenefitInfo: {
    loaded: false,
    loading: false,
    error: null,
    list: null,
  },
  postalCode: {
    loaded: false,
    loading: false,
    error: null,
    postalStatus: null,
  },
  countries: {
    loaded: false,
    loading: false,
    list: null,
    error: null,
  },
  countryCodes: {
    loaded: false,
    loading: false,
    list: null,
    error: null,
  },
  createRegistration: {
    loading: false,
    loaded: false,
    createRegistrationStatus: null,
    error: null,
  },
  passcodeState: {
    loading: false,
    loaded: false,
    isPasscodeValid: null,
    isEmailValid: null,
    error: null,
  },
  sendOTPState: {
    loading: false,
    loaded: false,
    sendOTPStatus: null,
    error: null,
  },
  mfaVerificationState: {
    loading: false,
    loaded: false,
    mfaVerificationStatus: null,
    error: null,
  },
  franchiseLocations: {
    loading: false,
    loaded: false,
    list: null,
    error: null,
    selectedLocation: null,
    searchQuery: null,
  },
  franchiseAccountQuestions: {
    loading: false,
    loaded: false,
    list: null,
    error: null,
  },
  franchiseAccountAnswers: {
    data: null,
  },
  franchiseRegistrationState: {
    loading: false,
    loaded: false,
    error: null,
    alreadyRegistered: null,
    uniqueValue: null,
  },
  mfaValidateOTPState: {
    loading: false,
    loaded: false,
    mfaValidateOTPStatus: null,
    error: null,
  },
  accountLockState: {
    loading: false,
    loaded: false,
    accountLockStatus: null,
    error: null,
  },
  geocodedLocations: {
    loading: false,
    loaded: false,
    list: null,
    error: null,
  },
  saveMfaState: {
    loading: false,
    loaded: false,
    saveMfaStatus: null,
    error: null,
  },
  ficEmailVerifyLinkState: {
    loading: false,
    loaded: false,
    ficEmailVerifyLinkStatus: null,
    error: null,
  },
  retrieveFICDetailsState: {
    loading: false,
    loaded: false,
    retrieveFICDetailsStatus: null,
    error: null,
  },
  emailActivityState: {
    loading: false,
    loaded: false,
    data: null,
    error: null,
  },
  personInfoState: {
    loading: false,
    loaded: false,
    data: null,
    error: null,
  },
  verifyEmailState: {
    loading: false,
    loaded: false,
    verifyEmailStatus: null,
    error: null,
  },
  createUNPwdState: {
    loading: false,
    loaded: false,
    createUNPwdStatus: null,
    error: null,
  },
  fetchEligibilityOsrDetailsState: {
    loading: false,
    loaded: false,
    data: null,
    error: null,
  },
  newOsrEmailState: {
    loading: false,
    loaded: false,
    data: false,
    error: null,
  },
  clientInfoState: {
    loading: false,
    loaded: false,
    data: null,
    error: null,
  },
  saveEmployeeDetailsState: {
    loading: false,
    loaded: false,
    saveEmployeeDetailsStatus: null,
    error: null,
  },
};

export const signupReducer = createReducer(
  initialState,
  on(
    SignupActions.registration,
    (state: ISignupState): ISignupState => ({
      ...state,
      registration: {
        ...state.registration,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.registrationSuccess,
    (state: ISignupState, { registrationStatus }: { registrationStatus: IRegistrationStatus }): ISignupState => ({
      ...state,
      registration: {
        ...state.registration,
        loaded: true,
        registrationStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.registrationFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      registration: {
        ...state.registration,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearRegistration,
    (state: ISignupState): ISignupState => ({
      ...state,
      registration: {
        registrationStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchRegistrationById: {
        ...state.fetchRegistrationById,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyidSuccess,
    (state: ISignupState, { fetchRegistrationByIdStatus }: { fetchRegistrationByIdStatus: IFetchRegistrationByIdStatus }): ISignupState => ({
      ...state,
      fetchRegistrationById: {
        ...state.fetchRegistrationById,
        loaded: true,
        fetchRegistrationByIdStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyidFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      fetchRegistrationById: {
        ...state.fetchRegistrationById,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFetchregistrationbyid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchRegistrationById: {
        fetchRegistrationByIdStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyalternateid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchRegistrationByalternateId: {
        ...state.fetchRegistrationByalternateId,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyalternateidSuccess,
    (state: ISignupState, { fetchRegistrationByIdStatus }: { fetchRegistrationByIdStatus: IFetchRegistrationByIdStatus }): ISignupState => ({
      ...state,
      fetchRegistrationByalternateId: {
        ...state.fetchRegistrationByalternateId,
        loaded: true,
        fetchRegistrationByIdStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.fetchregistrationbyalternateidFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      fetchRegistrationByalternateId: {
        ...state.fetchRegistrationByalternateId,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFetchregistrationbyalternateid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchRegistrationByalternateId: {
        fetchRegistrationByIdStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchuserbyid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchUserById: {
        ...state.fetchUserById,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchuserbyidSuccess,
    (state: ISignupState, { fetchUserByIdStatus }: { fetchUserByIdStatus: IFetchUserIdentityStatus }): ISignupState => ({
      ...state,
      fetchUserById: {
        ...state.fetchUserById,
        loaded: true,
        fetchUserByIdStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.fetchuserbyidFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      fetchUserById: {
        ...state.fetchUserById,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFetchuserbyid,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchUserById: {
        fetchUserByIdStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchFederatedDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchFederatedDetails: {
        ...state.fetchFederatedDetails,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchFederatedDetailsSuccess,
    (state: ISignupState, { fetchFederatedDetailsStatus }: { fetchFederatedDetailsStatus: IFetchFederatedDetailsStatus }): ISignupState => ({
      ...state,
      fetchFederatedDetails: {
        ...state.fetchFederatedDetails,
        loaded: true,
        fetchFederatedDetailsStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.fetchFederatedDetailsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      fetchFederatedDetails: {
        ...state.fetchFederatedDetails,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.loadEmployers,
    (state: ISignupState): ISignupState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadEmployersSuccess,
    (state: ISignupState, { list }: { list: Array<IEmployer> }): ISignupState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.loadEmployersFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearEmployers,
    (state: ISignupState): ISignupState => ({
      ...state,
      employers: {
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.eligibility,
    (state: ISignupState): ISignupState => ({
      ...state,
      eligibility: {
        ...state.eligibility,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.eligibilitySuccess,
    (state: ISignupState, { eligibilityStatus }: { eligibilityStatus: IEligibilityStatus }): ISignupState => ({
      ...state,
      eligibility: {
        ...state.eligibility,
        loaded: true,
        eligibilityStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.eligibilityFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      eligibility: {
        ...state.eligibility,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearEligibility,
    (state: ISignupState): ISignupState => ({
      ...state,
      eligibility: {
        eligibilityStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.selectedEmployer,
    (state: ISignupState, { selectedEmployer }: { selectedEmployer: ISelectedEmployerState | null }): ISignupState => ({
      ...state,
      selectedEmployer,
    }),
  ),
  on(
    SignupActions.employeeDetails,
    (state: ISignupState, { employeeDetails }: { employeeDetails: IEmployeeDetailsState }): ISignupState => ({
      ...state,
      employeeDetails: {
        ...state.employeeDetails,
        ...employeeDetails,
      },
    }),
  ),
  on(
    SignupActions.clearEmployeeDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      employeeDetails: null,
    }),
  ),
  on(
    SignupActions.ficClientDetails,
    (state: ISignupState, { ficClientDetails }: { ficClientDetails: IFicClientState }): ISignupState => ({
      ...state,
      ficClientDetails: {
        ...state.ficClientDetails,
        ...ficClientDetails,
      },
    }),
  ),
  on(
    SignupActions.sendmagiclink,
    (state: ISignupState): ISignupState => ({
      ...state,
      sendMagicLink: {
        ...state.sendMagicLink,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.sendmagiclinkSuccess,
    (state: ISignupState, { magicLinkEmailStatus }: { magicLinkEmailStatus: ISendMagicLinkStatus }): ISignupState => ({
      ...state,
      sendMagicLink: {
        ...state.sendMagicLink,
        loaded: true,
        magicLinkEmailStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.sendmagiclinkFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      sendMagicLink: {
        ...state.sendMagicLink,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearSendmagiclink,
    (state: ISignupState): ISignupState => ({
      ...state,
      sendMagicLink: {
        magicLinkEmailStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.eligibilityFromMagiclink,
    (state: ISignupState): ISignupState => ({
      ...state,
      eligibilityFromMagicLink: {
        ...state.eligibilityFromMagicLink,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.eligibilityfrommagiclinkSuccess,
    (state: ISignupState, { eligibilityFromMagicLinkStatus }: { eligibilityFromMagicLinkStatus: IEligibilityFromMagicLinkStatus }): ISignupState => ({
      ...state,
      eligibilityFromMagicLink: {
        ...state.eligibilityFromMagicLink,
        loaded: true,
        eligibilityFromMagicLinkStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.eligibilityfrommagiclinkFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      eligibilityFromMagicLink: {
        ...state.eligibilityFromMagicLink,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearEligibilityfrommagiclink,
    (state: ISignupState): ISignupState => ({
      ...state,
      eligibilityFromMagicLink: {
        eligibilityFromMagicLinkStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getBenefit,
    (state: ISignupState): ISignupState => ({
      ...state,
      benefitInfo: {
        ...state.benefitInfo,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getBenefitSuccess,
    (state: ISignupState, { info }: { info: IBenefitDetails }): ISignupState => ({
      ...state,
      benefitInfo: {
        ...state.benefitInfo,
        loading: false,
        loaded: true,
        benefit: info,
      },
    }),
  ),
  on(
    SignupActions.getBenefitFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      benefitInfo: {
        ...state.benefitInfo,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearBenefit,
    (state: ISignupState): ISignupState => ({
      ...state,
      benefitInfo: {
        benefit: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getClientBenefits,
    (state: ISignupState): ISignupState => ({
      ...state,
      clientbenefitInfo: {
        ...state.clientbenefitInfo,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getClientBenefitsSuccess,
    (state: ISignupState, { list }: { list: Array<IBenefitDetails> }): ISignupState => ({
      ...state,
      clientbenefitInfo: {
        ...state.clientbenefitInfo,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.getClientBenefitsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      clientbenefitInfo: {
        ...state.clientbenefitInfo,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearClientBenefits,
    (state: ISignupState): ISignupState => ({
      ...state,
      clientbenefitInfo: {
        list: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadCountries,
    (state: ISignupState): ISignupState => ({
      ...state,
      countries: {
        ...state.countries,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadCountriesSuccess,
    (state: ISignupState, { list }: { list: Array<IMasterListStatus> }): ISignupState => ({
      ...state,
      countries: {
        ...state.countries,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.loadCountriesFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      countries: {
        ...state.countries,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearCountries,
    (state: ISignupState): ISignupState => ({
      ...state,
      countries: {
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadCountryCodes,
    (state: ISignupState): ISignupState => ({
      ...state,
      countryCodes: {
        ...state.countryCodes,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadCountryCodesSuccess,
    (state: ISignupState, { list }: { list: Array<IMasterListStatus> }): ISignupState => ({
      ...state,
      countryCodes: {
        ...state.countryCodes,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.loadCountryCodesFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      countryCodes: {
        ...state.countryCodes,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearCountryCodes,
    (state: ISignupState): ISignupState => ({
      ...state,
      countryCodes: {
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getValidatepostalcode,
    (state: ISignupState): ISignupState => ({
      ...state,
      postalCode: {
        ...state.postalCode,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getValidatepostalcodeSuccess,
    (state: ISignupState, { zipCode }: { zipCode: boolean }): ISignupState => ({
      ...state,
      postalCode: {
        ...state.postalCode,
        loading: false,
        loaded: true,
        postalStatus: zipCode,
      },
    }),
  ),
  on(
    SignupActions.getValidatepostalcodeFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      postalCode: {
        ...state.postalCode,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearValidatepostalcode,
    (state: ISignupState): ISignupState => ({
      ...state,
      postalCode: {
        postalStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.createRegistration,
    (state: ISignupState): ISignupState => ({
      ...state,
      createRegistration: {
        ...state.createRegistration,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.createRegistrationSuccess,
    (state: ISignupState, { createRegistrationStatus }: { createRegistrationStatus: ICreateRegistrationStatus }): ISignupState => ({
      ...state,
      createRegistration: {
        ...state.createRegistration,
        loaded: true,
        createRegistrationStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.createRegistrationFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      createRegistration: {
        ...state.createRegistration,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearCreateRegistration,
    (state: ISignupState): ISignupState => ({
      ...state,
      createRegistration: {
        createRegistrationStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.verifyPasscode,
    (state: ISignupState): ISignupState => ({
      ...state,
      passcodeState: {
        ...state.passcodeState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.verifyPasscodeSuccess,
    (state: ISignupState, { isPasscodeValid, isEmailValid }: { isPasscodeValid: boolean; isEmailValid: boolean }): ISignupState => ({
      ...state,
      passcodeState: {
        ...state.passcodeState,
        loading: false,
        loaded: true,
        isPasscodeValid,
        isEmailValid,
      },
    }),
  ),
  on(
    SignupActions.verifyPasscodeFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      passcodeState: {
        ...state.passcodeState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.verifyEmail,
    (state: ISignupState): ISignupState => ({
      ...state,
      verifyEmailState: {
        ...state.verifyEmailState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.verifyEmailSuccess,
    (state: ISignupState, { verifyEmailStatus }: { verifyEmailStatus: boolean }): ISignupState => ({
      ...state,
      verifyEmailState: {
        ...state.verifyEmailState,
        loading: false,
        loaded: true,
        verifyEmailStatus,
      },
    }),
  ),
  on(
    SignupActions.verifyEmailFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      verifyEmailState: {
        ...state.verifyEmailState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearVerifyEmail,
    (state: ISignupState): ISignupState => ({
      ...state,
      verifyEmailState: {
        verifyEmailStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.clearVerifyPasscode,
    (state: ISignupState): ISignupState => ({
      ...state,
      passcodeState: {
        isPasscodeValid: null,
        isEmailValid: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.sendOtp,
    (state: ISignupState): ISignupState => ({
      ...state,
      sendOTPState: {
        ...state.sendOTPState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.sendOtpSuccess,
    (state: ISignupState, { sendOTPStatus }: { sendOTPStatus: boolean }): ISignupState => ({
      ...state,
      sendOTPState: {
        ...state.sendOTPState,
        loaded: true,
        sendOTPStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.sendOtpFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      sendOTPState: {
        ...state.sendOTPState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearSendotp,
    (state: ISignupState): ISignupState => ({
      ...state,
      sendOTPState: {
        sendOTPStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.verifyOtp,
    (state: ISignupState): ISignupState => ({
      ...state,
      mfaValidateOTPState: {
        ...state.mfaValidateOTPState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.verifyOtpSuccess,
    (state: ISignupState, { mfaValidateOTPStatus }: { mfaValidateOTPStatus: boolean }): ISignupState => ({
      ...state,
      mfaValidateOTPState: {
        ...state.mfaValidateOTPState,
        loaded: true,
        mfaValidateOTPStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.verifyOtpFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      mfaValidateOTPState: {
        ...state.mfaValidateOTPState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearVerifyOtp,
    (state: ISignupState): ISignupState => ({
      ...state,
      mfaValidateOTPState: {
        mfaValidateOTPStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.accountLock,
    (state: ISignupState): ISignupState => ({
      ...state,
      accountLockState: {
        ...state.accountLockState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.accountLockSuccess,
    (state: ISignupState, { accountLockStatus }: { accountLockStatus: boolean }): ISignupState => ({
      ...state,
      accountLockState: {
        ...state.accountLockState,
        loaded: true,
        accountLockStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.accountLockFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      accountLockState: {
        ...state.accountLockState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearAccountLock,
    (state: ISignupState): ISignupState => ({
      ...state,
      accountLockState: {
        accountLockStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.mfaVerification,
    (state: ISignupState): ISignupState => ({
      ...state,
      mfaVerificationState: {
        ...state.mfaVerificationState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.mfaVerificationSuccess,
    (state: ISignupState, { mfaVerificationStatus }: { mfaVerificationStatus: IMfaVerificationStatus }): ISignupState => ({
      ...state,
      mfaVerificationState: {
        ...state.mfaVerificationState,
        loaded: true,
        mfaVerificationStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.mfaVerificationFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      mfaVerificationState: {
        ...state.mfaVerificationState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearMfaVerification,
    (state: ISignupState): ISignupState => ({
      ...state,
      mfaVerificationState: {
        mfaVerificationStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.setFranchiseLocationQuery,
    (state: ISignupState, { input }: { input: string }): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        searchQuery: input,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseLocationQuery,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        searchQuery: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseLocations,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseLocationsSuccess,
    (state: ISignupState, { list }: { list: Array<IFranchiseLocation> }): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseLocationsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseLocations,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        loading: false,
        loaded: false,
        list: null,
        selectedLocation: null,
        error: null,
      },
      geocodedLocations: {
        ...state.geocodedLocations,
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.setSelectedFranchiseLocation,
    (state: ISignupState, { location }: { location: IFranchiseLocation }): ISignupState => ({
      ...state,
      franchiseLocations: {
        ...state.franchiseLocations,
        selectedLocation: location,
      },
    }),
  ),
  on(
    SignupActions.getGeocodedLocations,
    (state: ISignupState): ISignupState => ({
      ...state,
      geocodedLocations: {
        ...state.geocodedLocations,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getGeocodedLocationsSuccess,
    (state: ISignupState, { list }: { list: Array<IGeocodedLocation> }): ISignupState => ({
      ...state,
      geocodedLocations: {
        ...state.geocodedLocations,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.getGeocodedLocationsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      geocodedLocations: {
        ...state.geocodedLocations,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseAccountQuestions,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseAccountQuestions: {
        ...state.franchiseAccountQuestions,
        loading: true,
        loaded: false,
        error: null,
        list: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseAccountQuestionsSuccess,
    (state: ISignupState, { list }: { list: Array<IFranchiseAccountQuestion> }): ISignupState => ({
      ...state,
      franchiseAccountQuestions: {
        ...state.franchiseAccountQuestions,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseAccountQuestionsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      franchiseAccountQuestions: {
        ...state.franchiseAccountQuestions,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseAccountQuestions,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseAccountQuestions: {
        ...state.franchiseAccountQuestions,
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.setFranchiseAccountAnswers,
    (state: ISignupState, { data }: { data: Record<string, IFranchiseAccountAnswer> }): ISignupState => ({
      ...state,
      franchiseAccountAnswers: {
        ...state.franchiseAccountAnswers,
        data,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseAccountAnswers,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseAccountAnswers: {
        ...state.franchiseAccountAnswers,
        data: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseUniqueValue,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: true,
        loaded: false,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseUniqueValueSuccess,
    (state: ISignupState, { uniqueValue }: { uniqueValue: string }): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: true,
        uniqueValue,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseUniqueValueFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseUniqueValue,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: false,
        error: null,
        alreadyRegistered: null,
        uniqueValue: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseRegistrationStatus,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseRegistrationStatusSuccess,
    (state: ISignupState, { alreadyRegistered }: { alreadyRegistered: boolean }): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: true,
        alreadyRegistered,
      },
    }),
  ),
  on(
    SignupActions.getFranchiseRegistrationStatusFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearFranchiseRegistrationStatus,
    (state: ISignupState): ISignupState => ({
      ...state,
      franchiseRegistrationState: {
        ...state.franchiseRegistrationState,
        loading: false,
        loaded: false,
        error: null,
        alreadyRegistered: null,
        uniqueValue: null,
      },
    }),
  ),
  on(
    SignupActions.saveMfa,
    (state: ISignupState): ISignupState => ({
      ...state,
      saveMfaState: {
        ...state.saveMfaState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.saveMfaSuccess,
    (state: ISignupState, { saveMfaStatus }: { saveMfaStatus: boolean }): ISignupState => ({
      ...state,
      saveMfaState: {
        ...state.saveMfaState,
        loading: false,
        loaded: true,
        saveMfaStatus,
      },
    }),
  ),
  on(
    SignupActions.saveMfaFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      saveMfaState: {
        ...state.saveMfaState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.loadEmailActivity,
    (state: ISignupState): ISignupState => ({
      ...state,
      emailActivityState: {
        ...state.emailActivityState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadEmailActivitySuccess,
    (state: ISignupState, { data }: { data: IEmailActivity }): ISignupState => ({
      ...state,
      emailActivityState: {
        ...state.emailActivityState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    SignupActions.loadEmailActivityFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      emailActivityState: {
        ...state.emailActivityState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.loadPersonInfo,
    (state: ISignupState): ISignupState => ({
      ...state,
      personInfoState: {
        ...state.personInfoState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.loadPersonInfoSuccess,
    (state: ISignupState, { data }: { data: IPersonInfo }): ISignupState => ({
      ...state,
      personInfoState: {
        ...state.personInfoState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    SignupActions.loadPersonInfoFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      personInfoState: {
        ...state.personInfoState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.sendFicEmailVerifyLink,
    (state: ISignupState): ISignupState => ({
      ...state,
      ficEmailVerifyLinkState: {
        ...state.ficEmailVerifyLinkState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.sendFicEmailVerifyLinkSuccess,
    (state: ISignupState, { ficEmailVerifyStatus }: { ficEmailVerifyStatus: boolean }): ISignupState => ({
      ...state,
      ficEmailVerifyLinkState: {
        ...state.ficEmailVerifyLinkState,
        loaded: true,
        ficEmailVerifyLinkStatus: ficEmailVerifyStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.sendFicEmailVerifyLinkFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      ficEmailVerifyLinkState: {
        ...state.ficEmailVerifyLinkState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearSendFicEmailVerifyLink,
    (state: ISignupState): ISignupState => ({
      ...state,
      ficEmailVerifyLinkState: {
        ficEmailVerifyLinkStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.retrieveFicDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      retrieveFICDetailsState: {
        ...state.retrieveFICDetailsState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.retrieveFicDetailsSuccess,
    (state: ISignupState, { retrieveFICDetailsStatus }: { retrieveFICDetailsStatus: IRetrieveFICDetailsStatus }): ISignupState => ({
      ...state,
      retrieveFICDetailsState: {
        ...state.retrieveFICDetailsState,
        loaded: true,
        retrieveFICDetailsStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.retrieveFicDetailsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      retrieveFICDetailsState: {
        ...state.retrieveFICDetailsState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.createUnpwd,
    (state: ISignupState): ISignupState => ({
      ...state,
      createUNPwdState: {
        ...state.createUNPwdState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.createUnpwdSuccess,
    (state: ISignupState, { createUNPwdStatus }: { createUNPwdStatus: ICreateUNPwdStatus }): ISignupState => ({
      ...state,
      createUNPwdState: {
        ...state.createUNPwdState,
        loaded: true,
        createUNPwdStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.createUnpwdFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      createUNPwdState: {
        ...state.createUNPwdState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.fetchEligbilityOsrDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      fetchEligibilityOsrDetailsState: {
        ...state.fetchEligibilityOsrDetailsState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.fetchEligbilityOsrDetailsSuccess,
    (state: ISignupState, { fetchRegistrationByIdStatus }: { fetchRegistrationByIdStatus: IFetchEligibilityOsrDetailsStatus }): ISignupState => ({
      ...state,
      fetchEligibilityOsrDetailsState: {
        ...state.fetchEligibilityOsrDetailsState,
        loaded: true,
        data: fetchRegistrationByIdStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.fetchEligbilityOsrDetailsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      fetchEligibilityOsrDetailsState: {
        ...state.fetchEligibilityOsrDetailsState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.triggerNewOsrEmail,
    (state: ISignupState): ISignupState => ({
      ...state,
      newOsrEmailState: {
        ...state.newOsrEmailState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.triggerNewOsrEmailSuccess,
    (state: ISignupState, { data }: { data: boolean }): ISignupState => ({
      ...state,
      newOsrEmailState: {
        ...state.newOsrEmailState,
        loaded: true,
        data,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.triggerNewOsrEmailFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      newOsrEmailState: {
        ...state.newOsrEmailState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.getClientInfo,
    (state: ISignupState): ISignupState => ({
      ...state,
      clientInfoState: {
        ...state.clientInfoState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.getClientInfoSuccess,
    (state: ISignupState, { data }: { data: IClientInfo }): ISignupState => ({
      ...state,
      clientInfoState: {
        ...state.clientInfoState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    SignupActions.getClientInfoFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      clientInfoState: {
        ...state.clientInfoState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearClientInfo,
    (state: ISignupState): ISignupState => ({
      ...state,
      clientInfoState: {
        data: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.saveEmployeeDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      saveEmployeeDetailsState: {
        ...state.saveEmployeeDetailsState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    SignupActions.saveEmployeeDetailsSuccess,
    (state: ISignupState, { saveEmployeeDetailsStatus }: { saveEmployeeDetailsStatus: ISaveEmployeeDetailsStatus }): ISignupState => ({
      ...state,
      saveEmployeeDetailsState: {
        ...state.saveEmployeeDetailsState,
        loaded: true,
        saveEmployeeDetailsStatus,
        loading: false,
      },
    }),
  ),
  on(
    SignupActions.saveEmployeeDetailsFailure,
    (state: ISignupState, { error }: { error: string | HttpError }): ISignupState => ({
      ...state,
      saveEmployeeDetailsState: {
        ...state.saveEmployeeDetailsState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    SignupActions.clearSaveEmployeeDetails,
    (state: ISignupState): ISignupState => ({
      ...state,
      saveEmployeeDetailsState: {
        saveEmployeeDetailsStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
);

export const signupFeatureReducer = createFeature({
  name: 'signup',
  reducer: signupReducer,
});

export const {
  name,
  selectRegistration,
  selectSignupState,
  selectEmployers,
  selectEligibility,
  selectSelectedEmployer,
  selectSendMagicLink,
  selectEligibilityFromMagicLink,
  selectBenefitInfo,
  selectClientbenefitInfo,
  selectCountries,
  selectCountryCodes,
  selectPostalCode,
  selectCreateRegistration,
  selectFetchRegistrationById,
  selectFetchRegistrationByalternateId,
  selectFetchUserById,
  selectFetchFederatedDetails,
  selectEmployeeDetails,
  selectFicClientDetails,
  selectPasscodeState,
  selectVerifyEmailState,
  selectSendOTPState,
  selectMfaValidateOTPState,
  selectAccountLockState,
  selectMfaVerificationState,
  selectFranchiseLocations,
  selectGeocodedLocations,
  selectFranchiseAccountQuestions,
  selectFranchiseAccountAnswers,
  selectFranchiseRegistrationState,
  selectFicEmailVerifyLinkState,
  selectSaveMfaState,
  selectRetrieveFICDetailsState,
  selectEmailActivityState,
  selectCreateUNPwdState,
  selectFetchEligibilityOsrDetailsState,
  selectNewOsrEmailState,
  selectSaveEmployeeDetailsState,
  selectPersonInfoState,
  selectClientInfoState,
} = signupFeatureReducer;
