<ng-container *ngIf="(content | async)?.loaded">
  <article class="magiclink-expiry" *ngIf="{ page: (content | async)?.content, account: (accountContent | async)?.content } as pageContent">
    <div class="envelope">
      <img
        [src]="'assets/envelope.svg'"
        [alt]="''"
      />
    </div>
    <h1 class="bh--heading">{{ pageContent.page?.BHL_MagicLinkExpired_Label_Header }}</h1>
    <p class="expire-text">
      {{ pageContent.page?.BHL_MagicLinkExpired_Label_Content1 }}
    </p>
    <button class="bh-button--primary" (click)="OnResendLink()">{{ pageContent.page?.BHL_MagicLinkExpired_Button_Resend }}</button>
    <bh-already-have-account [content]="pageContent.account" [showLogin]="false"></bh-already-have-account>
  </article>
</ng-container>
