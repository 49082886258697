// import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatestWith, filter, map, Observable, Subject, take, takeUntil } from 'rxjs';

import { ConfigActions } from 'src/app/core/config/config.actions';
import { ConfigService } from 'src/app/core/config/config.service';
import { ContentActions } from 'src/app/core/content/content.actions';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { environment } from 'src/environments/environment';
import { contentSeparator, resetPasswordRoute, snackBarDefaultConfig } from '../../../../constants';
import { selectApiToken, selectBenefitId, selectBhPropertiesURL, selectFlowType, selectLoginType } from '../../../../core/config/config.reducers';
import { selectEmployeeSearchPage, selectSignupMFAOTPPage, selectStandardLoginPage } from '../../../../core/content/content.reducers';
import { ContentService } from '../../../../core/content/content.service';
import {
  selectMfaUserEmailAndMobileState,
  selectValidateUserState,
} from '../../../../core/credentials/credentials.reducers';
import { SignupActions } from '../../../../core/signup/signup.actions';
import {
  selectClientInfoState,
  selectEmployeeDetails,
  selectMfaValidateOTPState,
  selectSelectedEmployer,
  selectSendOTPState,
} from '../../../../core/signup/signup.reducers';
import {
  BhQueryParams,
  ContentPages,
  EventName,
  FlowType,
  ForgotType,
  IAppJwt,
  IBenefitLogo,
  IClientInfoState,
  IContentState,
  IEmployeeDetailsState,
  IMFAUserEmailAndMobileState,
  IMfaValidateOTPState,
  IPageLevelErrorData,
  ISelectedEmployerState,
  ISendOTPState,
  ISignupMFAOTPContent,
  IStandardLoginPageContent,
  IValidateUserState,
  LocalStorageKey,
  LoginSubCategory,
  LoginType,
  RegistrationSubCategory,
  SourceId,
} from '../../../../models';
import { SharedService } from 'src/app/shared/shared.service';
import { OverlayPopupComponent } from 'src/app/shared/components/overlay-popup/overlay-popup.component';
import { selectForgotType } from 'src/app/features/login/store/login.reducers';
import { LoggerService } from 'src/app/core/logger-service/logger.service';
import { LocationStrategy } from '@angular/common';
import { MaskEmailPipe } from 'src/app/shared/pipes/mask-email.pipe';
import { MaskPhoneNumberPipe } from 'src/app/shared/pipes/mask-phone-number.pipe';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: [ './verify-otp.component.scss' ],
})
export class VerifyOtpComponent implements OnInit, OnDestroy {
  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);
  public readonly content: Observable<IContentState[ContentPages.signupMFAOTPPage]> = this.store.select(selectSignupMFAOTPPage);
  public readonly loginContent: Observable<IContentState[ContentPages.standardLoginPage]> = this.store.select(selectStandardLoginPage);

  // public readonly isRecaptchaEnabled: boolean = environment.google.reCaptcha.enable;
  // public readonly captchaContent: Observable<IContentState[ContentPages.createAccountPage]> =
  //   this.store.select(selectCreateAccountPage);
  // @ViewChild('captchaRef') public readonly captchaRef: RecaptchaComponent | undefined;

  public submitted = false;
  public defaultContact: string | undefined = '';
  public clientName?: string = '';
  public showStepper: boolean = true;
  public isMFAEnabled: boolean = false;
  public isForgotType: boolean = false;

  // public isCaptchaDisabled: boolean | undefined = false;

  /**
   * BehaviorSubject used to control showing/hiding "Employer Not Eligible" error message.
   * Initial value is false.
   * Using a BehaviorSubject keeps the view in sync more reliably than a static boolean value.
   */
  public showPasscodeInvalid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAccountLocked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private isLoginFlow: boolean = false;
  private isSignupFlow: boolean = false;
  private accessToken: string;
  private userInfoId: string;
  public hideRememberDevice: boolean = false;
  public isOTPLoginFlow: boolean = false;
  private accountLockedErrorBodyTemplate: string = '';
  public isAccountLocked: boolean = false;
  private otpRequiredErrorMessage: string = '';
  public isOTPEnabledFlow: boolean = false;
  public resetPasswordUrl: string = '';
  private readonly isForgotUnFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotUnFlow) || '').toString();
  private readonly isForgotPwFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotPwFlow) || '').toString();
  public loginTitle: string = 'login';
  private isAccountLockedFormError: boolean = true;
  /**
   * Property to keep count of the failed OTP validation attempts. On the 5th failed attempt, we should redirect
   * to the login page per the AC in US287887.
   * @private
   */
  private numFailedCodes: number = 1;

  public readonly empVerifyForm: FormGroup<{
    passcode: FormControl<string>;
    chkRemember: FormControl<boolean>;
  }> = this.formBuilder.nonNullable.group({
      passcode: [ '' as string, [ Validators.required ] ],
      chkRemember: [ true ],
    });

  public get passcode(): AbstractControl<string> {
    return this.empVerifyForm.get('passcode') as FormControl;
  }

  /**
   * Data to pass to the page-level error component when "Employer Not Eligible" error occurs.
   * errorBody is populated dynamically by showEmployerNotEligibleError
   */
  public invalidPasscodeData: IPageLevelErrorData = {
    id: 'error-otp-not-valid',
    errorHeader: 'Verification Failed',
    errorBody: [],
  };

  public accountLockedErrorData: IPageLevelErrorData = {
    id: 'error-account-locked',
    errorHeader: 'Account locked',
    errorBody: [],
  };

  // eslint-disable-next-line max-params
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly config: ConfigService,
    private readonly loggerService: LoggerService,
    private readonly locationStrategy: LocationStrategy,

    // @Inject(DOCUMENT) private readonly document: Document,
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
    private readonly maskEmail: MaskEmailPipe,
    private readonly maskPhoneNumber: MaskPhoneNumberPipe,
    @Inject('Window') private readonly window: Window,
  ) { }

  public ngOnInit(): void {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.signupMFAOTPPage }));
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.standardLoginPage }));
    this.store
      .select(selectEmployeeDetails)
      .pipe(take(1))
      .subscribe((details: IEmployeeDetailsState | null): void => {
        if(details?.selectedContactType === 'email'){
            this.defaultContact = this.maskEmail.transform(details.defaultContact as string);
        }
        else{
          this.defaultContact = this.maskPhoneNumber.transform(details?.defaultContact as string);
        }
        this.clientName = details?.clientName;
      });


      this.store
      .select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(this.store.select(selectBenefitId)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ params, benefit ]: [BhQueryParams, IBenefitLogo]): void => {
        this.resetPasswordUrl = this.urlWithQueryParams(resetPasswordRoute, benefit.id, params);
      });
    
    this.store.select(selectClientInfoState)
      .pipe(take(1))
      .subscribe((clientInfo: IClientInfoState | null): void => {
        this.isOTPEnabledFlow = clientInfo?.data?.isLoginOtpEnabled as boolean;
        this.isMFAEnabled = clientInfo?.data?.isMfaEnabled as boolean;
      });

    // this.store.select(selectBenefitsDetails)
    // .pipe(
    //   filter((info: IBenefitDetails | null): boolean => info !== null),
    //   take(1),
    //   takeUntil(this.ngUnsubscribe),
    // )
    // .subscribe(( benefitInfo: IBenefitDetails | null): void => {
    //   const benefitDetails = benefitInfo as IBenefitDetails;
    //   this.isCaptchaDisabled = benefitDetails.isCaptchaDisabled;
    // });
    this.store
      .select(selectFlowType)
      .pipe(take(1))
      .subscribe((type: FlowType): void => {
        this.isLoginFlow = type === 'login';
        this.isSignupFlow = type === 'join';
      });
    this.store.select(selectLoginType)
      .pipe(take(1))
      .subscribe((loginType: LoginType): void => {
        this.isOTPLoginFlow = loginType === 'otp';
      });
    
    if(this.isOTPLoginFlow)
    {
      this.localStorageService.saveEncryptedItem(LocalStorageKey.isLoginFlow, 'true');
    }

    const verifyotpstate = this.localStorageService.getDecryptedItem(LocalStorageKey.verifyotpstate);
    const userNavigationState = (this.localStorageService.getItem(LocalStorageKey.userNavigationState) || '').toString();
    if(verifyotpstate !== 'true')
    {
      if(userNavigationState === 'true') {
        this.localStorageService.saveItem(LocalStorageKey.isPageRefresh, 'true');
      }
      this.window.location.href = this.localStorageService.getItem(LocalStorageKey.otpLink) as string;
      return;
    }
    this.localStorageService.removeItem(LocalStorageKey.verifyotpstate);

    this.store
      .select(selectMfaUserEmailAndMobileState)
      .pipe(take(1))
      .subscribe((state: IMFAUserEmailAndMobileState): void => {
        if (this.isLoginFlow) {
          this.showStepper = state.mfaUserEmailAndMobileStatus.length <= 0;
        }
      });

    if (this.isLoginFlow) {
      this.store.select(selectSelectedEmployer)
        .pipe(
          take(1),
        )
        .subscribe((employee: ISelectedEmployerState | null): void => {
          this.clientName = employee?.clientName as string;
          //this.isMFAEnabled = employee?.isMFAEnabled as boolean;
        });
    }

    if (this.isOTPLoginFlow || (this.isOTPEnabledFlow && !this.isMFAEnabled)) {
      this.hideRememberDevice = true;
    }

    this.store
      .select(selectSendOTPState)
      .pipe(
        filter((sendOtpState: ISendOTPState): boolean => sendOtpState.loaded || sendOtpState.error !== null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((sendOtpState: ISendOTPState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: false }));

        if (sendOtpState.loaded) {
          this.snackBar.open('Passcode has been resent.', 'Close', snackBarDefaultConfig);
        } else if (sendOtpState.error) {
          this.contentService.showErrorToast();
        }
      });

    this.passcode.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((): void => {
      this.showPasscodeInvalid$.next(false);
      this.showAccountLocked$.next(false);
    });

    this.loginContent
      .pipe(
        filter((pageContent: IContentState[ContentPages.standardLoginPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.standardLoginPage]): IStandardLoginPageContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: IStandardLoginPageContent): void => {
        this.accountLockedErrorData.errorHeader = pageContent.BHL_StandardLogin_Error_AccountLocked_Title;
        this.accountLockedErrorBodyTemplate = pageContent.BHL_StandardLogin_Error_AccountNewLocked;
        this.accountLockedErrorBodyTemplate = this.accountLockedErrorBodyTemplate.replace(/{{ resetPasswordUrl }}/g, this.resetPasswordUrl);
      });

    this.content
      .pipe(
        filter((pageContent: IContentState[ContentPages.signupMFAOTPPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.signupMFAOTPPage]): ISignupMFAOTPContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: ISignupMFAOTPContent): void => {
        this.otpRequiredErrorMessage = pageContent.BHL_MFAOTP_Error_MissOTP;
      });

    this.store
      .select(selectMfaValidateOTPState)
      .pipe(
        combineLatestWith(
          this.store.select(selectForgotType),
          this.store.select(selectEmployeeDetails),
        ),
        // eslint-disable-next-line max-len
        filter(([ validateOtpState, forgotType, empDetails ]: [IMfaValidateOTPState, ForgotType | null, IEmployeeDetailsState | null]): boolean => validateOtpState.loaded || validateOtpState.error !== null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ otpState, forgotType, empDetails ]: [IMfaValidateOTPState, ForgotType | null, IEmployeeDetailsState | null]): void => {
        this.store.dispatch(ConfigActions.loading({ loading: false }));
        /* eslint-disable no-console */
        console.log(otpState.mfaValidateOTPStatus);
        if (otpState.mfaValidateOTPStatus) {
          this.invalidPasscodeData.errorBody = [];
          this.showPasscodeInvalid$.next(false);
          if (forgotType === 'username' || this.isForgotUnFlow === 'true') {
            this.loggerService.sendTrackEvent('Forgot UserName With OTP Verification Success', JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName as string }), EventName.FORGOT_USERNAME, undefined, LoginSubCategory.FORGOT_USERNAME_OTP_VERIFICATION_SUCCESS);
            this.router.navigate([ 'global', 'confirmation-message' ]);
          } else if (forgotType === 'password' || this.isForgotPwFlow === 'true') {
            this.store.dispatch(ConfigActions.bhPropertiesUrl({
              bhPropertiesURL: {
                sourceid: SourceId['forgot-password'],
                fgPwdOtpFlow: 'true',
                userinfoid: this.localStorageService.getItem(LocalStorageKey.userInfoId) as string,
              },
            }));
            this.loggerService.sendTrackEvent('Forgot Pasword With OTP Verification Success', JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName as string }), EventName.FORGOT_USERNAME, undefined, LoginSubCategory.FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESS);
            this.router.navigate([ 'global', 'reset-password' ]);
          } else if (this.isLoginFlow && userNavigationState !== 'true') {
            if (this.isOTPLoginFlow) {
              this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);
              this.redirectMFA();
            } else {
              this.redirectBenefitsURL();
            }
          } else {
            // this.router.navigate([ '/signup', 'confirmation' ]);
            const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
            this.sharedService.TrackPendoEvent(EventName.REGISTRATION,
              benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_DISPLAY);
            this.contentService.openOverlayDialog(OverlayPopupComponent).subscribe((data) => {
              this.store.dispatch(ConfigActions.loading({ loading: true }));
            });
          }
        } else if (otpState.mfaValidateOTPStatus === false) {
          const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;

          // if it is the login flow and the OTP code validation failed 5 times, redirect to the login page (per AC in US287887)
          if (this.numFailedCodes > 2 && this.isLoginFlow) {
            this.store.dispatch(ConfigActions.loading({ loading: true }));
            this.localStorageService.removeItem(LocalStorageKey.clientGuid);
            this.store.dispatch(ConfigActions.bhPropertiesUrl({ bhPropertiesURL: { clientguid: null } }));

            // const secureAuthStr = JSON.stringify(secureAuth.fingerprint.getAllResults());
            // this.store.dispatch(ConfigActions.setSecureAuthInfo({ jsonDfp: secureAuthStr }));
            // eslint-disable-next-line max-len
            this.store.dispatch(SignupActions.accountLock({ userName: empDetails?.userName as string, userInfoID: this.userInfoId, unlockCheck: false, isReset: false }));
            if (this.isOTPLoginFlow && !this.isAccountLocked) {
              this.loggerService.sendTrackEvent('Login With OTP Verification Failed Account Lcoked', JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName }), EventName.LOGIN, undefined, LoginSubCategory.LOGIN_WITH_OTP_VERIFICATION_LOCKED);
            }
            this.isAccountLocked = true;
            this.showAccountLockedError();
            this.localStorageService.removeItem(LocalStorageKey.uname);
            this.store.dispatch(SignupActions.clearEmployeeDetails());
            this.store.dispatch(ConfigActions.loading({ loading: false }));

            // window.location.href = this.config.buildExternalUrl(loginUrl).href;
          } 
          else if(this.numFailedCodes > 2 && !this.isLoginFlow && !this.isForgotType){
                //Sing up flow 
                this.store.dispatch(ConfigActions.loading({ loading: true }));  
                this.store.dispatch(
                  SignupActions.accountLock(
                    {
                      userName: empDetails?.userName as string, userInfoID: this.userInfoId, unlockCheck: false, isReset: false
                    }
                    ));
                this.isAccountLocked = true;
                this.showAccountLockedError();
                this.store.dispatch(ConfigActions.loading({ loading: false }));
              }else {
            this.numFailedCodes += 1;
            if (forgotType === 'username' || this.isForgotUnFlow === 'true') {
              this.loggerService.sendTrackEvent(`Forgot UserName With OTP Verification Faild Attempt${this.numFailedCodes}`, JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName as string }), EventName.FORGOT_USERNAME, undefined, LoginSubCategory.FORGOT_USERNAME_OTP_VERIFICATION_FAILED);
            } else if (forgotType === 'password' || this.isForgotPwFlow === 'true') {
              this.loggerService.sendTrackEvent(`Forgot Pasword With OTP Verification Faild Attempt${this.numFailedCodes}`, JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName as string }), EventName.FORGOT_PASSWORD, undefined, LoginSubCategory.FORGOT_PASSWORD_OTP_VERIFICATION_FAILED);
            } else if (this.isOTPLoginFlow) {
              this.loggerService.sendTrackEvent(`Login With OTP Verification Failed Attempt${this.numFailedCodes}`, JSON.stringify({ userInfoId: this.userInfoId, userName: empDetails?.userName }), EventName.LOGIN, undefined, LoginSubCategory.LOGIN_WITH_OTP_VERIFICATION_FAILED);
            }
            this.invalidPasscodeData.errorBody = [ `The passcode entered doesn't match the one that was sent. Try again by re-entering code or requesting a new code below.` ];
            if(this.isLoginFlow){
              // eslint-disable-next-line
              this.sharedService.GA4pushEvent('login_error', { benefit_id: benefitIdFromLocalStorage, benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage), error_message: `${this.invalidPasscodeData.errorHeader}, ${this.invalidPasscodeData.errorBody[0]}` });
            }
            
            if(this.isSignupFlow){
              // eslint-disable-next-line
              this.sharedService.GA4pushEvent('sign_up_error', { benefit_id: benefitIdFromLocalStorage, benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage), error_message: `${this.invalidPasscodeData.errorHeader}, ${this.invalidPasscodeData.errorBody[0]}` });
            }
            // eslint-disable-next-line
            this.sharedService.GA4pushEvent('form_error', { form_name: "Login", error_message: `${this.invalidPasscodeData.errorHeader}, ${this.invalidPasscodeData.errorBody[0]}` });
            this.showPasscodeInvalid$.next(true);
          }

          // this.loggerService.sendTrackEvent('OTP Invalid Code Provided', JSON.stringify({ otpCode: this.passcode.value }), this.isLoginFlow ? EventName.LOGIN_INVALID_OTP : EventName.USER_ACCOUNTSETUP_ERROR);
        } else if (otpState.error) {
          this.contentService.showErrorToast();
        }
      });

    // this.store
    // .select(selectAccountLockState)
    // .pipe(
    //   filter((state: IAccountLockState): boolean => state.loaded || state.error !== null),
    //   take(1),
    // ).subscribe((state: IAccountLockState): void => {
    //   if(state.loaded)
    //   {
    //     this.store.dispatch(ConfigActions.loading({ loading: false }));
    //   }
    //   else if(state.loaded)
    //   {
    //     this.store.dispatch(ConfigActions.loading({ loading: false }));
    //   }
    // });
  }

  public sendNewPassCode(): void {
    this.localStorageService.saveEncryptedItem(LocalStorageKey.isresentotp, 'true');
    if(this.isSignupFlow)
    {
      this.localStorageService.saveEncryptedItem(LocalStorageKey.isLoginFlow, 'false');
    }
    this.store.dispatch(ConfigActions.loading({ loading: true }));
    this.showPasscodeInvalid$.next(false);
    this.store.dispatch(SignupActions.clearVerifyOtp());
    this.router.navigate([ 'global', 'mfa' ]);
  }

  public onSend(): void {
    this.showPasscodeInvalid$.next(false);
    this.showAccountLocked$.next(false);

    if (this.empVerifyForm.invalid) {
      const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
      if(this.isLoginFlow){
        this.sharedService.GA4pushEvent('login_error', {
          // eslint-disable-next-line camelcase
          benefit_id: benefitIdFromLocalStorage,
          // eslint-disable-next-line camelcase
          benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage),
          // eslint-disable-next-line camelcase
          error_message: `${this.otpRequiredErrorMessage}`,
        });
      }      
      if(this.isSignupFlow){
          /* eslint camelcase: ["error", {allow: ["benefit_id","benefit_source","error_message"]}]*/
          this.sharedService.GA4pushEvent('sign_up_error', {
          // eslint-disable-next-line camelcase
          benefit_id: benefitIdFromLocalStorage,
          // eslint-disable-next-line camelcase
          benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage),
          // eslint-disable-next-line camelcase
          error_message: `${this.otpRequiredErrorMessage}`,
        });
      }
      
      /* eslint camelcase: ["error", {allow: ["form_name","error_message"]}]*/
      // this.sharedService.GA4pushEvent('form_error', {
      //   form_name: "Login",
      //   error_message: `${this.otpRequiredErrorMessage}`,
      // });
      this.empVerifyForm.markAllAsTouched();
      this.passcode.markAsDirty();
    } else {
      this.resolved();
    }
  }

  // else if (!this.isCaptchaDisabled && this.isRecaptchaEnabled) {
  //   this.store.dispatch(ConfigActions.loading({ loading: true }));
  //   this.captchaRef?.execute();
  // }

  public resolved(): void {
    this.store.dispatch(ConfigActions.loading({ loading: true }));
    this.store.dispatch(SignupActions.verifyOtp({
      payload: {
        otp: this.passcode.value,
        isrememberDevice: this.isOTPLoginFlow ? false : this.empVerifyForm.controls.chkRemember.value,
      },
    }));
  }

  private showAccountLockedError(): void {
    this.passcode?.setValue('');
    this.accountLockedErrorData.errorBody = [
      this.accountLockedErrorBodyTemplate,
    ][0]!.split(contentSeparator);
    const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
    if(this.isLoginFlow){
      // eslint-disable-next-line
      this.sharedService.GA4pushEvent('login_error', { benefit_id: benefitIdFromLocalStorage, benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage), error_message: `${this.accountLockedErrorData.errorHeader}, ${this.accountLockedErrorData.errorBody[0]}` });
    }    
    if(this.isSignupFlow){
      // eslint-disable-next-line
      this.sharedService.GA4pushEvent('sign_up_error', { benefit_id: benefitIdFromLocalStorage, benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage), error_message: `${this.accountLockedErrorData.errorHeader}, ${this.accountLockedErrorData.errorBody[0]}` });
    }
    this.sendGa4AccountLockedFormError();
    this.showAccountLocked$.next(true);
  }
  private sendGa4AccountLockedFormError(): void{    
    if(this.isAccountLockedFormError){
      this.isAccountLockedFormError = false;
      // eslint-disable-next-line
      this.sharedService.GA4pushEvent('form_error', { form_name: "Login", error_message: `${this.accountLockedErrorData.errorHeader}, ${this.accountLockedErrorData.errorBody[0]}` });
    }
  }

  private urlWithQueryParams(route: readonly [string, string], benefitId: string, params: BhQueryParams): string {
    const urlTree: UrlTree = this.router.createUrlTree([ ...route ], {
      queryParams: {
        ...benefitId !== '0' && { benefitId },
        ...params,
      },
    });

    return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }

  private redirectMFA(): void {
    this.store
      .select(selectMfaUserEmailAndMobileState)
      .pipe(
        filter((state: IMFAUserEmailAndMobileState): boolean => state.loaded || state.error !== null),
        take(1),
      )
      .subscribe(
        (state: IMFAUserEmailAndMobileState): void => {
          if (state.loaded && state.mfaUserEmailAndMobileStatus.length > 0) {
            const isNonMFAFalseForAll = state.mfaUserEmailAndMobileStatus.every((item) => item.isNonMFA);
            if (isNonMFAFalseForAll) {
              this.store.dispatch(ConfigActions.loginType({ loginType: 'password' }));
              this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.loginMFAPage }));

              // this.store.dispatch(SignupActions.getClientInfo({ input: state.mfaUserEmailAndMobileStatus.clientguid as string }));

              //  this.store.dispatch(CredentialsActions.getMfaUserInfo({ input: userInfoId, isNonMfa: this.isMFAEnabled }));
              this.router.navigate([ 'global', 'mfa' ]);
            } else {
              this.redirectBenefitsURL();
            }
          } else {
            this.redirectBenefitsURL();
          }
        },
      );
  }

  private redirectBenefitsURL(): void {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
        combineLatestWith(
          this.store.select(selectApiToken),
          this.store.select(selectValidateUserState),
          this.store.select(selectClientInfoState),
          this.store.select(selectEmployeeDetails),
        ),
      )
      .subscribe(([ params, apiToken, userInfo, clientInfo, details ]:
      [BhQueryParams, IAppJwt | null, IValidateUserState | null, IClientInfoState | null, IEmployeeDetailsState | null]): void => {
        const sourceId: string | undefined = params.sourceid?.toLowerCase();
        const goToLogin: '' | null | undefined | boolean = sourceId && (sourceId !== SourceId['fic-registration']);
        const url: URL = this.config.buildExternalUrl(goToLogin ? undefined : environment.exploreBenefitsUrl);
        const isOTPEnabled = clientInfo?.data?.isLoginOtpEnabled as boolean;
        const userName = details?.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string;

        if (!params.accesstoken) {
          this.accessToken = apiToken?.userAccessToken ?? apiToken?.token as string;
        } else if (params.accesstoken) {
          this.accessToken = params.accesstoken;
        }

        if (!params.userinfoid) {
          this.userInfoId = userInfo?.validateUserStatus?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string;
        } else if (params.userinfoid) {
          this.userInfoId = params.userinfoid;
        }
        if (userInfo?.validateUserStatus) {
          this.sharedService.loginlogFullStory(userInfo.validateUserStatus.userName,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            userInfo.validateUserStatus.firstName as string + userInfo.validateUserStatus.lastName,
            userInfo.validateUserStatus.clientGuid, userInfo.validateUserStatus.clientName, userInfo.validateUserStatus.country as string);
        }

        if (isOTPEnabled) {
          this.loggerService.sendTrackEvent('Login With OTP Verification Success', JSON.stringify({ userInfoId: this.userInfoId, userName }), EventName.LOGIN, undefined, LoginSubCategory.LOGIN_WITH_OTP_VERIFICATION_SUCCESS);
        }

        this.sharedService.exploreBenefits(url.toString(), this.accessToken, this.userInfoId, 'login', userName, isOTPEnabled ? 'true' : 'false');
      });
  }

  // public onError(event: RecaptchaErrorParameters): void {
  //   this.store.dispatch(ConfigActions.loading({ loading: false }));
  //   this.contentService.showErrorToast();
  //   this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.RECAPTCHA, undefined, RecaptchaSubCategory.RECAPTCHA_FAILURE);
  // }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
