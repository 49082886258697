/* eslint-disable camelcase */
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { helpDeskRoute } from 'src/app/constants';

import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectBenefitId, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { combineLatestWith, take } from 'rxjs';
import { IBenefitLogo, IEmployeeSearchContent } from 'src/app/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: [ './page-not-found.component.scss' ],
})
export class PageNotFoundComponent {
  public readonly helpDeskRoute: typeof helpDeskRoute = helpDeskRoute;
  public queryParams: Record<string, string>;
  public readonly content: Partial<IEmployeeSearchContent> = {
    BHL_EmployerSearch_Label_Login: 'Already have an account?',
    BHL_EmployerSearch_Label_Login_Button: 'Login',
  };
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store,
  ) {
    this.store
      .select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(this.store.select(selectBenefitId)),
        take(1),
      )
      .subscribe(([ params, benefitInfo ]: [ object, IBenefitLogo ]): void => {
        this.queryParams = {
          ...benefitInfo.id !== '0' && { benefitId: benefitInfo.id },
          ...params, sourceurl: this.document.location.href,
        };
      });
  }
}
