import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';

import { environment } from '../environments/environment';
import { TemplatePageTitleStrategy } from './core/title-strategy/title-strategy.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./features/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'global',
    loadChildren: () => import('./features/global/global.module').then((m) => m.GlobalModule),
  },
  {
    path: 'edit',
    loadChildren: () => import('./features/edit/edit.module').then((m) => m.EditModule),
  },
  {
    path: '',
    redirectTo: '/signup/search',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/global/page-not-found',
    pathMatch: 'full',
  },
  {
    path: 'federatedaccount-not-found',
    redirectTo: '/global/federatedaccount-not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { enableTracing: environment.trace }) ],
  exports: [ RouterModule ],
  providers: [
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  ],
})
export class AppRoutingModule { }
