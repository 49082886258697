<article class="signup-confirmation">
  <div class="error-icon">
    <img
      [src]="'assets/exclamation.svg'"
      [alt]="''"
    />
  </div>
  <h1 class="bh--heading">Something's gone wrong.</h1>
  <div class="field-content">
    <p class="field-content--margin field-content--description-font ">
      We are unable to service your request at this time. Please try again in a
      few minutes.
    </p>
    <p class="field-content--no-margin field-content--description-font">
      For additional assistance, please call
    </p>
    <p class="field-content--no-margin field-content--font field-content--description-font">
      <a [href]="'tel:+1-833-225-5241'" aria-label="8 3 3. 2 2 5. 5 2 4 1">833-225-5241</a> (US
      or Canada),
      <a [href]="'tel:+44-0800-247-1101'" aria-label="0 8 0 0. 2 4 7. 1 1 0 1">0800 247 1101</a>
      (UK), or
      <a [href]="'tel:+353-1800-303-547'" aria-label="1 8 0 0. 3 0 3. 5 4 7">1800 303 547</a>
      (Ireland).
    </p>
  </div>
</article>
