/* eslint-disable camelcase */
import { ContentPages, IContentState } from '../../models';

/**
 * The initial value for the `content` (app global) app state.
 */
export const initialState: Readonly<IContentState> = {
  [ContentPages.employeeSearchPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_EmployerSearch_Label_PageHeader: '',
      BHL_EmployerSearch_Label_Header: '',
      BHL_EmployerSearch_Label_Employer: '',
      BHL_EmployerSearch_Button_Continue: '',
      BHL_EmployerSearch_Button_Confirm: '',
      BHL_EmployerSearch_Button_Previous: '',
      BHL_EmployerSearch_Field_Placeholder: '',
      BHL_EmployerSearch_Label_Login: '',
      BHL_EmployerSearch_Label_Login_Button: '',
      BHL_EmployerSearch_Label_Questions: '',
      BHL_EmployerSearch_Label_Questions_HelpDesk: '',
      BHL_EmployerSearch_Error_NoEmployerSelected: '',
      BHL_EmployerSearch_Label_ChangeEmployer: '',
      BHL_EmployerSearch_Link_ChangeEmployer: '',
      BHL_EmployerSearch_Error_FakeClient: '',
      BHL_EmployerSearch_Error_ManualClient: '',
      BHL_EmployerSearch_Error_InvalidClientBenefit: '',
    },
    error: null,
  },
  [ContentPages.eligibilityPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_Eligibility_Label_Header: '',
      BHL_Eligibility_Label_Required: '',
      BHL_Eligibility_Label_UniqueIDEmail: '',
      BHL_Eligibility_Label_UniqueIDWorkIDPlaceholder: '',
      BHL_Eligibility_Label_UniqueIDWorkID: '',
      BHL_Eligibility_Label_UniqueIDWorkIDEmail: '',
      BHL_Eligibility_Label_UniqueIDWorkIDLastName: '',
      BHL_Eligibility_Label_UniqueIDAlternateEmail: '',
      BHL_Eligibility_Label_AlternateEmailCheckBox: '',
      BHL_Eligibility_Label_AlternateEmailToolTip: '',
      BHL_Eligibility_Label_Passcode: '',
      BHL_Eligibility_Label_PasscodeContent: '',
      BHL_Eligibility_Label_PasscodePlaceholder: '',
      BHL_Eligibility_Error_PasscodeEmail: '',
      BHL_Eligibility_Error_EmailInvalidFormat: '',
      BHL_Eligibility_Error_EmailExisting: '',
      BHL_Eligibility_Error_AccountNotFound_Title: '',
      BHL_Eligibility_Error_AccountNotFound_Body: '',
      BHL_Eligibility_Error_IncorrectBenefit_Title: '',
      BHL_Eligibility_Error_IncorrectBenefit_Body: '',
      BHL_Eligibility_Error_FederatedAccountNotFound_Body: '',
      BHL_Eligibility_Error_IDInvalidFormat: '',
      BHL_Eligibility_Error_LN_Invalid: '',
      BHL_Eligibility_Error_AccountExisting_Title: '',
      BHL_Eligibility_Error_AccountExisting_Body: '',
      BHL_Eligibility_Error_BlankPasscode: '',
      BHL_Eligibility_Error_InvalidPasscode_Title: '',
      BHL_Eligibility_Error_InvalidPasscode_Body: '',
      BHL_Eligibility_Error_InvalidGeneric: '',
      BHL_Eligibility_Error_InvalidUniqueIDLength: '',
    },
    error: null,
  },
  [ContentPages.magicLinkPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MagicLink_Label_Header: '',
      BHL_MagicLink_Label_Content1: '',
      BHL_MagicLink_Label_Content2: '',
      BHL_MagicLink_Label_Link_Again: '',
      BHL_MagicLink_Label_Link_Toast: '',
    },
    error: null,
  },
  [ContentPages.passwordUpdatedPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MobilePWReset_MBD_Body: '',
      BHL_MobilePWReset_MBD_Header: '',
    },
    error: null,
  },
  [ContentPages.personalInfoPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_AcctCrt1_Label_Header: '',
      BHL_AcctCrt1_Label_FN: '',
      BHL_AcctCrt1_Label_FNPlaceholder: '',
      BHL_AcctCrt1_Label_LN: '',
      BHL_AcctCrt1_Label_LNPlaceholder: '',
      BHL_AcctCrt1_Label_Email: '',
      BHL_AcctCrt1_Label_EmailPlaceholder: '',
      BHL_AcctCrt1_Label_CTRY: '',
      BHL_AcctCrt1_Label_CTRYUSA: '',
      BHL_AcctCrt1_Label_CTRYCND: '',
      BHL_AcctCrt1_Label_CTRYIRE: '',
      BHL_AcctCrt1_Label_CTRYUK: '',
      BHL_AcctCrt1_Label_CTRYIND: '',
      BHL_AcctCrt1_Label_CTRYOther: '',
      BHL_AcctCrt1_Label_Zip_Opt1: '',
      BHL_AcctCrt1_Label_Zip_Opt2: '',
      BHL_AcctCrt1_Error_FN: '',
      BHL_AcctCrt1_Error_FN_Invalid: '',
      BHL_AcctCrt1_Error_LN: '',
      BHL_AcctCrt1_Error_LN_Invalid: '',
      BHL_AcctCrt1_Error_Email: '',
      BHL_AcctCrt1_Error_CTRY: '',
      BHL_AcctCrt1_Error_ZIP: '',
      BHL_AcctCrt1_Error_ZIP_Invalid: '',
      BHL_AcctCrt1_Error_Privacy: '',
      BHL_AcctCrt1_Label_EmailPlaceholder_ShortHelp: '',
    },
    error: null,
  },
  [ContentPages.createAccountPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_AcctCrt2_Label_Header: '',
      BHL_AcctCrt2_Label_SubHeader: '',
      BHL_AcctCrt2_Label_UN: '',
      BHL_AcctCrt2_Label_UNPlaceholder: '',
      BHL_AcctCrt2_Label_PW: '',
      BHL_AcctCrt2_Label_PWPlaceholder: '',
      BHL_AcctCrt2_Label_PW2: '',
      BHL_AcctCrt2_Label_PW2Placeholder: '',
      BHL_AcctCrt2_Error_UNRequired: '',
      BHL_AcctCrt2_Error_ExistUN: '',
      BHL_AcctCrt2_Error_UNAllowed: '',
      BHL_AcctCrt2_Error_PW_Validation1_Tile: '',
      BHL_AcctCrt2_Error_PW_Validation1_Rules: '',
      BHL_AcctCrt2_Error_PW_Validation2_Tile: '',
      BHL_AcctCrt2_Error_PW_Validation2_Rules: '',
      BHL_AcctCrt2_Error_PWConfirm: '',
      BHL_AcctCrt2_Error_PWRulesMissing: '',
      BHL_AcctCrt2_Error_WeakPW: '',
      BHL_AcctCrt2_Error_PWRequired: '',
      BHL_AcctCrt2_Error_PW2Required: '',
      BHL_AcctCrt2_BH_PrivacyTerms: '',
      BHL_AcctCrt2_reCAPTCHA: '',
      BHL_AcctCrt2_reCAPTCHA_Error_Toast: '',
    },
    error: null,
  },
  [ContentPages.signupConfirmationPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_Confirmation_Label_Header: '',
      BHL_Confirmation_Label_Content1: '',
    },
    error: null,
  },
  [ContentPages.franchiseLocatorPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_FranchiseLocation_Label_Header: '',
      BHL_FranchiseLocation_Label_Content1: '',
      BHL_FranchiseLocation_Label_ZipCityState: '',
      BHL_FranchiseLocation_Label_ZipCityState_Placeholder: '',
      BHL_FranchiseLocation_Error_IncorrectFormat: '',
      BHL_FranchiseLocation_Error_MissZIP: '',
      BHL_FranchiseLocation_Error_NoLocation_Title: '',
      BHL_FranchiseLocation_Error_NoLocation_Body: '',
      BHL_FranchiseLocation_Error_LocationNotFound_Title: '',
      BHL_FranchiseLocation_Error_LocationNotFound_Body: '',
    },
    error: null,
  },
  [ContentPages.fullServiceUniqueIdPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_FSUniqueID_Label_Header: '',
      BHL_FSUniqueID_Label_Content1: '',
      BHL_FSUniqueID_Label_WorkEmail: '',
      BHL_FSUniqueID_Label_NoEmail: '',
      BHL_FSUniqueID_Label_Content2: '',
      BHL_FSUniqueID_Label_WorkID: '',
      BHL_FSUniqueID_Label_NoID: '',
      BHL_FSUniqueID_Error_Email: '',
    },
    error: null,
  },
  [ContentPages.fullServiceEmployerSelectionPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_FSEmployer_Label_Header: '',
      BHL_FSEmployer_Label_Employed: '',
      BHL_FSEmployer_Label_Employed_Description: '',
      BHL_FSEmployer_Label_NOEmployed: '',
      BHL_FSEmployer_Error_Employed: '',
    },
    error: null,
  },
  [ContentPages.helpDeskPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_LoggedOutSupportForm_Label_Header: '',
      BHL_LoggedOutSupportFormFIC_Label_Content1: '',
      BHL_LoggedOutSupportForm_Label_Content: '',
      BHL_LoggedOutSupportForm_Label_Content3: '',
      BHL_LoggedOutSupportForm_Label_Help: '',
      BHL_LoggedOutSupportForm_Label_Services: '',
      BHL_LoggedOutSupportForm_Label_Emp: '',
      BHL_LoggedOutSupportForm_Label_FN: '',
      BHL_LoggedOutSupportForm_Label_FNPlaceholder: '',
      BHL_LoggedOutSupportForm_Label_LN: '',
      BHL_LoggedOutSupportForm_Label_LNPlaceholder: '',
      BHL_LoggedOutSupportForm_Label_Email: '',
      BHL_LoggedOutSupportForm_Label_Phone: '',
      BHL_LoggedOutSupportForm_Label_EmpID: '',
      BHL_LoggedOutSupportFormFIC_Label_Center: '',
      BHL_LoggedOutSupportForm_Label_City: '',
      BHL_LoggedOutSupportForm_Label_State: '',
      BHL_LoggedOutSupportForm_Label_Help2: '',
      BHL_LoggedOutSupportForm_Label_Content2: '',
      BHL_LoggedOutSupportForm_Label_ServicesB: '',
      BHL_LoggedOutSupportForm_Label_ServicesBH: '',
      BHL_LoggedOutSupportForm_Label_ServicesBHC: '',
      BHL_LoggedOutSupportForm_Label_ServicesChild: '',
      BHL_LoggedOutSupportForm_Label_ServicesCC: '',
      BHL_LoggedOutSupportForm_Label_ServicesEdA: '',
      BHL_LoggedOutSupportForm_Label_ServicesFIC: '',
      BHL_LoggedOutSupportForm_Label_ServicesMBD: '',
      BHL_LoggedOutSupportForm_Label_ServicesBenefits: '',
      BHL_LoggedOutSupportForm_Label_ServicesSitter: '',
      BHL_LoggedOutSupportForm_Label_ServicesWFS: '',
      BHL_LoggedOutSupportForm_Label_ServicesOther: '',
      BHL_LoggedOutSupportForm_Label_HelpLogin: '',
      BHL_LoggedOutSupportForm_Label_HelpReg: '',
      BHL_LoggedOutSupportForm_Label_HelpEmp: '',
      BHL_LoggedOutSupportForm_Label_HelpTech: '',
      BHL_LoggedOutSupportForm_Error_Help: '',
      BHL_LoggedOutSupportForm_Error_Services: '',
      BHL_LoggedOutSupportForm_Error_Emp: '',
      BHL_LoggedOutSupportForm_Error_FN: '',
      BHL_LoggedOutSupportForm_Error_FN_Invalid: '',
      BHL_LoggedOutSupportForm_Error_LN: '',
      BHL_LoggedOutSupportForm_Error_LN_Invalid: '',
      BHL_LoggedOutSupportForm_Error_Email: '',
      BHL_LoggedOutSupportForm_Error_Phone: '',
      BHL_LoggedOutSupportForm_Error_Help2: '',
      BHL_LoggedOutSupportFormFIC_Error_Center: '',
      BHL_LoggedOutSupportFormConfirm_Label_Header: '',
      BHL_LoggedOutSupportFormConfirm_Label_Content1: '',
      BHL_LoggedOutSupportForm_Label_EmpoyerPlaceholder: '',
      BHL_LoggedOutSupportForm_Label_PhonePlaceholder: '',
      BHL_LoggedOutSupportForm_Button_Send: '',
      BHL_LoggedOutSupportForm_Button_Remove: '',
      BHL_LoggedOutSupportForm_Button_Attach: '',
      BHL_LoggedOutSupportForm_Privacy: '',
      BHL_LoggedOutSupportForm_Label_Header_ShortHelp: '',
      BHL_LoggedOutSupportFormFIC_Label_Content1_ShortHelp: '',
      BHL_LoggedOutSupportForm_Label_Help2_ShortHelp: '',
      BHL_LoggedOutSupportForm_Label_checkbox_ShortHelp: '',
      BHL_LoggedOutSupportFormConfirm_Label_Header_ShortHelp: '',
      BHL_LoggedOutSupportFormConfirm_Label_Content1_ShortHelp: '',
    },
    error: null,
  },
  [ContentPages.magicLinkExpiredPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MagicLinkExpired_Label_Header: '',
      BHL_MagicLinkExpired_Label_Content1: '',
      BHL_MagicLinkExpired_Button_Resend: '',
      BHL_MagicLinkExpired_Label_Link_Toast: '',
    },
    error: null,
  },
  [ContentPages.userAlreadyRegisteredPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_AcctExist_Header: '',
      BHL_AcctExist_Content: '',
      BHL_AcctExist_Button_Login: '',
      BHL_AcctExist_Content_SSO_OSR: '',
      BHL_AcctExist_Header_SSO_OSR: '',
    },
    error: null,
  },
  [ContentPages.federatedSplitScreenPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_SplitScreen_Label_Header: '',
      BHL_SplitScreen_Label_Content: '',
      BHL_SplitScreen_Button_EmpLogin: '',
      BHL_SplitScreen_Button_NonEmpLogin: '',
      BHL_SplitScreen_Label_Disclaimer: '',
    },
    error: null,
  },
  [ContentPages.franchiseConfirmIdentityPage]: {
    loading: false,
    loaded: false,
    content: {
      'BHL_FranchiseEmployerInfo_Label_Header': '',
      'BHL_FranchiseEmployerInfo_Label_Content1': '',
      'BHL_FranchiseEmployerInfo_Label_DOB': '',
      'BHL_FranchiseEmployerInfo_Label_SSN': '',
      'BHL_FranchiseEmployerInfo_Label_Start': '',
      'BHL_FranchiseEmployerInfo_Label_Position': '',
      'BHL_FranchiseEmployerInfo_Label_Hours': '',
      'BHL_FranchiseEmployerInfo_Label_Gender': '',
      'BHL_FranchiseEmployerInfo_Label_Ethnicity': '',
      'BHL_FranchiseEmployerInfo_Label_PositionCrew': '',
      'BHL_FranchiseEmployerInfo_Label_PositioncrewT': '',
      'BHL_FranchiseEmployerInfo_Label_PositionFloor': '',
      'BHL_FranchiseEmployerInfo_Label_PositionPrimMain': '',
      'BHL_FranchiseEmployerInfo_Label_PositionBackMain': '',
      'BHL_FranchiseEmployerInfo_Label_PositionTrainee': '',
      'BHL_FranchiseEmployerInfo_Label_PositionGenMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionResMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionAsstMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionFAsstMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionSAsstMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionDepMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionSwing': '',
      'BHL_FranchiseEmployerInfo_Label_PositionShiftMan': '',
      'BHL_FranchiseEmployerInfo_Label_PositionOffice': '',
      'BHL_FranchiseEmployerInfo_Label_HoursLess': '',
      'BHL_FranchiseEmployerInfo_Label_Hours15': '',
      'BHL_FranchiseEmployerInfo_Label_Hours30': '',
      'BHL_FranchiseEmployerInfo_Label_GenderM': '',
      'BHL_FranchiseEmployerInfo_Label_GenderF': '',
      'BHL_FranchiseEmployerInfo_Label_GenderU': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 1': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 2': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 3': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 4': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 5': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 6': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 7': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 8': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 9': '',
      'BHL_FranchiseEmployerInfo_Label_Ethinicity 10': '',
      'BHL_FranchiseEmployerInfo_Error_DOB': '',
      'BHL_FranchiseEmployerInfo_Error_FutureDOB': '',
      'BHL_FranchiseEmployerInfo_Error_Before1900DOB': '',
      'BHL_FranchiseEmployerInfo_Error_SSN': '',
      'BHL_FranchiseEmployerInfo_Error_SSN_Pattern': '',
      'BHL_FranchiseEmployerInfo_Error_Start': '',
      'BHL_FranchiseEmployerInfo_Error_Before1900Start': '',
      'BHL_FranchiseEmployerInfo_Error_Position': '',
      'BHL_FranchiseEmployerInfo_Error_Hours': '',
      'BHL_FranchiseEmployerInfo_Error_Phone': '',
      'BHL_FranchiseEmployerInfo_Error_ExistingAccount_Title': '',
      'BHL_FranchiseEmployerInfo_Error_ExistingAccount_Body': '',
    },
    error: null,
  },
  logoUrl: {
    loading: false,
    loaded: false,
    path: null,
    error: null,
  },
  [ContentPages.signupMFAPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MFAContact_Error_Email: '',
      BHL_MFAContact_Error_InvalidEmail: '',
      BHL_MFAContact_Error_MissCTRY: '',
      BHL_MFAContact_Error_MissPhone: '',
      BHL_MFAContact_Error_PhoneCount: '',
      BHL_MFAContact_Label_CTRY: '',
      BHL_MFAContact_Label_CTRYCND: '',
      BHL_MFAContact_Label_CTRYIND: '',
      BHL_MFAContact_Label_CTRYIRE: '',
      BHL_MFAContact_Label_CTRYOther: '',
      BHL_MFAContact_Label_CTRYUK: '',
      BHL_MFAContact_Label_CTRYUSA: '',
      BHL_MFAContact_Label_Content1: '',
      BHL_MFAContact_Label_Email: '',
      BHL_MFAContact_Label_Header: '',
      BHL_MFAContact_Label_Mobile: '',
      BHL_MFAContact_Label_EmailPlaceholder: '',
      BHL_MFAContact_Label_NumberPlaceholder: '',
      BHL_MFAContact_Label_AddEmail: '',
      BHL_MFAContact_Label_AddNumber: '',
      BHL_MFAContact_Label_SkipNow: '',
      BHL_MFAContact_Label_RemoveEmail: '',
      BHL_MFAContact_Label_RemoveNumber: '',
    },
    error: null,
  },
  [ContentPages.signupMFAOTPPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MFAOTP_Error_MissOTP: '',
      BHL_MFAOTP_Error_IncorrectOTP_Title: '',
      BHL_MFAOTP_Error_IncorrectOTP_Body: '',
      BHL_MFAOTP_Label_Content1: '',
      BHL_MFAOTP_Label_Content2: '',
      BHL_MFAOTP_Label_Content3: '',
      BHL_MFAOTP_Label_Content4: '',
      BHL_MFAOTP_Label_Content5: '',
      BHL_MFAOTP_Label_Header: '',
      BHL_MFAOTP_Label_OTP: '',
      BHL_MFAOTP_Label_Content6: '',
    },
    error: null,
  },
  [ContentPages.standardLoginPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_StandardLogin_Label_Header: '',
      BHL_StandardLogin_Label_UN: '',
      BHL_StandardLogin_Error_UNRequired: '',
      BHL_StandardLogin_Label_PW: '',
      BHL_StandardLogin_Error_AccountLocked_Title: '',
      BHL_StandardLogin_Error_AccountLocked: '',
      BHL_StandardLogin_Error_AccountNewLocked: '',
      BHL_StandardLogin_Error_AccountNotFound_Title: '',
      BHL_StandardLogin_Error_AccountNotFound: '',
      BHL_StandardLogin_Error_AccountNewNotFound_Title: '',
      BHL_StandardLogin_Error_AccountNewNotFound: '',
      BHL_StandardLogin_Label_RememberMe: '',
      BHL_StandardLogin_Label_ContinueButton: '',
    },
    error: null,
  },
  [ContentPages.forgotUsernamePage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_ForgotUN_Error_Email: '',
      BHL_ForgotUN_Label_Email: '',
      BHL_ForgotUN_Header2: '',
      BHL_ForgotUN_Header: '',
      BHL_ForgotUNConfirm_Header2: '',
      BHL_ForgotUNConfirm_Header: '',
      BHL_ForgotUN_Placeholder_Email: '',
      BHL_ForgotUN_Button_Send: '',
      BHL_ForgotUNConfirm_NewHeader: '',
      BHL_ForgotUNConfirm_NewHeader2: '',
    },
    error: null,
  },
  [ContentPages.resetPasswordPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_ResetPW_Label_Email: '',
      BHL_ResetPW_Header2: '',
      BHL_ResetPW_Header: '',
      BHL_ResetPWConfirm_Header2: '',
      BHL_ResetPWConfirm_Header: '',
      BHL_ResetPW_Button_Send: '',
      BHL_ResetPWConfirm_NewHeader: '',
      BHL_ResetPWConfirm_NewHeader2: '',
    },
    error: null,
  },
  [ContentPages.loginMFAPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_MFALogin_Label_Email: '',
      BHL_MFALogin_Label_Mobile: '',
      BHL_MFALogin_Label_Header: '',
      BHL_MFALogin_Label_Header2: '',
      BHL_MFALogin_Error_ExtremeRiskError_Title: '',
      BHL_MFALogin_Error_ExtremeRiskError_Desc: '',
    },
    error: null,
  },
  [ContentPages.changePasswordPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_ChangePW_Header: '',
      BHL_ChangePW_CurrentPW: '',
      BHL_ChangePW_PHText_CurrentPW: '',
      BHL_ChangePW_NewPW: '',
      BHL_ChangePW_PHText_NewPW: '',
      BHL_ChangePW_ConfirmPW: '',
      BHL_ChangePW_PHText_ConfirmPW: '',
      BHL_ChangePW_UpdateButton: '',
      BHL_ChangePW_ErrorUsedPW: '',
      BHL_ChangePW_ErrorStrength: '',
    },
    error: null,
  },
  [ContentPages.signupOverlayPopupPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_Overlaypopup_Label_Content1: '',
      BHL_Overlaypopup_Label_Header: '',
    },
    error: null,
  },
  [ContentPages.benefitSelectionPage]: {
    loading: false,
    loaded: false,
    content: {
      BHL_BenefitSelection_Label_PageHeader: '',
      BHL_BenefitSelection_Header: '',
      BHL_BenefitSelection_BackUpCare_Header: '',
      BHL_BenefitSelection_BackUpCare_Summary: '',
      BHL_BenefitSelection_CollegeCoach_Header: '',
      BHL_BenefitSelection_CollegeCoach_Summary: '',
      BHL_BenefitSelection_EdAssist_Header: '',
      BHL_BenefitSelection_EdAssist_Summary: '',
    },
    error: null,
  },
};

