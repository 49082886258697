import { HttpErrorResponse } from '@angular/common/http';
import { applicationName } from '../constants';

import { BenefitId, ModeType, SourceOfRegistration } from './config';
import { EventName } from './events';
import { IFranchiseAccountAnswer } from './franchise-account-question';

/**
 * An interface to specify the expected response of the API authorization token.
 */
export interface IAppToken {

  /**
   * The access token we will be using to authenticate against the API.
   */
  accessToken: string | null;

  /**
   * The id token, which is do not expect to receive in the UI.
   */
  idToken: string | null;

  /**
   * The refresh token, which is do not expect to receive in the UI.
   */
  refreshToken: string | null;

  /**
   * The type of access token we are getting.
   */
  tokenType: 'Bearer';

  /**
   * An id for the user.
   */
  userInfoId: string | null;
}

export enum ErrorCode {

  // invalid username provided during the login flow
  ACCOUNT_NOT_FOUND = '1',

  // invalid password provided during the login flow
  INVALID_PASSWORD = '2',

  // when a user is resetting their password and provide invalid current password
  // this will be also used for invalid client error response during the login flow
  INVALID_COMBINATION = '3',

  // error code when the password we provided is one of the last 12 the user used
  // and for Account locked
  LAST_TWELVE_PASSWORDS = '4',
}

/**
 * An interface to describe the standard error object returned from the backend
 * when an error occurs on the backend (like a validation error). Please note that
 * errors returned from the backend are sent back in a 200 status code response.
 */
export interface IApiValidationError {

  /**
   * The name of the field/property that failed validation or errored out.
   */
  propertyName: string;

  /**
   * The error code that represents the failure.
   */
  errorCode: string;

  /**
   * A list of comma separated error messages that should be displayed
   * to the end user.
   */
  errorMessage: Array<string>;
}

/**
 * An interface to represent the standard API responses from the backend.
 * All responses will have this shape. The interface accepts a type for
 * the data property, because that is the only one that changes based on
 * the endpoint being called.
 */
export interface IApiResponse<T> {

  /**
   * Any data the UI would need (outside of errors) will be returned
   * to the data property. Thus, we allow it to be set to the expected
   * response type per endpoint to allow for better typing to reduce
   * the number of defects/exceptions and improve the intellisense
   * experience in IDEs and code editors that support it.
   */
  data: T;

  /**
   * A boolean indicating if there was any error in the request.
   */
  isError: boolean,

  /**
   * A boolean indicating if there was a validation error in the request.
   */
  isValidationError: boolean;

  /**
   * A list of error messages (similar to the errors.errorMessage below).
   */
  errorMessages: Array<string>;

  /**
   * The validation errors that we returned by the backend in a 200 status code.
   */
  errors: Array<IApiValidationError>;
}

export interface IEligibilityPayload {
  benefitId?: string,
  crmClientID?: string,
  eligibilityUniqueValue?: string,
  uniqueIdColumn?: string,
}

export interface IMagicLinkEmailPayload {
  eligibilityId?: number,
  firstName?: string,
  lastName?: string,
  email?: string | null,
  benefitId: BenefitId,
  fsTargetId?: string,
  redirectURL?: string,
  clientGuid?: string,
  nsn?: string,
  UniqueValue?: string,
  isAlternateEmailDuplicate?: boolean,
}

export interface IMfaObjectForRegistration {
  userMobileEmailId?: number,
  userInfoId?: string,
  type?: number,
  countryCode?: string,
  value?: string,
  isDefault?: boolean,
}

export interface IMfaContactObject {
  email?: string,
  phoneNumber?: string,
  country?: string,
  isDefault?: boolean,
  userInfoId?: string,
}

export interface ICreateRegistrationPayload {
  firstName?: string,
  lastName?: string,
  workEmail?: string,
  alternateEmail?: string,
  isAlternateEmailpreferred?: boolean,
  isAlternateEmailDuplicate?: boolean,
  clientGuid?: string,
  username?: string,
  country?: string,
  postalCode?: string,
  password?: string,
  eligibilityUniqueValue?: string,
  userContextInfo: {
    name?: string;
    application: typeof applicationName;
  },
  agreementAcceptedDate: string,
  acceptableUse: true,
  benefitId: BenefitId,
  fsTargetId?: string,
  sourceOfRegistration?: SourceOfRegistration
  accountQuestions?: Array<IFranchiseAccountAnswer>;
  nsn?: string;
  userMFAMobileEmail?: Array<IMfaObjectForRegistration>;
  eligibilityId?: number;
  isEmailVerified?: true;
  isNotEmployed?: boolean; // for FIC flow
  nonClientEmployerName?: string; // for FIC flow
  isOsrSSO?: boolean;
}

export interface ISendEmailVerifyLinkPayload {
  firstName?: string,
  lastName?: string,
  workEmail?: string,
  benefitId?: string,
  agreementAcceptedDate?: string,
  fsTargetId?: string,
  redirectURL?: string,
  clientGuid?: string,
  country?: string,
  postalCode?: string,
  eligibilityUniqueValue?: string,
  nonClientEmployerName?: string,
  isNotEmployed?: boolean,
}

export interface IFetchRegistrationByIdPayload {
  clientGuid?: string,
  eligibilityUniqueValue?: string,
  WorkEmail?: string
}

export interface IFetchFederatedDetailsPayload {
  benefitId?: string,
  crmClientID?: string,
  uniqueId?: string,
  uniqueIdColumn?: string,
}

export interface IFetchUserPayload {
  clientGuid?: string,
  username?: string,
}

export interface ISaveEmployeeDetailsPayload {
  employeeSaUniqueId: string;
  lastName?: string;
  userContextInfo: {
    name?: string;
    application?: string;
  },
  eligibilityUniqueValue: string;
}


/**
 * The expected payload format for the passcode verification endpoint.
 */
export interface IPasscodePayload {

  /**
   * The client's unique GUID.
   */
  clientGuid: string;

  /**
   * The passcode the user provided in the UI to be verified.
   */
  passcode: string;
}

export interface ISendOTPpayload {
  type: string;
  userName: string;
  userInfoId: string;
  clientGuid?: string,
  benefitId?: string,
  defaultContact?: string,
}

export interface IMfaVerificationPayload {
  userInfoId?: string;
  jsonDfp?: string;
  userName?: string;
  modeType: ModeType;
}

export interface IValidateOTPpayload {
  userInfoId?: string;
  userName?: string;
  otp: string;
  jsonDfp?: string;
  isrememberDevice: boolean;
}

export interface ISaveMfaPayload {
  userMFAMobileEmail?: Array<IMfaObjectForRegistration>;
  isOTPEnabled: boolean;
}

export interface IEmailVerify {
  email: string;
}

export interface IAccountLockPayload {
  userName: string;
  userInfoID: string;
  unlockCheck: boolean;
  isReset: boolean;
}

export interface ICreateUNPwdPayload {
  userInfoId: string,
  username: string,
  password: string,
  userContextInfo: {
    name: string,
    application: string,
  },
  agreementAcceptedDate: string,
}

/**
 * Type for the serialized HttpErrorResponse object, so it can be properly handled
 * by the NgRx store.
 */
export type HttpError = Pick<HttpErrorResponse, 'message' | 'status' | 'statusText' | 'url' | 'name'> & { eventType: EventName, eventCategory?: string, isLogNeed?: boolean, error: IApiResponse<null> | null };
