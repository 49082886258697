import { IMasterListStatus } from '../../models';

/**
 * Converts a provided date to an ISO string in the format YYYY-MM-DD
 * @param {Date} date the date to parse
 * @returns {string} the YYYY-MM-DD representation of the provided date
*/
export const convertDateToYYYYMMDD = (date: Date): string => {
  // First, subtract timezone offset, effectively setting UTC time equal to local time
  date.setTime(date.getTime() - (date.getTimezoneOffset() * 60000));

  // Generate full ISO string, including UTC timestamp
  const fullDateTimeString = date.toISOString();

  // Discard timestamp, returning only YYYY-MM-DD substring
  return fullDateTimeString.substring(0, fullDateTimeString.indexOf('T'));
};

/**
 * Helper function to ensure datepicker form controls are prepopulated with the correct date.
 * Converts a provided YYYY-MM-DD date string to a Date object with time set to midnight local time.
 * @param {string} dateStr an ISO date string in the format YYYY-MM-DD
 * @returns {Date | null} a date object represented by the provided string
 * OR null if the provided string does not match YYYY-MM-DD format
 */
export const convertYYYYMMDDToDate = (dateStr: string): Date | null => {
  const dateStrPattern: RegExp = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateStrPattern.exec(dateStr)) {
    return null;
  }

  // A Date constructed from a YYYY-MM-DD string has its time set to midnight UTC
  const date = new Date(dateStr);

  // Add timezone offset to reset time to midnight LOCAL time,
  // which ensures the date is displayed properly in the UI
  date.setTime(date.getTime() + (date.getTimezoneOffset() * 60000));

  return date;
};

/**
 * Returns Array from Object
 */
export const getArrayFromObject = (countriesObj: object): Array<IMasterListStatus> => {
  const countries: Array<IMasterListStatus> = [];
  for (const country in countriesObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (countriesObj.hasOwnProperty(country)) {
      countries.push({ objId: country, objName: countriesObj[country as keyof typeof countriesObj] });
    }
  }

  return countries;
};
