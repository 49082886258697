import { createSelector } from '@ngrx/store';

import {
  IEligibilityState,
  IEmployer,
  IEmployerState,
  IRegistrationState,
  IRegistrationStatus,
  IEligibilityStatus,
  ISendMagicLinkState,
  IEligibilityFromMagicLinkState,
  IEligibilityFromMagicLinkStatus,
  IBenefitDetailsState,
  IBenefitDetails,
  IPostalCodeState,
  ICreateRegistrationState,
  ICreateRegistrationStatus,
  IFetchRegistrationByIdState,
  IFetchRegistrationByIdStatus,
  ICountriesState,
  IMasterListStatus,
  IPasscodeState,
  ISendOTPState,
  IMfaVerificationState,
  IMfaVerificationStatus,
  IFranchiseLocation,
  IFranchiseLocationState,
  IMfaValidateOTPState,
  IAccountLockState,
  BhlError,
  ICountryCodesState,
  IFetchFederatedDetailsState,
  IFetchFederatedDetailsStatus,
  IGeocodedLocationState,
  IFICEmailVerifyLinkState,
  ISaveMfaState,
  IGeocodedLocation,
  IRetrieveFICDetailsState,
  IRetrieveFICDetailsStatus,
  IVerifyEmailState,
  IFranchiseAccountQuestion,
  IFranchiseAccountQuestionsState,
  ICreateUNPwdState,
  ICreateUNPwdStatus,
  IFranchiseAccountAnswersState,
  IFranchiseAccountAnswer,
  IFetchEligibilityOsrDetailsState,
  IFetchEligibilityOsrDetailsStatus,
  IFranchiseRegistrationState,
  IPersonInfoState,
  IPersonInfo,
  IClientInfoState,
  IClientInfo,
  ISaveEmployeeDetailsState,
  ISaveEmployeeDetailsStatus,
} from '../../models';
import {
  selectBenefitInfo,
  selectEligibility,
  selectEligibilityFromMagicLink,
  selectEmployers,
  selectPostalCode,
  selectFetchRegistrationById,
  selectRegistration,
  selectSendMagicLink,
  selectCountries,
  selectCreateRegistration,
  selectPasscodeState,
  selectSendOTPState,
  selectMfaVerificationState,
  selectFranchiseLocations,
  selectMfaValidateOTPState,
  selectAccountLockState,
  selectCountryCodes,
  selectFetchFederatedDetails,
  selectGeocodedLocations,
  selectFicEmailVerifyLinkState,
  selectSaveMfaState,
  selectRetrieveFICDetailsState,
  selectVerifyEmailState,
  selectFranchiseAccountQuestions,
  selectCreateUNPwdState,
  selectFranchiseAccountAnswers,
  selectFetchEligibilityOsrDetailsState,
  selectFranchiseRegistrationState,
  selectPersonInfoState,
  selectClientInfoState,
  selectSaveEmployeeDetailsState,
  selectFetchRegistrationByalternateId,
} from './signup.reducers';

export const selectEmployersList = createSelector(selectEmployers, (state: IEmployerState): Array<IEmployer> | null => state.list);
export const selectEmployersLoaded = createSelector(selectEmployers, (state: IEmployerState): boolean => state.loaded);
export const selectEmployersLoading = createSelector(selectEmployers, (state: IEmployerState): boolean => state.loading);
export const selectEmployersError = createSelector(selectEmployers, (state: IEmployerState): BhlError => state.error);

export const selectEligibilityStatus =
  createSelector(selectEligibility, (state: IEligibilityState): IEligibilityStatus | null => state.eligibilityStatus);
export const selectEligibilityLoaded = createSelector(selectEligibility, (state: IEligibilityState): boolean => state.loaded);
export const selectEligibilityLoading = createSelector(selectEligibility, (state: IEligibilityState): boolean => state.loading);
export const selectEligibilityError = createSelector(selectEligibility, (state: IEligibilityState): BhlError => state.error);

export const selectRegistrationStatus =
  createSelector(selectRegistration, (state: IRegistrationState): IRegistrationStatus | null => state.registrationStatus);
export const selectRegistrationLoaded = createSelector(selectRegistration, (state: IRegistrationState): boolean => state.loaded);
export const selectRegistrationLoading = createSelector(selectRegistration, (state: IRegistrationState): boolean => state.loading);
export const selectRegistrationError = createSelector(selectRegistration, (state: IRegistrationState): BhlError => state.error);

export const selectFetchRegistrationByIdStatus =
  createSelector(selectFetchRegistrationById, (state: IFetchRegistrationByIdState): IFetchRegistrationByIdStatus | null =>
    state.fetchRegistrationByIdStatus);
export const selectFetchRegistrationByIdStatusLoaded = createSelector(selectFetchRegistrationById,
  (state: IFetchRegistrationByIdState): boolean => state.loaded);
export const selectFetchRegistrationByIdStatusLoading = createSelector(selectFetchRegistrationById,
  (state: IFetchRegistrationByIdState): boolean => state.loading);
export const selectFetchRegistrationByIdStatusError = createSelector(selectFetchRegistrationById,
  (state: IFetchRegistrationByIdState): BhlError => state.error);

export const selectFetchRegistrationByAlternateIdStatus =
  createSelector(selectFetchRegistrationByalternateId, (state: IFetchRegistrationByIdState): IFetchRegistrationByIdStatus | null =>
    state.fetchRegistrationByIdStatus);
export const selectFetchRegistrationByAlternateIdStatusLoaded = createSelector(selectFetchRegistrationByalternateId,
  (state: IFetchRegistrationByIdState): boolean => state.loaded);
export const selectFetchRegistrationByAlternateIdStatusLoading = createSelector(selectFetchRegistrationByalternateId,
  (state: IFetchRegistrationByIdState): boolean => state.loading);
export const sselectFetchRegistrationByAlternateIdStatusError = createSelector(selectFetchRegistrationByalternateId,
  (state: IFetchRegistrationByIdState): BhlError => state.error);

export const selectFetchFederatedDetailsStatus =
  createSelector(selectFetchFederatedDetails, (state: IFetchFederatedDetailsState): IFetchFederatedDetailsStatus | null =>
    state.fetchFederatedDetailsStatus);
export const selectFetchFederatedDetailsLoaded = createSelector(selectFetchFederatedDetails,
  (state: IFetchFederatedDetailsState): boolean => state.loaded);
export const selectFetchFederatedDetailsLoading = createSelector(selectFetchFederatedDetails,
  (state: IFetchFederatedDetailsState): boolean => state.loading);
export const selectFetchFederatedDetailsError = createSelector(selectFetchFederatedDetails,
  (state: IFetchFederatedDetailsState): BhlError => state.error);

export const selectEligibilityFromMagicLinkStatus =
  createSelector(selectEligibilityFromMagicLink, (state: IEligibilityFromMagicLinkState):
  IEligibilityFromMagicLinkStatus | null => state.eligibilityFromMagicLinkStatus);
export const selectEligibilityFromMagicLinkLoaded = createSelector(selectEligibilityFromMagicLink,
  (state: IEligibilityFromMagicLinkState): boolean => state.loaded);
export const selectEligibilityFromMagicLinkLoading = createSelector(selectEligibilityFromMagicLink,
  (state: IEligibilityFromMagicLinkState): boolean => state.loading);
export const selectEligibilityFromMagicLinkError = createSelector(selectEligibilityFromMagicLink,
  (state: IEligibilityFromMagicLinkState): BhlError => state.error);

export const selectMagicLinkLoaded = createSelector(selectSendMagicLink, (state: ISendMagicLinkState): boolean => state.loaded);
export const selectMagicLinkLoading = createSelector(selectSendMagicLink, (state: ISendMagicLinkState): boolean => state.loading);

export const selectBenefitsDetails = createSelector(selectBenefitInfo, (state: IBenefitDetailsState): IBenefitDetails | null => state.benefit);
export const selectBenefitsInfoLoaded = createSelector(selectBenefitInfo, (state: IBenefitDetailsState): boolean => state.loaded);
export const selectBenefitsInfoLoading = createSelector(selectBenefitInfo, (state: IBenefitDetailsState): boolean => state.loading);
export const selectBenefitsError = createSelector(selectBenefitInfo, (state: IBenefitDetailsState): BhlError => state.error);

export const selectPostalCodeDetails = createSelector(selectPostalCode, (state: IPostalCodeState): boolean | null => state.postalStatus);
export const selectPostalCodeInfoLoaded = createSelector(selectPostalCode, (state: IPostalCodeState): boolean => state.loaded);
export const selectPostalCodeInfoLoading = createSelector(selectPostalCode, (state: IPostalCodeState): boolean => state.loading);
export const selectPostalCodeError = createSelector(selectPostalCode, (state: IPostalCodeState): BhlError => state.error);

export const selectCountryList = createSelector(selectCountries, (state: ICountriesState): Array<IMasterListStatus> | null => state.list);
export const selectCountryLoaded = createSelector(selectCountries, (state: ICountriesState): boolean => state.loaded);
export const selectCountryLoading = createSelector(selectCountries, (state: ICountriesState): boolean => state.loading);
export const selectCountryError = createSelector(selectCountries, (state: ICountriesState): BhlError => state.error);

export const selectCountryCodesList = createSelector(selectCountryCodes, (state: ICountryCodesState): Array<IMasterListStatus> | null => state.list);
export const selectCountryCodesLoaded = createSelector(selectCountryCodes, (state: ICountryCodesState): boolean => state.loaded);
export const selectCountryCodesLoading = createSelector(selectCountryCodes, (state: ICountryCodesState): boolean => state.loading);
export const selectCountryCodesError = createSelector(selectCountryCodes, (state: ICountryCodesState): BhlError => state.error);

export const selectCreateRegistrationDetails =
  createSelector(selectCreateRegistration, (state: ICreateRegistrationState): ICreateRegistrationStatus | null => state.createRegistrationStatus);
export const selectCreateRegistrationLoaded = createSelector(selectCreateRegistration, (state: ICreateRegistrationState): boolean => state.loaded);
export const selectCreateRegistrationLoading = createSelector(selectCreateRegistration, (state: ICreateRegistrationState): boolean => state.loading);
export const selectCreateRegistrationError =
  createSelector(selectCreateRegistration, (state: ICreateRegistrationState): BhlError => state.error);

export const selectPasscodeDetails =
  createSelector(selectPasscodeState, (state: IPasscodeState): { passcode: boolean | null; email: boolean | null } =>
    ({ passcode: state.isPasscodeValid, email: state.isEmailValid }));
export const selectPasscodeLoaded = createSelector(selectPasscodeState, (state: IPasscodeState): boolean => state.loaded);
export const selectPasscodeLoading = createSelector(selectPasscodeState, (state: IPasscodeState): boolean => state.loading);
export const selectPasscodeError = createSelector(selectPasscodeState, (state: IPasscodeState): BhlError => state.error);

export const selectVerifyEmailStatus = createSelector(selectVerifyEmailState, (state: IVerifyEmailState): boolean | null => state.verifyEmailStatus);
export const selectVerifyEmailLoaded = createSelector(selectVerifyEmailState, (state: IVerifyEmailState): boolean => state.loaded);
export const selectVerifyEmailLoading = createSelector(selectVerifyEmailState, (state: IVerifyEmailState): boolean => state.loading);
export const selectVerifyEmailError = createSelector(selectVerifyEmailState, (state: IVerifyEmailState): BhlError => state.error);

export const selectSendOTPStatus =
  createSelector(selectSendOTPState, (state: ISendOTPState): boolean | null => state.sendOTPStatus);
export const selectSendOTPLoaded = createSelector(selectSendOTPState, (state: ISendOTPState): boolean => state.loaded);
export const selectSendOTPLoading = createSelector(selectSendOTPState, (state: ISendOTPState): boolean => state.loading);
export const selectSendOTPError = createSelector(selectSendOTPState, (state: ISendOTPState): BhlError => state.error);

export const selectValidateOTPStatus =
  createSelector(selectMfaValidateOTPState, (state: IMfaValidateOTPState): boolean | null => state.mfaValidateOTPStatus);
export const selectValidateOTPLoaded = createSelector(selectMfaValidateOTPState, (state: IMfaValidateOTPState): boolean => state.loaded);
export const selectValidateOTPLoading = createSelector(selectMfaValidateOTPState, (state: IMfaValidateOTPState): boolean => state.loading);
export const selectValidateOTPError =
  createSelector(selectMfaValidateOTPState, (state: IMfaValidateOTPState): BhlError => state.error);

export const selectAccountLockStatus =
  createSelector(selectAccountLockState, (state: IAccountLockState): boolean | null => state.accountLockStatus);
export const selectAccountLockLoaded = createSelector(selectAccountLockState, (state: IAccountLockState): boolean => state.loaded);
export const selectAccountLockLoading = createSelector(selectAccountLockState, (state: IAccountLockState): boolean => state.loading);
export const selectAccountLockError =
  createSelector(selectAccountLockState, (state: IAccountLockState): BhlError => state.error);


export const selectMfaVerificationStatus =
  createSelector(selectMfaVerificationState, (state: IMfaVerificationState): IMfaVerificationStatus | null => state.mfaVerificationStatus);
export const selectMfaVerificationLoaded = createSelector(selectMfaVerificationState, (state: IMfaVerificationState): boolean => state.loaded);
export const selectMfaVerificationLoading = createSelector(selectMfaVerificationState, (state: IMfaVerificationState): boolean => state.loading);
export const selectMfaVerificationError = createSelector(selectMfaVerificationState,
  (state: IMfaVerificationState): BhlError => state.error);

export const selectFicEmailVerifyLinkStatus =
  createSelector(selectFicEmailVerifyLinkState, (state: IFICEmailVerifyLinkState): boolean | null => state.ficEmailVerifyLinkStatus);
export const selectFicEmailVerifyLinkLoaded =
  createSelector(selectFicEmailVerifyLinkState, (state: IFICEmailVerifyLinkState): boolean => state.loaded);
export const selectFicEmailVerifyLinkLoading =
  createSelector(selectFicEmailVerifyLinkState, (state: IFICEmailVerifyLinkState): boolean => state.loading);
export const selectFicEmailVerifyLinkError =
  createSelector(selectFicEmailVerifyLinkState, (state: IFICEmailVerifyLinkState): BhlError => state.error);

export const selectSaveMfaStatus =
  createSelector(selectSaveMfaState, (state: ISaveMfaState): boolean | null => state.saveMfaStatus);
export const selectSaveMfaLoaded = createSelector(selectSaveMfaState, (state: ISaveMfaState): boolean => state.loaded);
export const selectSaveMfaLoading = createSelector(selectSaveMfaState, (state: ISaveMfaState): boolean => state.loading);
export const selectSaveMfaError =
  createSelector(selectSaveMfaState, (state: ISaveMfaState): BhlError => state.error);

export const selectCreateUNPwdStatus =
  createSelector(selectCreateUNPwdState, (state: ICreateUNPwdState): ICreateUNPwdStatus | null => state.createUNPwdStatus);
export const selectCreateUNPwdLoaded = createSelector(selectCreateUNPwdState, (state: ICreateUNPwdState): boolean => state.loaded);
export const selectCreateUNPwdLoading = createSelector(selectCreateUNPwdState, (state: ICreateUNPwdState): boolean => state.loading);
export const selectCreateUNPwdError =
  createSelector(selectCreateUNPwdState, (state: ICreateUNPwdState): BhlError => state.error);

export const selectFetchEligibilityOsrDetailsStatus = createSelector(selectFetchEligibilityOsrDetailsState,
  (state: IFetchEligibilityOsrDetailsState): IFetchEligibilityOsrDetailsStatus | null => state.data);
export const selectFetchEligibilityOsrDetailsLoaded = createSelector(selectFetchEligibilityOsrDetailsState,
  (state: IFetchEligibilityOsrDetailsState): boolean => state.loaded);
export const selectFetchEligibilityOsrDetailsLoading = createSelector(selectFetchEligibilityOsrDetailsState,
  (state: IFetchEligibilityOsrDetailsState): boolean => state.loading);
export const selectFetchEligibilityOsrDetailsError = createSelector(selectFetchEligibilityOsrDetailsState,
  (state: IFetchEligibilityOsrDetailsState): BhlError => state.error);

export const selectFICEmailDetailsStatus =
  createSelector(selectRetrieveFICDetailsState, (state: IRetrieveFICDetailsState): IRetrieveFICDetailsStatus | null =>
    state.retrieveFICDetailsStatus);
export const selectFICEmailDetailsStatusLoaded = createSelector(selectRetrieveFICDetailsState,
  (state: IRetrieveFICDetailsState): boolean => state.loaded);
export const selectFICEmailDetailsStatusLoading = createSelector(selectRetrieveFICDetailsState,
  (state: IRetrieveFICDetailsState): boolean => state.loading);
export const selectFICEmailDetailsStatusError = createSelector(selectRetrieveFICDetailsState,
  (state: IRetrieveFICDetailsState): BhlError => state.error);

export const selectFranchiseLocationQuery = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): string | null => state.searchQuery,
);
export const selectFranchiseLocationsList = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): Array<IFranchiseLocation> | null => state.list,
);
export const selectFranchiseLocationsLoaded = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): boolean => state.loaded,
);
export const selectFranchiseLocationsLoading = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): boolean => state.loading,
);
export const selectFranchiseLocationsError = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): BhlError => state.error,
);
export const selectSelectedFranchiseLocation = createSelector(
  selectFranchiseLocations,
  (state: IFranchiseLocationState): IFranchiseLocation | null => state.selectedLocation,
);
export const selectGeocodedLocationsList = createSelector(
  selectGeocodedLocations,
  (state: IGeocodedLocationState): Array<IGeocodedLocation> | null => state.list,
);

export const selectFranchiseAccountQuestionsList = createSelector(
  selectFranchiseAccountQuestions,
  (state: IFranchiseAccountQuestionsState): Array<IFranchiseAccountQuestion> | null => state.list,
);
export const selectFranchiseAccountQuestionsLoaded = createSelector(
  selectFranchiseAccountQuestions,
  (state: IFranchiseAccountQuestionsState): boolean => state.loaded,
);
export const selectFranchiseAccountQuestionsLoading = createSelector(
  selectFranchiseAccountQuestions,
  (state: IFranchiseAccountQuestionsState): boolean => state.loading,
);
export const selectFranchiseAccountQuestionsError = createSelector(
  selectFranchiseAccountQuestions,
  (state: IFranchiseAccountQuestionsState): BhlError => state.error,
);

export const selectFranchiseAccountAnswersData = createSelector(
  selectFranchiseAccountAnswers,
  (state: IFranchiseAccountAnswersState): Record<string, IFranchiseAccountAnswer> | null => state.data,
);


export const selectFranchiseRegistrationUniqueValue = createSelector(
  selectFranchiseRegistrationState,
  (state: IFranchiseRegistrationState): string | null => state.uniqueValue,
);

export const selectFranchiseRegistrationStatus = createSelector(
  selectFranchiseRegistrationState,
  (state: IFranchiseRegistrationState): boolean | null => state.alreadyRegistered,
);
export const selectFranchiseRegistrationStateLoaded = createSelector(
  selectFranchiseRegistrationState,
  (state: IFranchiseRegistrationState): boolean => state.loaded,
);
export const selectFranchiseRegistrationStateLoading = createSelector(
  selectFranchiseRegistrationState,
  (state: IFranchiseRegistrationState): boolean => state.loading,
);
export const selectFranchiseRegistrationStateError = createSelector(
  selectFranchiseRegistrationState,
  (state: IFranchiseRegistrationState): BhlError => state.error,
);

export const selectSaveEmployeeDetailsStatus =
  createSelector(selectSaveEmployeeDetailsState, (state: ISaveEmployeeDetailsState): ISaveEmployeeDetailsStatus | null =>
    state.saveEmployeeDetailsStatus);
export const selectSaveEmployeeDetailsStatusLoaded = createSelector(selectSaveEmployeeDetailsState,
  (state: ISaveEmployeeDetailsState): boolean => state.loaded);
export const selectSaveEmployeeDetailsStatusLoading = createSelector(selectSaveEmployeeDetailsState,
  (state: ISaveEmployeeDetailsState): boolean => state.loading);
export const selectSaveEmployeeDetailsStatusError = createSelector(selectSaveEmployeeDetailsState,
  (state: ISaveEmployeeDetailsState): BhlError => state.error);

export const selectPersonInfoStatus = createSelector(selectPersonInfoState, (state: IPersonInfoState): IPersonInfo | null => state.data);
export const selectPersonInfoStatusLoaded = createSelector(selectPersonInfoState, (state: IPersonInfoState): boolean => state.loaded);
export const selectPersonInfoStatusLoading = createSelector(selectPersonInfoState, (state: IPersonInfoState): boolean => state.loading);
export const selectPersonInfoStatusError = createSelector(selectPersonInfoState, (state: IPersonInfoState): BhlError => state.error);

export const selectClientInfoStatus = createSelector(selectClientInfoState, (state: IClientInfoState): IClientInfo | null => state.data);
export const selectClientInfoStatusLoaded = createSelector(selectClientInfoState, (state: IClientInfoState): boolean => state.loaded);
export const selectClientInfoStatusLoading = createSelector(selectClientInfoState, (state: IClientInfoState): boolean => state.loading);
export const selectClientInfoStatusError = createSelector(selectClientInfoState, (state: IClientInfoState): BhlError => state.error);
