import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { bhTitle } from 'src/app/constants';

/**
 * Service to manage/set the browser title based on the page.
 */
@Injectable({
  providedIn: 'root',
})
export class TemplatePageTitleStrategy extends TitleStrategy {
  /**
   * The title strategy `constructor`
   * @param {Title} title The Angular Title service instance.
   */
  constructor(private readonly title: Title) {
    super();
  }

  /**
   * Set the browser title based on route definitions.
   * @param {RouterStateSnapshot} routerState The router snapshot object.
   */
  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    const defaultTitle: string = 'Bright Horizons®';

    if (title === undefined) {
      this.title.setTitle(defaultTitle);
    } else {
      this.title.setTitle(`${title} ${bhTitle}`);
    }
  }
}
