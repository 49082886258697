/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { RecaptchaModule } from 'ng-recaptcha';

import { AllowAlphanumericDirective } from './directives/allow-alphanumeric.directive';
import { AlreadyHaveAccountComponent } from './components/already-have-account/already-have-account.component';
import { BhStepperComponent } from './components/bh-stepper/bh-stepper.component';
import { EmployerLogoComponent } from './components/employer-logo/employer-logo.component';
import { FieldLevelErrorComponent } from './components/field-level-error/field-level-error.component';
import { FieldMessageComponent } from './components/field-message/field-message.component';
import { FooterLegalLinkComponent } from './components/footer-legal-link/footer-legal-link.component';
import { FranchiseConfirmIdentityComponent } from './components/franchise-confirm-identity/franchise-confirm-identity.component';
import { FranchiseLookupComponent } from './components/franchise-lookup/franchise-lookup.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { MaskEmailPipe } from './pipes/mask-email.pipe';
import { MaskPhoneNumberPipe } from './pipes/mask-phone-number.pipe';
import { MaskNamePipe } from './pipes/mask-name.pipe';
import { NumberOnlyDirective } from './directives/numbers-only.directive';
import { PageLevelErrorComponent } from './components/page-level-error/page-level-error.component';
import { SetupPasswordComponent } from './components/setup-password/setup-password.component';
import { SrOnlyPageLevelErrorComponent } from './components/sr-only-page-level-error/sr-only-page-level-error.component';
import { TimeoutPopupComponent } from './components/timeout-popup/timeout-popup.component';
import { WindowRefService } from './window-ref.service';
import { VerifyEligibilityComponent } from './components/verify-eligibility/verify-eligibility.component';
import { OverlayPopupComponent } from './components/overlay-popup/overlay-popup.component';
import { LineBreakPipePipe } from './pipes/line-break.pipe.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatStepperModule,
    MatTabsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RouterModule,
  ],
  exports: [
    AllowAlphanumericDirective,
    AlreadyHaveAccountComponent,
    BhStepperComponent,
    CommonModule,
    EmployerLogoComponent,
    FieldLevelErrorComponent,
    FieldMessageComponent,
    FooterLegalLinkComponent,
    FranchiseConfirmIdentityComponent,
    FranchiseLookupComponent,
    HighlightPipe,
    LoginButtonComponent,
    MaskEmailPipe,
    MaskPhoneNumberPipe,
    MaskNamePipe,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    NumberOnlyDirective,
    PageLevelErrorComponent,
    ReactiveFormsModule,
    RecaptchaModule,
    SetupPasswordComponent,
    FranchiseLookupComponent,
    VerifyEligibilityComponent,
    MaskPhoneNumberPipe,
    SrOnlyPageLevelErrorComponent,
  ],
  declarations: [
    AllowAlphanumericDirective,
    AlreadyHaveAccountComponent,
    BhStepperComponent,
    EmployerLogoComponent,
    FieldLevelErrorComponent,
    FieldMessageComponent,
    FooterLegalLinkComponent,
    FranchiseConfirmIdentityComponent,
    FranchiseLookupComponent,
    HighlightPipe,
    LoginButtonComponent,
    MaskEmailPipe,
    MaskNamePipe,
    NumberOnlyDirective,
    PageLevelErrorComponent,
    SetupPasswordComponent,
    SrOnlyPageLevelErrorComponent,
    TimeoutPopupComponent,
    VerifyEligibilityComponent,
    MaskPhoneNumberPipe,
    OverlayPopupComponent,
    LineBreakPipePipe,
  ],
  providers: [
    MaskEmailPipe,
    MaskNamePipe,
    MaskPhoneNumberPipe,
    MatSnackBar,
    WindowRefService,
  ],
})
export class SharedModule { }
