/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, concatMap, map, of } from 'rxjs';

import { tokenDuration } from 'src/app/constants';
import { HttpError, IApiResponse, IApiTokenRefreshState, IAppJwt, IAppToken } from 'src/app/models';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { ConfigActions } from './config.actions';
import { selectApiToken, selectApiTokenRefresh } from './config.reducers';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigEffects {
  public getApiToken = createEffect(() => {
    return this.actions.pipe(
      ofType(ConfigActions.fetchClientToken),
      concatMap(() => {
        return this.configService.authenticateApp()
          .pipe(
            map((response: IApiResponse<IAppToken>) => {
              // save the actual BE response in a separate state slice, so we have access to it
              // in case we need it, especially if we need to check for errors or loaded/loading
              // state for any reason
              return ConfigActions.getClientTokenSuccess({ info: response.data });
            }),
            catchError((error: Error | HttpError) =>
              of(
                ConfigActions.getClientTokenFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public getApiTokenSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(ConfigActions.getClientTokenSuccess),
      concatLatestFrom(() => [
        this.store.select(selectApiTokenRefresh),
        this.store.select(selectApiToken),
      ]),
      concatMap(([ newTokenInfo, apiInfo, appTokenInfo ]: [ { info: IAppToken; } & TypedAction<'[App Config] Get Client Token Success'>, IApiTokenRefreshState, IAppJwt | null ]) => {
        const tokenInfo: IAppJwt = {
          ...appTokenInfo,
          token: newTokenInfo.info.accessToken as string,
          // eslint-disable-next-line max-len
          userInfoId: newTokenInfo.info.userInfoId ?? apiInfo.apiTokenRefresh?.userInfoId as string | null ?? appTokenInfo?.userInfoId as string | null,
          expiresAt: Date.now() + tokenDuration,
        };

        // save the token with the expiration time in the `apiToken` state,
        // so we can check it and renew before it expires (when necessary)
        return of(ConfigActions.saveAppAuthToken({ tokenInfo }));
      }),
    );
  });

  constructor(
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly configService: ConfigService,
    private readonly errorService: ErrorHandlerService,
  ) { }
}
