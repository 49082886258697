<ng-container *ngIf="(content | async)?.loaded">
  <article
    class="signup-otp"
    *ngIf="{
      page: (content | async)?.content,
      account: (accountContent | async)?.content
    } as pageContent"
  >
    <h1 class="bh--heading">{{ pageContent.page?.BHL_MFAOTP_Label_Header }}</h1>
    <bh-stepper *ngIf="showStepper" [currentStep]="3"></bh-stepper>
    <bh-employer-logo [heading]="clientName"></bh-employer-logo>
    <div class="otp-form-intro" aria-live="polite">
      <p class="plain-text">
        {{ pageContent.page?.BHL_MFAOTP_Label_Content1 }}
      </p>
      <div class="emailsection">
        <p>{{ defaultContact }}</p>
      </div>
      <p class="plain-text">
        {{ pageContent.page?.BHL_MFAOTP_Label_Content2 }}
      </p>
    </div>
    <form class="bh-form" [formGroup]="empVerifyForm">

      <bh-sr-only-page-level-error
        [formGrp]="empVerifyForm"
      ></bh-sr-only-page-level-error>

      <bh-page-level-error
        *ngIf="showPasscodeInvalid$ | async"
        [id]="invalidPasscodeData.id"
        [errorHeader]="invalidPasscodeData.errorHeader"
        [errorBody]="invalidPasscodeData.errorBody"
      ></bh-page-level-error>
      <bh-page-level-error
      *ngIf="showAccountLocked$ | async"
      [id]="accountLockedErrorData.id"
      [errorHeader]="accountLockedErrorData.errorHeader"
      [errorBody]="accountLockedErrorData.errorBody"
      ></bh-page-level-error>
      <div
        class="bh-form__field"
        [class.bh-form__field--invalid]="
          empVerifyForm.invalid && passcode.touched && passcode.dirty && !this.isAccountLocked
        "
      >
        <label for="passcode" class="bh-form__field__label"
          >{{ pageContent.page?.BHL_MFAOTP_Label_OTP }}
          <span class="bh--marked">*</span></label
        >
        <input 
          type="text"
          class="bh-form__field__input"
          id="passcode"
          placeholder="{{ pageContent.page?.BHL_MFAOTP_Label_Content5 }}"
          formControlName="passcode"
          autocomplete="one-time-code"
          [readonly]="isAccountLocked"
          matInput
          data-atm-input-one-time-passcode="Enter your one-time passcode"
          [attr.aria-invalid]="
            empVerifyForm.invalid && passcode.touched && passcode.dirty
          "
          (keydown.Space)="$event.preventDefault()"
        />
        <div
          *ngIf="empVerifyForm.invalid && passcode.touched && passcode.dirty && !this.isAccountLocked"
          class="bh-form__field__error-message"
          aria-live="polite"
        >
          <bh-field-level-error [formErrorGA4] = "loginTitle"
            [message]="pageContent.page?.BHL_MFAOTP_Error_MissOTP"
          ></bh-field-level-error>
        </div>
      </div>
      <div class="retry-passcode" *ngIf="!isAccountLocked">
        <span class="passcode-query"
          >{{ pageContent.page?.BHL_MFAOTP_Label_Content3 }}
        </span>
        <button
          class="bh-button--inline-link"
          type="button"
          (click)="sendNewPassCode()"
        >
          {{ pageContent.page?.BHL_MFAOTP_Label_Content6 }}
        </button>
      </div>
      <div *ngIf="!this.hideRememberDevice" class="remember-chk">
      <mat-checkbox
        formControlName="chkRemember"
        class="bh-form__field--checkbox"
        data-atm-input-remember-device="Remember Device"
        [color]="'primary'"
      >
        {{ pageContent.page?.BHL_MFAOTP_Label_Content4 }}
      </mat-checkbox>
    </div>
      <button type="submit" class="bh-button--primary" (click)="onSend()" [disabled]="isAccountLocked">
        Submit
      </button>
      <!-- <div
          class="privacypolicies"
          *ngIf="!isCaptchaDisabled && isRecaptchaEnabled"
        >
          <label
            class="details"
            [innerHTML]="pageContent.captcha?.BHL_AcctCrt2_reCAPTCHA"
          ></label>
          <re-captcha
            #captchaRef
            (resolved)="resolved()"
            (error)="onError($event)"
            errorMode="handled"
          ></re-captcha>
        </div> -->


    </form>
    <bh-already-have-account
      [content]="(accountContent | async)?.content"
      [showLogin]="false"
    ></bh-already-have-account>
  </article>
</ng-container>
