import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  IEligibilityStatus,
  HttpError,
  IEmployer,
  ISelectedEmployerState,
  IRegistrationStatus,
  ISendMagicLinkStatus,
  IEligibilityFromMagicLinkStatus,
  IBenefitDetails,
  ICreateRegistrationStatus,
  IFetchRegistrationByIdStatus,
  IEmployeeDetailsState,
  IMasterListStatus,
  IMfaVerificationStatus,
  IMfaVerificationPayload,
  IFranchiseLocation,
  IFranchiseAccountQuestion,
  IValidateOTPpayload,
  IGeocodedLocation,
  IFetchFederatedDetailsStatus,
  IFicClientState,
  IRetrieveFICDetailsStatus,
  IEmailActivity,
  ICreateUNPwdStatus,
  IFranchiseAccountAnswer,
  IFetchEligibilityOsrDetailsStatus,
  IPersonInfo,
  IClientInfo,
  ISaveEmployeeDetailsStatus,
  IFetchUserIdentityStatus,
} from '../../models';

export const SignupActions = createActionGroup({
  source: 'Sign Up',
  events: {
    'Registration': emptyProps(),
    'Registration Success': props<{
      registrationStatus: IRegistrationStatus;
    }>(),
    'Registration Failure': props<{ error: string | HttpError }>(),
    'Clear Registration': emptyProps(),

    'FetchRegistrationById': props<{ id: string }>(),
    'FetchRegistrationById Success': props<{ fetchRegistrationByIdStatus: IFetchRegistrationByIdStatus }>(),
    'FetchRegistrationById Failure': props<{ error: string | HttpError }>(),
    'Clear FetchRegistrationById': emptyProps(),

    'FetchUserById': props<{ id: string, clientguid: string }>(),
    'FetchUserById Success': props<{ fetchUserByIdStatus: IFetchUserIdentityStatus }>(),
    'FetchUserById Failure': props<{ error: string | HttpError }>(),
    'Clear FetchUserById': emptyProps(),

    'FetchRegistrationByAlternateId': props<{ id: string }>(),
    'FetchRegistrationByAlternateId Success': props<{ fetchRegistrationByIdStatus: IFetchRegistrationByIdStatus }>(),
    'FetchRegistrationByAlternateId Failure': props<{ error: string | HttpError }>(),
    'Clear FetchRegistrationByAlternateId': emptyProps(),

    'Fetch Federated Details': emptyProps(),
    'Fetch Federated Details Success': props<{ fetchFederatedDetailsStatus: IFetchFederatedDetailsStatus }>(),
    'Fetch Federated Details Failure': props<{ error: string | HttpError }>(),

    'Load Employers': props<{ input: string }>(),
    'Load Employers Success': props<{ list: Array<IEmployer> }>(),
    'Load Employers Failure': props<{ error: string | HttpError }>(),
    'Clear Employers': emptyProps(),

    'Selected Employer': props<{ selectedEmployer: ISelectedEmployerState | null }>(),

    'Employee Details': props<{ employeeDetails: IEmployeeDetailsState }>(),
    'Clear Employee Details': emptyProps(),

    'Fic Client Details': props<{ ficClientDetails: IFicClientState }>(),

    'Eligibility': props<{ input: string }>(),
    'Eligibility Success': props<{ eligibilityStatus: IEligibilityStatus }>(),
    'Eligibility Failure': props<{ error: string | HttpError }>(),
    'Clear Eligibility': emptyProps(),

    'SendMagicLink': emptyProps(),
    'SendMagicLink Success': props<{
      magicLinkEmailStatus: ISendMagicLinkStatus;
    }>(),
    'SendMagicLink Failure': props<{ error: string | HttpError }>(),
    'Clear SendMagicLink': emptyProps(),

    'Eligibility From MagicLink': emptyProps(),
    'EligibilityFromMagicLink Success': props<{
      eligibilityFromMagicLinkStatus: IEligibilityFromMagicLinkStatus;
    }>(),
    'EligibilityFromMagicLink Failure': props<{ error: string | HttpError }>(),
    'Clear EligibilityFromMagicLink': emptyProps(),

    'Get Benefit': emptyProps(),
    'Get Benefit Success': props<{ info: IBenefitDetails }>(),
    'Get Benefit Failure': props<{ error: string | HttpError }>(),
    'Clear Benefit': emptyProps(),

    'Get Client Benefits': props<{ clientguid: string }>(),
    'Get Client Benefits Success': props<{ list: Array<IBenefitDetails> }>(),
    'Get Client Benefits Failure': props<{ error: string | HttpError }>(),
    'Clear Client Benefits': emptyProps(),

    'Get ValidatePostalCode': props<{ postalcode: string, country: string }>(),
    'Get ValidatePostalCode Success': props<{ zipCode: boolean }>(),
    'Get ValidatePostalCode Failure': props<{ error: string | HttpError }>(),
    'Clear ValidatePostalCode': emptyProps(),

    'Load Countries': emptyProps(),
    'Load Countries Success': props<{ list: Array<IMasterListStatus> }>(),
    'Load Countries Failure': props<{ error: string | HttpError }>(),
    'Clear Countries': emptyProps(),

    'Load Country Codes': emptyProps(),
    'Load Country Codes Success': props<{ list: Array<IMasterListStatus> }>(),
    'Load Country Codes Failure': props<{ error: string | HttpError }>(),
    'Clear Country Codes': emptyProps(),

    'Create Registration': emptyProps(),
    'Create Registration Success': props<{ createRegistrationStatus: ICreateRegistrationStatus }>(),
    'Create Registration Failure': props<{ error: string | HttpError }>(),
    'Clear Create Registration': emptyProps(),

    'Send FIC Email Verify Link': emptyProps(),
    'Send FIC Email Verify Link Success': props<{ ficEmailVerifyStatus: boolean }>(),
    'Send FIC Email Verify Link Failure': props<{ error: string | HttpError }>(),
    'Clear Send FIC Email Verify Link': emptyProps(),

    'Retrieve FIC Details': emptyProps(),
    'Retrieve FIC Details Success': props<{ retrieveFICDetailsStatus: IRetrieveFICDetailsStatus }>(),
    'Retrieve FIC Details Failure': props<{ error: string | HttpError }>(),

    'Verify Passcode': props<{ passcode: string; email: string }>(),
    'Verify Passcode Success': props<{ isPasscodeValid: boolean; isEmailValid: boolean }>(),
    'Verify Passcode Failure': props<{ error: string | HttpError }>(),
    'Clear Verify Passcode': emptyProps(),

    'Verify Email': props<{ email: string }>(),
    'Verify Email Success': props<{ verifyEmailStatus: boolean }>(),
    'Verify Email Failure': props<{ error: string | HttpError }>(),
    'Clear Verify Email': emptyProps(),

    'Send OTP': emptyProps(),
    'Send OTP Success': props<{ sendOTPStatus: boolean }>(),
    'Send OTP Failure': props<{ error: string | HttpError }>(),
    'Clear SendOTP': emptyProps(),

    'Verify OTP': props<{ payload: IValidateOTPpayload }>(),
    'Verify OTP Success': props<{ mfaValidateOTPStatus: boolean }>(),
    'Verify OTP Failure': props<{ error: string | HttpError }>(),
    'Clear Verify OTP': emptyProps(),

    'Account Lock': props<{ userName: string, userInfoID: string, unlockCheck: boolean, isReset: boolean }>(),
    'Account Lock Success': props<{ accountLockStatus: boolean }>(),
    'Account Lock Failure': props<{ error: string | HttpError }>(),
    'Clear Account Lock': emptyProps(),

    'MFA Verification': props<{ payload: IMfaVerificationPayload }>(),
    'MFA Verification Success': props<{ mfaVerificationStatus: IMfaVerificationStatus }>(),
    'MFA Verification Failure': props<{ error: string | HttpError }>(),
    'Clear MFA Verification': emptyProps(),

    'Set Franchise Location Query': props<{ input: string }>(),
    'Clear Franchise Location Query': emptyProps(),

    'Get Franchise Locations': props<{ input: string, isEditFlow: boolean }>(),
    'Get Franchise Locations Success': props<{ list: Array<IFranchiseLocation> }>(),
    'Get Franchise Locations Failure': props<{ error: string | HttpError }>(),
    'Clear Franchise Locations': emptyProps(),

    'Set Selected Franchise Location': props<{ location: IFranchiseLocation }>(),

    'Get Geocoded Locations': props<{ sessionKey: string }>(),
    'Get Geocoded Locations Success': props<{ list: Array<IGeocodedLocation> }>(),
    'Get Geocoded Locations Failure': props<{ error: string | HttpError }>(),

    'Get Franchise Account Questions': emptyProps(),
    'Get Franchise Account Questions Success': props<{ list: Array<IFranchiseAccountQuestion> }>(),
    'Get Franchise Account Questions Failure': props<{ error: string | HttpError }>(),
    'Clear Franchise Account Questions': emptyProps(),

    'Set Franchise Account Answers': props<{ data: Record<string, IFranchiseAccountAnswer> }>(),
    'Clear Franchise Account Answers': emptyProps(),

    'Get Franchise Unique Value': emptyProps(),
    'Get Franchise Unique Value Success': props<{ uniqueValue: string }>(),
    'Get Franchise Unique Value Failure': props<{ error: string | HttpError }>(),
    'Clear Franchise Unique Value': emptyProps(),

    'Get Franchise Registration Status': emptyProps(),
    'Get Franchise Registration Status Success': props<{ alreadyRegistered: boolean }>(),
    'Get Franchise Registration Status Failure': props<{ error: string | HttpError }>(),
    'Clear Franchise Registration Status': emptyProps(),

    'Save MFA': emptyProps(),
    'Save MFA Success': props<{ saveMfaStatus: boolean }>(),
    'Save MFA Failure': props<{ error: string | HttpError }>(),

    'Create UNPwd': emptyProps(),
    'Create UNPwd Success': props<{ createUNPwdStatus: ICreateUNPwdStatus }>(),
    'Create UNPwd Failure': props<{ error: string | HttpError }>(),

    'Load Email Activity': props<{ input: string }>(),
    'Load Email Activity Success': props<{ data: IEmailActivity }>(),
    'Load Email Activity Failure': props<{ error: string | HttpError }>(),

    'Fetch Eligbility Osr Details': emptyProps(),
    'Fetch Eligbility Osr Details Success': props<{ fetchRegistrationByIdStatus: IFetchEligibilityOsrDetailsStatus }>(),
    'Fetch Eligbility Osr Details Failure': props<{ error: string | HttpError }>(),

    'Trigger New OSR Email': emptyProps(),
    'Trigger New OSR Email Success': props<{ data: boolean }>(),
    'Trigger New OSR Email Failure': props<{ error: string | HttpError }>(),

    'Load Person Info': props<{ input: string }>(),
    'Load Person Info Success': props<{ data: IPersonInfo }>(),
    'Load Person Info Failure': props<{ error: string | HttpError }>(),

    'Get Client Info': props<{ input: string }>(),
    'Get Client Info Success': props<{ data: IClientInfo }>(),
    'Get Client Info Failure': props<{ error: string | HttpError }>(),
    'Clear Client Info': emptyProps(),

    'Save Employee Details': emptyProps(),
    'Save Employee Details Success': props<{ saveEmployeeDetailsStatus: ISaveEmployeeDetailsStatus }>(),
    'Save Employee Details Failure': props<{ error: string | HttpError }>(),
    'Clear Save Employee Details': emptyProps(),
  },
});
