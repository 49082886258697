import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { IAppJwt } from '../../models';
import { selectApiToken } from '../config/config.reducers';
import { environment } from '../../../environments/environment';
import {
  Authorization,
} from '../../constants';

/**
 * A context flag indicating the call is to an external API (e.g., Bing Maps)
 */
export const EXTERNAL_API = new HttpContextToken(() => false);

/**
 * An interceptor to append the API authentication jwt to the headers
 * if available in the store and prepends the API hosts to the URL.
 */
@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  /**
   * The interceptor `constructor`
   * @param {Store} store The NgRx store instance.
   */
  constructor(private readonly store: Store) { }

  /**
   * The interceptor implementation method.
   * @param {HttpRequest<unknown>} req An object containing the Http request information.
   * @param {HttpHandler} next Middleware to allow the execution to continue.
   * @returns {Observable<HttpEvent<unknown>>} The update Http event after any modifications.
   */
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let request = req;

    // If request is going to an external API, don't set token or prepend URL
    if (request.context.get(EXTERNAL_API)) {
      return next.handle(request);
    }

    let token: string | undefined;
    this.store
      .select(selectApiToken)
      .pipe(take(1))
      .subscribe((tokenInfo: IAppJwt | null): void => {
        token = tokenInfo?.token;
      });

    // prepend the api host
    request = request.clone({
      url: `${environment.apiHost}${request.url}`,
    });

    // add the token to the call if present and no token was passed with the headers already
    if (token && !request.headers.has(Authorization)) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return next.handle(request);
  }
}
