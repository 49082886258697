import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BhQueryParams, IApiResponse, IAppToken, IBenefitLogo, QueryParamsSet } from '../../models';
import { selectBenefitId, selectBhPropertiesURL, selectOrigin } from './config.reducers';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
  ) { }

  /**
   * Add any query params we grabbed from the URL when the app was loaded, so we can pass them along
   * when we navigate to the corresponding LOB.
   * @param {string} [host] Optional param to provide a host for the URL, it defaults to the one pulled from
   * the store, which is the app's origin.
   * @returns {URL} Returning the built URL object.
   */
  public buildExternalUrl(host?: string): URL {
    let builtUrl: URL | undefined;

    this.store
      .select(selectBenefitId)
      .pipe(
        combineLatestWith(
          this.store.select(selectBhPropertiesURL),
          this.store.select(selectOrigin),
        ),
        take(1),
      )
      .subscribe(([ benefitId, params, origin ]: [IBenefitLogo, BhQueryParams, string]): void => {
        const url: URL = new URL(host ?? '/', origin);

        url.searchParams.set('benefitid', benefitId.id);
        Object.keys(params).forEach((key: string): void => {
          const value: string | null | undefined = params[key as QueryParamsSet];

          if (value) {
            url.searchParams.set(key, value);
          }
        });

        if (params.isnonsso?.toLowerCase() === 'true') {
          url.searchParams.set('usertype', '2');
        }

        builtUrl = url;
      });

    return builtUrl as URL;
  }

  public authenticateApp(): Observable<IApiResponse<IAppToken>> {
    const body = new HttpParams()
      .set('clientid', environment.versionDate)
      .set('clientsecret', environment.cacheBustingVersion);

    return this.http.post<IApiResponse<IAppToken>>(
      `${environment.apiVersionUri.v1}/ClientToken`,
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }
}
