import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';
import { debounceTime, distinctUntilChanged, map, Observable, skip, Subject, take, takeUntil } from 'rxjs';

import {
  bhQueryParamsSet,
  emailPattern,
  employerSearch,
  phonePattern,
  typeaheadDelay,
  typeaheadMinLength,
  namePattern,
  privacyNotice,
} from 'src/app/constants';
import { ConfigActions } from 'src/app/core/config/config.actions';
import { selectBenefitId, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { ContentActions } from 'src/app/core/content/content.actions';
import {
  selectCreateAccountPage,
  selectEligibilityPage,
  selectHelpDeskPage,
  selectPersonalInfoPage,
} from 'src/app/core/content/content.reducers';
import { selectPersonInfoState } from 'src/app/core/signup/signup.reducers';
import {
  BenefitId,
  BhQueryParams,
  BhlError,
  ContentPages,
  EventName,
  IBenefitLogo,
  IContentState,
  IEmployer,
  IHelpState,
  ILegalLink,
  IMasterListStatus,
  IPersonInfo,
  IPersonInfoState,
  QueryParamsSet,
  RecaptchaSubCategory,
} from 'src/app/models';
import { environment } from '../../../../../environments/environment';
import { ContentService } from '../../../../core/content/content.service';
import { LoggerService } from '../../../../core/logger-service/logger.service';
import { GlobalActions } from '../../store/global.actions';
import { selectHelpDeskSupport } from '../../store/global.reducers';
import {
  selectEmployersError,
  selectEmployersList,
  selectEmployersLoading,
  selectStateListDetails,
  selectStateListLoading,
  selectSupportHelpsList,
  selectSupportHelpsLoading,
  selectSupportServicesList,
  selectSupportServicesLoading,
} from '../../store/global.selectors';
import { DOCUMENT } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-help',
  templateUrl: './help.component.html',
  styleUrls: [ './help.component.scss' ],
})
export class HelpComponent implements OnInit, OnDestroy {
  public readonly isRecaptchaEnabled: boolean = environment.google.reCaptcha.enable;
  public readonly content: Observable<IContentState[ContentPages.helpDeskPage]> = this.store.select(selectHelpDeskPage);
  public readonly supportHelps: Observable<Array<IMasterListStatus> | null> = this.store.select(selectSupportHelpsList);
  public readonly supportServices: Observable<Array<IMasterListStatus> | null> = this.store.select(selectSupportServicesList);
  public readonly accountContent: Observable<IContentState[ContentPages.createAccountPage]> =
    this.store.select(selectCreateAccountPage);
  public readonly eligbilityContent: Observable<IContentState[ContentPages.eligibilityPage]> =
    this.store.select(selectEligibilityPage);
  public readonly personalInfoContent: Observable<IContentState[ContentPages.personalInfoPage]> =
    this.store.select(selectPersonalInfoPage);
  @ViewChild('captchaRef') public readonly captchaRef: RecaptchaComponent | undefined;
  public file: File | null = null;
  public selectedEmployer: IEmployer | null = null;
  public toHighlight: string = '';
  public readonly employers: Observable<Array<IEmployer> | null> =
    this.store.select(selectEmployersList);
  public readonly stateList: Observable<Array<IMasterListStatus> | null> =
    this.store.select(selectStateListDetails);
  public isFicMbdVersion: boolean = false;
  public showMessageInvalid: boolean = false;
  public benefitId: string;
  public userInfoId: string | null | undefined = '';
  public paramsSet: Set<QueryParamsSet> = new Set<QueryParamsSet>(bhQueryParamsSet);
  public bhPropertiesURL: BhQueryParams = {};
  public sourceURL: string;
  public helpDeskForm: FormGroup = this.fb.group({
    helpDesk: [ '' as string, Validators.required ],
    needHelpWith: [ '', Validators.required ],
    servicesInterested: [ '', Validators.required ],
    fcEmployer: [ '' as string, Validators.required ],
    firstName: [ '' as string, [ Validators.required, Validators.pattern(namePattern) ] ],
    lastName: [ '' as string, [ Validators.required, Validators.pattern(namePattern) ] ],
    email: [ '', [ Validators.required, Validators.pattern(emailPattern) ] ],
    phone: [
      '' as string,
      [ Validators.required, Validators.pattern(phonePattern) ],
    ],
    centerName: [ '' as string ],
    city: [ '' as string, Validators.required ],
    state: [ '' as string, Validators.required ],
    employeeId: [ '' as string ],
    attachmentFile: [ [] as Array<object> ],
  });
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public displayContent : string ;
  private sendFormStartEvent: boolean = true;
  private isNeedHelpChanged: boolean = true;
  private autoFilledNeedHelp: boolean = true;
  private isServiceChanged: boolean = true;
  private autoFilledService: boolean = true;
  private isEmployerChanged: boolean = true;
  private autoFilledEmployer: boolean = true;
  private isFirstNameChanged: boolean = true;
  private autoFilledFirstName: boolean = true;
  private isLastNameChanged: boolean = true;
  private autoFilledLastName: boolean = true;
  private isEmailChanged: boolean = true;
  private autoFilledEmail: boolean = true;
  private isPhoneChanged: boolean = true;
  private autoFilledPhone: boolean = true;
  private isEmployeeIdChanged: boolean = true;
  private autoFilledEmployeeId: boolean = true;
  private isHelpDeskDescriptionChanged: boolean = true;
  private autoFilledHelpDeskDescription: boolean = true;
  public helpDeskTitle: string = "Contact Helpdesk";
  public readonly privacyNotice: ILegalLink = privacyNotice;

  // eslint-disable-next-line max-params
  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly loggerService: LoggerService,
    private readonly contentService: ContentService,
    private readonly sharedService: SharedService
  ) {
    this.store.dispatch(
      ContentActions.loadPageContentPattern({ page: ContentPages.helpDeskPage }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.createAccountPage,
      }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.eligibilityPage,
      }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.personalInfoPage,
      }),
    );
    this.store.dispatch(GlobalActions.loadSupportHelps());
    this.store.dispatch(GlobalActions.loadSupportServices());
  }

  public ngOnInit(): void {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        this.userInfoId = params?.uid as string;
        this.sourceURL = params?.sourceurl as string;
      });

      this.content
            .subscribe((contentState: IContentState[ContentPages.helpDeskPage]): void => {
              if (this.userInfoId) {
                this.displayContent = contentState.content.BHL_LoggedOutSupportForm_Label_Content;
              } else {
                this.displayContent = contentState.content.BHL_LoggedOutSupportForm_Label_Content3;
              }
            });

    this.store
      .select(selectEmployersLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectSupportHelpsLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectSupportServicesLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectStateListLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectEmployersError)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((error: BhlError): void => {
        if (error) {
          if (typeof error !== 'string' && error.status === 404) {
            this.store.dispatch(
              GlobalActions.loadEmployersSuccess({ list: [] }),
            );
          }
        }
      });

    this.store
      .select(selectHelpDeskSupport)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((helpState: IHelpState): void => {
        if (helpState.loaded) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));
          this.router.navigate([ 'global', 'confirmation' ]);
        } else if (helpState.error) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));
          this.contentService.showErrorToast();
        }
      });

    this.helpDeskForm.controls['fcEmployer']!.valueChanges.pipe(
      debounceTime(typeaheadDelay),
      map((value: string | IEmployer): string | IEmployer => {
        if (typeof value === 'string') {
          // remove any special chars to prevent duplicate calls to the backend
          return value.replace(employerSearch, '');
        }

        return value;
      }),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    ).subscribe((value: string | null | IEmployer): void => {
      if (value) {
        if (typeof value === 'string') {
          if (value.length >= typeaheadMinLength) {
            this.toHighlight = value;
            this.store.dispatch(GlobalActions.loadEmployers({ input: value }));
            this.employers.subscribe((employersList) => {
              if (employersList && employersList.length >= 0) {
                const employerObj = employersList.find((x) => x.clientName.toLowerCase() === value.toLowerCase());
                if (employerObj !== undefined) {
                  this.setSelectedEmployer(employerObj);
                }
              }
            });
          } else {
            this.store.dispatch(GlobalActions.clearEmployers());
          }
        } else {
          this.setSelectedEmployer(value);
        }
      } else {
        this.store.dispatch(GlobalActions.clearEmployers());
      }
    });

    this.store
      .select(selectBenefitId)
      .pipe(take(1))
      .subscribe((benefitId: IBenefitLogo | null): void => {
        if (benefitId) {
          this.benefitId = benefitId.id as string;
          this.setServicesInteresteddefaultvalue(this.benefitId);
        }
      });

    if (this.sourceURL && !(this.sourceURL.includes('login-new') || this.sourceURL.includes('password'))) {
        this.getPersonalInfoData();
    }

    this.helpDeskForm.controls['helpDesk']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.showHelpDeskTextAreaError();
        this.sendFormStart();
        if(this.isHelpDeskDescriptionChanged){
          this.isHelpDeskDescriptionChanged = false;
          this.sendFormFillGa4Event("How can we help?","no");
        }
      });

      this.helpDeskForm.controls['fcEmployer']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmployerChanged){
          this.isEmployerChanged = false;
          this.sendFormFillGa4Event("Employer","no");
        }
      });

      this.helpDeskForm.controls['firstName']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isFirstNameChanged){
          this.isFirstNameChanged = false;
          this.sendFormFillGa4Event("First Name","no");
        }
      });

      this.helpDeskForm.controls['lastName']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isLastNameChanged){
          this.isLastNameChanged = false;
          this.sendFormFillGa4Event("Last Name","no");
        }
      });

      this.helpDeskForm.controls['email']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmailChanged){
          this.isEmailChanged = false;
          this.sendFormFillGa4Event("Email","no");
        }
      });

      this.helpDeskForm.controls['phone']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isPhoneChanged){
          this.isPhoneChanged = false;
          this.sendFormFillGa4Event("Phone","no");
        }
      });

      this.helpDeskForm.controls['employeeId']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmployeeIdChanged){
          this.isEmployeeIdChanged = false;
          this.sendFormFillGa4Event("Employee ID","no");
        }
      });

      if(this.helpDeskForm.controls['needHelpWith']?.value !== '' && this.helpDeskForm.controls['needHelpWith']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledNeedHelp){
          this.autoFilledNeedHelp = false;
          this.sendFormFillGa4Event("I need help with","yes");
        }
      }
    
      if(this.helpDeskForm.controls['servicesInterested']?.value !== '' && this.helpDeskForm.controls['servicesInterested']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledService){
          this.autoFilledService = false;
          this.sendFormFillGa4Event("Which service can we help you with","yes");
        }
      }

      if(this.helpDeskForm.controls['fcEmployer']?.value !== '' && this.helpDeskForm.controls['fcEmployer']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmployer){
          this.autoFilledEmployer = false;
          this.sendFormFillGa4Event("Employer","yes");
        }
      }

      if(this.helpDeskForm.controls['firstName']?.value !== '' && this.helpDeskForm.controls['firstName']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledFirstName){
          this.autoFilledFirstName = false;
          this.sendFormFillGa4Event("First Name","yes");
        }
      }
      
      if(this.helpDeskForm.controls['lastName']?.value !== '' && this.helpDeskForm.controls['lastName']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledLastName){
          this.autoFilledLastName = false;
          this.sendFormFillGa4Event("Last Name","yes");
        }
      }

      if(this.helpDeskForm.controls['email']?.value !== '' && this.helpDeskForm.controls['email']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmail){
          this.autoFilledEmail = false;
          this.sendFormFillGa4Event("Email","yes");
        }
      }

      if(this.helpDeskForm.controls['phone']?.value !== '' && this.helpDeskForm.controls['phone']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledPhone){
          this.autoFilledPhone = false;
          this.sendFormFillGa4Event("Phone","yes");
        }
      }

      if(this.helpDeskForm.controls['employeeId']?.value !== '' && this.helpDeskForm.controls['employeeId']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmployeeId){
          this.autoFilledEmployeeId = false;
          this.sendFormFillGa4Event("Employee ID","yes");
        }
      }
      
      if(this.helpDeskForm.controls['helpDesk']?.value !== '' && this.helpDeskForm.controls['helpDesk']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledHelpDeskDescription){
          this.autoFilledHelpDeskDescription = false;
          this.sendFormFillGa4Event("How can we help?","yes");
        }
      }    
  }

  public sendFormStart(): void{
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
  }

  public showHelpDeskTextAreaError(): void {
    this.showMessageInvalid =
      (this.helpDeskForm.get('helpDesk')!.value as string).length < 1;
  }

  public get helpDesk(): AbstractControl<string> {
    return this.helpDeskForm.get('helpDesk')!;
  }

  public get needHelpWith(): AbstractControl<string> {
    return this.helpDeskForm.get('needHelpWith')!;
  }

  public get servicesInterested(): AbstractControl<string> {
    return this.helpDeskForm.get('servicesInterested')!;
  }

  public get fcEmployer(): AbstractControl<string> {
    return this.helpDeskForm.get('fcEmployer')!;
  }

  public get firstName(): AbstractControl<string> {
    return this.helpDeskForm.get('firstName')!;
  }

  public get lastName(): AbstractControl<string> {
    return this.helpDeskForm.get('lastName')!;
  }

  public get email(): AbstractControl<string> {
    return this.helpDeskForm.get('email')!;
  }

  public get phone(): AbstractControl<string> {
    return this.helpDeskForm.get('phone')!;
  }

  public minLengthValid(): boolean | undefined {
    return this.helpDeskForm.get('phone')!.hasError('pattern');
  }

  public phoneNoValue(): boolean | undefined {
    const phoneNumberValidate = this.helpDeskForm.get('phone')!.value as string;

    return (
      phoneNumberValidate.toString().length > 9 ||
      phoneNumberValidate.toString().length <= 9
    );
  }

  public get centerName(): AbstractControl<string> {
    return this.helpDeskForm.get('centerName')!;
  }

  public get city(): AbstractControl<string> {
    return this.helpDeskForm.get('city')!;
  }

  public get state(): AbstractControl<string> {
    return this.helpDeskForm.get('state')!;
  }

  public get employeeId(): AbstractControl<string> {
    return this.helpDeskForm.get('employeeId')!;
  }

  public get attachmentFile(): AbstractControl<string> {
    return this.helpDeskForm.get('attachmentFile')!;
  }

  public setServicesInteresteddefaultvalue(benefitId: string): void {
    switch (benefitId) {
      case BenefitId.FIC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_FIC');
        this.isFicMbdVersion = true;
        this.store.dispatch(GlobalActions.loadState());
        break;
      case BenefitId.BUC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_BUC');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.CCSS:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_CCSS');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.CollegeCoach:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_CC');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.EdAssist:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_EdA');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.MyBH:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_Benefits');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.SC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_Sitter');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.WFS:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_WorkFamily');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.Default:
        this.isFicMbdVersion = false;
        break;
      default:
        break;
    }
    if (this.isFicMbdVersion) {
      this.helpDeskForm.controls['fcEmployer']!.clearValidators();
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['city']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['state']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    } else {
      this.helpDeskForm.controls['fcEmployer']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.clearValidators();
      this.helpDeskForm.controls['city']!.clearValidators();
      this.helpDeskForm.controls['state']!.clearValidators();
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
      this.helpDeskForm.updateValueAndValidity();
    }
  }

  private setSelectedEmployer(employer: IEmployer): void {
    this.selectedEmployer = employer;
  }

  public onNeedHelpWithChange(): void{
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
    if(this.isNeedHelpChanged){
      this.isNeedHelpChanged = false;
      this.sendFormFillGa4Event("I need help with","no");
    }
  }

  public onServiceChange(): void {
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
    if(this.isServiceChanged){
      this.isServiceChanged = false;
      this.sendFormFillGa4Event("Which service can we help you with?","no");
    }
    if (
      this.helpDeskForm.controls['servicesInterested']?.value ===
        'BHL_MyBHSupport_MBD' ||
      this.helpDeskForm.controls['servicesInterested']?.value ===
        'BHL_MyBHSupport_FIC'
    ) {
      this.store.dispatch(GlobalActions.loadState());
      this.isFicMbdVersion = true;
      this.helpDeskForm.controls['fcEmployer']!.clearValidators();
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['city']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['state']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    } else {
      this.isFicMbdVersion = false;
      this.store.dispatch(GlobalActions.clearState());
      this.helpDeskForm.controls['fcEmployer']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['centerName']!.clearValidators();
      this.helpDeskForm.controls['city']!.clearValidators();
      this.helpDeskForm.controls['state']!.clearValidators();
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    }
    this.helpDeskForm.updateValueAndValidity();
  }

  public onFilechange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (
      target.files![0]!.size < 2097152 &&
      (target.files![0]!.type === 'application/pdf' ||
        target.files![0]!.type === 'image/png' ||
        target.files![0]!.type === 'image/jpg' ||
        target.files![0]!.type === 'image/jpeg' ||
        target.files![0]!.type === 'image/bmp' ||
        target.files![0]!.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        target.files![0]!.type === 'text/plain' ||
        target.files![0]!.type === 'image/gif')
    ) {
      this.file = target.files![0] as File;
    } else if (target.files![0]!.size > 2097152) {
      this.showValidationOnFileChange(
        'Attachment exceeds max size. Please try again.',
      );
      this.helpDeskForm.get('attachmentFile')!.setValue('');
    } else {
      this.showValidationOnFileChange(
        'Attachment must be a .bmp, .gif, .jpeg, .jpg, .png, .doc, .docx, .txt, or .pdf.',
      );
    }
  }

  private showValidationOnFileChange(message: string): void {
    this.file = null;
    this.contentService.showErrorToast(message);
    this.helpDeskForm.get('attachmentFile')!.setValue('');
  }

  public onSend(): void {
    this.showHelpDeskTextAreaError();
    if (this.helpDeskForm.invalid) {
      this.helpDeskForm.markAllAsTouched();
    } else if (this.isRecaptchaEnabled) {
      this.store.dispatch(ConfigActions.loading({ loading: true }));
      this.captchaRef?.execute();
    } else {
      this.resolved();
    }
  }

  public resolved(): void {
    this.sharedService.GA4pushEvent('form_submit_success', {
      // eslint-disable-next-line camelcase
      form_name: "Contact Helpdesk"
    });
    this.store.dispatch(
      GlobalActions.helpDeskSupport({
        payload: {
          NeedHelp: this.needHelpWith.value,
          Employer: this.selectedEmployer?.clientName ?? this.fcEmployer.value,
          ClientGuid: this.selectedEmployer?.clientGuid ?? '',
          BHService: this.servicesInterested.value,
          ServiceInterest: '',
          FirstName: this.firstName.value.trim(),
          LastName: this.lastName.value.trim(),
          Email: this.email.value,
          Phone: this.phone.value,
          CenterName: this.centerName.value,
          City: this.city.value,
          State: this.state.value,
          Message: this.helpDesk.value,
          FileAttachments: this.file,
          EmployeeID: this.employeeId.value,
          userInfoID: this.userInfoId,
          SourceURL: this.sourceURL,
          CurrentURL: this.doc.location.href,
          OptForSMS: false,
          IsNewHelpForm: false,
        },
      }),
    );
  }

  public onError(event: RecaptchaErrorParameters): void {
    this.store.dispatch(ConfigActions.loading({ loading: false }));
    this.contentService.showErrorToast();

    // this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.SIGNUP_TECHNICAL_ERROR_FE);
    this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.RECAPTCHA, undefined, RecaptchaSubCategory.RECAPTCHA_FAILURE);
  }

  public displayFn(emp: IEmployer): string {
    return emp.clientName;
  }

  public trackByEmployerFn(index: number, emp: IEmployer): number {
    return emp.clientId;
  }

  public trackByFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public trackByServicesFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public trackByStateFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public onRemoveFile(): void {
    this.file = null;
    this.helpDeskForm.get('attachmentFile')!.setValue(null);
  }

  private getPersonalInfoData(): void {
    this.store.select(selectPersonInfoState)
      .pipe(
        take(1),
      )
      .subscribe((state: IPersonInfoState): void => {
        if (state.loaded) {
          const formData: IPersonInfo | null = state.data;
          this.helpDeskForm.get('firstName')?.setValue(formData?.firstName);
          this.helpDeskForm.get('lastName')?.setValue(formData?.lastName);
          this.helpDeskForm.get('email')?.setValue(formData?.primaryEmail);
          const selectedEmployer: IEmployer = {
            clientGuid: formData?.crmClientId ?? '',
            clientName: formData?.clientName ?? '',
            clientId: 0,
          };
          this.setSelectedEmployer(selectedEmployer);
          this.helpDeskForm.get('fcEmployer')?.setValue(selectedEmployer);
          this.helpDeskForm.updateValueAndValidity();
        }
      });
  }

  public sendFormStartGa4Event(): void{
    this.sharedService.GA4pushEvent('form_start', {
      // eslint-disable-next-line camelcase
      form_name: "Contact Helpdesk", //value changed
    });
  }

  public sendFormFillGa4Event(fieldName: string, autoFilled: string): void{
    this.sharedService.GA4pushEvent('form_fill', {
      // eslint-disable-next-line camelcase
      form_name: "Contact Helpdesk", // Name of Form
      // eslint-disable-next-line camelcase
      field_name: fieldName, // Field Name user interacted
      autofilled: autoFilled // If the field was auto filled or not
    })
  }

  public onPrivacyClick() : void{
    this.sendOutboundGa4Event(privacyNotice.label, privacyNotice.url);
  }

  public sendOutboundGa4Event(label : string, url : string) : void{
    this.sharedService.GA4pushEvent('outbound_link_click', {
      /* eslint camelcase: ["error", {allow: ["link_text","link_url","link_section"]}]*/
      link_text: label, //Text on link clicked
      link_url: url,// Link click for outbound links
      link_section: "body" // Section of DOM where the outbound link is clicked
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
