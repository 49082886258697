import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigActions } from 'src/app/core/config/config.actions';
import { ContentActions } from 'src/app/core/content/content.actions';
import { selectEmployeeSearchPage, selectPasswordUpdatedPage } from 'src/app/core/content/content.reducers';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { IContentState, ContentPages, LocalStorageKey } from 'src/app/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-password-updated',
  templateUrl: './password-updated.component.html',
  styleUrls: [ './password-updated.component.scss' ],
})
export class PasswordUpdatedComponent {
  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the page content.
   */
  public readonly content: Observable<IContentState[ContentPages.passwordUpdatedPage]> = this.store.select(selectPasswordUpdatedPage);

  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.store.dispatch(ConfigActions.loading({ loading: false }));

    // fetch page content
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.passwordUpdatedPage }));

    // Clear localStorage, since there's no longer any need for it
    const clientGuid = this.localStorageService.getItem(LocalStorageKey.clientGuid) as string;
    this.localStorageService.clearAll();
    this.localStorageService.saveItem(LocalStorageKey.clientGuid,clientGuid );
  }
}
