import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';

import { selectFlowType, selectIsClientGuidFromQueryParam } from '../../../core/config/config.reducers';
import { ContentActions } from '../../../core/content/content.actions';
import { selectEmployeeSearchPage, selectLogoUrl, selectStandardLoginPage } from '../../../core/content/content.reducers';
import { selectClientLogoIsLoading } from '../../../core/content/content.selectors';
import { ContentPages, FlowType, IClientLogoUrl, IContentState } from '../../../models';

/**
 * Component to display the employer logo during the registration flow
 * and default to the BH logo if nothing is provided.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-employer-logo',
  templateUrl: './employer-logo.component.html',
  styleUrls: [ './employer-logo.component.scss' ],
})
export class EmployerLogoComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public readonly content: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the page content.
   */
  public readonly loginContent: Observable<IContentState[ContentPages.standardLoginPage]> = this.store.select(selectStandardLoginPage);

  public readonly logoLoading: Observable<boolean> = this.store.select(selectClientLogoIsLoading);
  public guidIsFromQuery: boolean = false;

  public flowType: FlowType = 'join';

  /**
   * The client logo URL that will be assigned based on the response from the backend.
   */
  public clientLogoUrl: string | null = null;

  /**
   * A default logo to be displayed. Defaults to BH logo.
   */
  @Input() public defaultLogo: string = 'assets/logos/logo-no-text.svg';

  /**
   * The employer name to be displayed. Defaults to 'Welcome to Bright Horizons!'.
   */
  @Input() public heading: string | undefined | null = 'Welcome to Bright Horizons!';

  @Input() public useDefaultLogo: boolean = false; // maybe the split screen scenario if we need to force it to be MS logo??? otherwise we do not need it!!!
  @Input() public headingIsClientName: boolean = true;
  @Input() public imgClass = 'bh-login-intro__logo';
  @Input() public showLoginHeading: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.store
      .select(selectFlowType)
      .pipe(
        take(1),
      )
      .subscribe((flowType: FlowType): void => {
        this.flowType = flowType;
      });

    this.store.select(selectIsClientGuidFromQueryParam)
      .pipe(take(1))
      .subscribe((isFromQuery: boolean): void => {
        this.guidIsFromQuery = !isFromQuery;
      });

    // grab the client logo URL if we do not want to use the default one and the passed heading is the client name
    if (!this.useDefaultLogo && this.headingIsClientName && this.heading !== null) {
      this.store.dispatch(ContentActions.fetchLogoUrl());
      this.store.select(selectLogoUrl)
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((logo: IClientLogoUrl): void => {
          // update the view only on success and the backend returned a url to the client's logo
          if (logo.loaded && logo.path) {
            this.clientLogoUrl = logo.path;
            this.cdr.detectChanges();
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
