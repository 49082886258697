/* eslint-disable security/detect-object-injection */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to mask an email address.
 */
@Pipe({
  name: 'bhMaskEmail',
})
export class MaskEmailPipe implements PipeTransform {
  /**
   * Transform an email to mask all characters between the first and last
   * character before the `@` sign.
   * @param {string} value The email to mask.
   * @returns {string} The masked email.
   */
  public transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    const email: Array<string> = value.split('');
    const maskArr: Array<string> = [];
    const initial: number = 1;
    const len: number = email.indexOf('@');
    const slice: number = 2;
    const end: number = len - slice;

    email.forEach((item: string, pos: number): void => {
      if (pos >= initial && pos <= end) {
        maskArr.push('*');
      } else {
        maskArr.push(item);
      }
    });

    return maskArr.join('');
  }
}
