import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BhQueryParams, ClickType, DisplayType, FlowType, HttpError, IAppJwt, IAppToken, IBenefitLogo, LoginType } from '../../models';

/**
 * The `config` (app global) action group.
 */
export const ConfigActions = createActionGroup({
  source: 'App Config',
  events: {
    'Flow Type': props<{ flowType: FlowType }>(),
    'Login Type': props<{ loginType: LoginType }>(),
    'Loading': props<{ loading: boolean }>(),
    'Benefit ID': props<{ benefitId: IBenefitLogo }>(),
    'Bh Properties URL': props<{ bhPropertiesURL: BhQueryParams }>(),
    'Display Type': props<{ displayType: DisplayType }>(),
    'Save App Auth Token': props<{ tokenInfo: Partial<IAppJwt> }>(),
    'Set Secure Auth Info': props<{ jsonDfp: string }>(),
    'Set App Origin': props<{ origin: string }>(),
    'Clear App Auth Token': emptyProps(),
    'Client Guid from Query': props<{ isFromQuery: boolean }>(),

    'Fetch Client Token': emptyProps(),
    'Get Client Token Success': props<{ info: IAppToken }>(),
    'Get Client Token Failure': props<{ error: string | HttpError }>(),
    'Clear Client Token': emptyProps(),

    'Save Timeout Button Click': props<{ clickType: ClickType}>(),
  },
});
