import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: [ './forget-password.component.scss' ],
})
export class ForgetPasswordComponent {
}
