import { ChangeDetectionStrategy, Component, Input, Inject } from '@angular/core';
import { Router , UrlTree} from '@angular/router';
import { joinTodayUrlRoute, loginUrl } from '../../../constants';
import { ConfigActions } from '../../../core/config/config.actions';
import { ConfigService } from '../../../core/config/config.service';
import { Store } from '@ngrx/store';
import { selectFlowType } from 'src/app/core/config/config.reducers';
import { take } from 'rxjs';
import { FlowType, LocalStorageKey } from 'src/app/models';
import { SharedService } from 'src/app/shared/shared.service';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: [ './login-button.component.scss' ],
})
export class LoginButtonComponent {
  /**
   * The label for the button, it defaults to `Log In`.
   */
  @Input() public label: string | undefined = 'Log In';

  /**
   * A class name or a list of class names, space separated, for the button visual
   * representation. The default is 'bh-button--secondary'.
   */
  @Input() public classNames: string = 'bh-link';

  /**
   * A property to hold the built URL that includes any query params we need to pass along.
   */
  public loginUrl: URL;
  public isLoginFlow: boolean = false;
  // eslint-disable-next-line max-params
  constructor(
    private readonly config: ConfigService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly sharedService: SharedService,
    private readonly locationStrategy: LocationStrategy,
    private readonly localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.store.select(selectFlowType)
      .pipe(take(1))
      .subscribe((flowType: FlowType): void => {
        this.isLoginFlow = flowType === 'login';

        if (!this.isLoginFlow) {
          this.loginUrl = this.config.buildExternalUrl(loginUrl);
        }
      });
  }

  public goToLogin(): void {
    this.sharedService.GA4pushEvent('cta_click', {
      // eslint-disable-next-line camelcase
      link_text: "Log In", // Text clicked on CTA
      // eslint-disable-next-line camelcase
      link_url: this.loginUrl.href,  // Update the Link over here.
      // eslint-disable-next-line camelcase
      link_section: "Body" // Section of website where CTA is available
    });
  }

  private urlWithoutQueryParams(route: readonly [string, string]): string {
    const urlTree: UrlTree = this.router.createUrlTree([ ...route ]);
    return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }

  public goToSignUp(): void {
    this.localStorageService.removeItem(LocalStorageKey.isPreviousPageBenefitSelection);
    const url = this.urlWithoutQueryParams(joinTodayUrlRoute);
    const link = this.document.createElement('a');
    //link.target = '_blank';
    link.href = url;

    this.sharedService.GA4pushEvent('cta_click', {
      // eslint-disable-next-line camelcase
      link_text: "Join Today", // Text clicked on CTA
      // eslint-disable-next-line camelcase
      link_url: link.href,  // Update the Link over here.
      // eslint-disable-next-line camelcase
      link_section: "Body" // Section of website where CTA is available
    });
    this.store.dispatch(ConfigActions.flowType({ flowType: 'join' }));
    this.router.navigate([ '/signup', 'search' ]);
    
  }




}
