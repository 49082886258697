import { ActionReducer, createFeature, createReducer, on } from '@ngrx/store';

import { HttpError, ICredentials, ICredentialsState, IMFAUserEmailAndMobileStatus, IValidateUserStatus } from '../../models';
import { CredentialsActions } from './credentials.actions';

const initialState: ICredentialsState = {
  changePasswordState: {
    loading: false,
    loaded: false,
    error: null,
    changePassword: null,
  },
  sendChangePassEmailState: {
    loading: false,
    loaded: false,
    error: null,
    data: false,
  },
  validateUserState: {
    loading: false,
    loaded: false,
    error: null,
    validateUserStatus: null,
  },
  resetPasswordState: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },
  forgotPasswordState: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },
  forgotUsernameState: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },
  mfaUserEmailAndMobileState: {
    loading: false,
    loaded: false,
    error: null,
    mfaUserEmailAndMobileStatus: [],
  },
};

/**
 * The `credentials` (app global) reducer.
 */
export const credentialsReducer: ActionReducer<ICredentialsState> = createReducer(
  initialState,
  on(
    CredentialsActions.changePassword,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      changePasswordState: {
        ...state.changePasswordState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.changePasswordSuccess,
    (state: ICredentialsState, { data }: { data: ICredentials }): ICredentialsState => ({
      ...state,
      changePasswordState: {
        ...state.changePasswordState,
        loading: false,
        loaded: true,
        changePassword: data,
      },
    }),
  ),
  on(
    CredentialsActions.changePasswordFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      changePasswordState: {
        ...state.changePasswordState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.sendChangePasswordEmail,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      sendChangePassEmailState: {
        ...state.sendChangePassEmailState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.sendChangePasswordEmailSuccess,
    (state: ICredentialsState, { data }: { data: boolean }): ICredentialsState => ({
      ...state,
      sendChangePassEmailState: {
        ...state.sendChangePassEmailState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    CredentialsActions.sendChangePasswordEmailFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      sendChangePassEmailState: {
        ...state.sendChangePassEmailState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.validateUser,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      validateUserState: {
        ...state.validateUserState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.validateUserSuccess,
    (state: ICredentialsState, { validateUserStatus }: { validateUserStatus: IValidateUserStatus }): ICredentialsState => ({
      ...state,
      validateUserState: {
        ...state.validateUserState,
        loading: false,
        loaded: true,
        validateUserStatus,
      },
    }),
  ),
  on(
    CredentialsActions.validateUserFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      validateUserState: {
        ...state.validateUserState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.clearValidateUser,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      validateUserState: {
        validateUserStatus: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.resetPassword,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      resetPasswordState: {
        ...state.resetPasswordState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.resetPasswordSuccess,
    (state: ICredentialsState, { data }: { data: boolean }): ICredentialsState => ({
      ...state,
      resetPasswordState: {
        ...state.resetPasswordState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    CredentialsActions.resetPasswordFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      resetPasswordState: {
        ...state.resetPasswordState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.forgotPassword,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      forgotPasswordState: {
        ...state.forgotPasswordState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.forgotPasswordSuccess,
    (state: ICredentialsState, { data }: { data: boolean }): ICredentialsState => ({
      ...state,
      forgotPasswordState: {
        ...state.forgotPasswordState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    CredentialsActions.forgotPasswordFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      forgotPasswordState: {
        ...state.forgotPasswordState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.clearForgotPassword,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      forgotPasswordState: {
        data: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),

  on(
    CredentialsActions.forgotUsername,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      forgotUsernameState: {
        ...state.forgotUsernameState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.forgotUsernameSuccess,
    (state: ICredentialsState, { data }: { data: boolean }): ICredentialsState => ({
      ...state,
      forgotUsernameState: {
        ...state.forgotUsernameState,
        loading: false,
        loaded: true,
        data,
      },
    }),
  ),
  on(
    CredentialsActions.forgotUsernameFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      forgotUsernameState: {
        ...state.forgotUsernameState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    CredentialsActions.clearForgotUsername,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      forgotUsernameState: {
        data: null,
        loading: false,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.getMfaUserInfo,
    (state: ICredentialsState): ICredentialsState => ({
      ...state,
      mfaUserEmailAndMobileState: {
        ...state.mfaUserEmailAndMobileState,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    CredentialsActions.getMfaUserInfoSuccess,
    (state: ICredentialsState, { mfaUserEmailAndMobileStatus }:
    { mfaUserEmailAndMobileStatus: Array<IMFAUserEmailAndMobileStatus> }): ICredentialsState => ({
      ...state,
      mfaUserEmailAndMobileState: {
        ...state.mfaUserEmailAndMobileState,
        loading: false,
        loaded: true,
        mfaUserEmailAndMobileStatus,
      },
    }),
  ),
  on(
    CredentialsActions.getMfaUserInfoFailure,
    (state: ICredentialsState, { error }: { error: string | HttpError }): ICredentialsState => ({
      ...state,
      mfaUserEmailAndMobileState: {
        ...state.mfaUserEmailAndMobileState,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
);

/**
 * The `credentials` (app global) reducer feature creator that will generate
 * metadata and selectors for us.
 */
export const contentFeatureReducer = createFeature({
  name: 'credentials',
  reducer: credentialsReducer,
});

/**
 * Name export all the generated selectors by the feature reducer creator.
 */
export const {
  name,
  selectCredentialsState,
  selectChangePasswordState,
  selectSendChangePassEmailState,
  selectValidateUserState,
  selectResetPasswordState,
  selectForgotPasswordState,
  selectForgotUsernameState,
  selectMfaUserEmailAndMobileState,
} = contentFeatureReducer;
