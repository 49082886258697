<ng-container *ngIf="(content | async)?.loaded">
    <section
      *ngIf="{
        page: (content | async)?.content,
        account: (accountContent | async)?.content,
        eligibility: (eligbilityContent | async)?.content,
        personalInfo: (personalInfoContent | async)?.content
      } as pageContent"
    >
      <article class="helpdesk">
        <bh-employer-logo
          [heading]="pageContent.page?.BHL_LoggedOutSupportForm_Label_Header_ShortHelp"
          [headingIsClientName]="false"
          [defaultLogo]="'assets/face-glasses.svg'"
        ></bh-employer-logo>
        <p
          class="field-content"          
          [innerHTML]="DescriptionBody"
        ></p>
        <!-- [innerHTML]="pageContent.page?.BHL_LoggedOutSupportFormFIC_Label_Content1_ShortHelp" -->
        <form class="form" [formGroup]="helpDeskForm">  
          <p class="marked-form-label">
            <span class="bh--marked">*</span
            >{{ pageContent.eligibility?.BHL_Eligibility_Label_Required }}
          </p>       
          <bh-sr-only-page-level-error
            [formGrp]="helpDeskForm"
          ></bh-sr-only-page-level-error>  
          <div 
          class="form-field donotshow"  style="display: none;"
          [class.has-errors]="
            needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched)
          "
        >
          <label
            class="label-field"
            for="help"
            [class.has-errors]="
              needHelpWith.invalid &&
              (needHelpWith.dirty || needHelpWith.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Help
            }}<span class="bh--marked">*</span></label
          >
          <select
            id="help"
            class="select-field"
            data-atm-select-help="Help"
            name="needHelpWith"
            formControlName="needHelpWith"
            (change)="onNeedHelpWithChange()"
            aria-required="true"
            [attr.aria-describedby]="needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched) && needHelpWith.errors?.['required'] ? ' error-needHelpWith-select-empty' : ''"
          >
            <option value="" disabled>Select</option>
            <option
              *ngFor="let help of supportHelps | async; trackBy: trackByFn"
              [value]="help.objId"
            >
              {{ help.objName }}
            </option>
          </select>
          <bh-field-level-error [formErrorGA4] = "supportTitle"
            *ngIf="needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched) && needHelpWith.errors?.['required']"
            [id]="'error-needHelpWith-select-empty'"
            [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Help"
          ></bh-field-level-error>
        </div>
        <div 
          class="form-field donotshow"   style="display: none;"
          [class.has-errors]="
            servicesInterested.invalid &&
            (servicesInterested.dirty || servicesInterested.touched)
          "
        >
          <label
            class="label-field"
            for="servicesInterested"
            [class.has-errors]="
              servicesInterested.invalid &&
              (servicesInterested.dirty || servicesInterested.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Services
            }}<span class="bh--marked">*</span></label
          >
          <select
            id="servicesInterested"
            class="select-field"
            data-atm-select-service="Service"
            name="servicesInterested"
            formControlName="servicesInterested"
            aria-required="true"
            (change)="onServiceChange()"
            [attr.aria-describedby]="servicesInterested.invalid && (servicesInterested.dirty || servicesInterested.touched) && servicesInterested.errors?.['required'] ? ' error-serviceOptions-select-empty' : ''"
          >
            <option value="" disabled>Select</option>
            <option
              *ngFor="
                let service of supportServices | async;
                trackBy: trackByServicesFn
              "
              [value]="service.objId"
            >
              {{ service.objName }}
            </option>
          </select>
          <bh-field-level-error [formErrorGA4] = "supportTitle"
            *ngIf="servicesInterested.invalid && (servicesInterested.dirty || servicesInterested.touched) && servicesInterested.errors?.['required']"
            [id]="'error-serviceOptions-select-empty'"
            [message]="
              pageContent.page?.BHL_LoggedOutSupportForm_Error_Services
            "
          ></bh-field-level-error>
        </div>
        <div
          class="form-field employersearchbox donotshow" style="display: none;"
          [class.error-text]="
            fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
          "
          *ngIf="!isFicMbdVersion"
        >
          <label
            class="label-field"
            for="employer"
            [class.error-text]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Emp
            }}<span class="bh--marked">*</span></label
          >
          <input
            type="text"
            id="employer"
            placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_EmpoyerPlaceholder }}"
            data-atm-input-service="Employer"
            name="employer"
            class="searchfield"
            formControlName="fcEmployer"            
            
            bh-alphanumerics
            [attr.aria-describedby]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
                ? 'error-employer-input-empty'
                : ''
            "
            [attr.aria-invalid]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
            "
            matInput
            [matAutocomplete]="auto"
          />
          <span class="search-icon"></span>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            class="employeroptions"
          >
            <mat-option
              *ngFor="
                let employer of employers | async;
                trackBy: trackByEmployerFn
              "
              [value]="employer"
            >
              <span
                [innerHTML]="employer.clientName | highlight : toHighlight"
              ></span>
            </mat-option>
          </mat-autocomplete>
          <bh-field-level-error [formErrorGA4] = "supportTitle"
            *ngIf="(fcEmployer.dirty || fcEmployer.touched) && fcEmployer.errors?.['required']"
            [id]="'error-employer-input-empty'"
            [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Emp"
          ></bh-field-level-error>
        </div>         
          <div class="divide">
            <div
              class="form-field margin-r margin-b"
              [class.has-errors]="
                firstName.invalid && (firstName.dirty || firstName.touched)
              "
            >
              <label
                class="label-field"
                for="firstName"
                [class.has-errors]="
                  firstName.invalid && (firstName.dirty || firstName.touched)
                "
                >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_FN
                }}<span class="bh--marked">*</span></label
              >
              <input
                type="text"
                id="firstName"
                class="input-field"
                data-atm-input-first-name="Enter First Name"
                [attr.aria-describedby]="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required'] ? ' error-firstName-input-empty' : ''"
                name="firstName"
                formControlName="firstName"
                autocomplete="off"
                placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_FNPlaceholder }}"
              />
              <bh-field-level-error [formErrorGA4] = "supportTitle"
                *ngIf="
                  firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required']
                "
                [class.has-errors]="
                  firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required']
                "
                [id]="'error-firstName-input-empty'"
                [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_FN"
              ></bh-field-level-error>
              <bh-field-level-error [formErrorGA4] = "supportTitle"
              *ngIf="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['pattern']
              "
              [class.has-errors]="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['pattern']
              "
              [id]="'error-firstName-input-invalid'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_FN_Invalid"
            ></bh-field-level-error>
            </div>
            <div
              class="form-field margin-b"
              [class.has-errors]="
                lastName.invalid && (lastName.dirty || lastName.touched)
              "
            >
              <label
                class="label-field"
                for="lastName"
                [class.has-errors]="
                  lastName.invalid && (lastName.dirty || lastName.touched)
                "
                >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_LN
                }}<span class="bh--marked">*</span></label
              >
              <input
                type="text"
                id="lastName"
                class="input-field"
                data-atm-input-last-name="Enter Last Name"
                [attr.aria-describedby]="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['required'] ? ' error-lastName-input-empty' : ''"
                name="lastName"
                formControlName="lastName"
                autocomplete="off"
                placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_LNPlaceholder }}"
              />
              <bh-field-level-error [formErrorGA4] = "supportTitle"
                *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['required']"
                [id]="'error-lastName-input-empty'"
                [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_LN"
              ></bh-field-level-error>
              <bh-field-level-error [formErrorGA4] = "supportTitle"
              *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['pattern']"
              [id]="'error-lastName-input-invalid'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_LN_Invalid"
            ></bh-field-level-error>
            </div>
          </div>
          <div class="form-field" *ngIf="!isFicMbdVersion">            
            <label
            class="label-field"
            [class.has-errors]="
            employeeId.invalid && (employeeId.dirty || employeeId.touched)
          "
            for="employeeId"           
          >
            {{ this.displayName }}<span class="bh--marked">*</span>
          </label>
            <input
              type="text"
              id="employeeId"
              class="input-field" 
              data-atm-input-employee-id="Enter Employee ID"
              name="employeeId"
              formControlName="employeeId"
              autocomplete="off"
              (change)="onEmployeeIdChange()"
              [(ngModel)]="uniqueid"
            />
            <bh-field-level-error [formErrorGA4] = "supportTitle"
            *ngIf="
            employeeId.invalid && (employeeId.dirty || employeeId.touched)
                "
                [class.has-errors]="
                employeeId.invalid && (employeeId.dirty || employeeId.touched)
                "
           
            [id]="'error-uniqueid-input-empty'"
            [message]="this.uniqueidErrorMessages"
          ></bh-field-level-error>
          <label
          *ngIf="instructionText &&(!employeeId.invalid || !employeeId.touched || !employeeId.errors?.['required'])"   
            class="label-field-instruction"
          >
            {{instructionText }}
          </label>   
          </div>   
          <div
            class="form-field email"
            [class.has-errors]="email.invalid && (email.dirty || email.touched)"
          >
            <label
              class="label-field"
              for="email"
              [class.has-errors]="email.invalid && (email.dirty || email.touched)"
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Email
              }}<span class="bh--marked">*</span></label
            >
            <input
              type="email"
              id="email"
              matInput
              class="input-field email"
              placeholder="{{ pageContent.personalInfo?.BHL_AcctCrt1_Label_EmailPlaceholder_ShortHelp }}"
              [attr.aria-describedby]="(email.invalid && (email.dirty || email.touched) && (email.errors?.['required'] || email.errors?.['pattern']) ? ' error-email-input-empty' : '')"
              data-atm-input-email="Enter Email"
              formControlName="email"
              autocomplete="off"
            />
            <bh-field-level-error [formErrorGA4] = "supportTitle"
              *ngIf="email.invalid && (email.dirty || email.touched) && (email.errors?.['required'] || email.errors?.['pattern'])"
              [id]="'error-email-input-empty'"
              [message]="email.errors?.['pattern'] ? pageContent.eligibility?.BHL_Eligibility_Error_PasscodeEmail: pageContent.page?.BHL_LoggedOutSupportForm_Error_Email"
            ></bh-field-level-error>
          </div>
          <div
            class="form-field"
            [class.has-errors]="
              phone.invalid &&
              (phone.dirty || phone.touched) &&
              (minLengthValid() || phoneNoValue())
            "
          >
            <label
              class="label-field"
              for="phone"
              [class.has-errors]="
                phone.invalid &&
                (phone.dirty || phone.touched) &&
                (minLengthValid() || phoneNoValue())
              "
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Phone 
              }}</label
            >
            <input
              type="text"
              id="phone"
              class="input-field"
              placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_PhonePlaceholder }}"
              [attr.aria-describedby]="(phone.invalid && (phone.dirty || phone.touched) && (phone.errors?.['required']) ? ' error-phone-input-empty' : '')"
              data-atm-input-phone="Enter Phone"
              name="phone"
              formControlName="phone"
              inputmode="numeric"
              bh-numbers-only
              autocomplete="off"
            />
            <bh-field-level-error [formErrorGA4] = "supportTitle"
              *ngIf="phone.invalid && (phone.dirty || phone.touched) && (phone.errors?.['required'])"
              [id]="'error-phone-input-empty'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Phone"
            ></bh-field-level-error>
            <bh-field-level-error [formErrorGA4] = "supportTitle"
              *ngIf="minLengthValid() && phoneNoValue()"
              [id]="'error-phone-length-input-empty'"
              [message]="'Mobile Number has to be 10 digits.'"
            ></bh-field-level-error>            
          </div>
          <div class="checkboxsection">
          <mat-checkbox 
          data-atm-input-receive-sms-updates="Recieve SMS updates"
          formControlName="OptForSMS" 
            class="bh-form__field--checkbox"  [disabled]="false"
            [color]="'primary'"> {{ pageContent.page?.BHL_LoggedOutSupportForm_Label_checkbox_ShortHelp }}
          </mat-checkbox>
          </div><br>
          <div class="emailsection">
            <label
              for="helpDesk"
              class="label-field"              
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Help2_ShortHelp
              }}</label
            >
            <textarea
              type="text"
              id="helpDesk"
              #helpDesk
              class="input-field helptext"
              name="helpDesk"
              formControlName="helpDesk"
              maxlength="1000"
              data-atm-input-phone="Enter Help Desk"              
              [attr.aria-describedby]="
                helpDeskForm.controls['helpDesk']?.invalid
                && (helpDeskForm.controls['helpDesk']?.dirty || helpDeskForm.controls['helpDesk']?.touched)
                && helpDeskForm.controls['helpDesk']?.errors?.['required']
                ? 'error-helpDesk-message-empty'
                : ''
              "
            ></textarea>  
            <div class="d-flex">
                <p class="textlimit">{{ helpDesk.value.length }} / 1000</p>
              </div>
          </div>
          
          <div class="bh-button-single">          
            <button
              class="bh-button--primary"
              type="submit"
              (click)="onSend()"
            >
              {{ pageContent.page?.BHL_LoggedOutSupportForm_Button_Send }}
            </button>
          </div>
          <div class="form-field informative">
            <label
              class="details" (click) = "onPrivacyClick()"
              [innerHTML]="pageContent.page?.BHL_LoggedOutSupportForm_Privacy"
            >
            </label>
          </div>
          <div
            class="privacypolicies"
            *ngIf="isRecaptchaEnabled"
          >
            <label
              class="details"
              [innerHTML]="pageContent.account?.BHL_AcctCrt2_reCAPTCHA"
            ></label>
            <re-captcha
              #captchaRef
              (resolved)="resolved()"
              (error)="onError($event)"
              errorMode="handled"
            ></re-captcha>
          </div>
        </form>
      </article>
    </section>
  </ng-container>
  