<ng-container *ngIf="!isLoginFlow && loginUrl?.href">
  <a
    [href]="loginUrl.href"
    [class]="classNames"
    (click)="goToLogin()"
  >
    {{ label }}
  </a>
</ng-container>
<ng-container *ngIf="isLoginFlow">
  <a
  *ngIf="isLoginFlow"
  class="bh-link"
  rel="noopener noreferrer"
  [attr.aria-label]="label"
  [href]=""
  (click)="goToSignUp()"
>
  {{ label }}
</a>

</ng-container>
