<ng-container *ngIf="(content | async)?.loaded">
  <section
    *ngIf="{
      page: (content | async)?.content,
      account: (accountContent | async)?.content,
      eligibility: (eligbilityContent | async)?.content,
      personalInfo: (personalInfoContent | async)?.content
    } as pageContent"
  >
    <article class="helpdesk">
      <bh-employer-logo
        [heading]="pageContent.page?.BHL_LoggedOutSupportForm_Label_Header"
        [headingIsClientName]="false"
        [defaultLogo]="'assets/support.svg'"
      ></bh-employer-logo>
      <p
        class="field-content"
        [innerHTML]="this.displayContent
        "
      ></p>
      <form class="form" [formGroup]="helpDeskForm">
        <p class="marked-form-label">
          <span class="bh--marked">*</span
          >{{ pageContent.eligibility?.BHL_Eligibility_Label_Required }}
        </p>
        <bh-sr-only-page-level-error
          [formGrp]="helpDeskForm"
        ></bh-sr-only-page-level-error>
        <div
          class="form-field"
          [class.has-errors]="
            needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched)
          "
        >
          <label
            class="label-field"
            for="help"
            [class.has-errors]="
              needHelpWith.invalid &&
              (needHelpWith.dirty || needHelpWith.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Help
            }}<span class="bh--marked">*</span></label
          >
          <select
            id="help"
            class="select-field"
            data-atm-select-help="Help"
            name="needHelpWith"
            formControlName="needHelpWith"
            (change)="onNeedHelpWithChange()"
            aria-required="true"
            [attr.aria-describedby]="needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched) && needHelpWith.errors?.['required'] ? ' error-needHelpWith-select-empty' : ''"
          >
            <option value="" disabled>Select</option>
            <option
              *ngFor="let help of supportHelps | async; trackBy: trackByFn"
              [value]="help.objId"
            >
              {{ help.objName }}
            </option>
          </select>
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="needHelpWith.invalid && (needHelpWith.dirty || needHelpWith.touched) && needHelpWith.errors?.['required']"
            [id]="'error-needHelpWith-select-empty'"
            [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Help"
          ></bh-field-level-error>
        </div>
        <div
          class="form-field"
          [class.has-errors]="
            servicesInterested.invalid &&
            (servicesInterested.dirty || servicesInterested.touched)
          "
        >
          <label
            class="label-field"
            for="servicesInterested"
            [class.has-errors]="
              servicesInterested.invalid &&
              (servicesInterested.dirty || servicesInterested.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Services
            }}<span class="bh--marked">*</span></label
          >
          <select
            id="servicesInterested"
            class="select-field"
            data-atm-select-service="Service"
            name="servicesInterested"
            formControlName="servicesInterested"
            aria-required="true"
            (change)="onServiceChange()"
            [attr.aria-describedby]="servicesInterested.invalid && (servicesInterested.dirty || servicesInterested.touched) && servicesInterested.errors?.['required'] ? ' error-serviceOptions-select-empty' : ''"
          >
            <option value="" disabled>Select</option>
            <option
              *ngFor="
                let service of supportServices | async;
                trackBy: trackByServicesFn
              "
              [value]="service.objId"
            >
              {{ service.objName }}
            </option>
          </select>
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="servicesInterested.invalid && (servicesInterested.dirty || servicesInterested.touched) && servicesInterested.errors?.['required']"
            [id]="'error-serviceOptions-select-empty'"
            [message]="
              pageContent.page?.BHL_LoggedOutSupportForm_Error_Services
            "
          ></bh-field-level-error>
        </div>
        <div
          class="form-field employersearchbox"
          [class.error-text]="
            fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
          "
          *ngIf="!isFicMbdVersion"
        >
          <label
            class="label-field"
            for="employer"
            [class.error-text]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Emp
            }}<span class="bh--marked">*</span></label
          >
          <input
            type="text"
            id="employer"
            placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_EmpoyerPlaceholder }}"
            data-atm-input-service="Employer"
            name="employer"
            class="searchfield"
            formControlName="fcEmployer"
            bh-alphanumerics
            [attr.aria-describedby]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
                ? 'error-employer-input-empty'
                : ''
            "
            [attr.aria-invalid]="
              fcEmployer.invalid && (fcEmployer.dirty || fcEmployer.touched)
            "
            matInput
            [matAutocomplete]="auto"
          />
          <span class="search-icon"></span>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            class="employeroptions"
          >
            <mat-option
              *ngFor="
                let employer of employers | async;
                trackBy: trackByEmployerFn
              "
              [value]="employer"
            >
              <span
                [innerHTML]="employer.clientName | highlight : toHighlight"
              ></span>
            </mat-option>
          </mat-autocomplete>
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="(fcEmployer.dirty || fcEmployer.touched) && fcEmployer.errors?.['required']"
            [id]="'error-employer-input-empty'"
            [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Emp"
          ></bh-field-level-error>
        </div>
        <div class="divide">
          <div
            class="form-field margin-r margin-b"
            [class.has-errors]="
              firstName.invalid && (firstName.dirty || firstName.touched)
            "
          >
            <label
              class="label-field"
              for="firstName"
              [class.has-errors]="
                firstName.invalid && (firstName.dirty || firstName.touched)
              "
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_FN
              }}<span class="bh--marked">*</span></label
            >
            <input
              type="text"
              id="firstName"
              class="input-field"
              data-atm-input-first-name="Enter First Name"
              [attr.aria-describedby]="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required'] ? ' error-firstName-input-empty' : ''"
              name="firstName"
              formControlName="firstName"
              placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_FNPlaceholder }}"
              autocomplete="off"
            />
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required']
              "
              [class.has-errors]="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required']
              "
              [id]="'error-firstName-input-empty'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_FN"
            ></bh-field-level-error>
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['pattern']
              "
              [class.has-errors]="
                firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['pattern']
              "
              [id]="'error-firstName-input-invalid'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_FN_Invalid"
            ></bh-field-level-error>
          </div>
          <div
            class="form-field margin-b"
            [class.has-errors]="
              lastName.invalid && (lastName.dirty || lastName.touched)
            "
          >
            <label
              class="label-field"
              for="lastName"
              [class.has-errors]="
                lastName.invalid && (lastName.dirty || lastName.touched)
              "
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_LN
              }}<span class="bh--marked">*</span></label
            >
            <input
              type="text"
              id="lastName"
              class="input-field"
              data-atm-input-last-name="Enter Last Name"
              [attr.aria-describedby]="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['required'] ? ' error-lastName-input-empty' : ''"
              name="lastName"
              formControlName="lastName"
              autocomplete="off"
              placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_LNPlaceholder }}"
            />
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              [id]="'error-lastName-input-empty'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_LN"
            ></bh-field-level-error>
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['pattern']"
              [id]="'error-lastName-input-invalid'"
              [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_LN_Invalid"
            ></bh-field-level-error>
          </div>
        </div>
        <div
          class="form-field email"
          [class.has-errors]="email.invalid && (email.dirty || email.touched)"
        >
          <label
            class="label-field"
            for="email"
            [class.has-errors]="email.invalid && (email.dirty || email.touched)"
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Email
            }}<span class="bh--marked">*</span></label
          >
          <input
            type="email"
            id="email"
            matInput
            class="input-field email"
            placeholder="{{ pageContent.personalInfo?.BHL_AcctCrt1_Label_EmailPlaceholder }}"
            [attr.aria-describedby]="(email.invalid && (email.dirty || email.touched) && (email.errors?.['required'] || email.errors?.['pattern']) ? ' error-email-input-empty' : '')"
            data-atm-input-email="Enter Email"
            formControlName="email"
            autocomplete="off"
          />
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="email.invalid && (email.dirty || email.touched) && (email.errors?.['required'] || email.errors?.['pattern'])"
            [id]="'error-email-input-empty'"
            [message]="email.errors?.['pattern'] ? pageContent.eligibility?.BHL_Eligibility_Error_PasscodeEmail: pageContent.page?.BHL_LoggedOutSupportForm_Error_Email"
          ></bh-field-level-error>
        </div>
        <div
          class="form-field"
          [class.has-errors]="
            phone.invalid &&
            (phone.dirty || phone.touched) &&
            (minLengthValid() || phoneNoValue())
          "
        >
          <label
            class="label-field"
            for="phone"
            [class.has-errors]="
              phone.invalid &&
              (phone.dirty || phone.touched) &&
              (minLengthValid() || phoneNoValue())
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Phone 
            }}<span class="bh--marked">*</span></label
          >
          <input
            type="text"
            id="phone"
            class="input-field"
            placeholder="{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_PhonePlaceholder }}"
            [attr.aria-describedby]="(phone.invalid && (phone.dirty || phone.touched) && (phone.errors?.['required']) ? ' error-phone-input-empty' : '')"
            data-atm-input-phone="Enter Phone"
            name="phone"
            formControlName="phone"
            inputmode="numeric"
            bh-numbers-only
            autocomplete="off"
          />
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="phone.invalid && (phone.dirty || phone.touched) && (phone.errors?.['required'])"
            [id]="'error-phone-input-empty'"
            [message]="pageContent.page?.BHL_LoggedOutSupportForm_Error_Phone"
          ></bh-field-level-error>
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="minLengthValid() && phoneNoValue()"
            [id]="'error-phone-length-input-empty'"
            [message]="'Mobile Number has to be 10 digits.'"
          ></bh-field-level-error>
        </div>
        <div
          class="form-field"
          *ngIf="isFicMbdVersion"
          [class.has-errors]="
            centerName.invalid && (centerName.dirty || centerName.touched)
          "
        >
          <label
            class="label-field"
            for="centerName"
            [class.has-errors]="
              centerName.invalid && (centerName.dirty || centerName.touched)
            "
            >{{ pageContent.page?.BHL_LoggedOutSupportFormFIC_Label_Center
            }}<span class="bh--marked">*</span></label
          >
          <input
            type="text"
            id="centerName"
            class="input-field"
            data-atm-input-center-name="Enter Center Name"
            name="centerName"
            [attr.aria-describedby]="(centerName.invalid && (centerName.dirty || centerName.touched) && (centerName.errors?.['required']) ? 'error-centerName-input-empty' : '')"
            formControlName="centerName"
          />
          <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
            *ngIf="centerName.invalid && (centerName.dirty || centerName.touched) && (centerName.errors?.['required'])"
            [id]="'error-centerName-input-empty'"
            [message]="
              pageContent.page?.BHL_LoggedOutSupportFormFIC_Error_Center
            "
          ></bh-field-level-error>
        </div>
        <div class="dividesection" *ngIf="isFicMbdVersion">
          <div
            class="form-field margin-r city"
            [class.has-errors]="city.invalid && (city.dirty || city.touched)"
          >
            <label
              class="label-field"
              for="city"
              [class.has-errors]="city.invalid && (city.dirty || city.touched)"
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_City
              }}<span class="bh--marked">*</span></label
            >
            <input
              type="text"
              id="city"
              class="input-field"
              data-atm-input-city="Enter City"
              [attr.aria-describedby]="(city.invalid && (city.dirty || city.touched) && (city.errors?.['required']) ? 'error-city-input-empty' : '')"
              name="city"
              formControlName="city"
            />
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="city.invalid && (city.dirty || city.touched) && (city.errors?.['required'])"
              [id]="'error-city-input-empty'"
              [message]="'City is required.'"
            ></bh-field-level-error>
          </div>
          <div
            class="form-field state"
            [class.has-errors]="state.invalid && (state.dirty || state.touched)"
          >
            <label
              class="label-field"
              for="state"
              [class.has-errors]="
                state.invalid && (state.dirty || state.touched)
              "
              >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_State
              }}<span class="bh--marked">*</span></label
            >
            <select
              type="text"
              id="state"
              class="select-field"
              data-atm-select-state="State"
              name="state"
              formControlName="state"
              [attr.aria-describedby]="state.invalid && (state.dirty || state.touched) && (state.errors?.['required']) ? ' error-state-select-empty' : ''"
            >
              <option value="" disabled>Select</option>
              <option
                *ngFor="let state of stateList | async; trackBy: trackByStateFn"
                [value]="state.objId"
              >
                {{ state.objName }}
              </option>
            </select>
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="state.invalid && (state.dirty || state.touched) && (state.errors?.['required'])"
              [id]="'error-state-select-empty'"
              [message]="'State is required.'"
            ></bh-field-level-error>
          </div>
        </div>
        <div class="form-field" *ngIf="!isFicMbdVersion">
          <label class="label-field" for="employeeId">{{
            pageContent.page?.BHL_LoggedOutSupportForm_Label_EmpID
          }}</label>
          <input
            type="text"
            id="employeeId"
            class="input-field"
            data-atm-input-employee-id="Enter Employee ID"
            name="employeeId"
            formControlName="employeeId"
            autocomplete="off"
          />
        </div>
        <div class="emailsection">
          <label
            for="helpDesk"
            class="label-field"
            [class.has-errors]="showMessageInvalid"
            >{{ pageContent.page?.BHL_LoggedOutSupportForm_Label_Help2
            }}<span class="bh--marked">*</span></label
          >
          <textarea
            type="text"
            id="helpDesk"
            #helpDesk
            class="input-field helptext"
            name="helpDesk"
            formControlName="helpDesk"
            maxlength="1000"
            data-atm-input-phone="Enter Help Desk"
            required
            [attr.aria-describedby]="
              helpDeskForm.controls['helpDesk']?.invalid
              && (helpDeskForm.controls['helpDesk']?.dirty || helpDeskForm.controls['helpDesk']?.touched)
              && helpDeskForm.controls['helpDesk']?.errors?.['required']
              ? 'error-helpDesk-message-empty'
              : ''
            "
            (blur)="showHelpDeskTextAreaError()"
          ></textarea>
          <div class="d-flex">
            <bh-field-level-error [formErrorGA4] = "helpDeskTitle"
              *ngIf="showMessageInvalid"
              [id]="'error-helpDesk-message-empty'"
              [message]="'Message is required.'"
            ></bh-field-level-error>
            <p class="textlimit">{{ helpDesk.value.length }} / 1000</p>
          </div>
          <div class="divide">
            <div class="form-field margin-r attach-screenshot">
              <label
                class="bh-button--secondary"
                for="input-file"
              >
                <img
                  [src]="'assets/attachment-icon.svg'"
                  class="attachmenticon"
                  [alt]="''"
                />
                {{ pageContent.page?.BHL_LoggedOutSupportForm_Button_Attach }}
                <input
                  class="sr-only"
                  id="input-file"
                  type="file"
                  data-atm-input-attachment="Add Attach Screenshot"
                  (change)="this.onFilechange($event)"
                  accept=".bmp, .gif, .jpeg, .jpg, .png, .doc, .docx, .txt, .pdf"
                  data-max-size="2097152"
                  name="attachmentFile"
                  formControlName="attachmentFile"
                  [attr.aria-describedby]="
                    'attach-file-context'
                    + (file ? ' uploaded-file-name' : '')
                  "
                />
              </label>
            </div>
            <div class="form-field m-0">
              <p
                class="attachments"
                id="attach-file-context"
                [innerHTML]="
                  pageContent.page?.BHL_LoggedOutSupportForm_Label_Content2
                "
              ></p>
            </div>
          </div>
          <div class="attachment-panel" *ngIf="file">
            <div class="attachment-details">
              <p class="uploaded-file" id="uploaded-file-name">{{ file.name }}</p>
              <button
                class="bh-button--secondary remove-attachment"
                type="button"
                [attr.aria-label]="pageContent.page?.BHL_LoggedOutSupportForm_Button_Remove + ' ' + file.name"
                (click)="onRemoveFile()"
              >
                <img
                  [src]="'assets/bin.svg'"
                  [alt]="''"
                />{{ pageContent.page?.BHL_LoggedOutSupportForm_Button_Remove }}
              </button>
            </div>
          </div>
        </div>
        <div class="bh-button-single">          
          <button
            class="bh-button--primary"
            type="submit"
            (click)="onSend()"
          >
            {{ pageContent.page?.BHL_LoggedOutSupportForm_Button_Send }}
          </button>
        </div>
        <div class="form-field informative">
          <label
            class="details" (click) = "onPrivacyClick()"
            [innerHTML]="pageContent.page?.BHL_LoggedOutSupportForm_Privacy"
          >
          </label>
        </div>
        <div
          class="privacypolicies"
          *ngIf="isRecaptchaEnabled"
        >
          <label
            class="details"
            [innerHTML]="pageContent.account?.BHL_AcctCrt2_reCAPTCHA"
          ></label>
          <re-captcha
            #captchaRef
            (resolved)="resolved()"
            (error)="onError($event)"
            errorMode="handled"
          ></re-captcha>
        </div>
      </form>
    </article>
  </section>
</ng-container>
