/**
 * Pattern to detect characters not in the specified list for the employer search field.
 * @type {RegExp}
 */
export const employerSearch: RegExp = /[^a-zA-Z0-9\-+ &,.':()_\/]/g;

/**
 * RegEx to detect email.
 */

export const emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

/**
 * RegEx to detect username.
 */

export const username = '^[A-Za-z0-9._@-]*$';
export const phonePattern = '^[0-9]{10}$';
export const passcode = '^[A-Za-z0-9_@./#&+-]*$';
export const namePattern = '^(?!.*(&gt;|&lt;|>|<))[A-Za-z0-9._ \'-]*$';
  //'^(?!.*[><])[A-Za-z0-9._ \'-]*$';
/**
 * RegEx to detect special characters in that list.
 */
export const specialChars = '[$@#^!%*?&]';

/**
 * The RegEx object/construct for the list of {@see specialChars}
 */
// This is defined within the application and is not derived from user input, safe to suppress.
// eslint-disable-next-line security/detect-non-literal-regexp
export const specialCharsRegEx: Readonly<RegExp> = new RegExp(specialChars);

/**
 * The list of rules to determine the password strength. The sort order of the array matches
 * the order in which the rules are defined in the content table/API.
 */
export const passwordValidationRules: Readonly<Array<RegExp>> = [
  /[A-Z]/,
  /[a-z]/,
  /\d/,
  specialCharsRegEx,
];
