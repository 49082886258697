import { createFeature, createReducer, on } from '@ngrx/store';
import {
  HttpError, IEmployer, IMasterListStatus,
} from '../../../models';
import { IGlobalState, IHelpDeskStatus } from '../../../models/global';
import { GlobalActions } from './global.actions';

export const initialState: IGlobalState = {
  employers: {
    loading: false,
    loaded: false,
    list: null,
    error: null,
  },
  supportHelps: {
    loaded: false,
    loading: false,
    list: null,
    error: null,
  },
  supportServices: {
    loaded: false,
    loading: false,
    list: null,
    error: null,
  },
  helpDeskSupport: {
    loaded: false,
    loading: false,
    helpDeskSupportStatus: null,
    error: null,
  },
  stateList: {
    loaded: false,
    loading: false,
    list: null,
    error: null,
  },
};

export const globalReducer = createReducer(
  initialState,
  on(
    GlobalActions.loadEmployers,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.loadEmployersSuccess,
    (state: IGlobalState, { list }: { list: Array<IEmployer> }): IGlobalState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    GlobalActions.loadEmployersFailure,
    (state: IGlobalState, { error }: { error: string | HttpError }): IGlobalState => ({
      ...state,
      employers: {
        ...state.employers,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    GlobalActions.clearEmployers,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      employers: {
        loading: false,
        loaded: false,
        list: null,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportHelps,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      supportHelps: {
        ...state.supportHelps,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportHelpsSuccess,
    (state: IGlobalState, { list }: { list: Array<IMasterListStatus> }): IGlobalState => ({
      ...state,
      supportHelps: {
        ...state.supportHelps,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportHelpsFailure,
    (state: IGlobalState, { error }: { error: string | HttpError }): IGlobalState => ({
      ...state,
      supportHelps: {
        ...state.supportHelps,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportServices,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      supportServices: {
        ...state.supportServices,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportServicesSuccess,
    (state: IGlobalState, { list }: { list: Array<IMasterListStatus> }): IGlobalState => ({
      ...state,
      supportServices: {
        ...state.supportServices,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    GlobalActions.loadSupportServicesFailure,
    (state: IGlobalState, { error }: { error: string | HttpError }): IGlobalState => ({
      ...state,
      supportServices: {
        ...state.supportServices,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),

  on(
    GlobalActions.helpDeskSupport,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      helpDeskSupport: {
        ...state.helpDeskSupport,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.helpDeskSupportSuccess,
    (state: IGlobalState, { helpDeskSupportStatus }: { helpDeskSupportStatus: IHelpDeskStatus }): IGlobalState => ({
      ...state,
      helpDeskSupport: {
        ...state.helpDeskSupport,
        loading: false,
        loaded: true,
        helpDeskSupportStatus,
      },
    }),
  ),
  on(
    GlobalActions.helpDeskSupportFailure,
    (state: IGlobalState, { error }: { error: string | HttpError }): IGlobalState => ({
      ...state,
      helpDeskSupport: {
        ...state.helpDeskSupport,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
  on(
    GlobalActions.loadState,
    (state: IGlobalState): IGlobalState => ({
      ...state,
      stateList: {
        ...state.stateList,
        loading: true,
        loaded: false,
        error: null,
      },
    }),
  ),
  on(
    GlobalActions.loadStateSuccess,
    (state: IGlobalState, { list }: { list: Array<IMasterListStatus> }): IGlobalState => ({
      ...state,
      stateList: {
        ...state.stateList,
        loading: false,
        loaded: true,
        list,
      },
    }),
  ),
  on(
    GlobalActions.loadStateFailure,
    (state: IGlobalState, { error }: { error: string | HttpError }): IGlobalState => ({
      ...state,
      stateList: {
        ...state.stateList,
        loading: false,
        loaded: false,
        error,
      },
    }),
  ),
);

export const globalFeatureReducer = createFeature({
  name: 'global',
  reducer: globalReducer,
});

export const {
  name,
  selectEmployers,
  selectGlobalState,
  selectSupportHelps,
  selectSupportServices,
  selectHelpDeskSupport,
  selectStateList,
} = globalFeatureReducer;
