<span [class]="className">
  <a
    [href]="linkInfo.url"
    rel="noopener noreferrer"
    [attr.aria-label]="linkInfo.label + ' (opens in new tab)'"
    target="_blank"
  >
    {{ linkInfo.label }}
  </a>
</span>
