import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { routerReducer, RouterStateSerializer } from '@ngrx/router-store';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { configReducer, name as config } from '../config/config.reducers';
import { contentReducer, name as content } from '../content/content.reducers';
import { credentialsReducer, name as credentials } from '../credentials/credentials.reducers';
import { signupReducer, name as signup } from '../signup/signup.reducers';
import { IAppState, IRouterStateUrl } from './state';

/**
 * Initial set of reducers in a reducer map to initialize the StoreModule.
 */
export const reducers: InjectionToken<ActionReducerMap<IAppState>> =
  new InjectionToken<ActionReducerMap<IAppState>>(
    'Root reducers token',
    {
      factory: (): ActionReducerMap<IAppState> => ({
        router: routerReducer,
        [config]: configReducer,
        [content]: contentReducer,
        [credentials]: credentialsReducer,
        [signup]: signupReducer,
      }),
    },
  );

/**
 * Utility function to log all actions to the console.
 */
export const logger: (
  reducer: ActionReducer<IAppState>
) => ActionReducer<IAppState> =
  (reducer: ActionReducer<IAppState>): ActionReducer<IAppState> =>
    (state: IAppState | undefined, action: Action) => {
      const result = reducer(state, action);

      /* eslint-disable no-console */
      console.groupCollapsed(action.type);
      console.log('prev state', state);
      console.log('action', action);
      console.log('next state', result);
      console.groupEnd();
      /* eslint-enable no-console */

      return result;
    };

/**
 * Any meta reducers we would like to define/use to pre-process actions before
 * normal reducers are invoked.
 */
export const metaReducers: Array<MetaReducer<IAppState>> = environment.trace ? [ logger ] : [];

/**
 * Custom serializer class for the router events that are stored in the store.
 */
export class CustomSerializer implements RouterStateSerializer<IRouterStateUrl> {
  /**
   * The implementation method of the route action payload.
   * @param {RouterStateSnapshot} routerState The router snapshot object.
   * @returns {IRouterStateUrl} Returns serialized object of the route containing
   * the url, query and route params.
   */
  public serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) {
      state = state.firstChild;
    }

    const { url } = routerState;
    const { queryParams } = routerState.root;
    const { params } = state;

    return { url, queryParams, params };
  }
}
