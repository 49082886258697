import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ILegalLink } from '../../../models';

/**
 * Reusable component for the global footer links.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-footer-legal-link',
  templateUrl: './footer-legal-link.component.html',
  styleUrls: [ './footer-legal-link.component.scss' ],
})
export class FooterLegalLinkComponent {
  /**
   * An input to allow us to populate the link element with
   * the necessary content like the url, label, etc.
   */
  @Input() public linkInfo!: ILegalLink;

  @Input() public className: string = 'bh-footer__legal-link';
}
