import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';

import { retries } from '../../constants';
import { ErrorHandlerService } from './error-handler.service';

/**
 * Intercept any API error responses (error status codes) and perform any custom handling.
 */
@Injectable({
  providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
  /**
   * The interceptor `constructor`
   * @param {ErrorHandlerService} errorService The instance of the ErrorHandlerService service
   */
  constructor(
    private readonly errorService: ErrorHandlerService,
  ) {}

  /**
   * The interceptor implementation method.
   * @param {HttpRequest<unknown>} request An object containing the Http request information.
   * @param {HttpHandler} next Middleware to allow the execution to continue.
   * @returns {Observable<HttpEvent<unknown>>} The update Http event after any modifications.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(retries),
      catchError((error: HttpErrorResponse) => throwError(() => this.errorService.parseHttpError(error))),
    );
  }
}
