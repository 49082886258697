/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, concatMap, map, of } from 'rxjs';

import { ApiAuthService } from '../auth/api-auth.service';
import {
  selectEmailActivityState,
  selectEmployeeDetails,
} from '../signup/signup.reducers';
import {
  BenefitId,
  BhQueryParams,
  ErrorCode,
  EventName,
  HttpError,
  IApiResponse,
  IApiValidationError,
  IBenefitLogo,
  ICredentials,
  ICredentialsPayload,
  IEmailActivityState,
  IEmployeeDetailsState,
  IForgotPasswordPayload,
  IForgotUsernamePayload,
  IMFAUserEmailAndMobileStatus,
  IResetPasswordPayload,
  ISendChangePassEmailPayload,
  IValidateUserPayload,
  IValidateUserStatus,
  LocalStorageKey,
  LoginSubCategory,
  SourceId,
} from '../../models';
import { selectBenefitId, selectBhPropertiesURL } from '../config/config.reducers';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { CredentialsActions } from './credentials.actions';
import { CredentialsService } from './credentials.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CredentialsEffects {
  public changePassword = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.changePassword),
      concatLatestFrom(() => [
        this.store.select(selectEmailActivityState),
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBenefitId),
      ]),
      concatMap(([ , emailActivity, empDetails, benefitId ]: [TypedAction<'[Credentials] Change Password'>, IEmailActivityState, IEmployeeDetailsState | null, IBenefitLogo]) => {
        const payload: ICredentialsPayload = {
          currentPassword: empDetails?.currentPassword && empDetails.currentPassword.length > 0 ? empDetails.currentPassword : null,
          password: empDetails?.password as string,
          // eslint-disable-next-line max-len
          userInfoId: empDetails?.userinfoId ?? emailActivity.data?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string,
          userContextInfo: {
            name: emailActivity.data?.personResponse?.userName ?? empDetails?.userName ?? emailActivity.data?.personResponse?.firstName as string, // Prefer userName, if not present, send firstName
            application: benefitId.applicationName,
          },
        };

        return this.credentialsService.changePassword(payload).pipe(
          map((response: IApiResponse<ICredentials>) =>
            CredentialsActions.changePasswordSuccess({ data: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              CredentialsActions.changePasswordFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public sendChangePassEmail = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.sendChangePasswordEmail),
      concatLatestFrom(() => [
        this.store.select(selectEmailActivityState),
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([ , emailActivity, benefitId, params ]: [TypedAction<'[Credentials] Send Change Password Email'>, IEmailActivityState, IBenefitLogo, BhQueryParams]) => {
        const payload: ISendChangePassEmailPayload = {
          email: emailActivity.data?.personResponse?.primaryEmail as string,
          clientGuid: emailActivity.data?.personResponse?.crmClientId as string,
          benefitId: benefitId.id,
          fsTargetId: params.fstargetid as string | undefined,
          sourceId: params.sourceid as SourceId,
        };

        return this.credentialsService.sendChangePassEmail(payload)
          .pipe(
            map((response: IApiResponse<boolean>) =>
              CredentialsActions.sendChangePasswordEmailSuccess({ data: response.data }),
            ),
            catchError((error: Error | HttpError) =>
              of(
                CredentialsActions.sendChangePasswordEmailFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public validateUser = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.validateUser),
      concatLatestFrom(() => [
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectBenefitId),
      ]),
      concatMap(([ value, params, benefitId ]: [{ payload: IValidateUserPayload }, BhQueryParams, IBenefitLogo]) => {
        const payload: IValidateUserPayload = {
          userID: value.payload.userID,
          password: value.payload.password,
        };
        if (params.clientguid && !(benefitId.id === BenefitId.FIC || benefitId.id === BenefitId.OW)) {
          payload.clientGuid = params.clientguid;
        }

        return this.credentialsService.validateUser(payload)
          .pipe(
            map((response: IApiResponse<IValidateUserStatus>) =>
              CredentialsActions.validateUserSuccess({ validateUserStatus: response.data }),
            ),
            catchError((error: Error | HttpError) => {
              if (!(error instanceof Error) && error.status === 400) {
                for (const err of error.error?.errors as Array<IApiValidationError>) {
                  const errorCode: string = err.errorCode;
                  error.eventType = EventName.LOGIN;

                  // error.isLogNeed = true;

                  switch (errorCode) {
                    case ErrorCode.ACCOUNT_NOT_FOUND:
                      error.eventCategory = LoginSubCategory.LOGIN_INVALID_USERNAME;
                      break;
                    case ErrorCode.INVALID_PASSWORD:
                      error.eventCategory = LoginSubCategory.LOGIN_INVALID_PASSWORD;
                      break;
                    case ErrorCode.INVALID_COMBINATION:
                      error.eventCategory = LoginSubCategory.LOGIN_INVALID_CLIENT;
                      break;
                    case ErrorCode.LAST_TWELVE_PASSWORDS:
                      error.eventCategory = LoginSubCategory.LOGIN_ACCOUNT_LOCKED;
                      break;
                    default:
                      error.eventCategory = LoginSubCategory.API_VALIDATION_ERROR;
                  }
                }
              } else if (!(error instanceof Error) && error.status === 500) {
                error.eventType = EventName.LOGIN;
                error.eventCategory = LoginSubCategory.BHLOGIN_TECHNICAL_ERROR_BE;

                // error.isLogNeed = true;
              }

              return of(
                CredentialsActions.validateUserFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              );
            }),
          );
      }),
    );
  });

  public resetPassword = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.resetPassword),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectEmailActivityState),
      ]),
      concatMap(([ , benefitId, emailActivity ]: [TypedAction<'[Credentials] Reset Password'>, IBenefitLogo, IEmailActivityState]) => {
        const payload: IResetPasswordPayload = {
          email: emailActivity.data?.personResponse?.primaryEmail as string,
          benefitId: Number(benefitId.id),
          myBH: benefitId.id === BenefitId.MyBH,
        };

        return this.credentialsService.resetPassword(payload)
          .pipe(
            map((response: IApiResponse<boolean>) =>
              CredentialsActions.resetPasswordSuccess({ data: response.data }),
            ),
            catchError((error: Error | HttpError) =>
              of(
                CredentialsActions.resetPasswordFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public forgotPassword = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.forgotPassword),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([ value, benefitId, params ]: [{ input: string }, IBenefitLogo, BhQueryParams]) => {
        const payload: IForgotPasswordPayload = {
          email: value.input,
          benefitId: Number(benefitId.id),
          fsTargetId: params.fstargetid === null || params.fstargetid === undefined ? 0 : Number(params.fstargetid),
          isCreatePassword: true,
        };

        return this.credentialsService.forgotPassword(payload)
          .pipe(
            map((response: IApiResponse<boolean>) =>
              CredentialsActions.forgotPasswordSuccess({ data: response.data }),
            ),
            catchError((error: Error | HttpError) =>
              of(
                CredentialsActions.forgotPasswordFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public forgotUsername = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.forgotUsername),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([ value, benefitId, params ]: [{ input: string }, IBenefitLogo, BhQueryParams]) => {
        const payload: IForgotUsernamePayload = {
          email: value.input,
          benefitId: Number(benefitId.id),
          fsTargetId: params.fstargetid === null || params.fstargetid === undefined ? 0 : Number(params.fstargetid),
          redirectURL: params.redirecturl as string,
        };

        return this.credentialsService.forgotUsername(payload)
          .pipe(
            map((response: IApiResponse<boolean>) =>
              CredentialsActions.forgotUsernameSuccess({ data: response.data }),
            ),
            catchError((error: Error | HttpError) =>
              of(
                CredentialsActions.forgotUsernameFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public getMfaUserEmailPhone = createEffect(() => {
    return this.actions.pipe(
      ofType(CredentialsActions.getMfaUserInfo),
      concatMap(({ input, isNonMfa }: { input: string, isNonMfa: boolean }) => {
        const encodedInput: string = encodeURIComponent(input);

        return this.credentialsService.getMFAUserEmailAndMobile(encodedInput, isNonMfa, true).pipe(
          map((response: IApiResponse<string>) =>
            CredentialsActions.getMfaUserInfoSuccess({
              // eslint-disable-next-line max-len
              mfaUserEmailAndMobileStatus: this.authService.decryptResponse<Array<IMFAUserEmailAndMobileStatus>>(response.data) as Array<IMFAUserEmailAndMobileStatus>,
            }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              CredentialsActions.getMfaUserInfoFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  // eslint-disable-next-line max-params
  constructor(
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly credentialsService: CredentialsService,
    private readonly errorService: ErrorHandlerService,
    private readonly authService: ApiAuthService,
    private readonly localStorageService: LocalStorageService,
  ) { }
}
