<div
  class="field-message"
  [ngClass]="{
    'text-success': success,
    'text-danger': !success
  }"
>
  <i
    [attr.class]="
      success
        ? 'bi-check-square-fill'
        : 'bi-x-square'
    "
  ></i>
  <p>{{ message }}</p>
</div>
