import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { bhTitle, loginUrl } from 'src/app/constants';
import { ConfigActions } from 'src/app/core/config/config.actions';
import { ConfigService } from 'src/app/core/config/config.service';

import { ContentActions } from 'src/app/core/content/content.actions';
import { selectEmployeeSearchPage, selectForgotUsernamePage, selectResetPasswordPage } from 'src/app/core/content/content.reducers';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { selectEmployeeDetails } from 'src/app/core/signup/signup.reducers';
import { selectForgotType } from 'src/app/features/login/store/login.reducers';
import { ContentPages, ForgotType, IContentState, IEmployeeDetailsState, LocalStorageKey } from 'src/app/models';


@Component({
  selector: 'bh-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: [ './confirmation-message.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationMessageComponent implements OnInit {
/**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);
  public readonly usernameContent: Observable<IContentState[ContentPages.forgotUsernamePage]> = this.store.select(selectForgotUsernamePage);
  public readonly passwordContent: Observable<IContentState[ContentPages.resetPasswordPage]> = this.store.select(selectResetPasswordPage);
  public showForgotUNConfirmation: boolean = false;
  public showResetPWConfirmation: boolean = false;
  public userName?: string = '';
  private readonly isForgotUnFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotUnFlow) || '').toString();
  private readonly isForgotPwFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isForgotPwFlow) || '').toString();

  constructor(
    private readonly store: Store,
    private readonly getTitle: Title,
    private readonly config: ConfigService,
    private readonly localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.helpDeskPage }));
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.forgotUsernamePage }));
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.resetPasswordPage }));
  }

  public ngOnInit(): void {
    this.store.dispatch(ConfigActions.loading({ loading: false }));
    this.store.select(selectEmployeeDetails)
      .pipe(take(1))
      .subscribe((details: IEmployeeDetailsState | null): void => {
        if (details) {
          this.userName = details.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string;
        }
      });

    this.store.select(selectForgotType)
      .pipe(
        take(1))
      .subscribe((forgotType: ForgotType | null) => {
        if (forgotType === 'username' || this.isForgotUnFlow === 'true') {
          this.showForgotUNConfirmation = true;
          this.getTitle.setTitle(`Forgot Username ${bhTitle}`);
        } else if (forgotType === 'password' || this.isForgotPwFlow === 'true') {
          this.showResetPWConfirmation = true;
          this.getTitle.setTitle(`Forgot Password ${bhTitle}`);
        }
      });
  }

  public onLogin(): void {
    this.document.location.href = this.config.buildExternalUrl(loginUrl).href;
  }
}

