<article class="signup-confirmation" *ngIf="this.isContentLoaded">
  <div class="error-icon">
    <img
      [src]="'assets/exclamation.svg'"
      [alt]="''"
    />
  </div>
  <h1 class="bh--heading">Something's gone wrong.</h1>
  <div class="field-content">
    <p class="field-content--margin field-content--description-font">
      Please refresh the browser or try again later.
    </p>
    <p class="field-content--margin field-content--description-font">
      For immediate registration or login support, please  contact us at
      <a [href]="'tel:+1-833-225-5241'" aria-label="8 3 3. 2 2 5. 5 2 4 1" class="no-wrap">833-225-5241</a> in the US and Canada,
      <a [href]="'tel:+44-0800-247-1101'" aria-label="0 8 0 0. 2 4 7. 1 1 0 1" class="no-wrap">0800 247 1101</a> in the UK, or
      <a [href]="'tel:+353-1800-303-547'" aria-label="1 8 0 0. 3 0 3. 5 4 7" class="no-wrap">1800 303 547</a> in Ireland.
    </p>
    <div class="bh-button-single">
      <button
        type="button"
        class="bh-button--primary"
        (click)="handleTryAgain()"
      >
        Try Again
      </button>
    </div>
  </div>
  <bh-already-have-account
    [content]="$any(content)"
    [showHelpDesk]="false"
    [showLogin]="false"
  ></bh-already-have-account>
</article>
