<div
  class="bh-alert bh-alert--error"
  [attr.id]="id"
  role="alert"
  aria-atomic="true"
>
  <article class="bh-alert__container bh-alert__container--error">
    <!-- updating to a `p` tag for the error title because JAWS reads the H tags twice in Chrome/Edge -->
    <p class="bh-alert__header bh-alert__header--error">
      {{ errorHeader }}
    </p>
    <section class="bh-alert__body bh-alert__body--error">
      <p class="bh-alert__paragraph bh-alert__paragraph--error" *ngFor="let parag of errorBody; trackBy: trackByFn">
        <span [innerHTML]="parag"></span>
      </p>
    </section>
  </article>
</div>
