import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { filter, Observable, skip, Subject, take, takeUntil } from 'rxjs';

import { snackBarDefaultConfig } from '../../../../constants';
import { ConfigActions } from '../../../../core/config/config.actions';
import { selectBhPropertiesURL } from '../../../../core/config/config.reducers';
import {
  selectEmployeeSearchPage,
  selectMagicLinkExpiredPage,
} from '../../../../core/content/content.reducers';
import { ContentService } from '../../../../core/content/content.service';
import { CredentialsActions } from '../../../../core/credentials/credentials.actions';
import {
  selectForgotPasswordState,
  selectResetPasswordState,
  selectSendChangePassEmailState,
} from '../../../../core/credentials/credentials.reducers';
import {
  selectEmailActivityState,
  selectFicEmailVerifyLinkState,
  selectNewOsrEmailState,
} from '../../../../core/signup/signup.reducers';
import {
  BhQueryParams,
  ContentPages,
  IContentState,
  IEmailActivityState,
  IFICEmailVerifyLinkState,
  IForgotPasswordState,
  INewOsrEmailState,
  IResetPasswordState,
  ISendChangePassEmailState,
  SourceId,
} from '../../../../models';
import { SignupActions } from '../../../../core/signup/signup.actions';
import {
  selectMagicLinkLoaded,
  selectMagicLinkLoading,
} from '../../../../core/signup/signup.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-magic-link-expired',
  templateUrl: './magic-link-expired.component.html',
  styleUrls: [ './magic-link-expired.component.scss' ],
})
export class MagicLinkExpiredComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private toastMessage: string = 'Link has been resent.';
  private isOsr: boolean = false;
  private isFic: boolean = false;
  private isFicContactCenter: boolean = false;
  private isCohs: boolean = false;
  private isOperatorOwner: boolean = false;
  private isBrokerLiaison: boolean = false;
  private isResetPass: boolean = false;
  private isForgotPass: boolean = false;
  private userEmail: string | undefined;

  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the page content.
   */
  public readonly content: Observable<IContentState[ContentPages.magicLinkExpiredPage]> = this.store.select(selectMagicLinkExpiredPage);

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly contentService: ContentService,
  ) { }

  public ngOnInit(): void {
    this.content
      .pipe(
        filter((content: IContentState[ContentPages.magicLinkExpiredPage]): boolean => content.loaded),
        take(1),
      )
      .subscribe((contentState: IContentState[ContentPages.magicLinkExpiredPage]): void => {
        // hide the spinner when we land on the page (it was activated by the personal info guard)
        this.store.dispatch(ConfigActions.loading({ loading: false }));
        this.toastMessage = contentState.content.BHL_MagicLinkExpired_Label_Link_Toast;
      });


    // determine the flow (or rather the source/type of user - e.g., OSR, FIC Manual/CC
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        this.isOsr = params.sourceid?.toLowerCase() === SourceId.osr;
        this.isFic = params.sourceid?.toLowerCase() === SourceId['fic-registration'];
        this.isFicContactCenter = params.sourceid?.toLowerCase() === SourceId['fic-contact-center'];
        this.isCohs = params.sourceid?.toLowerCase() === SourceId.cohs;
        this.isOperatorOwner = params.sourceid?.toLowerCase() === SourceId['operator-owner'];
        this.isBrokerLiaison = params.sourceid?.toLowerCase() === SourceId['broker-liaison'];
        this.isResetPass = params.sourceid?.toLowerCase() === SourceId['reset-password'];
        this.isForgotPass = params.sourceid?.toLowerCase() === SourceId['forgot-password'];
      });

    if (this.isFic) {
      this.handleFICManualResendEvent();
    } else if (this.isOsr) {
      this.handleOsrResendEvent();
    } else if (this.isCohs || this.isOperatorOwner || this.isFicContactCenter || this.isBrokerLiaison) {
      this.handleChangePassResendEvent();
    } else if (this.isResetPass) {
      this.handleResetPassResendEvent();
    } else if (this.isForgotPass) {
      this.handleForgotPassResendEvent();
    } else {
      this.handleRegularFlowResendEvent();
    }
  }

  private displayToast(): void {
    // the below content is coming from our backend and is not user generated, safe to suppress
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.snackBar.open(this.toastMessage, 'Close', snackBarDefaultConfig);
  }

  public OnResendLink(): void {
    if (this.isFic) {
      this.store.dispatch(SignupActions.sendFicEmailVerifyLink());
    } else if (this.isOsr) {
      this.store.dispatch(SignupActions.triggerNewOsrEmail());
    } else if (this.isCohs || this.isOperatorOwner || this.isFicContactCenter || this.isBrokerLiaison) {
      this.store.dispatch(CredentialsActions.sendChangePasswordEmail());
    } else if (this.isResetPass) {
      this.store.dispatch(CredentialsActions.resetPassword());
    } else if (this.isForgotPass) {
      this.store.dispatch(CredentialsActions.forgotPassword({ input: this.userEmail as string }));
    } else {
      this.store.dispatch(SignupActions.sendmagiclink());
    }
  }

  private handleRegularFlowResendEvent(): void {
    this.store.select(selectMagicLinkLoading)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store.select(selectMagicLinkLoaded)
      .pipe(
        filter((loaded: boolean): boolean => loaded),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((): void => {
        this.displayToast();
      });
  }

  private handleOsrResendEvent(): void {
    this.store.select(selectNewOsrEmailState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: INewOsrEmailState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded && state.data) {
          this.displayToast();
        }
      });
  }

  private handleFICManualResendEvent(): void {
    this.store.select(selectFicEmailVerifyLinkState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: IFICEmailVerifyLinkState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded && state.ficEmailVerifyLinkStatus) {
          this.displayToast();
        }
      });
  }

  private handleResetPassResendEvent(): void {
    this.store.select(selectResetPasswordState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: IResetPasswordState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded && state.data) {
          this.displayToast();
        }
      });
  }

  private handleForgotPassResendEvent(): void {
    this.store.select(selectEmailActivityState)
      .pipe(take(1))
      .subscribe((state: IEmailActivityState): void => {
        this.userEmail = state.data?.personResponse?.primaryEmail;
      });

    this.store.select(selectForgotPasswordState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: IForgotPasswordState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded && state.data) {
          this.displayToast();
        }
      });
  }

  private handleChangePassResendEvent(): void {
    this.store.select(selectSendChangePassEmailState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: ISendChangePassEmailState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded && state.data) {
          this.displayToast();
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
