<ng-container *ngIf="(content | async)?.loaded">
  <article *ngIf="{ page: (content | async)?.content, account: (accountContent | async)?.content } as pageContent">
    <img
      [src]="'assets/envelope.svg'"
      [alt]="''"
    />
    <h1 class="bh--heading">{{ pageHeader }}</h1>
    <p class="page-description--large">
      {{ this.acctExistContent }}
    </p>
    <bh-login-button
      [label]="pageContent.page?.BHL_AcctExist_Button_Login"
      [classNames]="'bh-button--primary'"
    ></bh-login-button>
    <bh-already-have-account
      [content]="pageContent.account"
      [showLogin]="false"
    ></bh-already-have-account>
  </article>
</ng-container>
