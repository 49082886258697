import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { acceptableUsePolicy, cookieNotice, privacyNotice, trademarks } from '../../constants';
import { DisplayType, ILegalLink } from '../../models';
import { selectDisplayType } from '../config/config.reducers';
import { SharedService } from 'src/app/shared/shared.service';

/**
 * The global footer component, which should be used once within the application.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
})
export class FooterComponent {
  /**
   * An observable to update the page elements based on display mode we are in based
   * on the breaking point(s).
   */
  public readonly displayType: Observable<DisplayType> = this.store.select(selectDisplayType);

  /**
   * The privacy notice link content.
   */
  public readonly privacyNotice: ILegalLink = privacyNotice;

  /**
   * The cookie notice link content.
   */
  public readonly cookieNotice: ILegalLink = cookieNotice;

  /**
   * The acceptable use policy notice link content.
   */
  public readonly acceptableUsePolicy: ILegalLink = acceptableUsePolicy;

  /**
   * The trademark link content.
   */
  public readonly trademarks: ILegalLink = trademarks;

  /**
   * The copyright text for desktop view.
   */
  public readonly copyRightText: string = `&copy; ${new Date().getFullYear()} Bright Horizons Family Solutions LLC. All Rights Reserved.`;

  /**
   * The copyright text for mobile view.
   */
  public readonly copyRightTextMobile: string = `&copy; ${new Date().getFullYear()} Bright Horizons Family Solutions LLC<br />All Rights Reserved.`;

  /**
   * The component `constructor`
   * @param {Store} store The NgRx store instance.
   */
  constructor(
    private readonly store: Store,
    private readonly sharedService: SharedService
  ) { }
  
  public onPrivacyClick() : void{
    this.sendOutboundGa4Event(privacyNotice.label, privacyNotice.url);
  }
  public onCookieNoticeClick() : void{
    this.sendOutboundGa4Event(cookieNotice.label, cookieNotice.url);
  }
  public onAcceptableUsePolicyClick() : void{
    this.sendOutboundGa4Event(acceptableUsePolicy.label, acceptableUsePolicy.url);
  }
  public onTrademarksClick() : void{
    this.sendOutboundGa4Event(trademarks.label, trademarks.url);
  }
  public sendOutboundGa4Event(label : string, url : string) : void{
    this.sharedService.GA4pushEvent('outbound_link_click', {
      /* eslint camelcase: ["error", {allow: ["link_text","link_url","link_section"]}]*/
      link_text: label, //Text on link clicked
      link_url: url,// Link click for outbound links
      link_section: "footer" // Section of DOM where the outbound link is clicked
    });
  }
}
