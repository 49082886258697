import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bh-system-outage-error',
  templateUrl: './system-outage-error.component.html',
  styleUrls: [ './system-outage-error.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemOutageErrorComponent {

}
