import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, Observable, Subject, takeUntil } from 'rxjs';

import { ConfigActions } from 'src/app/core/config/config.actions';
import { selectApiTokenRefresh, selectBenefitId } from 'src/app/core/config/config.reducers';
import { selectEmployeeSearchPage } from 'src/app/core/content/content.reducers';
import { BenefitId, ContentPages, IApiTokenRefreshState, IBenefitLogo, IContentState } from 'src/app/models';
import { ContentService } from '../../../../core/content/content.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: [ './session-expired.component.scss' ],
})
export class SessionExpiredComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly router: Router,
    private readonly contentService: ContentService,
  ) { }

  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  public ngOnInit(): void {
    this.store.select(selectApiTokenRefresh)
      .pipe(
        filter((state: IApiTokenRefreshState): boolean => state.loaded || state.error !== null),
        combineLatestWith(
          this.store.select(selectBenefitId),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ state, benefitId ]: [IApiTokenRefreshState, IBenefitLogo]): void => {
        if (state.error) {
          this.contentService.showErrorToast();
        } else if (state.loaded) {
          if (benefitId.id === BenefitId.FIC) {
            this.router.navigate([ '/signup', 'personal-info' ]);
          } else {
            this.store.dispatch(ConfigActions.clientGuidFromQuery({ isFromQuery: true }));
            this.router.navigate([ '/signup', 'search' ]);
          }
        }
      });
  }

  public onContinue(): void {
    this.store.dispatch(ConfigActions.fetchClientToken());
    this.dialog.closeAll();
  }
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
