import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable, take, filter, map, takeUntil, Subject } from 'rxjs';
import { Router } from '@angular/router';
import {
  ContentPages,
  ISelectedEmployerState,
  IContentState,
  IEligibilityContent,
  BhQueryParams,
  IBenefitLogo,
  LocalStorageKey,
} from 'src/app/models';
import { selectSelectedEmployer } from 'src/app/core/signup/signup.reducers';
import { helpDeskRoute } from 'src/app/constants';
import { selectEmployeeSearchPage, selectEligibilityPage } from '../../../../core/content/content.reducers';
import { selectBenefitId, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { ContentActions } from 'src/app/core/content/content.actions';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-federatedaccount-not-found',
  templateUrl: './federatedaccount-not-found.component.html',
  styleUrls: [ './federatedaccount-not-found.component.scss' ],
})
export class FederatedaccountNotFoundComponent implements OnInit {
  public clientName: string = '';
  public eligibilityFederatedAccountNotFoundbody: string = '';
  public readonly selectedEmployer: Observable<ISelectedEmployerState | null> = this.store.select(selectSelectedEmployer);
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);
  private helpDeskUrl: string = '';
  public eligibilityFederatedAccountNotFoundheader: string = '';
  /**
   * Subject to pass to the RxJS `takeUntil` to properly unsubscribe when we destroy
   * the component.
   */
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  /**
   * Subscription for the Required label content.
   */
  public readonly requiredContent: Observable<IContentState[ContentPages.eligibilityPage]> = this.store.select(selectEligibilityPage);

  /**
   * The query params we need to pass to the help desk URL when we navigate because the help desk is opened in a new window/tab.
   */
  public queryParams: Record<string, string>;

  /**
   * The help desk form route.
   */
  public readonly helpDeskRoute: typeof helpDeskRoute = helpDeskRoute;

  /**
   * The component `constructor`
   * @param {Store} store The NgRx store instance.
   */
  constructor(
    private readonly store: Store,
    private readonly locationStrategy: LocationStrategy,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedService: SharedService,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.eligibilityPage }));
  }

  public ngOnInit(): void {  
    this.selectedEmployer
      .pipe(
        take(1),
      )
      .subscribe((employee: ISelectedEmployerState | null): void => {
        this.clientName = (employee as ISelectedEmployerState).clientName;
      });

    this.store.select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(
          this.store.select(selectBenefitId),
        ),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ params, benefit ]: [BhQueryParams, IBenefitLogo]): void => {
        // generate the link for the help desk for the error message {@see setErrorMessage}
        this.helpDeskUrl = this.locationStrategy.prepareExternalUrl(
          this.router.serializeUrl(
            this.router.createUrlTree(
              [ ...helpDeskRoute ],
              {
                queryParams: {
                  ...benefit.id !== '0' && { benefitId: benefit.id },
                  ...params,
                },
              },
            ),
          ),
        );
      });

    this.requiredContent
      .pipe(
        filter((pageContent: IContentState[ContentPages.eligibilityPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.eligibilityPage]): IEligibilityContent => pageContent.content),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((pageContent: IEligibilityContent): void => {
        this.eligibilityFederatedAccountNotFoundbody = pageContent.BHL_Eligibility_Error_FederatedAccountNotFound_Body;
        this.eligibilityFederatedAccountNotFoundbody = this.eligibilityFederatedAccountNotFoundbody.replace('{{ clientName }}', this.clientName);
        this.eligibilityFederatedAccountNotFoundbody = this.eligibilityFederatedAccountNotFoundbody.replace('{{ helpDeskUrl }}', this.helpDeskUrl);
        this.eligibilityFederatedAccountNotFoundheader = pageContent.BHL_Eligibility_Error_AccountNotFound_Title;

        const errorHeader = this.eligibilityFederatedAccountNotFoundheader ? this.eligibilityFederatedAccountNotFoundheader : '';
        const errorBody =this.eligibilityFederatedAccountNotFoundbody.length > 0 ? this.eligibilityFederatedAccountNotFoundbody : '';
        
        const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
        this.sharedService.GA4pushEvent('sign_up_error', {
          // eslint-disable-next-line camelcase
          benefit_id: benefitIdFromLocalStorage,
          // eslint-disable-next-line camelcase
          benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage),
          // eslint-disable-next-line camelcase
          error_message: `${errorHeader}, ${errorBody}`,
        });
        /* eslint camelcase: ["error", {allow: ["form_name","error_message"]}]*/
        this.sharedService.GA4pushEvent('form_error', {
          form_name: "Sign Up",
          error_message: `${errorHeader}, ${errorBody}`,
        });
      });
  }
}
