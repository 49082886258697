/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, concatMap, EMPTY, map, of } from 'rxjs';

import { selectSelectedEmployer } from 'src/app/core/signup/signup.reducers';
import { errorPageRoute } from '../../constants';

import {
  BhQueryParams,
  Content,
  ContentPages,
  ContentPrefix,
  EventName,
  HttpError,
  IApiResponse,
  IBenefitLogo,
  IClientLogoUrl,
  IConfigState,
  IContentState,
  IPageContentPayload,
  ISelectedEmployerState,
  LocalStorageKey,
} from '../../models';
import { selectBenefitId, selectBhPropertiesURL, selectLocale } from '../config/config.reducers';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { ContentActions } from './content.actions';
import { selectContentState, selectLogoUrl } from './content.reducers';
import { ContentService } from './content.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ContentEffects {
  public loadContentByPattern = createEffect(() => {
    return this.actions.pipe(
      ofType(ContentActions.loadPageContentPattern),
      concatLatestFrom(() => [
        this.store.select(selectContentState),
        this.store.select(selectLocale),
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([ pageName, contentState, locale, benefitId, params ]: [{ page: ContentPages }, IContentState, IConfigState['locale'], IBenefitLogo, BhQueryParams]) => {
        // first check if the content has been loaded already, if so, do not make a call to the backend
        const pageContent: Array<string> = Object.values(contentState[pageName.page].content) as Array<string>;

        for (const content of pageContent) {
          // find at least one key that has value/is not empty string, which will indicate the content has been loaded
          if (content.trim().length > 0) {
            return of(ContentActions.getPageContentPattern({ page: pageName.page }));
          }
        }

        const payload: IPageContentPayload = {
          culture: locale,
          keys: [ ContentPrefix[pageName.page] ],
          benefitId: benefitId.id,
          clientGuid: params.clientguid?.length ? params.clientguid : null,
        };

        return this.contentService.getPageContent(payload).pipe(
          map((response: Content) =>
            ContentActions.loadPageContentPatternSuccess({ content: response, page: pageName.page }),
          ),
          catchError((error: Error | HttpError) => {
            if (!(error instanceof Error) && error.status !== 0) {
              this.router.navigate([ ...errorPageRoute ]);
            }

            return of(
              ContentActions.loadPageContentPatternFailure({
                error: this.errorService.extractErrorMsg(error),
                page: ContentPages.employeeSearchPage,
              }),
            );
          }),
        );
      }),
    );
  });

  public loadLogoUrl = createEffect(() => {
    return this.actions.pipe(
      ofType(ContentActions.fetchLogoUrl),
      concatLatestFrom(() => [
        this.store.select(selectLogoUrl),
      ]),
      concatMap(([ , logo ]: [ TypedAction<'[App Content] Fetch Logo Url'>, IClientLogoUrl ]) => {
        if (logo.loaded) {
          return EMPTY;
        }

        return of(ContentActions.getLogoUrl());
      }),
    );
  });

  public getLogoUrl = createEffect(() => {
    return this.actions.pipe(
      ofType(ContentActions.getLogoUrl),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([ , employer, params ]: [ TypedAction<'[App Content] Get Logo Url'>, ISelectedEmployerState | null, BhQueryParams ]) => {
        let clientGuid = '';

        if (employer === null) {
          clientGuid = params.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string;
        } else {
          clientGuid = employer.clientGuid;
        }

        if (!clientGuid) {
          return EMPTY;
        }

        return this.contentService.getLogoUrl(encodeURIComponent(clientGuid))
          .pipe(
            map((response: IApiResponse<string | null>) => ContentActions.fetchLogoUrlSuccess({ path: response.data })),
            catchError((error: Error | HttpError) => {
              if (!(error instanceof Error)) {
                error.eventType = EventName.LOGIN_TECHNICAL_ERROR_BE;
              }

              return of(ContentActions.fetchLogoUrlFailure({ error: this.errorService.extractErrorMsg(error) }));
            }),
          );
      }),
    );
  });

  // eslint-disable-next-line max-params
  constructor(
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly errorService: ErrorHandlerService,
    private readonly localStorageService: LocalStorageService,
  ) {}
}
