/* eslint-disable @typescript-eslint/no-shadow */
import { PipeTransform, Pipe } from '@angular/core';

/**
 * Pipe to bold any part of a string that matches the provided search input
 */
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  /**
   * Transform any string to bold the part that matches the provided input
   * @param {string} text The input text we would like to look for matches
   * @param {string} search The actual match we are looking for
   * @returns {string} The original string with the matching part bolded if there is one
   */
  public transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp(pattern, 'gi');
    const match = text.match(regex);
    if (!match) {
      return text;
    }

    return search ? text.replace(regex, (match) => `<b>${match}</b>`) : text;
  }
}
