import { MatSnackBarConfig } from '@angular/material/snack-bar';

/**
 * Constant for any typeahead/autocomplete or any other input
 * delay that we need to apply between triggering any logic and
 * waiting for the user to enter input.
 */
export const typeaheadDelay: number = 300;

/**
 * Constant for any typeahead/autocomplete or any other input
 * minimum number of characters to be entered by the user before
 * triggering any logic.
 */
export const typeaheadMinLength: number = 2;

/**
 * Constant for the duration a toast message should be visible
 * on the screen before auto closing.
 */
export const toastDuration: number = 5000;

/**
 * Constant for the duration a toast message should be visible for warning
 * on the screen before auto closing.
 */
export const warningToastDuration: number = 5000;

/**
 * Constant for the duration of the screen reader only page level
 * error message before hiding it.
 */
export const srMessageDelay: number = 1000;

export const helpLoginDetailsDelay: number = 2000;

export const snackBarDefaultConfig: MatSnackBarConfig = {
  panelClass: 'my-custom-snackbar',
  duration: toastDuration,
  verticalPosition: 'top',
  horizontalPosition: 'center',
};
