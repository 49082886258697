import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';
import { debounceTime, distinctUntilChanged, filter, map, Observable, skip, Subject, take, takeUntil, combineLatestWith, switchMap, of } from 'rxjs';
import {
  bhQueryParamsSet,
  emailPattern,
  employerSearch,
  phonePattern,
  typeaheadDelay,
  typeaheadMinLength,
  namePattern,
  privacyNotice,
} from 'src/app/constants';
import { ConfigActions } from 'src/app/core/config/config.actions';
import { selectBenefitId, selectBhPropertiesURL } from 'src/app/core/config/config.reducers';
import { ContentActions } from 'src/app/core/content/content.actions';
import {
  selectCreateAccountPage,
  selectEligibilityPage,
  selectHelpDeskPage,
  selectPersonalInfoPage,
} from 'src/app/core/content/content.reducers';
import { selectPersonInfoState, selectSelectedEmployer } from 'src/app/core/signup/signup.reducers';//
import {
  BenefitId,
  BhQueryParams,
  BhlError,
  ContentPages,
  EventName,
  IBenefitLogo,
  IContentState,
  IEligibilityContent,
  IEmployer,
  IHelpDeskContent,
  IHelpState,
  ILegalLink,
  IMasterListStatus,
  IPersonInfo,
  IPersonInfoState,
  IRegistrationStatus,
  ISelectedEmployerState,
  LocalStorageKey,
  QueryParamsSet,
  RecaptchaSubCategory,
} from 'src/app/models';
import { environment } from '../../../../../environments/environment';
import { ContentService } from '../../../../core/content/content.service';
import { LoggerService } from '../../../../core/logger-service/logger.service';
import { GlobalActions } from '../../store/global.actions';
import { selectHelpDeskSupport } from '../../store/global.reducers';
import {
  selectEmployersError,
  selectEmployersList,
  selectEmployersLoading,
  selectStateListDetails,
  selectStateListLoading,
  selectSupportHelpsList,
  selectSupportHelpsLoading,
  selectSupportServicesList,
  selectSupportServicesLoading,
} from '../../store/global.selectors';
import { DOCUMENT } from '@angular/common';
import { selectRegistrationStatus } from 'src/app/core/signup/signup.selectors';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
import { SignupActions } from 'src/app/core/signup/signup.actions';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-app-short-help',
  templateUrl: './short-help.component.html',
  styleUrls: [ './short-help.component.scss' ],
})
export class ShortHelpComponent implements OnInit, OnDestroy {
  public readonly isRecaptchaEnabled: boolean = environment.google.reCaptcha.enable;
  public readonly contentEligibility: Observable<IContentState[ContentPages.eligibilityPage]> = this.store.select(selectEligibilityPage);
  public readonly content: Observable<IContentState[ContentPages.helpDeskPage]> = this.store.select(selectHelpDeskPage);
  public readonly supportHelps: Observable<Array<IMasterListStatus> | null> = this.store.select(selectSupportHelpsList);
  public readonly supportServices: Observable<Array<IMasterListStatus> | null> = this.store.select(selectSupportServicesList);
  public readonly selectedEmployerName: Observable<ISelectedEmployerState | null> = this.store.select(selectSelectedEmployer);
  public readonly selectedEmployerPrevious: Observable<ISelectedEmployerState | null> = this.store.select(selectSelectedEmployer);
  public readonly registrationStatus: Observable<IRegistrationStatus | null> = this.store.select(selectRegistrationStatus);

  public readonly accountContent: Observable<IContentState[ContentPages.createAccountPage]> =
    this.store.select(selectCreateAccountPage);
  public readonly eligbilityContent: Observable<IContentState[ContentPages.eligibilityPage]> =
    this.store.select(selectEligibilityPage);
  public readonly personalInfoContent: Observable<IContentState[ContentPages.personalInfoPage]> =
    this.store.select(selectPersonalInfoPage);
  @ViewChild('captchaRef') public readonly captchaRef: RecaptchaComponent | undefined;
  public file: File | null = null;
  public selectedEmployer: IEmployer | null = null;
  public toHighlight: string = '';
  public readonly employers: Observable<Array<IEmployer> | null> =
    this.store.select(selectEmployersList);
  public readonly stateList: Observable<Array<IMasterListStatus> | null> =
    this.store.select(selectStateListDetails);
  public isFicMbdVersion: boolean = false;
  public showMessageInvalid: boolean = false;
  public benefitId: string;
  public userInfoId: string | null | undefined = '';
  public paramsSet: Set<QueryParamsSet> = new Set<QueryParamsSet>(bhQueryParamsSet);
  public bhPropertiesURL: BhQueryParams = {};
  public sourceURL: string;
  public disableCheckboxflag: boolean = true;
  public clientName: string = '';
  public DescriptionBody: string = '';
  public uniqueid: string;
  public displayName: string = '';
  public uniqueidErrorMessages: string = '';
  public instructionTextErrorMessages: string = '';
  public instructionText: string = '';
  public queryParams: Record<string, string>;
  public helpDeskForm: FormGroup = this.fb.group({
    helpDesk: [ '' as string ],
    needHelpWith: [ '' ],
    servicesInterested: [ '' ],
    fcEmployer: [ '' as string ],
    firstName: [ '' as string, [ Validators.required, Validators.pattern(namePattern) ] ],
    lastName: [ '' as string, [ Validators.required, Validators.pattern(namePattern) ] ],
    email: [ '', [ Validators.required, Validators.pattern(emailPattern) ] ],
    phone: [
      '' as string,
      [ Validators.pattern(phonePattern) ],
    ],
    centerName: [ '' as string ],
    city: [ '' as string ],
    state: [ '' as string ],
    employeeId: [ '' as string, Validators.required ],

    // attachmentFile: [ [] as Array<object> ],
    OptForSMS: [{ value: false, disabled: false }],
  });
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public get fcempId(): AbstractControl<string> {
    return this.helpDeskForm.get('employeeId') as FormControl;
  }
  private sendFormStartEvent: boolean = true;
  private isNeedHelpChanged: boolean = true;
  private autoFilledNeedHelp: boolean = true;
  private isServiceChanged: boolean = true;
  private autoFilledService: boolean = true;
  private isEmployerChanged: boolean = true;
  private autoFilledEmployer: boolean = true;
  private isFirstNameChanged: boolean = true;
  private autoFilledFirstName: boolean = true;
  private isLastNameChanged: boolean = true;
  private autoFilledLastName: boolean = true;
  private isEmailChanged: boolean = true;
  private autoFilledEmail: boolean = true;
  private isPhoneChanged: boolean = true;
  private autoFilledPhone: boolean = true;
  private isEmployeeIdChanged: boolean = true;
  private autoFilledEmployeeId: boolean = true;
  private isHelpDeskDescriptionChanged: boolean = true;
  private autoFilledHelpDeskDescription: boolean = true;
  public supportTitle: string = "Support";
  public readonly privacyNotice: ILegalLink = privacyNotice;
  // eslint-disable-next-line max-params
  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly loggerService: LoggerService,
    private readonly contentService: ContentService,
    private readonly localStorageService: LocalStorageService,
    private readonly sharedService: SharedService
  ) {
    this.store.dispatch(
      ContentActions.loadPageContentPattern({ page: ContentPages.helpDeskPage }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.createAccountPage,
      }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.eligibilityPage,
      }),
    );
    this.store.dispatch(
      ContentActions.loadPageContentPattern({
        page: ContentPages.personalInfoPage,
      }),
    );
    this.store.dispatch(GlobalActions.loadSupportHelps());
    this.store.dispatch(GlobalActions.loadSupportServices());
  }

  public ngOnInit(): void {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        this.userInfoId = params.uid as string;
        this.sourceURL = params.sourceurl as string;
        this.uniqueid = params.uniqueid as string;
        this.clientName = params.clientname as string;
        this.localStorageService.saveItem(LocalStorageKey.clientGuid, params.clientguid as string);
        this.store.dispatch(SignupActions.selectedEmployer({
          selectedEmployer: {
            clientGuid: params.clientguid as string,
            clientName: params.clientname as string,
          } as ISelectedEmployerState,
        }));
        this.store.dispatch(ConfigActions.bhPropertiesUrl({ bhPropertiesURL: { clientguid: params.clientguid as string } }));

        // this.store.dispatch(SignupActions.registration());
        this.store.dispatch(SignupActions.getBenefit());
        this.store.dispatch(SignupActions.getClientInfo({ input: params.clientguid as string }));
        this.store.select(selectRegistrationStatus)
          .pipe(
            take(1),
            switchMap(() => {
              // Dispatch the action
              this.store.dispatch(SignupActions.registration());

              // Return an observable to continue the sequence
              return this.store.select(selectRegistrationStatus);
            }),
          )
          .subscribe((status: IRegistrationStatus | null) => {
            // Process the status and handle the result
            this.displayName = status?.displayname as string;
            this.instructionText = status?.instructionText as string;
            if (this.instructionText) {
              this.uniqueidErrorMessages = this.instructionTextErrorMessages.replace('{{UNIQUEID}}', this.displayName);
              this.uniqueidErrorMessages = this.uniqueidErrorMessages.replace('{{INSTRUCTIONTEXT}}', this.instructionText);
            }
          });

        // Dispatch the action
        this.store.dispatch(SignupActions.registration());

        // Use switchMap to switch to the select observable after the dispatch is completed
        this.store.select(selectRegistrationStatus)
          .pipe(
            take(1),
            switchMap((status: IRegistrationStatus | null) => {
              // Process the status and return the result if needed
              this.displayName = status?.displayname as string;
              this.instructionText = status?.instructionText as string;
              if (this.instructionText) {
                this.uniqueidErrorMessages = this.instructionTextErrorMessages.replace('{{UNIQUEID}}', this.displayName);
                this.uniqueidErrorMessages = this.uniqueidErrorMessages.replace('{{INSTRUCTIONTEXT}}', this.instructionText);
              }

              // Return the result if needed
              return of(/* your result */);
            }),
          )
          .subscribe(/* handle the result if needed */);
      });


    this.content
      .pipe(
        filter((pageContent: IContentState[ContentPages.helpDeskPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.helpDeskPage]): IHelpDeskContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: IHelpDeskContent): void => {
        this.DescriptionBody = pageContent.BHL_LoggedOutSupportFormFIC_Label_Content1_ShortHelp.replace('{{CLIENTNAME}}', this.clientName);
      });
    this.contentEligibility
      .pipe(
        filter((pageContent: IContentState[ContentPages.eligibilityPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.eligibilityPage]): IEligibilityContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: IEligibilityContent): void => {
        this.uniqueidErrorMessages = pageContent.BHL_Eligibility_Error_IDInvalidFormat;
        this.instructionTextErrorMessages = pageContent.BHL_Eligibility_Error_InvalidGeneric;

        this.store.select(selectRegistrationStatus)
          .pipe(
            take(1))
          .subscribe((status: IRegistrationStatus | null): void => {
            this.displayName = status?.displayname as string;
            this.instructionText = status?.instructionText as string;
            if (this.instructionText) {
              this.uniqueidErrorMessages = this.instructionTextErrorMessages.replace('{{UNIQUEID}}', this.displayName);
              this.uniqueidErrorMessages = this.uniqueidErrorMessages.replace('{{INSTRUCTIONTEXT}}', this.instructionText);
            }
          });
      });

    this.store.select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(
          this.store.select(selectBenefitId),
        ),
        take(1),
      )
      .subscribe(([ params, benefitInfo ]: [ object, IBenefitLogo ]): void => {
        this.queryParams = {
          ...benefitInfo.id !== '0' && { benefitId: benefitInfo.id },
          ...params, sourceurl: this.doc.location.href,
        };
      });

    this.store
      .select(selectEmployersLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));// show
      });

    this.store
      .select(selectSupportHelpsLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));// error
      });

    this.store
      .select(selectSupportServicesLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectStateListLoading)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    this.store
      .select(selectEmployersError)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((error: BhlError): void => {
        if (error) {
          if (typeof error !== 'string' && error.status === 404) {
            this.store.dispatch(
              GlobalActions.loadEmployersSuccess({ list: [] }),
            );
          }
        }
      });

    this.store
      .select(selectHelpDeskSupport)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((helpState: IHelpState): void => {
        if (helpState.loaded) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));
          this.router.navigate([ 'global', 'confirmation-short' ]);
        } else if (helpState.error) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));
          this.contentService.showErrorToast();
        }
      });

    this.helpDeskForm.controls['fcEmployer']!.valueChanges.pipe(
      debounceTime(typeaheadDelay),
      map((value: string | IEmployer): string | IEmployer => {
        if (typeof value === 'string') {
          // remove any special chars to prevent duplicate calls to the backend
          return value.replace(employerSearch, '');
        }

        return value;
      }),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    ).subscribe((value: string | null | IEmployer): void => {
      if (value) {
        if (typeof value === 'string') {
          if (value.length >= typeaheadMinLength) {
            this.toHighlight = value;
            this.store.dispatch(GlobalActions.loadEmployers({ input: value }));
            this.employers.subscribe((employersList) => {
              if (employersList && employersList.length >= 0) {
                const employerObj = employersList.find((x) => x.clientName.toLowerCase() === value.toLowerCase());
                if (employerObj !== undefined) {
                  this.setSelectedEmployer(employerObj);
                }
              }
            });
          } else {
            this.store.dispatch(GlobalActions.clearEmployers());
          }
        } else {
          this.setSelectedEmployer(value);
        }
      } else {
        this.store.dispatch(GlobalActions.clearEmployers());
      }
    });

    this.store
      .select(selectBenefitId)
      .pipe(take(1))
      .subscribe((benefitId: IBenefitLogo | null): void => {
        if (benefitId) {
          this.benefitId = benefitId.id as string;
          this.setServicesInteresteddefaultvalue(this.benefitId);
        }
      });
    this.getPersonalInfoData();

    this.helpDeskForm.controls['helpDesk']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.showHelpDeskTextAreaError();
        this.sendFormStart();
        if(this.isHelpDeskDescriptionChanged){
          this.isHelpDeskDescriptionChanged = false;
          this.sendFormFillGa4Event("How can we help?","no");
        }
      });

      this.helpDeskForm.controls['fcEmployer']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmployerChanged){
          this.isEmployerChanged = false;
          this.sendFormFillGa4Event("Employer","no");
        }
      });

      this.helpDeskForm.controls['firstName']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isFirstNameChanged){
          this.isFirstNameChanged = false;
          this.sendFormFillGa4Event("First Name","no");
        }
      });

      this.helpDeskForm.controls['lastName']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isLastNameChanged){
          this.isLastNameChanged = false;
          this.sendFormFillGa4Event("Last Name","no");
        }
      });

      this.helpDeskForm.controls['email']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmailChanged){
          this.isEmailChanged = false;
          this.sendFormFillGa4Event("Email","no");
        }
      });

      this.helpDeskForm.controls['phone']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isPhoneChanged){
          this.isPhoneChanged = false;
          this.sendFormFillGa4Event("Phone","no");
        }
      });

      this.helpDeskForm.controls['employeeId']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): void => {
        this.sendFormStart();
        if(this.isEmployeeIdChanged){
          this.isEmployeeIdChanged = false;
          this.sendFormFillGa4Event("Employee ID","no");
        }
      });

      if(this.helpDeskForm.controls['needHelpWith']?.value !== '' && this.helpDeskForm.controls['needHelpWith']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledNeedHelp){
          this.autoFilledNeedHelp = false;
          this.sendFormFillGa4Event("I need help with","yes");
        }
      }
    
      if(this.helpDeskForm.controls['servicesInterested']?.value !== '' && this.helpDeskForm.controls['servicesInterested']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledService){
          this.autoFilledService = false;
          this.sendFormFillGa4Event("Which service can we help you with","yes");
        }
      }

      if(this.helpDeskForm.controls['fcEmployer']?.value !== '' && this.helpDeskForm.controls['fcEmployer']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmployer){
          this.autoFilledEmployer = false;
          this.sendFormFillGa4Event("Employer","yes");
        }
      }

      if(this.helpDeskForm.controls['firstName']?.value !== '' && this.helpDeskForm.controls['firstName']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledFirstName){
          this.autoFilledFirstName = false;
          this.sendFormFillGa4Event("First Name","yes");
        }
      }
      
      if(this.helpDeskForm.controls['lastName']?.value !== '' && this.helpDeskForm.controls['lastName']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledLastName){
          this.autoFilledLastName = false;
          this.sendFormFillGa4Event("Last Name","yes");
        }
      }

      if(this.helpDeskForm.controls['email']?.value !== '' && this.helpDeskForm.controls['email']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmail){
          this.autoFilledEmail = false;
          this.sendFormFillGa4Event("Email","yes");
        }
      }

      if(this.helpDeskForm.controls['phone']?.value !== '' && this.helpDeskForm.controls['phone']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledPhone){
          this.autoFilledPhone = false;
          this.sendFormFillGa4Event("Phone","yes");
        }
      }

      if(this.helpDeskForm.controls['employeeId']?.value !== '' && this.helpDeskForm.controls['employeeId']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledEmployeeId){
          this.autoFilledEmployeeId = false;
          this.sendFormFillGa4Event("Employee ID","yes");
        }
      }
      
      if(this.helpDeskForm.controls['helpDesk']?.value !== '' && this.helpDeskForm.controls['helpDesk']?.value !== null){
        this.sendFormStart();
        if(this.autoFilledHelpDeskDescription){
          this.autoFilledHelpDeskDescription = false;
          this.sendFormFillGa4Event("How can we help?","yes");
        }
      }

    // debugger;
    const clientGuidFromLocalStorage: string | null = this.localStorageService.getItem(LocalStorageKey.clientGuid);
    const selectedEmployer: IEmployer = {
      clientGuid: clientGuidFromLocalStorage ?? '',
      clientName: this.clientName,
      clientId: 0,
    };
    this.setSelectedEmployer(selectedEmployer);
    this.helpDeskForm.get('fcEmployer')?.setValue(selectedEmployer);
  }

  public sendFormStart(): void{
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
  }

  public showHelpDeskTextAreaError(): void {
    this.showMessageInvalid =
        (this.helpDeskForm.get('helpDesk')!.value as string).length < 1;
  }

  public get helpDesk(): AbstractControl<string> {
    return this.helpDeskForm.get('helpDesk')!;
  }

  public get needHelpWith(): AbstractControl<string> {
    return this.helpDeskForm.get('needHelpWith')!;
  }

  public get servicesInterested(): AbstractControl<string> {
    return this.helpDeskForm.get('servicesInterested')!;
  }

  public get fcEmployer(): AbstractControl<string> {
    return this.helpDeskForm.get('fcEmployer')!;
  }

  public get firstName(): AbstractControl<string> {
    return this.helpDeskForm.get('firstName')!;
  }

  public get lastName(): AbstractControl<string> {
    return this.helpDeskForm.get('lastName')!;
  }

  public get email(): AbstractControl<string> {
    return this.helpDeskForm.get('email')!;
  }

  public get phone(): AbstractControl<string> {
    return this.helpDeskForm.get('phone')!;
  }
  public get employeeId(): AbstractControl<string> {
    return this.helpDeskForm.get('employeeId')!;
  }
  public minLengthValid(): boolean | undefined {
    const phoneNumberValidate = this.helpDeskForm.get('phone')!.value as string;
    if (phoneNumberValidate.toString().length <= 9) {
      this.disableCheckboxflag = true;

      // this.helpDeskForm.get('OptForSMS')!.disable();
      // this.helpDeskForm.get('OptForSMS')?.setValue(false);
    }

    return this.helpDeskForm.get('phone')!.hasError('pattern');
  }

  public phoneNoValue(): boolean | undefined {
    const phoneNumberValidate = this.helpDeskForm.get('phone')!.value as string;
    this.disableCheckboxflag = phoneNumberValidate.toString().length > 9 || phoneNumberValidate.toString().length <= 9;
    if (this.disableCheckboxflag) {
      // this.helpDeskForm.get('OptForSMS')!.disable();
      // this.helpDeskForm.get('OptForSMS')?.setValue(false);
    } else {
      // this.helpDeskForm.get('OptForSMS')!.enable();
    }
    if (phoneNumberValidate.toString().length <= 9) {
      this.disableCheckboxflag = true;

      // this.helpDeskForm.get('OptForSMS')!.disable();
      // this.helpDeskForm.get('OptForSMS')?.setValue(false);
    }

    return (
      phoneNumberValidate.toString().length > 9 ||
        phoneNumberValidate.toString().length <= 9
    );
  }
  public onEmployeeIdChange(): void {
    // this.helpDeskForm?.controls?.employeeId.setValue('');
    // this.helpDeskForm.controls.employeeId.markAsUntouched();
    // this.helpDeskForm.controls.employeeId.markAsPristine();
  }
  public get centerName(): AbstractControl<string> {
    return this.helpDeskForm.get('centerName')!;
  }

  public get city(): AbstractControl<string> {
    return this.helpDeskForm.get('city')!;
  }

  public get state(): AbstractControl<string> {
    return this.helpDeskForm.get('state')!;
  }


  // public get attachmentFile(): AbstractControl<string> {
  //   return this.helpDeskForm.get('attachmentFile')!;
  // }

  public setServicesInteresteddefaultvalue(benefitId: string): void {
    switch (benefitId) {
      case BenefitId.FIC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_FIC');
        this.isFicMbdVersion = true;
        this.store.dispatch(GlobalActions.loadState());
        break;
      case BenefitId.BUC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_BUC');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.CCSS:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_CCSS');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.CollegeCoach:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_CC');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.EdAssist:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_EdA');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.MyBH:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_Benefits');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.SC:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_Sitter');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.WFS:
        this.helpDeskForm
          .get('servicesInterested')
          ?.setValue('BHL_MyBHSupport_WorkFamily');
        this.isFicMbdVersion = false;
        break;
      case BenefitId.Default:
        this.isFicMbdVersion = false;
        break;
      default:
        break;
    }
    if (this.isFicMbdVersion) {
      this.helpDeskForm.controls['fcEmployer']!.clearValidators();
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['city']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['state']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    } else {
      this.helpDeskForm.controls['fcEmployer']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.clearValidators();
      this.helpDeskForm.controls['city']!.clearValidators();
      this.helpDeskForm.controls['state']!.clearValidators();
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
      this.helpDeskForm.updateValueAndValidity();
    }
  }

  private setSelectedEmployer(employer: IEmployer): void {
    this.selectedEmployer = employer;
  }

  public onNeedHelpWithChange(): void{
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
    if(this.isNeedHelpChanged){
      this.isNeedHelpChanged = false;
      this.sendFormFillGa4Event("I need help with","no");
    }
  }
  
  public onServiceChange(): void {
    if(this.sendFormStartEvent){
      this.sendFormStartEvent = false;
      this.sendFormStartGa4Event();
    }
    if(this.isServiceChanged){
      this.isServiceChanged = false;
      this.sendFormFillGa4Event("Which service can we help you with?","no");
    }
    if (
      this.helpDeskForm.controls['servicesInterested']?.value ===
          'BHL_MyBHSupport_MBD' ||
        this.helpDeskForm.controls['servicesInterested']?.value ===
          'BHL_MyBHSupport_FIC'
    ) {
      this.store.dispatch(GlobalActions.loadState());
      this.isFicMbdVersion = true;
      this.helpDeskForm.controls['fcEmployer']!.clearValidators();
      this.helpDeskForm.controls['fcEmployer']!.updateValueAndValidity();
      this.helpDeskForm.controls['centerName']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['city']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['state']!.setValidators([ Validators.required ]);
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    } else {
      this.isFicMbdVersion = false;
      this.store.dispatch(GlobalActions.clearState());
      this.helpDeskForm.controls['fcEmployer']!.setValidators([
        Validators.required,
      ]);
      this.helpDeskForm.controls['centerName']!.clearValidators();
      this.helpDeskForm.controls['city']!.clearValidators();
      this.helpDeskForm.controls['state']!.clearValidators();
      this.helpDeskForm.controls['centerName']!.updateValueAndValidity();
      this.helpDeskForm.controls['city']!.updateValueAndValidity();
      this.helpDeskForm.controls['state']!.updateValueAndValidity();
    }
    this.helpDeskForm.updateValueAndValidity();
  }

  public onFilechange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (
      target.files![0]!.size < 2097152 &&
        (target.files![0]!.type === 'application/pdf' ||
          target.files![0]!.type === 'image/png' ||
          target.files![0]!.type === 'image/jpg' ||
          target.files![0]!.type === 'image/jpeg' ||
          target.files![0]!.type === 'image/bmp' ||
          target.files![0]!.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          target.files![0]!.type === 'text/plain' ||
          target.files![0]!.type === 'image/gif')
    ) {
      this.file = target.files![0] as File;
    } else if (target.files![0]!.size > 2097152) {
      this.showValidationOnFileChange(
        'Attachment exceeds max size. Please try again.',
      );

      // this.helpDeskForm.get('attachmentFile')!.setValue('');
    } else {
      this.showValidationOnFileChange(
        'Attachment must be a .bmp, .gif, .jpeg, .jpg, .png, .doc, .docx, .txt, or .pdf.',
      );
    }
  }

  private showValidationOnFileChange(message: string): void {
    this.file = null;
    this.contentService.showErrorToast(message);

    // this.helpDeskForm.get('attachmentFile')!.setValue('');
  }

  public onSend(): void {
    // debugger;
    this.showHelpDeskTextAreaError();
    if (this.helpDeskForm.invalid) {
      this.helpDeskForm.markAllAsTouched();
    } else if (this.isRecaptchaEnabled) {
      this.store.dispatch(ConfigActions.loading({ loading: true }));
      this.captchaRef?.execute();
    } else {
      this.resolved();
    }
  }

  public resolved(): void {
    // debugger;
    this.sharedService.GA4pushEvent('form_submit_success', {
      // eslint-disable-next-line camelcase
      form_name: "Support"
    });

    this.store.dispatch(
      GlobalActions.helpDeskSupport({
        payload: {
          NeedHelp: 'Manual verification request',
          Employer: this.selectedEmployer?.clientName ?? this.fcEmployer.value,
          ClientGuid: this.selectedEmployer?.clientGuid ?? '',
          BHService: this.servicesInterested.value,
          ServiceInterest: '',
          FirstName: this.firstName.value.trim(),
          LastName: this.lastName.value.trim(),
          Email: this.email.value,
          Phone: this.phone.value,
          CenterName: this.centerName.value,
          City: this.city.value,
          State: this.state.value,
          Message: this.helpDesk.value,
          FileAttachments: this.file,
          EmployeeID: this.employeeId.value,
          userInfoID: this.userInfoId,
          SourceURL: this.sourceURL,
          CurrentURL: this.doc.location.href,
          OptForSMS: this.helpDeskForm.get('OptForSMS')!.value as boolean,
          IsNewHelpForm: true,
        },
      }),
    );
  }

  public onError(event: RecaptchaErrorParameters): void {
    this.store.dispatch(ConfigActions.loading({ loading: false }));
    this.contentService.showErrorToast();

    // this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.SIGNUP_TECHNICAL_ERROR_FE);
    this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.RECAPTCHA, undefined, RecaptchaSubCategory.RECAPTCHA_FAILURE);
  }

  public displayFn(emp: IEmployer): string {
    return emp.clientName;
  }

  public trackByEmployerFn(index: number, emp: IEmployer): number {
    return emp.clientId;
  }

  public trackByFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public trackByServicesFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public trackByStateFn(
    index: number,
    option: IMasterListStatus,
  ): string | undefined {
    return option.objId;
  }

  public onRemoveFile(): void {
    this.file = null;

    // this.helpDeskForm.get('attachmentFile')!.setValue(null);
  }

  private getPersonalInfoData(): void {
    this.store.select(selectPersonInfoState)
      .pipe(
        take(1),
      )
      .subscribe((state: IPersonInfoState): void => {
        if (state.loaded) {
          const formData: IPersonInfo | null = state.data;
          this.helpDeskForm.get('firstName')?.setValue(formData?.firstName);
          this.helpDeskForm.get('lastName')?.setValue(formData?.lastName);
          this.helpDeskForm.get('email')?.setValue(formData?.primaryEmail);
          const selectedEmployer: IEmployer = {
            clientGuid: formData?.crmClientId ?? '',
            clientName: formData?.clientName ?? '',
            clientId: 0,
          };
          this.setSelectedEmployer(selectedEmployer);
          this.helpDeskForm.get('fcEmployer')?.setValue(selectedEmployer);
          this.helpDeskForm.updateValueAndValidity();
        }
      });
  }

  public sendFormStartGa4Event(): void{
    this.sharedService.GA4pushEvent('form_start', {
      // eslint-disable-next-line camelcase
      form_name: "Support", //value changed
    });
  }

  public sendFormFillGa4Event(fieldName: string, autoFilled: string): void{
    this.sharedService.GA4pushEvent('form_fill', {
      // eslint-disable-next-line camelcase
      form_name: "Support", // Name of Form
      // eslint-disable-next-line camelcase
      field_name: fieldName, // Field Name user interacted
      autofilled: autoFilled // If the field was auto filled or not
    })
  }

  public onPrivacyClick() : void{
    this.sendOutboundGa4Event(privacyNotice.label, privacyNotice.url);
  }

  public sendOutboundGa4Event(label : string, url : string) : void{
    this.sharedService.GA4pushEvent('outbound_link_click', {
      /* eslint camelcase: ["error", {allow: ["link_text","link_url","link_section"]}]*/
      link_text: label, //Text on link clicked
      link_url: url,// Link click for outbound links
      link_section: "body" // Section of DOM where the outbound link is clicked
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

