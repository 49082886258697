/* eslint-disable @angular-eslint/use-injectable-provided-in,arrow-body-style */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, concatMap, forkJoin, map, of } from 'rxjs';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';

import { applicationName, isMcDonaldsFranchise } from '../../constants';
import {
  BhQueryParams,
  EmailContactType,
  EventName,
  FlowType,
  HttpError,
  IApiResponse,
  IApiValidationError,
  IAppJwt,
  IBenefitDetails,
  IBenefitLogo,
  IBingMapsLocationApiResponse,
  IClientInfo,
  ICreateRegistrationPayload,
  ICreateRegistrationStatus,
  ICreateUNPwdPayload,
  ICreateUNPwdStatus,
  IEligibilityFromMagicLinkStatus,
  IEligibilityPayload,
  IEligibilityStatus,
  IEmailActivity,
  IEmailActivityState,
  IEmployeeDetailsState,
  IEmployer,
  IFetchEligibilityOsrDetailsStatus,
  IFetchFederatedDetailsPayload,
  IFetchFederatedDetailsStatus,
  IFetchRegistrationByIdPayload,
  IFetchRegistrationByIdStatus,
  IFetchUserIdentityStatus,
  IFicClientState,
  IFranchiseAccountAnswer,
  IFranchiseAccountQuestion,
  IFranchiseLocation,
  IFranchiseUniqueValueRequestParams,
  IGeocodedLocation,
  IMagicLinkEmailPayload,
  IMfaVerificationPayload,
  IMfaVerificationStatus,
  IOsrNewEmailPayload,
  IPasscodePayload,
  IPersonInfo,
  IRegistrationStatus,
  IRetrieveFICDetailsStatus,
  ISaveEmployeeDetailsPayload,
  ISaveEmployeeDetailsStatus,
  ISaveMfaPayload,
  ISelectedEmployerState,
  ISendEmailVerifyLinkPayload,
  ISendMagicLinkStatus,
  ISendOTPpayload,
  IValidateOTPpayload,
  MobileContactType,
  RegistrationSubCategory,
  SourceOfRegistration,
  VerifyEmployerSubCategory,
  LocalStorageKey,
  IMFAOtpValidationStatus,
  IAccountLockPayload,
  IEmailVerify,
} from '../../models';
import { getArrayFromObject } from '../../utils';
import { ApiAuthService } from '../auth/api-auth.service';
import { selectApiToken, selectBenefitId, selectBhPropertiesURL, selectFlowType, selectJsonDfp } from '../config/config.reducers';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { MapService } from './map.service';
import { SignupActions } from './signup.actions';
import {
  selectEmailActivityState,
  selectEmployeeDetails,
  selectFicClientDetails,
  selectFranchiseLocations,
  selectFranchiseRegistrationState,
  selectSelectedEmployer,
} from './signup.reducers';
import {
  selectEligibilityFromMagicLinkStatus,
  selectEligibilityStatus,
  selectFetchEligibilityOsrDetailsStatus,
  selectFetchFederatedDetailsStatus,
  selectFICEmailDetailsStatus,
  selectFranchiseAccountAnswersData,
  selectPersonInfoStatus,
  selectRegistrationStatus,
  selectSelectedFranchiseLocation,
} from './signup.selectors';
import { SignupService } from './signup.service';

@Injectable()
export class SignupEffects {
  public getRegistrationInfo = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.registration),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(
        ([, employer, bhProperties]: [
          TypedAction<'[Sign Up] Registration'>,
          ISelectedEmployerState | null,
          BhQueryParams,
        ]) => {
          const encodedInput: string = encodeURIComponent(
            employer?.clientGuid ?? bhProperties.clientguid ?? 'unknown',
          );

          return this.signupService.getRegistrationInfo(encodedInput).pipe(
            map((response: IApiResponse<IRegistrationStatus>) =>
              SignupActions.registrationSuccess({
                registrationStatus: response.data,
              }),
            ),
            catchError((error: Error | HttpErrorResponse) =>
              of(
                SignupActions.registrationFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
        },
      ),
    );
  });

  public loadEmployers = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.loadEmployers),
      concatMap(({ input }: { input: string }) => {
        return this.signupService.getEmployersList(input).pipe(
          map((response: IApiResponse<Array<IEmployer>>) =>
            SignupActions.loadEmployersSuccess({ list: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.loadEmployersFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public getValidatepostalcode = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getValidatepostalcode),
      concatMap(({ postalcode, country }: { postalcode: string, country: string }) => {
        const encodePostalCode: string = encodeURIComponent(postalcode);

        const encodedCountry: string = encodeURIComponent(country);

        return this.signupService.getValidatePostalCode(encodePostalCode, encodedCountry).pipe(
          map((response: IApiResponse<boolean>) =>
            SignupActions.getValidatepostalcodeSuccess({ zipCode: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.getValidatepostalcodeFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public verifyEligibility = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.eligibility),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectRegistrationStatus),
        this.store.select(selectSelectedEmployer),
        this.store.select(selectEmployeeDetails),
        this.store.select(selectFlowType),
      ]),
      concatMap(
        ([value, benefitId, registration, selectedEmployer, employer, flowType]: [
          { input: string },
          IBenefitLogo,
          IRegistrationStatus | null,
          ISelectedEmployerState | null,
          IEmployeeDetailsState | null,
          FlowType,
        ]) => {
          const payload: IEligibilityPayload = {
            benefitId: benefitId.id,
            crmClientID: selectedEmployer?.clientGuid,
            eligibilityUniqueValue: value.input,
            uniqueIdColumn:
              registration === null ? employer?.eligibilityField : registration.eligibilityField,
          };

          // The response will be encrypted for this endpoint, hence, the return type is `string`,
          // and the effect has the responsibility to decrypt it before storing in the state
          return this.signupService.verifyEligibility(payload).pipe(
            map((response: IApiResponse<string>) =>
              SignupActions.eligibilitySuccess({
                eligibilityStatus: this.authService.decryptResponse<IEligibilityStatus>(response.data) as IEligibilityStatus,
              }),
            ),
            catchError((error: Error | HttpError) => {
              if (!(error instanceof Error) && error.status === 404) {
                error.eventType = flowType === 'login' ? EventName.LOGIN : EventName.REGISTRATION;
                error.eventCategory = RegistrationSubCategory.ELIGIBILITY_MISMATCH_ERROR;
                error.isLogNeed = true;
              }

              return of(
                SignupActions.eligibilityFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              );
            }),
          );
        },
      ),
    );
  });

  public fetchFederatedDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.fetchFederatedDetails),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectRegistrationStatus),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([, benefitId, registration, bhProperties]:
        [TypedAction<'[Sign Up] Fetch Federated Details'>,
          IBenefitLogo,
          IRegistrationStatus | null,
          BhQueryParams,
        ]) => {
        const payload: IFetchFederatedDetailsPayload = {
          benefitId: benefitId.id,
          crmClientID: bhProperties.clientguid as string,
          uniqueId: bhProperties.uniqueid as string,
          uniqueIdColumn: (registration as IRegistrationStatus)
            .eligibilityField,
        };

        return this.signupService.fetchFederatedDetails(payload).pipe(
          map((response: IApiResponse<IFetchFederatedDetailsStatus>) =>
            SignupActions.fetchFederatedDetailsSuccess({ fetchFederatedDetailsStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.fetchFederatedDetailsFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public sendMagicLinkEmail = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.sendmagiclink),
      concatLatestFrom(() => [
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectEligibilityStatus),
        this.store.select(selectSelectedEmployer),
        this.store.select(selectEmployeeDetails),
        this.store.select(selectSelectedFranchiseLocation),
        this.store.select(selectEligibilityFromMagicLinkStatus),
      ]),
      concatMap(([
        ,
        benefitId,
        bhProperties,
        eligibility,
        employer,
        employeeDetails,
        selectedLocation,
        magicLinkInfo,
      ]:
        [
          TypedAction<'[Sign Up] SendMagicLink'>,
          IBenefitLogo,
          BhQueryParams,
          IEligibilityStatus | null,
          ISelectedEmployerState | null,
          IEmployeeDetailsState | null,
          IFranchiseLocation | null,
          IEligibilityFromMagicLinkStatus | null,
        ],
      ) => {
        const payload: IMagicLinkEmailPayload = {
          eligibilityId: eligibility?.eligibilityID ?? magicLinkInfo?.eligibilityId ?? 0,
          firstName: employeeDetails?.firstName ?? eligibility?.firstName ?? magicLinkInfo?.firstName,
          lastName: employeeDetails?.lastname ?? eligibility?.lastName ?? magicLinkInfo?.lastName,
          email: (employeeDetails?.IsAlternateEmailPrefrred ? employeeDetails.alternateEmail : employeeDetails?.email) ?? eligibility?.primaryEmail,
          benefitId: benefitId.id,
          ...bhProperties.fstargetid && { fsTargetId: bhProperties.fstargetid },
          ...bhProperties.redirecturl && { redirectURL: bhProperties.redirecturl },
          clientGuid: employer?.clientGuid,
          UniqueValue: employeeDetails?.uniqueId ?? employeeDetails?.email,
          isAlternateEmailDuplicate: eligibility?.isAlternateEmailDuplicate ?? false,
        };

        // If franchise, set NSN
        if (selectedLocation) {
          // NSN will be on selectedLocation if we are sending the link for the first time
          payload.nsn = selectedLocation.nsn;
        } else if (bhProperties.nsn) {
          // NSN will be on bhProperties if we are re-sending the link
          payload.nsn = bhProperties.nsn;
        }

        return this.signupService.sendEmailVerify(payload).pipe(
          map((response: IApiResponse<ISendMagicLinkStatus>) =>
            SignupActions.sendmagiclinkSuccess({
              magicLinkEmailStatus: response.data,
            }),
          ),
          catchError((error: Error | HttpErrorResponse) =>
            of(
              SignupActions.sendmagiclinkFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      },
      ),
    );
  });

  public retrieveEligbilityFromMagicLink = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.eligibilityFromMagiclink),
      concatLatestFrom(() => [this.store.select(selectBhPropertiesURL)]),
      concatMap(
        ([, bhProperties]: [
          TypedAction<'[Sign Up] Eligibility From MagicLink'>,
          BhQueryParams,
        ]) => {
          const encodedInput: string = encodeURIComponent(
            (bhProperties.requestid ?? this.localStorageService.getItem(LocalStorageKey.requestId)) ?? 'unknown',
          );

          return this.signupService
            .retrieveEligibilityFromMagicLink(encodedInput)
            .pipe(
              map((response: IApiResponse<IEligibilityFromMagicLinkStatus>) =>
                SignupActions.eligibilityfrommagiclinkSuccess({
                  eligibilityFromMagicLinkStatus: response.data,
                }),
              ),
              catchError((error: Error | HttpErrorResponse) =>
                of(
                  SignupActions.eligibilityfrommagiclinkFailure({
                    error: this.errorService.extractErrorMsg(error),
                  }),
                ),
              ),
            );
        },
      ),
    );
  });

  public getBenefitsDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getBenefit),
      concatLatestFrom(() => [
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectBenefitId),
      ]),
      concatMap(
        ([, bhProperties, benefitId]: [
          TypedAction<'[Sign Up] Get Benefit'>,
          BhQueryParams,
          IBenefitLogo,
        ]) => {
          const encodedGuid: string = encodeURIComponent(
            bhProperties.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string,
          );
          const encodedBenefitId: string = encodeURIComponent(benefitId.id);

          return this.signupService
            .getBenefitDetails(encodedGuid, encodedBenefitId)
            .pipe(
              map((response: IApiResponse<IBenefitDetails>) =>
                SignupActions.getBenefitSuccess({ info: response.data }),
              ),
              catchError((error: Error | HttpError) => {
                if (!(error instanceof Error) && error.status === 404) {
                  error.eventType = EventName.REGISTRATION;
                  error.eventCategory = RegistrationSubCategory.BENEFIT_NOT_SUPPORTED;
                  error.isLogNeed = true;
                }

                return of(
                  SignupActions.getBenefitFailure({
                    error: this.errorService.extractErrorMsg(error),
                  }),
                );
              }),
            );
        },
      ),
    );
  });

  public createRegistration = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.createRegistration),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectEligibilityFromMagicLinkStatus),
        this.store.select(selectFranchiseAccountAnswersData),
        this.store.select(selectFetchEligibilityOsrDetailsStatus),
        this.store.select(selectFICEmailDetailsStatus),
        this.store.select(selectFetchFederatedDetailsStatus),
      ]),
      concatMap(([, empDetails, benefitId, bhProperties, eligibilityFromMagicLink, accountAnswers, osrDetails, ficEmailDetails, federatedDetails]:
        [
          TypedAction<'[Sign Up] Create Registration'>,
          IEmployeeDetailsState | null,
          IBenefitLogo, BhQueryParams,
          IEligibilityFromMagicLinkStatus | null,
          Record<string, IFranchiseAccountAnswer> | null,
          IFetchEligibilityOsrDetailsStatus | null,
          IRetrieveFICDetailsStatus | null,
          IFetchFederatedDetailsStatus | null,
        ]) => {
        let payload: ICreateRegistrationPayload = {
          username: empDetails?.userName,
          password: empDetails?.password,
          acceptableUse: true,
          benefitId: benefitId.id,
          ...bhProperties.fstargetid && { fsTargetId: bhProperties.fstargetid },
          userContextInfo: { name: empDetails?.userName, application: applicationName },
          agreementAcceptedDate: new Date().toISOString(),
          nsn: bhProperties.nsn ?? '',
          isEmailVerified: true,
        };
        if (osrDetails) { // OSR Registration payload
          payload = {
            ...payload,
            firstName: osrDetails.firstName,
            lastName: osrDetails.lastName,
            workEmail: osrDetails.workEmail,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            clientGuid: osrDetails.clientGuid ?? bhProperties.clientguid as string,
            country: osrDetails.country,
            postalCode: osrDetails.postalCode,
            eligibilityUniqueValue: osrDetails.eligibilityUniqueValue ?? '',
            sourceOfRegistration: SourceOfRegistration.OptInRegistration,
            eligibilityId: osrDetails.eligibilityId,
          };
        } else if (ficEmailDetails) { // Temporary registration flow with fic
          payload = {
            ...payload,
            firstName: ficEmailDetails.registrationData?.firstName,
            lastName: ficEmailDetails.registrationData?.lastName,
            workEmail: ficEmailDetails.registrationData?.workEmail,
            clientGuid:
              ficEmailDetails.registrationData?.clientGuid ??
              (bhProperties.clientguid as string),
            country: ficEmailDetails.registrationData?.country,
            postalCode: ficEmailDetails.registrationData?.postalCode,
            eligibilityUniqueValue:
              ficEmailDetails.registrationData?.eligibilityUniqueValue ?? '',
            sourceOfRegistration:
              empDetails?.sourceOfRegistration ??
              SourceOfRegistration.BHLoginNonFederatedRegistration,
            eligibilityId: eligibilityFromMagicLink?.eligibilityId,
            isNotEmployed: ficEmailDetails.registrationData?.isNotEmployed,
            nonClientEmployerName: ficEmailDetails.registrationData?.nonClientEmployerName,
          };
        } else if (federatedDetails) { // Federated flow
          payload = {
            firstName: empDetails?.firstName,
            lastName: empDetails?.lastname,
            workEmail: empDetails?.email,
            benefitId: benefitId.id,
            clientGuid: bhProperties.clientguid as string,
            ...bhProperties.fstargetid && { fsTargetId: bhProperties.fstargetid },
            country: empDetails?.country,
            postalCode: empDetails?.zipCode,
            eligibilityUniqueValue: federatedDetails.uniqueId ?? '',
            sourceOfRegistration: SourceOfRegistration.BHLoginFederatedRegistration,
            eligibilityId: Number(federatedDetails.eligibilityID),
            acceptableUse: true,
            userContextInfo: { name: empDetails?.firstName, application: applicationName },
            agreementAcceptedDate: new Date().toISOString(),
            nsn: '',
            isOsrSSO: empDetails?.isOsrSSO,
          };
        } else { // Normal Registration flow
          payload = {
            ...payload,
            firstName: empDetails?.firstName,
            lastName: empDetails?.lastname,
            workEmail: eligibilityFromMagicLink?.workEmail,
            alternateEmail: eligibilityFromMagicLink?.alternateEmail,
            isAlternateEmailpreferred: eligibilityFromMagicLink?.isAlternateEmailPreferred,
            isAlternateEmailDuplicate: bhProperties.altemailduplicate ? bhProperties.altemailduplicate.toLowerCase() === 'true' : false,
            clientGuid: eligibilityFromMagicLink?.clientGuid ?? bhProperties.clientguid as string,
            country: empDetails?.country,
            postalCode: empDetails?.zipCode,
            eligibilityUniqueValue: eligibilityFromMagicLink?.eligibilityUniqueValue ?? '',
            sourceOfRegistration: empDetails?.sourceOfRegistration ?? SourceOfRegistration.BHLoginNonFederatedRegistration,
            eligibilityId: eligibilityFromMagicLink?.eligibilityId,
          };
        }

        // If franchise flow AND account questions exist, append accountQuestions to payload
        if (accountAnswers) {
          payload.accountQuestions = Object.values(accountAnswers);
          if (!isMcDonaldsFranchise(eligibilityFromMagicLink?.clientGuid as string)) {
            // If franchise and NOT McDonald's, set eligibilityUniqueValue to email address
            payload.eligibilityUniqueValue = eligibilityFromMagicLink?.workEmail;
          }
        }

        return this.signupService.createRegistration(payload).pipe(
          map((response: IApiResponse<ICreateRegistrationStatus>) =>
            SignupActions.createRegistrationSuccess({ createRegistrationStatus: response.data })),
          catchError((error: Error | HttpError) => {
            const isError = error instanceof Error;

            if (!isError && error.status === 400) {
              for (const err of error.error?.errors as Array<IApiValidationError>) {
                if (err.propertyName.toLowerCase() === 'username') {
                  error.eventType = EventName.REGISTRATION;
                  error.eventCategory = RegistrationSubCategory.ALREADY_REGISTERED;
                  error.isLogNeed = true;
                  break;
                }
              }
            }

            return of(SignupActions.createRegistrationFailure({ error: this.errorService.extractErrorMsg(error) }));
          }),
        );
      }),
    );
  });

  public fetchRegistrationById = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.fetchregistrationbyid),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
        this.store.select(selectEmployeeDetails),
      ]),
      concatMap(([id, employer, employeeDetails]: [{ id: string }, ISelectedEmployerState | null, IEmployeeDetailsState | null]) => {
        const payload: IFetchRegistrationByIdPayload = {
          clientGuid: (employer as ISelectedEmployerState).clientGuid,
          eligibilityUniqueValue: id.id,
          WorkEmail: employeeDetails?.email ?? id.id,
        };

        return this.signupService.fetchRegistrationByID(payload).pipe(
          map((response: IApiResponse<IFetchRegistrationByIdStatus>) =>
            SignupActions.fetchregistrationbyidSuccess({ fetchRegistrationByIdStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.fetchregistrationbyidFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public fetchRegistrationByalternateId = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.fetchregistrationbyalternateid),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
      ]),
      concatMap(([id, employer]: [{ id: string }, ISelectedEmployerState | null]) => {
        const payload: IFetchRegistrationByIdPayload = {
          clientGuid: (employer as ISelectedEmployerState).clientGuid,
          eligibilityUniqueValue: id.id,
          WorkEmail: id.id,
        };

        return this.signupService.fetchRegistrationByID(payload).pipe(
          map((response: IApiResponse<IFetchRegistrationByIdStatus>) =>
            SignupActions.fetchregistrationbyalternateidSuccess({ fetchRegistrationByIdStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.fetchregistrationbyalternateidFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public fetchUserById = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.fetchuserbyid),
      concatMap(({ id, clientguid }: { id: string, clientguid: string }) => {
        return this.signupService.fetchUserByID(id, clientguid).pipe(
          map((response: IApiResponse<IFetchUserIdentityStatus>) =>
            SignupActions.fetchuserbyidSuccess({ fetchUserByIdStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.fetchuserbyidFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public getClientBenefitDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getClientBenefits),
      concatMap(({ clientguid }: { clientguid: string }) => {
        return this.signupService.getClientBenefitDetails(clientguid).pipe(
          map((response: IApiResponse<Array<IBenefitDetails>>) =>
            SignupActions.getClientBenefitsSuccess({ list: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.getClientBenefitsFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });


  public loadCountries = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.loadCountries),
      concatMap(() => {
        return this.signupService.getCountriesList().pipe(
          map((response: IApiResponse<object>) =>
            SignupActions.loadCountriesSuccess({ list: getArrayFromObject(response.data) }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.loadCountriesFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public loadCountryCodes = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.loadCountryCodes),
      concatMap(() => {
        return this.signupService.getCountriesCodeList().pipe(
          map((response: IApiResponse<object>) =>
            SignupActions.loadCountryCodesSuccess({ list: getArrayFromObject(response.data) }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.loadCountryCodesFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public verifyPasscode = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.verifyPasscode),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
      ]),
      concatMap(([params, employer]: [{ passcode: string; email: string }, ISelectedEmployerState | null]) => {
        const payload: IPasscodePayload = {
          clientGuid: (employer as ISelectedEmployerState).clientGuid,
          passcode: params.passcode,
        };
        const emailPayload: IEmailVerify ={
          email: params.email as string,
        }
        return forkJoin({
          passcode: this.signupService.verifyPasscode(payload),
          email: this.signupService.verifyEmailExists(emailPayload),
        }).pipe(
          map((response: { passcode: IApiResponse<boolean>; email: IApiResponse<boolean> }) => SignupActions.verifyPasscodeSuccess({
            isPasscodeValid: response.passcode.data,
            isEmailValid: response.email.data,
          })),
          catchError((error: Error | HttpError) => {
            if (!(error instanceof Error) && error.status === 500) {
              error.eventType = EventName.VERIFYEMPLOYER_FAILED;
              error.eventCategory = VerifyEmployerSubCategory.BHLOGIN_TECHNICAL_ERROR;
              error.isLogNeed = true;
            } else if (!(error instanceof Error) && error.status === 400) {
              error.eventType = EventName.VERIFYEMPLOYER_FAILED;
              error.eventCategory = VerifyEmployerSubCategory.API_VALIDATION_ERROR;
              error.isLogNeed = true;
            }

            return of(SignupActions.verifyPasscodeFailure({ error: this.errorService.extractErrorMsg(error) }));
          }),
        );
      }),
    );
  });

  public verifyEmail = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.verifyEmail),
      concatMap(({ email }: { email: string }) => {
        const emailPayload: IEmailVerify ={
          email: email as string,
        }
        return this.signupService.verifyEmailExists(emailPayload).pipe(
          map((response: IApiResponse<boolean>) => SignupActions.verifyEmailSuccess({
            verifyEmailStatus: response.data,
          })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.verifyEmailFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public mfaSignup = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.mfaVerification),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectApiToken),
        this.store.select(selectJsonDfp),
      ]),
      concatMap(([data, empDetails, token, jsonDfp]:
        [{ payload: IMfaVerificationPayload }, IEmployeeDetailsState | null, IAppJwt | null, string | null]) => {
        const mfaVerificationPayload: IMfaVerificationPayload = {
          userInfoId: token?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string,
          jsonDfp: jsonDfp as string,
          userName: data.payload.userName ?? empDetails?.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string,
          modeType: data.payload.modeType,
        };

        return this.signupService.mfaVerification(mfaVerificationPayload).pipe(
          map((response: IApiResponse<IMfaVerificationStatus>) =>
            SignupActions.mfaVerificationSuccess({ mfaVerificationStatus: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.mfaVerificationFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public sendOTP = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.sendOtp),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBenefitId),
      ]),
      concatMap(([, empDetails, benefitId]:
        [TypedAction<'[Sign Up] Send OTP'>, IEmployeeDetailsState | null, IBenefitLogo]) => {
        const contactType = empDetails?.defaultContact?.includes('@') ? EmailContactType : MobileContactType;
        const payload: ISendOTPpayload = {
          type: contactType,
          userName: empDetails?.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string,
          // eslint-disable-next-line max-len
          userInfoId: (empDetails?.mfaContact && empDetails?.mfaContact?.length ? empDetails.mfaContact[0]?.userInfoId : empDetails?.userinfoId) as string,
          clientGuid: empDetails?.clientGuid as string,
          benefitId: benefitId?.id,
          defaultContact: empDetails?.defaultContact,
        };

        return this.signupService.mfaSendOTP(payload).pipe(
          map((response: IApiResponse<boolean>) =>
            SignupActions.sendOtpSuccess({ sendOTPStatus: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.sendOtpFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public verifyOTP = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.verifyOtp),
      concatLatestFrom(() => [
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectEmployeeDetails),
        this.store.select(selectApiToken),
        this.store.select(selectJsonDfp),
      ]),
      concatMap(([data, params, empDetails, token, jsonDfp]:
        [{ payload: IValidateOTPpayload }, BhQueryParams, IEmployeeDetailsState | null, IAppJwt | null, string | null]) => {
        const validateOtpPayload: IValidateOTPpayload = {
          // eslint-disable-next-line max-len
          userInfoId: token?.userInfoId ?? params.userinfoid ?? empDetails?.userinfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string,
          userName: empDetails?.userName ?? this.localStorageService.getItem(LocalStorageKey.uname) as string,
          otp: data.payload.otp.trim(),
          jsonDfp: jsonDfp as string,
          isrememberDevice: data.payload.isrememberDevice,
        };

        return this.signupService.mfaValidateOTP(validateOtpPayload).pipe(
          map((response: IApiResponse<string>) => {
            const responseData = this.authService.decryptResponse<IMFAOtpValidationStatus>(response.data) as IMFAOtpValidationStatus;
            //const encryExpiryDate: Date = new Date(responseData.date);
             /* eslint-disable no-console */
            console.log(response);
            return SignupActions.verifyOtpSuccess({
              mfaValidateOTPStatus: responseData.isOtpValid,
              //mfaValidateOTPStatus: encryExpiryDate > new Date() ? responseData.isOtpValid : false,
            });
          }),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.verifyOtpFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public accountLock = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.accountLock),
      concatMap(({ userName, userInfoID, unlockCheck, isReset }: 
        { userName: string, userInfoID: string, unlockCheck: boolean, isReset: boolean }) => {
        const payload: IAccountLockPayload = {
          userName: userName,
          userInfoID: userInfoID,
          unlockCheck: unlockCheck,
          isReset: isReset,
        };
        return this.signupService.accountLock(payload)
          .pipe(
            map((response: IApiResponse<boolean>) =>
              SignupActions.accountLockSuccess({ accountLockStatus: response.data }),
            ),
            catchError((error: Error | HttpError) =>
              of(
                SignupActions.accountLockFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });


  public getFranchiseLocations = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getFranchiseLocations),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployer),
      ]),
      concatMap(([{ input, isEditFlow }, selectedEmployer]) => {
        const encodedGuid: string = encodeURIComponent(
          selectedEmployer?.clientGuid ?? 'unknown',
        );
        const encodedInput: string = encodeURIComponent(input); // eslint-disable-next-line @typescript-eslint/no-unsafe-argument

        return this.signupService.getFranchiseLocations(encodedInput, encodedGuid, isEditFlow).pipe(
          map((response: IApiResponse<Array<IFranchiseLocation>>) =>
            SignupActions.getFranchiseLocationsSuccess({ list: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.getFranchiseLocationsFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public getGeocodedLocations = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getGeocodedLocations),
      concatLatestFrom(() => this.store.select(selectFranchiseLocations)),
      concatMap(([{ sessionKey }, { list }]) => {
        if (list === null) {
          return of(
            SignupActions.getGeocodedLocationsFailure({
              error: 'Cannot get geocoded locations because franchise location list is null',
            }),
          );
        }

        // Get ALL locations in franchiseLocation list (must be requested from API one at a time)
        return forkJoin(list.map((location: IFranchiseLocation) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return this.mapService.getMapLocation(sessionKey, location).pipe(
            map((response: IBingMapsLocationApiResponse) => {
              return {
                franchiseLocation: location,
                pinLocation: {
                  latitude: response.resourceSets[0]?.resources[0]?.point?.coordinates[0] ?? 0,
                  longitude: response.resourceSets[0]?.resources[0]?.point?.coordinates[1] ?? 0,
                },
              };
            }),
            catchError(() =>

              // If geocoding request returned an error, return null to prevent it from blocking the entire list
              of(null),
            ),
          );
        }))
          .pipe(
            map((geocodedLocations: Array<IGeocodedLocation | null>) => {
              // Filter out null values before saving in store
              const filteredGeocodedLocations = geocodedLocations.filter((location) => location !== null) as Array<IGeocodedLocation>;

              return SignupActions.getGeocodedLocationsSuccess({ list: filteredGeocodedLocations });
            }),
            catchError((error: Error | HttpError) =>
              of(
                SignupActions.getGeocodedLocationsFailure({
                  error: this.errorService.extractErrorMsg(error),
                }),
              ),
            ),
          );
      }),
    );
  });

  public getFranchiseAccountQuestions = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getFranchiseAccountQuestions),
      concatLatestFrom(() => [
        this.store.select(selectEligibilityFromMagicLinkStatus),
        this.store.select(selectSelectedEmployer),
      ]),
      concatMap(([, magicLinkData, selectedEmployer]:
        [TypedAction<'[Sign Up] Get Franchise Account Questions'>, IEligibilityFromMagicLinkStatus | null, ISelectedEmployerState | null]) => {
        const clientId: number = magicLinkData?.clientId ?? selectedEmployer?.clientId ?? 0;

        return this.signupService.getFranchiseAccountQuestions(clientId).pipe(
          map((response: IApiResponse<Array<IFranchiseAccountQuestion>>) =>
            SignupActions.getFranchiseAccountQuestionsSuccess({ list: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.getFranchiseAccountQuestionsFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public getFranchiseUniqueValue = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getFranchiseUniqueValue),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectEligibilityFromMagicLinkStatus),
        this.store.select(selectFranchiseAccountAnswersData),
      ]),
      concatMap(([, employeeDetails, magicLinkData, accountAnswers]) => {
        const uniqueValuePayload: IFranchiseUniqueValueRequestParams = {
          firstName: employeeDetails?.firstName as string,
          lastName: employeeDetails?.lastname as string,
          country: employeeDetails?.country as string,
          postalCode: employeeDetails?.zipCode as string,
          clientGuid: magicLinkData?.clientGuid as string,
          eligibilityUniqueValue: magicLinkData?.eligibilityUniqueValue as string | null,
          sourceOfRegistration: SourceOfRegistration.BHLoginNonFederatedRegistration,
          accountQuestions: accountAnswers ? Object.values(accountAnswers) : null,
        };

        return this.signupService.getFranchiseEligibilityUniqueValue(uniqueValuePayload).pipe(
          map((response: IApiResponse<string>) => {
            const uniqueValue = response.data;

            return SignupActions.getFranchiseUniqueValueSuccess({ uniqueValue });
          }),
          catchError((error: Error | HttpError) =>
            of(SignupActions.getFranchiseUniqueValueFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public getFranchiseRegistrationStatus = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getFranchiseRegistrationStatus),
      concatLatestFrom(() => [
        this.store.select(selectFranchiseRegistrationState),
        this.store.select(selectEligibilityFromMagicLinkStatus),
      ]),
      concatMap(([, franchiseRegistrationState, magicLinkData]) => {
        const eligibilityUniqueValue = franchiseRegistrationState.uniqueValue ?? '';
        const clientGuid: string = magicLinkData?.clientGuid ?? '';

        return this.signupService.fetchRegistrationByID({ clientGuid, eligibilityUniqueValue }).pipe(
          map(() =>

            // On success from fetch registration, set alreadyRegistered to true
            SignupActions.getFranchiseRegistrationStatusSuccess({ alreadyRegistered: true }),
          ),
          catchError((error: Error | HttpError) =>
            of(SignupActions.getFranchiseRegistrationStatusFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public saveMfa = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.saveMfa),
      concatLatestFrom(() => [this.store.select(selectEmployeeDetails)]),
      concatMap(([, empDetails]: [TypedAction<'[Sign Up] Save MFA'>, IEmployeeDetailsState | null]) => {
        const payload: ISaveMfaPayload = {
          userMFAMobileEmail: empDetails?.mfaContact,
          isOTPEnabled: false,

        };

        return this.signupService.saveMfaMobileEmail(payload).pipe(
          map((response: IApiResponse<boolean>) =>
            SignupActions.saveMfaSuccess({ saveMfaStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.saveMfaFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public loadEmailActivity = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.loadEmailActivity),
      concatMap(({ input }: { input: string }) => {
        const encodedInput: string = encodeURIComponent(input);

        return this.signupService.getEmailActivity(encodedInput).pipe(
          map((response: IApiResponse<IEmailActivity>) =>
            SignupActions.loadEmailActivitySuccess({ data: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.loadEmailActivityFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public loadPersonInfo = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.loadPersonInfo),
      concatMap(({ input }: { input: string }) => {
        const encodedInput: string = encodeURIComponent(input);

        return this.signupService.getPersonInfo(encodedInput).pipe(
          map((response: IApiResponse<IPersonInfo>) =>
            SignupActions.loadPersonInfoSuccess({ data: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.loadPersonInfoFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public retrieveFICEmailDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.retrieveFicDetails),
      concatLatestFrom(() => [this.store.select(selectBhPropertiesURL)]),
      concatMap(([, bhProperties]: [TypedAction<'[Sign Up] Retrieve FIC Details'>, BhQueryParams]) => {
        const encodedInput: string = encodeURIComponent(
          bhProperties.requestid ?? 'unknown',
        );

        return this.signupService.getFICMagicLinkEmailDetails(encodedInput).pipe(
          map((response: IApiResponse<IRetrieveFICDetailsStatus>) =>
            SignupActions.retrieveFicDetailsSuccess({ retrieveFICDetailsStatus: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.retrieveFicDetailsFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public fetchEligbilityOsrDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.fetchEligbilityOsrDetails),
      concatLatestFrom(() => [this.store.select(selectBhPropertiesURL)]),
      concatMap(([, bhProperties]: [TypedAction<'[Sign Up] Fetch Eligbility Osr Details'>, BhQueryParams]) => {
        const encodedInput: string = encodeURIComponent(
          bhProperties.requestid ?? 'unknown',
        );

        return this.signupService.fetchEligbilityOsrDetails(encodedInput).pipe(
          map((response: IApiResponse<IFetchEligibilityOsrDetailsStatus>) =>
            SignupActions.fetchEligbilityOsrDetailsSuccess({ fetchRegistrationByIdStatus: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.fetchEligbilityOsrDetailsFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  // For temporary registration
  public createSendFICEmailVerifyLink = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.sendFicEmailVerifyLink),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBenefitId),
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectFicClientDetails),
      ]),
      concatMap(([, empDetails, benefitId, bhProperties, ficClientDetails]:
        [TypedAction<'[Sign Up] Send FIC Email Verify Link'>,
          IEmployeeDetailsState | null,
          IBenefitLogo, BhQueryParams,
          IFicClientState | null,
        ]) => {
        let nonClientEmployerName: string = '';
        if (ficClientDetails?.nonClientName) {
          nonClientEmployerName = ficClientDetails.nonClientName;
        } else {
          nonClientEmployerName = ficClientDetails?.nonClientEmployerName as string;
        }
        const payload: ISendEmailVerifyLinkPayload = {
          firstName: empDetails?.firstName,
          lastName: empDetails?.lastname,
          workEmail: empDetails?.email,
          benefitId: benefitId.id,
          agreementAcceptedDate: new Date().toISOString(),
          fsTargetId: bhProperties.fstargetid ?? this.localStorageService.getItem(LocalStorageKey.fstargetid) as string,
          redirectURL: bhProperties.redirecturl as string,
          clientGuid: empDetails?.clientGuid as string,
          country: empDetails?.country,
          postalCode: empDetails?.zipCode,
          eligibilityUniqueValue: ficClientDetails?.eligibilityUniqueValue ?? '',
          nonClientEmployerName,
          isNotEmployed: !ficClientDetails?.isEmployer,
        };

        return this.signupService.sendFICEmailVerifyLink(payload).pipe(
          map((response: IApiResponse<boolean>) =>
            SignupActions.sendFicEmailVerifyLinkSuccess({ ficEmailVerifyStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.sendFicEmailVerifyLinkFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public createUNPwd = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.createUnpwd),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBhPropertiesURL),
        this.store.select(selectEmailActivityState),
        this.store.select(selectPersonInfoStatus),
      ]),
      concatMap(([, empDetails, bhProperties, emailActivity, personInfo]:
        [TypedAction<'[Sign Up] Create UNPwd'>,
          IEmployeeDetailsState | null,
          BhQueryParams,
          IEmailActivityState | null,
          IPersonInfo | null,
        ]) => {
        const payload: ICreateUNPwdPayload = {
          userInfoId: bhProperties.userinfoid ?? emailActivity?.data?.userInfoId ?? personInfo?.userInfoID ?? bhProperties.requestid as string,
          username: empDetails?.userName as string,
          password: empDetails?.password as string,
          userContextInfo: {
            name: empDetails?.userName as string,
            application: applicationName,
          },
          agreementAcceptedDate: new Date().toISOString(),
        };

        return this.signupService.createUNPwd(payload).pipe(
          map((response: IApiResponse<ICreateUNPwdStatus>) =>
            SignupActions.createUnpwdSuccess({ createUNPwdStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.createUnpwdFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });

  public triggerNewOsrEmail = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.triggerNewOsrEmail),
      concatLatestFrom(() => [
        this.store.select(selectFetchEligibilityOsrDetailsStatus),
      ]),
      concatMap(([, osrDetails]: [TypedAction<'[Sign Up] Trigger New OSR Email'>, IFetchEligibilityOsrDetailsStatus | null]) => {
        const payload: IOsrNewEmailPayload = {
          firstName: osrDetails!.firstName,
          lastName: osrDetails!.lastName,
          email: osrDetails!.workEmail,
          eligibilityId: osrDetails!.eligibilityId,
          clientGuid: osrDetails!.clientGuid,
        };

        return this.signupService.triggerNewOsrEmail(payload)
          .pipe(
            map((response: IApiResponse<boolean>) => SignupActions.triggerNewOsrEmailSuccess({ data: response.data })),
            catchError((error: Error | HttpError) =>
              of(SignupActions.triggerNewOsrEmailFailure({ error: this.errorService.extractErrorMsg(error) })),
            ),
          );
      }),
    );
  });

  public getClientInfo = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.getClientInfo),
      concatMap(({ input }: { input: string }) => {
        return this.signupService.getClientInfo(input).pipe(
          map((response: IApiResponse<IClientInfo>) =>
            SignupActions.getClientInfoSuccess({ data: response.data }),
          ),
          catchError((error: Error | HttpError) =>
            of(
              SignupActions.getClientInfoFailure({
                error: this.errorService.extractErrorMsg(error),
              }),
            ),
          ),
        );
      }),
    );
  });

  public saveEmployeeDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(SignupActions.saveEmployeeDetails),
      concatLatestFrom(() => [
        this.store.select(selectEmployeeDetails),
        this.store.select(selectBhPropertiesURL),
      ]),
      concatMap(([, empDetails, params]:
        [TypedAction<'[Sign Up] Save Employee Details'>,
          IEmployeeDetailsState | null,
          BhQueryParams,
        ]) => {
        const payload: ISaveEmployeeDetailsPayload = {
          eligibilityUniqueValue: empDetails?.uniqueId ?? empDetails?.email as string,
          employeeSaUniqueId: params.userinfoid as string,
          lastName: empDetails?.lastname,
          userContextInfo: {
            name: empDetails?.lastname ?? empDetails?.email as string,
            application: applicationName,
          },
        };

        return this.signupService.saveEmployeeDetails(payload).pipe(
          map((response: IApiResponse<ISaveEmployeeDetailsStatus>) =>
            SignupActions.saveEmployeeDetailsSuccess({ saveEmployeeDetailsStatus: response.data })),
          catchError((error: Error | HttpErrorResponse) =>
            of(SignupActions.saveEmployeeDetailsFailure({ error: this.errorService.extractErrorMsg(error) })),
          ),
        );
      }),
    );
  });


  // eslint-disable-next-line max-params
  constructor(
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly signupService: SignupService,
    private readonly errorService: ErrorHandlerService,
    private readonly mapService: MapService,
    private readonly authService: ApiAuthService,
    private readonly localStorageService: LocalStorageService,
  ) { }
}
