import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { HttpError, ICredentials, IMFAUserEmailAndMobileStatus, IValidateUserPayload, IValidateUserStatus } from '../../models';

/**
 * The `credentials` (app global) action group.
 */
export const CredentialsActions = createActionGroup({
  source: 'Credentials',
  events: {
    'Change Password': emptyProps(),
    'Change Password Success': props<{ data: ICredentials }>(),
    'Change Password Failure': props<{ error: string | HttpError }>(),

    'Send Change Password Email': emptyProps(),
    'Send Change Password Email Success': props<{ data: boolean }>(),
    'Send Change Password Email Failure': props<{ error: string | HttpError }>(),

    'Validate User': props<{ payload: IValidateUserPayload }>(),
    'Validate User Success': props<{ validateUserStatus: IValidateUserStatus }>(),
    'Validate User Failure': props<{ error: string | HttpError }>(),
    'Clear Validate User': emptyProps(),

    'Reset Password': emptyProps(),
    'Reset Password Success': props<{ data: boolean }>(),
    'Reset Password Failure': props<{ error: string | HttpError }>(),

    'Forgot Password': props<{ input: string }>(),
    'Forgot Password Success': props<{ data: boolean }>(),
    'Forgot Password Failure': props<{ error: string | HttpError }>(),
    'Clear Forgot Password': emptyProps(),

    'Forgot Username': props<{ input: string }>(),
    'Forgot Username Success': props<{ data: boolean }>(),
    'Forgot Username Failure': props<{ error: string | HttpError }>(),
    'Clear Forgot Username': emptyProps(),

    'Get MFA User Info': props<{ input: string, isNonMfa: boolean }>(),
    'Get MFA User Info Success': props<{ mfaUserEmailAndMobileStatus: Array<IMFAUserEmailAndMobileStatus> }>(),
    'Get MFA User Info Failure': props<{ error: string | HttpError }>(),
    'Clear MFA User Info': emptyProps(),
  },
});
