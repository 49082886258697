import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationRef, ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { environment } from '../../../environments/environment';
import { errorPageRoute } from '../../constants';
import { EventName, FlowType, HttpError, IApiResponse } from '../../models';
import { ConfigActions } from '../config/config.actions';
import { selectFlowType } from '../config/config.reducers';
import { LoggerService } from '../logger-service/logger.service';
import { Router } from '@angular/router';

/**
 * Service to capture and handle any runtime exception or server error status codes
 * globally and allow the application to continue execution, as well as send the
 * error to the Logger Service.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  /**
   * Property to hold the right URL to the error page based on localhost vs web server.
   * @private
   */
  private readonly errorUrl: string = `${environment.baseHref}${errorPageRoute.join('/')}`;

  /**
   * The service `constructor`
   * @param {Injector} injector The Angular DI Injector service instance
   */
  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
  ) { }

  /**
   * Method to extract the client/browser's error description.
   * @param {Error} error The error object containing info about the error that occurred.
   * @returns {string} Return only the error's description.
   */
  public getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }

    return error.message;
  }

  /**
   * Method to extract the client/browser error stack trace.
   * @param {Error} error The error object containing info about the error that occurred.
   * @returns {string | null} Return only the error's stack trace if there is one.
   */
  public getClientStack(error: Error): string | null {
    if (error.stack !== undefined) {
      return error.stack;
    }

    return null;
  }

  /**
   * Method to extract the Angular Http client's error description.
   * @param {HttpError} error The error object containing info about the error that occurred.
   * @returns {string} Return only the error's description.
   */
  public getServerMessage(error: HttpError): string {
    return error.message;
  }

  /**
   * Method to extract the Angular Http client's error description.
   * @param {HttpError} error The error object containing info about the error that occurred.
   * @returns {string} Return only the error's status text.
   */
  public getServerStack(error: HttpError): string {
    return error.statusText;
  }

  /**
   * The ErrorHandler implementation method that handles the error handling logic based on the instanceof
   * the exception client/browser vs. http one.
   * @param {Error | HttpError} error The error object containing info about the error that occurred.
   */
  public handleError(error: Error | HttpError): void {
    const store = this.injector.get(Store);
    const loggerService: LoggerService = this.injector.get(LoggerService);
    let message: string;
    let stackTrace: string | null;
    let errorName: EventName;
    let flowType: FlowType = 'join';
    let errorSubcategory: string | undefined;
    let isLogNeed: boolean = false;
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    store
    .select(selectFlowType)
    .pipe(take(1))
    .subscribe((flow: FlowType): void => {
      flowType = flow;
    });

    if (chunkFailedMessage.test(error.message)) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if(flowType === 'join'){
        this.router.navigate([ '/signup', 'search' ]);
        return void 0;
      }
      else if(flowType === 'login'){
        this.router.navigate([ '/login' ]);
        return void 0;
      }
      else{
        window.location.reload();
      }
    }
    else{
    if (error.name === 'HttpErrorResponse') {
      message = this.getServerMessage(error as HttpError);
      stackTrace = this.getServerStack(error as HttpError);
      errorName = (error as HttpError).eventType;
      errorSubcategory = (error as HttpError).eventCategory;
      isLogNeed = (error as HttpError).isLogNeed as boolean;
    } else {
      message = this.getClientMessage(error);
      stackTrace = this.getClientStack(error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      errorName = flowType === 'login' ? EventName.LOGIN_TECHNICAL_ERROR_FE : EventName.SIGNUP_TECHNICAL_ERROR_FE;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isLogNeed !== undefined && isLogNeed) {
      loggerService.sendError(message, stackTrace, errorName, error, errorSubcategory);
    }

    if (environment.debugLogs) {
      console.error('An error occurred:', error);
    }

    // if a JS exception happens, redirect to the error page
    if (error instanceof Error) {
      const appRef: ApplicationRef = this.injector.get(ApplicationRef);

      /**
       * We need to detach the application to prevent a JS exception to force change detection on each throw,
       * which will result in an infinite loop and crash the app and report the same error to AppInsights
       * infinitely and thus, we need to do `document.location.href` rather than `router.navigate` because we
       * remove the app from the browser. An example of the issue can be seen in this
       * [GitHub issue](https://github.com/angular/angular/issues/4323) with repro steps and even though the
       * issue is closed, the issue is the present.
       */
      for (const comp of appRef.components) {
        appRef.detachView(comp.hostView);
      }

      document.location.href = this.errorUrl;
    } else {
      // hide any spinners that might be active
      store.dispatch(ConfigActions.loading({ loading: false }));
    }
  }
  }

  /**
   * Method to extract the error or return an object that is serializable, so
   * it can be properly handles by the NgRx store.
   * @param error The error received in the RxJS `catchError` pipe to be serialized.
   * @returns {string | HttpError } Returns a serialized object or a string,
   */
  public extractErrorMsg(error: Error | HttpError): string | HttpError {
    const err: Error | HttpError = error;
    const isError: boolean = err instanceof Error; // whether it is a JavaScript Error object

    if (!isError) {
      // report the error to the logging service(s)
      if ((err as HttpError).status === 400 || (err as HttpError).status === 404) {
        this.handleError(err);
      }
    }

    return isError ? err.message : err as HttpError;
  }

  /**
   * Method to properly parse the error response from HttpClient service
   * and serialize it, so it can be properly stored in the store.
   * @param {HttpErrorResponse} error The error thrown by the HttpClient.
   * @returns {HttpError} The serialized error object.
   */
  public parseHttpError(error: HttpErrorResponse): HttpError {
    const store = this.injector.get(Store);
    let flowType: FlowType = 'join';
    let errorType: EventName | undefined;

    store
      .select(selectFlowType)
      .pipe(take(1))
      .subscribe((flow: FlowType): void => {
        flowType = flow;
      });

    switch (error.status) {
      case 400:
        // When it is a 400, we default to FE failure, but each effect has the option to change this to
        // match the appropriate error event and the {@see extractErrorMsg} will pass the set message (either
        // this one or an updated value from the effect).
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        errorType = flowType === 'login' ? EventName.LOGIN_TECHNICAL_ERROR_FE : EventName.SIGNUP_TECHNICAL_ERROR_FE;
        break;
      case 404:
        // When it is a 404, we default to employer verification failure, but each effect has the option
        // to change this to match the appropriate error event and the {@see extractErrorMsg} will pass
        // the set message (either this one or an updated value from the effect).
        errorType = EventName.VERIFYEMPLOYER_FAILED;
        break;
      case 0:
        errorType = EventName.EVENT_CANCELLED_ERROR;
        break;
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        errorType = flowType === 'login' ? EventName.LOGIN_TECHNICAL_ERROR_BE : EventName.SIGNUP_TECHNICAL_ERROR_BE;
        break;
      default:
        errorType = EventName.LOB_TECHNICAL_ERROR;
    }

    const parsedError: HttpError = {
      name: error.name,
      message: error.message,
      status: error.status,
      statusText: error.statusText,
      url: error.url,
      eventType: errorType,
      error: error.status >= 400 && error.status < 500 ? error.error as IApiResponse<null> : null,
    };

    // report the error to the logging service(s) when it is not a 400 or 404. A 400/404 will be passed along
    // in the {@see extractErrorMsg} in case we want to pass a different event name for the 400/404 response
    if (parsedError.status !== 400 && parsedError.status !== 404) {
      this.handleError({
        ...parsedError,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        stack: error.error,
      });
    }

    return parsedError;
  }
}
