import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, Observable, take } from 'rxjs';

import { ConfigActions } from '../../../../core/config/config.actions';
import { selectEmployeeSearchPage, selectUserAlreadyRegisteredPage } from '../../../../core/content/content.reducers';
import {
  ContentPages,
  IContentState,
  IFetchRegistrationByIdStatus,
  LocalStorageKey,
} from '../../../../models';
import { selectFetchRegistrationByIdStatus } from '../../../../core/signup/signup.selectors';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-already-registered',
  templateUrl: './already-registered.component.html',
  styleUrls: [ './already-registered.component.scss' ],
})
export class AlreadyRegisteredComponent implements OnInit {
  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the page content.
   */
  public readonly content: Observable<IContentState[ContentPages.userAlreadyRegisteredPage]> = this.store.select(selectUserAlreadyRegisteredPage);

  public pageHeader: string | undefined;
  public acctExistContent: string;

  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
  ) {}
  public isGUID(value: string): boolean {
    const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return guidPattern.test(value);
  }
  public ngOnInit(): void {
    this.content
      .pipe(
        filter((content: IContentState[ContentPages.userAlreadyRegisteredPage]): boolean => content.loaded),
        combineLatestWith(this.store.select(selectFetchRegistrationByIdStatus)),
        take(1),
      )
      .subscribe(([ content, info ]: [IContentState[ContentPages.userAlreadyRegisteredPage], IFetchRegistrationByIdStatus | null]): void => {
        this.store.dispatch(ConfigActions.loading({ loading: false }));
        if (this.isGUID(info?.userName as string)) {
          const emailFromLocalStorage: string = this.localStorageService.getDecryptedItem(LocalStorageKey.email) as string;
          this.localStorageService.removeItem(LocalStorageKey.email);
          this.pageHeader = content.content.BHL_AcctExist_Header_SSO_OSR.replace('{{ emailAddress }}', emailFromLocalStorage);
          this.acctExistContent = content.content.BHL_AcctExist_Content_SSO_OSR;
        } else {
          this.acctExistContent = content.content.BHL_AcctExist_Content;
          this.pageHeader = content.content.BHL_AcctExist_Header.replace('{{ emailAddress }}', info?.userName as string);
        }
      });
  }
}
