import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatestWith, filter, map, Observable, take } from 'rxjs';

import { environment } from '../../../environments/environment';
import { snackBarDefaultConfig, warningToastDuration } from '../../constants';
import {
  BhQueryParams,
  Content,
  ContentPages,
  EventName,
  IApiResponse,
  IAppJwt,
  IContentState,
  ICreateAccountContent,
  ICreateRegistrationStatus,
  IPageContentPayload,
  LocalStorageKey,
  RegistrationSubCategory,
  SourceId,
} from '../../models';
import { TimeoutPopupComponent } from '../../shared/components/timeout-popup/timeout-popup.component';
import { selectCreateAccountPage } from './content.reducers';
import { OverlayPopupComponent } from 'src/app/shared/components/overlay-popup/overlay-popup.component';
import { selectApiToken, selectBhPropertiesURL } from '../config/config.reducers';
import { selectCreateRegistrationDetails } from '../signup/signup.selectors';
import { ConfigService } from '../config/config.service';
import { SharedService } from 'src/app/shared/shared.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private unknownErrorCopy: string = 'Something went wrong. Try again.';
  private readonly unknownErrorContent: Observable<IContentState[ContentPages.createAccountPage]> = this.store.select(selectCreateAccountPage);
  public onYesClick: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  // eslint-disable-next-line max-params
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly config: ConfigService,
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
  ) {
    // get the error messages content for the error toast
    this.unknownErrorContent
      .pipe(
        filter((content: IContentState[ContentPages.createAccountPage]): boolean => content.loaded),
        map((content: IContentState[ContentPages.createAccountPage]): ICreateAccountContent => content.content),
        take(1),
      )
      .subscribe((content: ICreateAccountContent): void => {
        this.unknownErrorCopy = content.BHL_AcctCrt2_reCAPTCHA_Error_Toast;
      });
  }

  public showErrorToast(message?: string): void {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.snackBar.open(message ?? this.unknownErrorCopy, 'Close', {
      ...snackBarDefaultConfig,
      panelClass: [ 'my-custom-snackbar', 'my-custom-snackbar_warning' ],
      duration: warningToastDuration,
    });
  }

  public openDialog(component: typeof TimeoutPopupComponent): void {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(component, {
      panelClass: 'timeout-popup-dialog',
      backdropClass: 'backdropbackground',
      disableClose: true,
      role: 'alertdialog',
      ariaLabelledBy: 'dialog-title',
      ariaDescribedBy: 'dialog-message',
      autoFocus: '#autofocus-target',
    });

    dialogRef.afterClosed();
  }

  public openOverlayDialog(component: typeof OverlayPopupComponent): Observable<any> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(component, {
      panelClass: 'timeout-popup-dialog',
      backdropClass: 'backdropbackground',
      disableClose: true,
      role: 'alertdialog',
      ariaLabelledBy: 'dialog-title',
      ariaDescribedBy: 'dialog-message',
      autoFocus: '#autofocus-target',
    });

    // dialogRef.afterClosed();
    dialogRef.afterOpened().subscribe((_) => {
      setTimeout(() => {
        this.store.select(selectApiToken)
          .pipe(
            combineLatestWith(
              this.store.select(selectCreateRegistrationDetails),
              this.store.select(selectBhPropertiesURL),
            ),
            take(1),

            // takeUntil(this.ngUnsubscribe),
          )
          .subscribe(([ apiToken, userInfo, params ]: [IAppJwt | null, ICreateRegistrationStatus | null, BhQueryParams]): void => {
            const sourceId: string | undefined = params.sourceid?.toLowerCase();
            const goToLogin: '' | null | undefined | boolean = sourceId && (sourceId !== SourceId['fic-registration']);
            const url: URL = this.config.buildExternalUrl(goToLogin ? undefined : environment.exploreBenefitsUrl);
            if (userInfo) {
              this.sharedService.loginlogFullStory(userInfo.workEmail,
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                userInfo.firstName as string + userInfo.lastName,
                userInfo.clientGuid, '', userInfo.country as string);
            }
            const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
            this.sharedService.TrackPendoEvent(EventName.REGISTRATION,
              benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_TIMEOUT);
            this.sharedService.exploreBenefits(url.toString(), apiToken?.userAccessToken ?? apiToken?.token as string, userInfo?.userInfoId ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string, '', '', 'false');
          });
        dialogRef.close();
      }, environment.OverlayPopupTimeout);
    });

    return dialogRef.afterClosed();
  }

  public getPageContent(payload: IPageContentPayload): Observable<Content> {
    return this.http.post<Content>(`${environment.apiVersionUri.v1}/Content/Pattern`, payload);
  }

  public getLogoUrl(clientGuid: string): Observable<IApiResponse<string | null>> {
    return this.http.get<IApiResponse<string | null>>(`${environment.apiVersionUri.v1}/Client/Logo/${clientGuid}`);
  }
}
