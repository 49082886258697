import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to mask a name/string.
 */
@Pipe({
  name: 'maskName',
})
export class MaskNamePipe implements PipeTransform {
  /**
   * Transform a name (or a string) to mask all characters except the first one
   * with an asterisk (*).
   * @param {string} value The name/string to mask.
   * @returns {string} The masked name/string.
   */
  public transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    const name: Array<string> = value.split('');
    const maskArr: Array<string> = [];
    const initial: number = 1;

    name.forEach((item: string, pos: number): void => {
      if (pos >= initial) {
        maskArr.push('*');
      } else {
        maskArr.push(item);
      }
    });

    return maskArr.join('');
  }
}
