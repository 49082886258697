import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

/**
 * Directive to prevent characters other than alphanumeric in an input field. The
 * allowed characters are `[^a-zA-Z0-9\-+ &,.]`.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[bh-numbers-only]',
})
export class NumberOnlyDirective {
  /**
   * The directive `constructor`
   *
   * @param {ElementRef} element An ElementRef to grab a reference of the input element
   * @param {Renderer2} renderer
   */
  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
  ) { }

  /**
   * A host listener for the input event (onchange) on an input field
   *
   * @param {InputEvent} event The InputEvent that was emitted by the input field
   */
  @HostListener('input', [ '$event' ]) public onInputChange(event: InputEvent): void {
    const inputElm: HTMLInputElement = this.renderer.selectRootElement(this.element.nativeElement, true) as HTMLInputElement;
    const initialValue: string = inputElm.value;

    this.renderer.setProperty(inputElm, 'value', initialValue.replace(/\D/g, ''));

    if (initialValue !== inputElm.value) {
      event.stopPropagation();
    }
  }
}
