import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, Observable, Subject, take, takeUntil } from 'rxjs';

import { helpDeskRoute } from '../../constants';
import { BhQueryParams, DisplayType, IBenefitLogo } from '../../models';
import { selectBenefitId, selectBhPropertiesURL, selectDisplayType } from '../config/config.reducers';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';

/**
 * The global header component, which should be used once within the application.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent {
  /**
   * An observable to get the benefit ID/LOB information set in the store.
   */
  public readonly benefitId: Observable<IBenefitLogo> = this.store.select(selectBenefitId);

  /**
   * An observable to update the page elements based on display mode we are in based
   * on the breaking point(s).
   */
  public readonly displayType: Observable<DisplayType> = this.store.select(selectDisplayType);

  /**
   * The help desk form route.
   */
  public readonly helpDeskRoute: typeof helpDeskRoute = helpDeskRoute;

  /**
   * The query params we need to pass to the help desk URL when we navigate because the help desk is opened in a new window/tab.
   */
  public queryParams: Record<string, string>;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  /**
   * Property to hide the help button when on the help page.
   */
  public displayHelpButton: boolean = true;
  public sourceURL: string = '';
  public removeQueryParam(url: string, paramKey: string): string {
    const parsedUrl = new URL(url);
    const params = parsedUrl.searchParams;

    // Iterate through all keys in the URLSearchParams
    for (const key of params.keys()) {
      // Check if the current key matches the one to be removed (case-insensitive)
      if (key.toLowerCase() === paramKey.toLowerCase()) {
        // Delete the key and its associated value
        params.delete(key);
        break; // Assuming you want to remove only the first occurrence of the parameter
      }
    }

    // Return the modified URL
    return parsedUrl.toString();
  }

  /**
   * The component `constructor`
   * @param {Store} store The NgRx store instance.
   * @param {Router} router The Angular Router instance.
   * @param {ChangeDetectorRef} cdr The Angular ChangeDetectorRef instance, so we can trigger change detection manually.
   */
  // eslint-disable-next-line max-params
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly locationStrategy: LocationStrategy,
    private readonly sharedService: SharedService
  ) {
    this.store.select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(
          this.store.select(selectBenefitId),
        ),
        take(1),
      )
      .subscribe(([ params, benefitInfo ]: [ object, IBenefitLogo ]): void => {
        this.queryParams = {
          ...benefitInfo.id !== '0' && { benefitId: benefitInfo.id },
          ...params, sourceurl: this.sourceURL,
        };
      });

    // listen for the router's `NavigationEnd` event and set the button visibility, we need to
    // do this only on page load (`take(1)`) because the help link always opens in a new window,
    // and we cannot navigate to any other area of the app from the help desk form.
    this.router.events
      .pipe(
        filter((event): boolean => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe((event): void => {
        this.displayHelpButton = !(event as NavigationEnd).urlAfterRedirects.startsWith(helpDeskRoute.join('/'));
        this.cdr.detectChanges();
      });
  }

  // Method to handle the click event on the anchor element
  public onHelpButtonClick(): void {
    this.store
      .select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(this.store.select(selectBenefitId)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([ params, benefit ]: [BhQueryParams, IBenefitLogo]): void => {
        this.sourceURL = this.urlWithQueryParams(helpDeskRoute, benefit.id, params);
        this.openNewWindow(this.sourceURL);
      });
  }
  private urlWithQueryParams(route: readonly [string, string], benefitId: string, params: BhQueryParams): string {
    const currentHref = this.document.location.href;
    const sourceUrl = this.removeQueryParam(currentHref, 'accessToken');
    const urlTree: UrlTree = this.router.createUrlTree([ ...route ], {
      queryParams: {
        ...benefitId !== '0' && { benefitId },
        ...params, sourceurl: sourceUrl,
      },
    });

    return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }
  public openNewWindow(url: string): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = url;

    this.sharedService.GA4pushEvent('cta_click', {
      // eslint-disable-next-line camelcase
      link_text: "Help", // Text clicked on CTA
      // eslint-disable-next-line camelcase
      link_url: link.href,  // Update the Link over here.
      // eslint-disable-next-line camelcase
      link_section: "Header" // Section of website where CTA is available
    });

    link.click();
    link.remove();
  }
}
