import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  skip,
  Subject,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { ConfigActions } from 'src/app/core/config/config.actions';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';

import { environment } from '../../../../environments/environment';
import { bhTitle, contentSeparator, loginUrl, passwordValidationRules, username } from '../../../constants';
import { selectApiToken, selectBhPropertiesURL } from '../../../core/config/config.reducers';
import { ConfigService } from '../../../core/config/config.service';
import { ContentActions } from '../../../core/content/content.actions';
import {
  selectChangePasswordPage,
  selectCreateAccountPage,
  selectEligibilityPage,
  selectEmployeeSearchPage,
  selectPersonalInfoPage,
} from '../../../core/content/content.reducers';
import { ContentService } from '../../../core/content/content.service';
import { CredentialsActions } from '../../../core/credentials/credentials.actions';
import { selectChangePasswordState } from '../../../core/credentials/credentials.reducers';
import { LoggerService } from '../../../core/logger-service/logger.service';
import {
  BhQueryParams,
  ContentPages,
  ErrorCode,
  EventName,
  FsRetailClientGuid,
  HttpError,
  IApiValidationError,
  IAppJwt,
  IChangePasswordState,
  IContentState,
  ICreateAccountContent,
  ICreateRegistrationState,
  ICreateUNPwdState,
  IEligibilityFromMagicLinkStatus,
  IEmployeeDetailsState,
  IFetchEligibilityOsrDetailsStatus,
  IPersonInfoState,
  IPersonalInfoContent,
  IRetrieveFICDetailsState,
  ISelectedEmployerState,
  LocalStorageKey,
  RegistrationSubCategory,
  SourceId,
} from '../../../models';
import { PasswordValidators } from '../../../utils/validators/password-validators';
import { SignupActions } from '../../../core/signup/signup.actions';
import {
  selectCreateRegistration,
  selectCreateUNPwdState,
  selectEmployeeDetails,
  selectPersonInfoState,
  selectRetrieveFICDetailsState,
} from '../../../core/signup/signup.reducers';
import {
  selectCreateRegistrationLoading,
  selectEligibilityFromMagicLinkStatus,
  selectFetchEligibilityOsrDetailsStatus,
} from '../../../core/signup/signup.selectors';
import { Title } from '@angular/platform-browser';
import { OverlayPopupComponent } from '../overlay-popup/overlay-popup.component';
import { SharedService } from '../../shared.service';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: [ './setup-password.component.scss' ],
})
export class SetupPasswordComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public readonly contentSeparator: string = contentSeparator;

  /**
   * Subscription for the already have an account component content.
   */
  public readonly accountContent: Observable<IContentState[ContentPages.employeeSearchPage]> = this.store.select(selectEmployeeSearchPage);

  /**
   * Subscription for the Required label content.
   */
  public readonly requiredContent: Observable<IContentState[ContentPages.eligibilityPage]> = this.store.select(selectEligibilityPage);

  /**
   * Subscription for the page content.
   */
  public readonly content: Observable<IContentState[ContentPages.createAccountPage]> = this.store.select(selectCreateAccountPage);
  public readonly changePassContent: Observable<IContentState[ContentPages.changePasswordPage]> = this.store.select(selectChangePasswordPage);

  public readonly privacyerrorContent: Observable<IContentState[ContentPages.personalInfoPage]> = this.store.select(selectPersonalInfoPage);

  /**
   * Flag to enable/disable reCAPTCHA based on the environment build, because the lower environments have it blocked/disabled.
   */
  public readonly isRecaptchaEnabled: boolean = environment.google.reCaptcha.enable;

  /**
   * Using this variable to hide previous button for full service flow.
   */
  public isFic: boolean = false;
  public isFicContactCenter: boolean = false;

  @ViewChild('captchaRef') public readonly captchaRef: RecaptchaComponent | undefined;

  public onlyDigits: boolean = false;
  public onlyLowerCase: boolean = false;
  public onlyUpperCase: boolean = false;
  public onlySpecialChars: boolean = false;
  public isFocusedOnPasswordLost: boolean = false;
  public showCurrentPassword: boolean = false;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public submitted = false;
  public isOSRenabled: boolean = false;
  public isBrokerLiaison: boolean = false;
  public isOperatorOwner: boolean = false;
  public isCohs: boolean = false;
  public isCollegeCoach: boolean = false;
  public isForgotPass: boolean = false;
  public isResetPass: boolean = false;
  public clientName?: string = '';
  public isAcceptableUseEnable: boolean = false;
  public isCaptchaDisabled: boolean = true;
  public isFederatedNonEmployee: boolean = false;
  private fsTargetId: number = 0;
  private loginUserName: string = '';
  public isforgotpasswordOtpFlow: boolean = false;
  public errorMessageUNRequired: string = '';
  public errorMessageExistUN: string = '';
  public errorMessageUNAllowed: string = '';
  public errorMessagePWRequired: string = '';
  public errorMessageWeakPW: string = '';
  public errorMessagePW2Required: string = '';
  public errorMessagePWConfirm: string = '';
  public errorMessagePrivacy: string = '';

  public errorMessagePWValidation1Rules: string = '';
  public errorMessagePWValidation2Rules: string = '';
  public ga4FormName: string = "";
  private isUsernameChanged: boolean = true;
  private isPasswordChanged: boolean = true;
  private isConfirmPasswordChanged: boolean = true;

  public readonly accCredentialsForm: FormGroup<{
    username: FormControl<string>,
    password: FormControl<string>,
    confirmPassword: FormControl<string>,
    currentPassword: FormControl<string>,
    acceptTerms: FormControl,
  }> = this.formBuilder.nonNullable.group({
      username: [
        '' as string,
        [
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(username),
        ],
      ],
      password: [ '' as string,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(68),
          PasswordValidators.passwordStrengthValidator(),
        ],
      ],
      confirmPassword: [ '' as string, [ Validators.required ] ],
      currentPassword: [ '' as string ],
      acceptTerms: [ false ],
    });
  public passwordMismatch: boolean = false;
  public usernameNotUnique: boolean = false;
  public weakPassword: boolean = false;
  public autoFilledUsername: boolean = true;

  // eslint-disable-next-line max-params
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly location: Location,
    private readonly cdr: ChangeDetectorRef,
    private readonly config: ConfigService,
    private readonly renderer: Renderer2,
    private readonly loggerService: LoggerService,
    private readonly contentService: ContentService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly pageTitle: Title,
    private readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
    @Inject('Window') private readonly window: Window,
  ) {
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.personalInfoPage }));
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.createAccountPage }));
    this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.changePasswordPage }));
    this.store.dispatch(ConfigActions.loading({ loading: false }));
  }

  public ngOnInit(): void {
    if(this.localStorageService.getItem(LocalStorageKey.magicLink) !== null){
      if(this.localStorageService.getItem(LocalStorageKey.tempMagicLink) === 'bh-tempMagicLink'){      
        this.localStorageService.removeItem(LocalStorageKey.tempMagicLink);
        this.localStorageService.saveItem(LocalStorageKey.tempMagicLink, this.localStorageService.getItem(LocalStorageKey.magicLink) as string);
      }
      else{
        this.localStorageService.removeItem(LocalStorageKey.tempMagicLink);
        this.localStorageService.saveItem(LocalStorageKey.tempMagicLink, 'bh-tempMagicLink');
        this.window.location.href = this.localStorageService.getItem(LocalStorageKey.magicLink) as string;
      }
    }
    this.content
      .pipe(
        filter((pageContent: IContentState[ContentPages.createAccountPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.createAccountPage]): ICreateAccountContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: ICreateAccountContent): void => {
        this.errorMessageUNRequired = pageContent.BHL_AcctCrt2_Error_UNRequired;
        this.errorMessageExistUN = pageContent.BHL_AcctCrt2_Error_ExistUN;
        this.errorMessageUNAllowed = pageContent.BHL_AcctCrt2_Error_UNAllowed;
        this.errorMessagePWRequired = pageContent.BHL_AcctCrt2_Error_PWRequired;
        this.errorMessageWeakPW = pageContent.BHL_AcctCrt2_Error_WeakPW;
        this.errorMessagePW2Required = pageContent.BHL_AcctCrt2_Error_PW2Required;
        this.errorMessagePWConfirm = pageContent.BHL_AcctCrt2_Error_PWConfirm;
        this.errorMessagePWValidation1Rules= pageContent.BHL_AcctCrt2_Error_PW_Validation1_Rules;
        this.errorMessagePWValidation2Rules= pageContent.BHL_AcctCrt2_Error_PW_Validation2_Rules;
      });

    this.privacyerrorContent
      .pipe(
        filter((pageContent: IContentState[ContentPages.personalInfoPage]): boolean => pageContent.loaded),
        map((pageContent: IContentState[ContentPages.personalInfoPage]): IPersonalInfoContent => pageContent.content),
        take(1),
      )
      .subscribe((pageContent: IPersonalInfoContent): void => {
        this.errorMessagePrivacy = pageContent.BHL_AcctCrt1_Error_Privacy;
      });

    // determine the flow (or rather the source/type of user - e.g., OSR, Liaison/Broker
    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        this.isBrokerLiaison = params.sourceid?.toLowerCase() === SourceId['broker-liaison'];
        this.isOperatorOwner = params.sourceid?.toLowerCase() === SourceId['operator-owner'];
        this.isCohs = params.sourceid?.toLowerCase() === SourceId.cohs;
        this.isOSRenabled = params.sourceid?.toLowerCase() === SourceId.osr;
        this.isCollegeCoach = params.sourceid?.toLowerCase() === SourceId['cc-care-recipients'];
        this.isFic = params.sourceid?.toLowerCase() === SourceId['fic-registration'];
        this.isFicContactCenter = params.sourceid?.toLowerCase() === SourceId['fic-contact-center'];
        this.isForgotPass = params.sourceid?.toLowerCase() === SourceId['forgot-password'];
        this.isResetPass = params.sourceid?.toLowerCase() === SourceId['reset-password'];
        this.isFederatedNonEmployee = params.sourceid?.toLowerCase() === SourceId['federated-non-employees'];
        this.fsTargetId = Number(params.fstargetid);
        this.isforgotpasswordOtpFlow = params.fgPwdOtpFlow?.toLowerCase() === 'true';

        if (this.isOSRenabled || this.isOperatorOwner || this.isBrokerLiaison || this.isCohs || this.isResetPass || this.isForgotPass) {
          this.getPersonInfoDetails();
        }

        if (this.isResetPass) {
          this.pageTitle.setTitle(`Reset Password ${bhTitle}`);
          this.currentPassword.setValidators([ Validators.required ]);
          this.currentPassword.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }

        if (this.isForgotPass) {
          this.pageTitle.setTitle(`Forgot Password ${bhTitle}`);
        }

        // eslint-disable-next-line max-len
        if (this.isOSRenabled || this.isBrokerLiaison || this.isCollegeCoach || this.isOperatorOwner || this.isCohs || this.isFederatedNonEmployee || this.isFicContactCenter) {
          this.isAcceptableUseEnable = true;
          this.acceptTerms.setValidators([ Validators.requiredTrue ]);
        }

        if (this.isBrokerLiaison || this.isOperatorOwner || this.isCohs || this.isResetPass || this.isForgotPass || this.isFicContactCenter) {
          this.username.clearValidators();
          this.username.updateValueAndValidity();
        }
      });

    if (this.isCollegeCoach || this.isFederatedNonEmployee) {
      this.getPersonalInfoData();
      this.initCreateUserPassSub();
    }

    if (this.isBrokerLiaison || this.isOperatorOwner || this.isCohs || this.isResetPass || this.isForgotPass || this.isFicContactCenter) {
      this.initPassChangeSub();
    }

    this.store.select(selectEmployeeDetails)
      .pipe(take(1))
      .subscribe((details: IEmployeeDetailsState | null): void => {
        if (details) {
          this.clientName = details.clientName;
        }
      });
    // eslint-disable-next-line max-len
    if (!this.isBrokerLiaison && !this.isOperatorOwner && !this.isCohs && !this.isCollegeCoach && !this.isResetPass && !this.isForgotPass && !this.isFederatedNonEmployee && !this.isFicContactCenter) {
      this.initCreateRegistrationSub();
    }

    if (this.isOSRenabled) {
      this.getOsrDetailsData();
    }

    this.accCredentialsForm.controls.username.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe((): void => {
        this.usernameNotUnique = false;
        if(this.isUsernameChanged){
          this.isUsernameChanged = false;
          this.sendFormFillGa4Event(this.ga4FormName,"Username or Email","no");
        }  
      });

    // subscribe to changes to the password field, so we can properly update the matching criteria(s)
    // on the screen (green checkmark vs red x)
    this.accCredentialsForm.controls.password.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((value: string): void => {
        this.weakPassword = false;
        this.onlyUpperCase = (passwordValidationRules[0] as RegExp).test(value);
        this.onlyLowerCase = (passwordValidationRules[1] as RegExp).test(value);
        this.onlyDigits = (passwordValidationRules[2] as RegExp).test(value);
        this.onlySpecialChars = (passwordValidationRules[3] as RegExp).test(value);
        if(this.isPasswordChanged){
          this.isPasswordChanged = false;
          this.sendFormFillGa4Event(this.ga4FormName,"Password","no");
        }  
      });

    this.accCredentialsForm.controls.confirmPassword.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((): void => {
        if(this.isConfirmPasswordChanged){
          this.isConfirmPasswordChanged = false;
          this.sendFormFillGa4Event(this.ga4FormName,"Confirm Password","no");
        }
      });
      this.getGa4FormName();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if(this.accCredentialsForm.controls.username.value !== '' && this.accCredentialsForm.controls.username?.value !== null){
        if(this.autoFilledUsername){
          this.autoFilledUsername = false;
          this.sendFormFillGa4Event(this.ga4FormName,"Username or Email","yes");
        }
      }
  }

  private getPersonalInfoData(): void {
    this.store.select(selectPersonInfoState)
      .pipe(
        take(1),
      )
      .subscribe((state: IPersonInfoState): void => {
        if (state.loaded) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));

          const personInfoClientGuid = state.data?.crmClientId?.toUpperCase();

          if (personInfoClientGuid !== FsRetailClientGuid) {
            this.clientName = state.data?.clientName;
          }
          if (personInfoClientGuid) {
            this.store.dispatch(
              SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientGuid: state.data?.crmClientId,
                  clientName: personInfoClientGuid === FsRetailClientGuid ? '' : state.data?.clientName,
                } as ISelectedEmployerState,
              }),
            );
          }
        }
      });
  }

  private initPassChangeSub(): void {
    this.store.select(selectChangePasswordState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: IChangePasswordState): void => {
        if (state.loaded) {
          this.store.dispatch(ConfigActions.loading({ loading: true }));
          if (this.isForgotPass && this.fsTargetId === 2) {
            this.router.navigate([ '/global', 'password-updated' ]);
          } else if (this.isforgotpasswordOtpFlow) {
            this.router.navigate([ 'global', 'confirmation-message' ]);
          } else {
            // eslint-disable-next-line max-len
            this.document.location.href = this.config.buildExternalUrl(this.isResetPass || this.isForgotPass ? loginUrl : undefined).href;
          }
        } else if (state.error) {
          this.store.dispatch(ConfigActions.loading({ loading: false }));
          const error: string | HttpError = state.error;
          let msg: string | undefined;
          if (typeof error !== 'string' && error.status === 400) {
            for (const err of error.error?.errors as Array<IApiValidationError>) {
              const errorCode: string = err.errorCode;

              // we cannot use any of the last 12 passwords, thus, get the error message from the BE response
              if (errorCode === ErrorCode.LAST_TWELVE_PASSWORDS) {
                msg = err.errorMessage[0];
                break;
              } else if (errorCode === ErrorCode.INVALID_COMBINATION) {
                msg = err.errorMessage[0];
                break;
              } else if (err.propertyName.toLowerCase() === 'password') {
                this.weakPassword = true;
                break;
              }
            }
          }

          if (this.weakPassword) {
            this.cdr.detectChanges();
          } else {
            this.contentService.showErrorToast(msg);
            this.pushGA4Data(msg as string);
          }
        }
      });
  }

  private initCreateRegistrationSub(): void {
    this.store
      .select(selectCreateRegistrationLoading)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((loading: boolean): void => {
        this.store.dispatch(ConfigActions.loading({ loading }));
      });

    /**
     * On successful registration check mfa enabled or not to redirect to mfa screen
     * If user is not mfa enabled redirect to confirmation screen
     */
    this.store.select(selectCreateRegistration)
      .pipe(
        combineLatestWith(
          this.store.select(selectEligibilityFromMagicLinkStatus),
          this.store.select(selectApiToken),
          this.store.select(selectRetrieveFICDetailsState),
        ),
        // eslint-disable-next-line max-len
        takeWhile(([ , , apiToken ]: [ICreateRegistrationState | null, IEligibilityFromMagicLinkStatus | null, IAppJwt | null, IRetrieveFICDetailsState | null]): boolean => apiToken?.userInfoId === null),
        takeUntil(this.ngUnsubscribe),
      )
      // eslint-disable-next-line max-len
      .subscribe(([ createRegistration, userInfo, apiToken, ficDetails ]: [ICreateRegistrationState | null, IEligibilityFromMagicLinkStatus | null, IAppJwt | null, IRetrieveFICDetailsState | null]): void => {
        if (ficDetails?.loaded && this.isFic) {
          const fsRetailClientGuidFromFicDetails = ficDetails.retrieveFICDetailsStatus?.registrationData?.clientGuid?.toUpperCase();
          this.isCaptchaDisabled = ficDetails.retrieveFICDetailsStatus?.isCaptchaDisabled ?? this.isCaptchaDisabled;

          if (fsRetailClientGuidFromFicDetails !== FsRetailClientGuid) {
            this.clientName = ficDetails.retrieveFICDetailsStatus?.clientName;
          }
          if (ficDetails.retrieveFICDetailsStatus?.registrationData?.clientGuid) {
            this.store.dispatch(
              SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientGuid: ficDetails.retrieveFICDetailsStatus.registrationData.clientGuid,
                  clientName: fsRetailClientGuidFromFicDetails === FsRetailClientGuid ? '' : ficDetails.retrieveFICDetailsStatus.clientName,
                } as ISelectedEmployerState,
              }),
            );

            // need to Pass when otp enabled flow
            this.store.dispatch(SignupActions.getClientInfo({ input: ficDetails.retrieveFICDetailsStatus.registrationData.clientGuid }));
          }
        }

        if (createRegistration?.createRegistrationStatus) {
          this.store.dispatch(ConfigActions.saveAppAuthToken({
            tokenInfo: {
              userInfoId: createRegistration.createRegistrationStatus.userInfoId as string,
              token: apiToken?.token as string,
              expiresAt: apiToken?.expiresAt as number,
              userAccessToken: createRegistration.createRegistrationStatus.oidcToken?.accessToken,
            },
          }));
          // eslint-disable-next-line max-len
          if (userInfo?.isMfaEnabled || userInfo?.isOtpenabled || ficDetails?.retrieveFICDetailsStatus?.isMfaEnabled || ficDetails?.retrieveFICDetailsStatus?.isOtpenabled) {
            if (ficDetails?.retrieveFICDetailsStatus?.isMfaEnabled) {
              this.store.dispatch(
                SignupActions.employeeDetails({
                  employeeDetails: {
                    email: ficDetails.retrieveFICDetailsStatus.registrationData?.workEmail,
                    firstName: ficDetails.retrieveFICDetailsStatus.registrationData?.firstName,
                    lastname: ficDetails.retrieveFICDetailsStatus.registrationData?.lastName,
                  } as IEmployeeDetailsState,
                }),
              );
            }
            
            // eslint-disable-next-line max-len
            this.localStorageService.saveItem(LocalStorageKey.requestId, userInfo?.requestId ?? ficDetails?.retrieveFICDetailsStatus?.requestId as string);
            this.localStorageService.saveItem(LocalStorageKey.userNavigationState, 'true');
            this.localStorageService.saveItem(LocalStorageKey.clientGuid, createRegistration.createRegistrationStatus.clientGuid as string);
            this.localStorageService.saveItem(LocalStorageKey.userInfoId, createRegistration.createRegistrationStatus.userInfoId as string);
            this.localStorageService.saveItem(LocalStorageKey.uname, this.username.value as string);
            this.router.navigate([ '/global', 'mfa' ]);
          } else if (this.isOSRenabled || this.isBrokerLiaison || this.isOperatorOwner || this.isCohs) {
            this.router.navigate([ 'signup', 'confirmation' ]);
          } else {
            const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId);
            this.sharedService.TrackPendoEvent(EventName.REGISTRATION,
              benefitIdFromLocalStorage as string, RegistrationSubCategory.OVERLAY_DISPLAY);


            this.contentService.openOverlayDialog(OverlayPopupComponent).subscribe(() => {
              this.store.dispatch(ConfigActions.loading({ loading: true }));
            });
          }
        } else if (createRegistration?.error) {
          this.handleUserPassCreateFailure(createRegistration.error);
        }
      });

    // Prepopulate email for franchise account
    this.store.select(selectEligibilityFromMagicLinkStatus).pipe(
      takeUntil(this.ngUnsubscribe),
    )
      .subscribe((magicLinkData) => {
        if (magicLinkData) {
          this.isCaptchaDisabled = magicLinkData.isCaptchaDisabled as boolean;

          if (magicLinkData.isFranchise && magicLinkData.workEmail) {
            this.accCredentialsForm.controls.username.setValue(magicLinkData.workEmail);
          }
        }
      });
  }

  private initCreateUserPassSub(): void {
    this.store
      .select(selectCreateUNPwdState)
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state: ICreateUNPwdState): void => {
        this.store.dispatch(ConfigActions.loading({ loading: state.loading }));

        if (state.createUNPwdStatus) {
          this.store.dispatch(ConfigActions.loading({ loading: true }));
          this.document.location.href = this.config.buildExternalUrl().href;
        } else if (state.error) {
          this.handleUserPassCreateFailure(state.error);
        }
      });
  }

  private getOsrDetailsData(): void {
    this.store
      .select(selectFetchEligibilityOsrDetailsStatus)
      .pipe(
        take(1),
      )
      .subscribe((osrDetails: IFetchEligibilityOsrDetailsStatus | null): void => {
        if (osrDetails) {
          this.clientName = osrDetails.clientName;
          this.isCaptchaDisabled = osrDetails.isCaptchaDisabled;
          this.username.setValue(osrDetails.workEmail);
        }
      });
  }

  /**
   * Method to check the error response from the BE and determine if the error is related to the username
   * or password or something else and display an appropriate error message.
   * @param {HttpError | string} error The error that was captured by the effect
   * @private
   */
  private handleUserPassCreateFailure(error: HttpError | string): void {
    if (typeof error !== 'string' && error.status === 400) {
      let passwordFailed: boolean = false;

      for (const err of error.error?.errors as Array<IApiValidationError>) {
        const field: string = err.propertyName.toLowerCase();

        if (field === 'username') {
          this.usernameNotUnique = true;
          this.cdr.detectChanges();
          this.pushGA4Data(this.errorMessageExistUN);
          (this.renderer.selectRootElement('#username', true) as HTMLInputElement | null)?.focus();
          break;
        }

        if (field === 'password') {
          passwordFailed = true;
        }
      }

      if (!this.usernameNotUnique) {
        this.weakPassword = passwordFailed;
      }

      if (this.usernameNotUnique || this.weakPassword) {
        this.cdr.detectChanges();
      } else {
        this.contentService.showErrorToast();
      }
    } else {
      this.contentService.showErrorToast();
    }
  }

  public get username(): AbstractControl<string> {
    return this.accCredentialsForm.get('username') as FormControl;
  }

  public get password(): AbstractControl<string> {
    return this.accCredentialsForm.get('password') as FormControl;
  }

  public get confirmPassword(): AbstractControl<string> {
    return this.accCredentialsForm.get('confirmPassword') as FormControl;
  }

  public get currentPassword(): AbstractControl<string> {
    return this.accCredentialsForm.get('currentPassword') as FormControl;
  }

  public get acceptTerms(): AbstractControl<string> {
    return this.accCredentialsForm.get('acceptTerms') as FormControl;
  }

  public get usernameValid(): boolean {
    return this.accCredentialsForm.controls.username.errors === null;
  }
  public get passwordValid(): boolean {
    return this.accCredentialsForm.controls.password.errors === null;
  }

  public get requiredValid(): boolean {
    return !this.accCredentialsForm.controls.password.hasError('required');
  }

  public get minLengthValid(): boolean {
    return !this.accCredentialsForm.controls.password.hasError('minlength');
  }

  public get unPasswordMatch(): boolean {
    // eslint-disable-next-line max-len
    let userName = this.isforgotpasswordOtpFlow ? this.localStorageService.getItem(LocalStorageKey.uname) as string : this.accCredentialsForm.controls.username.value;
    const password = this.accCredentialsForm.controls.password.value.toString();
    if (this.isOSRenabled || this.isOperatorOwner || this.isBrokerLiaison || this.isCohs || this.isResetPass || this.isForgotPass) {
      userName = this.isforgotpasswordOtpFlow ? this.localStorageService.getItem(LocalStorageKey.uname) as string : this.loginUserName;
    }
    for (let i = 0; (i + 3) < userName.length; i++) {
      if (password.trim().toLocaleLowerCase()
        .includes(userName.trim().toLocaleLowerCase()
          .substring(i, i + 4))) {
        this.password.setErrors({ 'incorrect': true });

        return false;
      }
    }

    return true;
  }

  public handlePasswordFldFocus(focusLost: boolean = true): void {
    this.isFocusedOnPasswordLost = focusLost;
  }

  public togglePassword(field: string): void {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'currentPassword') {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  public navigateBack(): void {
    this.location.back();
  }

  public updateEmployeeDetailsState(): void {
    this.store.dispatch(SignupActions.employeeDetails({
      employeeDetails: {
        userName: this.isforgotpasswordOtpFlow ? this.localStorageService.getItem(LocalStorageKey.uname) : this.username.value,
        password: this.password.value,
        currentPassword: this.currentPassword.value,
      } as IEmployeeDetailsState,
    }));
  }
  public pushGA4Data(errorMessage: string): void {
    if (errorMessage) {
      const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
      if(!this.isforgotpasswordOtpFlow){
        /* eslint camelcase: ["error", {allow: ["benefit_id","benefit_source","error_message"]}]*/
        this.sharedService.GA4pushEvent('sign_up_error', {
        // eslint-disable-next-line camelcase
        benefit_id: benefitIdFromLocalStorage,
        // eslint-disable-next-line camelcase
        benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage),
        // eslint-disable-next-line camelcase
        error_message: errorMessage,
      });
      }      
      /* eslint camelcase: ["error", {allow: ["form_name","error_message"]}]*/
      this.sharedService.GA4pushEvent('form_error', {
        form_name: this.ga4FormName,
        error_message: errorMessage,
      });
    }
  }
  public checkGA4Validation(): void {
    let errorMessage = '';
    if (this.username.errors?.['required'] && this.username.invalid && (this.username.dirty || this.submitted || this.username.touched)) {
      errorMessage = this.errorMessageUNRequired;
      this.pushGA4Data(errorMessage);
    }
    if (this.usernameNotUnique) {
      errorMessage = this.errorMessageExistUN;
      this.pushGA4Data(errorMessage);
    }
    if (this.username.invalid && this.username.dirty && this.username.value !== '') {
      errorMessage = this.errorMessageUNAllowed;
      this.pushGA4Data(errorMessage);
    }
    if (this.currentPassword.errors?.['required']) {
      errorMessage = this.errorMessagePWRequired;
      this.pushGA4Data(errorMessage);
    }
    if (this.password.invalid && (this.password.dirty || this.submitted || this.password.touched) && !this.requiredValid) {
      errorMessage = this.errorMessagePWRequired;
      this.pushGA4Data(errorMessage);
    }
    if (this.weakPassword) {
      errorMessage = this.errorMessageWeakPW;
      this.pushGA4Data(errorMessage);
    }
    if (this.confirmPassword.errors?.['required']) {
      errorMessage = this.errorMessagePW2Required;
      this.pushGA4Data(errorMessage);
    }
    if (!this.confirmPassword.errors?.['required'] && this.passwordMismatch) {
      errorMessage = this.errorMessagePWConfirm;
      this.pushGA4Data(errorMessage);
    }
    if (this.acceptTerms.invalid && (this.acceptTerms.dirty || this.acceptTerms.touched) && this.acceptTerms.errors?.['required']) {
      errorMessage = this.errorMessagePrivacy;
      this.pushGA4Data(errorMessage);
    }
  }
  public onContinue(): void {
    if (this.accCredentialsForm.controls.password.value.toString() === this.accCredentialsForm.controls.confirmPassword.value) {
      this.passwordMismatch = false;

      if (this.accCredentialsForm.invalid) {
        this.accCredentialsForm.markAllAsTouched();
        this.checkGA4Validation();
      } else if (!this.isCaptchaDisabled && this.isRecaptchaEnabled) {
        this.store.dispatch(ConfigActions.loading({ loading: true }));
        this.captchaRef?.execute();
      } else {
        this.resolved();
      }
    } else {
      this.passwordMismatch = true;
      this.cdr.detectChanges();
      if (this.accCredentialsForm.controls.password.value.toString() === '') {
        this.pushGA4Data(this.errorMessagePWRequired);
      }      
      if (this.accCredentialsForm.controls.confirmPassword.value.toString() === '') {
        this.pushGA4Data(this.errorMessagePW2Required);
      }
      if (this.accCredentialsForm.controls.password.value.toString() !== '' &&
        this.accCredentialsForm.controls.confirmPassword.value.toString() !== '' &&
        this.accCredentialsForm.controls.password.value.toString() !== this.accCredentialsForm.controls.confirmPassword.value) {
        this.pushGA4Data(this.errorMessagePWConfirm);
      }
      if (this.accCredentialsForm.controls.password.value.toString() !== '' &&
      (this.accCredentialsForm.controls.password.invalid ||
      this.accCredentialsForm.controls.password.dirty)) {
      let error1Array: string[] = this.errorMessagePWValidation1Rules?.split(contentSeparator);
      let error2Array: string[] = this.errorMessagePWValidation2Rules?.split(contentSeparator);
      let errorArray: string[] = [];

      let errorMessage = '';
      if(!this.minLengthValid){
        errorArray.push((error1Array[0] as string).trim());
      }
      if(!this.unPasswordMatch){
        errorArray.push((error1Array[1] as string).trim());
      }
      if(!this.onlyUpperCase){
        errorArray.push((error2Array[0] as string).trim());
      }
      if(!this.onlyLowerCase){
        errorArray.push((error2Array[1] as string).trim());
      }
      if(!this.onlyDigits){
        errorArray.push((error2Array[2] as string).trim());
      }
      if(!this.onlySpecialChars){
        errorArray.push((error2Array[3] as string).trim());
      }
      if (errorArray.length > 0) {
        errorMessage = errorArray.join(", ");
        this.pushGA4Data(errorMessage);
    }
        
      }
      (this.renderer.selectRootElement('#confirmPassword', true) as HTMLInputElement | null)?.focus();
    }
  }
  public resolved(): void {
    this.updateEmployeeDetailsState();
    if (this.isBrokerLiaison || this.isOperatorOwner || this.isCohs || this.isResetPass || this.isForgotPass || this.isFicContactCenter) {
      this.sharedService.GA4pushEvent('form_submit_success', {
        // eslint-disable-next-line camelcase
        form_name: "Reset Password"
      });

      const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;

      this.sharedService.GA4pushEvent('password_reset_success', {
        // eslint-disable-next-line camelcase
        benefit_id: benefitIdFromLocalStorage, // The benefit ID through which the user is trying to reset their password.
        // eslint-disable-next-line camelcase
        benefit_source: this.sharedService.getBenefitNameByBenefitId(benefitIdFromLocalStorage) // The benefit source through which the user is trying to reset their password.
      });
      
      this.store.dispatch(CredentialsActions.changePassword());
    } else if (this.isCollegeCoach || this.isFederatedNonEmployee) {
      this.sharedService.GA4pushEvent('form_submit_success', {
        // eslint-disable-next-line camelcase
        form_name: "Create Username Password"
      });
      this.store.dispatch(SignupActions.createUnpwd());
    } else {
      this.sharedService.GA4pushEvent('form_submit_success', {
        // eslint-disable-next-line camelcase
        form_name: "Sign Up"
      });
      this.store.dispatch(SignupActions.createRegistration());
    }
  }
  
  public getGa4FormName(): void {
    if (this.isBrokerLiaison || this.isOperatorOwner || this.isCohs || this.isResetPass || this.isForgotPass || this.isFicContactCenter) {
      this.ga4FormName = "Reset Password";
    } else if (this.isCollegeCoach || this.isFederatedNonEmployee) {
        this.ga4FormName = "Create Username Password";
      }
     else {
      this.ga4FormName = "Sign Up"
    }
  }

  public sendFormFillGa4Event(formName: string, fieldName: string, autoFilled: string): void{
    this.sharedService.GA4pushEvent('form_fill', {
      // eslint-disable-next-line camelcase
      form_name: formName, // Name of Form
      // eslint-disable-next-line camelcase
      field_name: fieldName, // Field Name user interacted
      autofilled: autoFilled // If the field was auto filled or not
    })
  }

  public onError(event: RecaptchaErrorParameters): void {
    this.store.dispatch(ConfigActions.loading({ loading: false }));
    this.contentService.showErrorToast();
    this.loggerService.sendTrackEvent('reCAPTCHA Failed', JSON.stringify(event), EventName.REGISTRATION, undefined, RegistrationSubCategory.SIGNUP_TECHNICAL_ERROR_FE);
    this.pushGA4Data('reCAPTCHA Failed');
  }

  private getPersonInfoDetails(): void {
    this.store.select(selectPersonInfoState)
      .pipe(
        take(1),
      )
      .subscribe((state: IPersonInfoState): void => {
        if (state.loaded) {
          this.loginUserName = state.data?.userName as string;
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
