import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IApiResponse, IAppToken } from '../../models';

/**
 * Service to authenticate the FE app with the backend API.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  /**
   * The service `constructor`
   * @param {HttpClient} http The Angular HTTP client used to make Ajax requests.
   */
  constructor(
    private readonly http: HttpClient,
  ) { }

  /**
   * Method to call the API authorization endpoint to request an access token.
   * @returns {Promise<IApiResponse<IAppToken>>} Promise containing the access token when successful authentication.
   */
  public authenticateApp(): Promise<IApiResponse<IAppToken>> {
    const body = new HttpParams()
      .set('clientid', environment.versionDate)
      .set('clientsecret', environment.cacheBustingVersion);

    return firstValueFrom(this.http.post<IApiResponse<IAppToken>>(
      `${environment.apiVersionUri.v1}/ClientToken`,
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ));
  }

  /**
   * Decrypt an encrypted payload from the backend.
   * @param {string} payload The encrypted response that was received from the backend.
   * @param {boolean} [isObject] Whether the type of response we are decrypting is an object, so we can JSON.parse
   * it or return the plain decrypted response. Defaults to `true`.
   * @returns {Object|string} Returns the decrypted response.
   */
  public decryptResponse<T>(payload: string, isObject: boolean = true): T | string {
    const decryptedResponse: string = CryptoJS.AES.decrypt(
      payload,
      CryptoJS.enc.Utf8.parse(environment.versionNumber),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(environment.versionNumber),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    )
      .toString(CryptoJS.enc.Utf8);

    if (isObject) {
      return JSON.parse(decryptedResponse) as T;
    }

    return decryptedResponse;
  }

  /**
   * encrypt an data
   * @param {string} payload The encrypted response that was received from the backend.
   * @param {boolean} [isObject] Whether the type of response we are decrypting is an object, so we can JSON.parse
   * it or return the plain decrypted response. Defaults to `true`.
   * @returns {Object|string} Returns the decrypted response.
   */
  public encrypt(valueToEncrypt: string): string {
    const encryptedValue = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(valueToEncrypt),
      CryptoJS.enc.Utf8.parse(environment.versionNumber),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(environment.versionNumber),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    return encryptedValue.toString();
  }

  public decrypt(valueToDecrypt: string): string {
    const decryptedValue = CryptoJS.AES.decrypt(
      valueToDecrypt,
      CryptoJS.enc.Utf8.parse(environment.versionNumber),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(environment.versionNumber),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decryptedValue.toString(CryptoJS.enc.Utf8);
  }
}
