import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  IApiResponse,
  ICredentials,
  ICredentialsPayload,
  IForgotPasswordPayload,
  IForgotUsernamePayload,
  IResetPasswordPayload,
  ISendChangePassEmailPayload,
  IValidateUserPayload,
  IValidateUserStatus,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(
    private readonly http: HttpClient,
  ) { }

  public changePassword(payload: ICredentialsPayload): Observable<IApiResponse<ICredentials>> {
    return this.http.post<IApiResponse<ICredentials>>(`${environment.apiVersionUri.v1}/Person/ChangePassword`, payload);
  }

  public sendChangePassEmail(payload: ISendChangePassEmailPayload): Observable<IApiResponse<boolean>> {
    return this.http.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Person/CreatePasswordEmail`, payload);
  }

  public validateUser(payload: IValidateUserPayload): Observable<IApiResponse<IValidateUserStatus>> {
    return this.http.post<IApiResponse<IValidateUserStatus>>(`${environment.apiVersionUri.v1}/Authenticate/ValidateUser`, payload);
  }

  public forgotPassword(payload: IForgotPasswordPayload): Observable<IApiResponse<boolean>> {
    return this.http.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Person/ForgotUserPassword`, payload);
  }

  public resetPassword(payload: IResetPasswordPayload): Observable<IApiResponse<boolean>> {
    return this.http.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Person/ResetPassword`, payload);
  }

  public forgotUsername(payload: IForgotUsernamePayload): Observable<IApiResponse<boolean>> {
    return this.http.post<IApiResponse<boolean>>(`${environment.apiVersionUri.v1}/Person/ForgotUserName`, payload);
  }

  public getMFAUserEmailAndMobile(userInfoId: string, isNonMfa: boolean, isMfaProfileAvailable: boolean): Observable<IApiResponse<string>> {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return this.http.get<IApiResponse<string>>(`${environment.apiVersionUri.v1}/Person/${userInfoId}/UserMfamobileEmailsEnc/${isNonMfa}/${isMfaProfileAvailable}`);
  }
}
