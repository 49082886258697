import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
} from 'rxjs';

import { EXTERNAL_API } from 'src/app/core/auth/http.interceptor';
import { environment } from '../../../environments/environment';
import {
  IBingMapsLocationApiResponse,
  IFranchiseLocation,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(private readonly httpService: HttpClient) { }

  private readonly mapControlCallbackName = 'onMapControlLoad';

  public get mapControlUrl(): string {
    return `${environment.microsoft.maps.mapControlUrl}?callback=${this.mapControlCallbackName}`;
  }

  public get bingMapsKey(): string {
    return environment.microsoft.maps.key;
  }

  public readonly defaultPinIcon = 'assets/pushpin-default.svg';

  public readonly selectedPinIcon = 'assets/pushpin-active.svg';

  public readonly inactivePinIcon = 'assets/pushpin-inactive.svg';

  // If icon SVG changes, update pinIconWidth to ensure it equals the SVG's width
  public readonly pinIconWidth = 31;

  // If icon SVG changes, update pinIconHeight to ensure it equals the SVG's height
  public readonly pinIconHeight = 45;

  public getMapLocation(sessionKey: string, location: IFranchiseLocation): Observable<IBingMapsLocationApiResponse> {
    const query = encodeURIComponent(`${location.address} ${location.city} ${location.state} ${location.postalCode}`);

    /**
     * Note: the request below uses the "unstructured URL" format recommended by the Bing Maps docs.
     * This helps ensure better results with non-standard addresses (e.g., '65th & Western' instead of '6501 Western').
     */
    return this.httpService.get<IBingMapsLocationApiResponse>(
      `${environment.microsoft.maps.restApiUrl}/Locations?q=${query}&o=json&key=${sessionKey}`,
      {
        context: new HttpContext().set(EXTERNAL_API, true),
      },
    );
  }
}
