<ng-container *ngIf="(accountContent | async)?.loaded">
    <article class="signup-confirmation" *ngIf="{ account: (accountContent | async)?.content } as pageContent">
        <div class="success-icon">
            <img [src]="'assets/face-glasses.svg'" [alt]="'Success'" />
        </div>
        <div class="sr-only" role="alert">{{ userConfirmationPageData.confirmationHeader }} {{ contentWithoutHTMLTag }}</div>
        <h1 class="bh--heading" aria-hidden="true">
            {{ userConfirmationPageData.confirmationHeader }}
        </h1>
        <p class="explore-message" aria-hidden="true">
            <span class="email-placeholder" [innerHtml]="userConfirmationPageData.confirmationBody"></span>
        </p>
        <div *ngIf="showAlreadyAccount">
            <bh-already-have-account
              [showJoinToday]="true"
              [showLogin]="false"
              [content]="(accountContent | async)?.content"
            ></bh-already-have-account>
        </div>
    </article>
</ng-container>
