import { ChangeDetectionStrategy, Component, Input, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, Observable, take } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { helpDeskRoute } from '../../../constants';
import { selectBenefitId, selectBhPropertiesURL } from '../../../core/config/config.reducers';
import { BhQueryParams, IBenefitLogo, IEmployeeSearchContent,LocalStorageKey } from '../../../models';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
import { LocalStorageService } from 'src/app/core/local-storage/local-storage.service';
/**
 * Component to display the "Already have an account" content
 * that is displayed under the main content on several steps.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-already-have-account',
  templateUrl: './already-have-account.component.html',
  styleUrls: [ './already-have-account.component.scss' ],
})
export class AlreadyHaveAccountComponent {
  public readonly joinToday: string = 'Join Today';
  public readonly notRegistered: string = 'Not registered?';

  /**
   * An optional input to allow us to hind the Login button or
   * any other content on this page based on different scenarios.
   */
  @Input() public showLogin: boolean = true;

  /**
   * An optional input to allow us to hind the help desk link or
   * any other content on this page based on different scenarios.
   */
  @Input() public showHelpDesk: boolean = true;

  /**
   * A required input to provide the content for the component.
   */
  @Input() public content: IEmployeeSearchContent | undefined;

  @Input() public showJoinToday: boolean = true;

  /**
   * The query params we need to pass to the help desk URL when we navigate because the help desk is opened in a new window/tab.
   */
  public queryParams: Record<string, string>;

  /**
   * An observable to get the benefit ID/LOB information set in the store.
   */
  public readonly benefitId: Observable<IBenefitLogo> = this.store.select(selectBenefitId);

  /**
   * The help desk form route.
   */
  public readonly helpDeskRoute: typeof helpDeskRoute = helpDeskRoute;
  public removeQueryParam(url: string, paramKey: string): string {
    const parsedUrl = new URL(url);
    const params = parsedUrl.searchParams;

    // Iterate through all keys in the URLSearchParams
    for (const key of params.keys()) {
      // Check if the current key matches the one to be removed (case-insensitive)
      if (key.toLowerCase() === paramKey.toLowerCase()) {
        // Delete the key and its associated value
        params.delete(key);
        break; // Assuming you want to remove only the first occurrence of the parameter
      }
    }

    // Return the modified URL
    return parsedUrl.toString();
  }

  public sourceURL: string = '';

  /**
   * The component `constructor`
   * @param {Store} store The NgRx store instance.
   */
  // eslint-disable-next-line max-params
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly locationStrategy: LocationStrategy,
    private readonly localStorageService: LocalStorageService,
  ) {
    const currentHref = this.document.location.href;
    this.sourceURL = this.removeQueryParam(currentHref, 'accessToken');

    this.store.select(selectBhPropertiesURL)
      .pipe(
        combineLatestWith(
          this.store.select(selectBenefitId),
        ),
        take(1),
      )
      .subscribe(([ params, benefitInfo ]: [ object, IBenefitLogo ]): void => {
        this.queryParams = {
          ...benefitInfo.id !== '0' && { benefitId: benefitInfo.id },
          ...params, sourceurl: this.sourceURL,
        };
      });
  }

  private urlWithQueryParams(route: readonly [string, string], benefitId: string, params: BhQueryParams): string {
    const currentHref = this.document.location.href;
    const sourceUrl = this.removeQueryParam(currentHref, 'accessToken');
    const urlTree: UrlTree = this.router.createUrlTree([ ...route ], {
      queryParams: {
        ...benefitId !== '0' && { benefitId },
        ...params, sourceurl: sourceUrl,
      },
    });

    return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(urlTree));
  }

  public goToHelpDesk(): void {

    const benefitIdFromLocalStorage = this.localStorageService.getItem(LocalStorageKey.benefitId) as string;
    const url = this.urlWithQueryParams(helpDeskRoute,benefitIdFromLocalStorage,this.queryParams)
    const link = this.document.createElement('a');
    //link.target = '_blank';
    link.href = url;

    this.sharedService.GA4pushEvent('cta_click', {
      // eslint-disable-next-line camelcase
      link_text: this.content?.BHL_EmployerSearch_Label_Questions_HelpDesk, // Text clicked on CTA
      // eslint-disable-next-line camelcase
      link_url: link.href,  // Update the Link over here.
      // eslint-disable-next-line camelcase
      link_section: "Body" // Section of website where CTA is available
    });

  }


}
