<section class="bh-login-intro">
  <ng-container *ngIf="(logoLoading | async) === false">
    <img
      [class]="imgClass"
      [src]="clientLogoUrl ?? defaultLogo"
      [alt]="(clientLogoUrl ? heading : 'Bright Horizons') + ' logo'"
    />
   
    <div *ngIf="flowType === 'login' && headingIsClientName && showLoginHeading">
      <h2 class="bh-login-intro__heading">
        {{ (loginContent | async)?.content?.BHL_StandardLogin_Label_Header }}
      </h2>
      <p class="bh-login-intro__emp_name">
        {{ heading }}
      </p> 
    </div>
    <h1
      *ngIf="!headingIsClientName"
      class="bh-login-intro__heading"
    >
      {{ heading }}
    </h1>
    <div *ngIf="flowType !== 'login'">
      <h2
        *ngIf="headingIsClientName && heading && !clientLogoUrl"
        class="bh-login-intro__heading bh-login-intro__heading--logo"
      >
        {{ heading }}
      </h2>
    </div>
    
  </ng-container>
</section>
