<footer class="bh-footer" role="contentinfo" [ngSwitch]="(displayType | async)">
  <ng-container *ngSwitchCase="'desktop'" [ngTemplateOutlet]="desktopFooter"></ng-container>
  <ng-container *ngSwitchCase="'mobile'" [ngTemplateOutlet]="mobileFooter"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="desktopFooter"></ng-container>
</footer>

<ng-template #desktopFooter>
  <p [innerHTML]="copyRightText"></p>
  <section>
    <ng-container [ngTemplateOutlet]="privacyLinkTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="cookieNoticeTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="usePolicyTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="trademarksTemplate"></ng-container>
  </section>
</ng-template>

<ng-template #mobileFooter>
  <section>
    <ng-container [ngTemplateOutlet]="privacyLinkTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="cookieNoticeTemplate"></ng-container>
  </section>
  <section>
    <ng-container [ngTemplateOutlet]="usePolicyTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="trademarksTemplate"></ng-container>
  </section>
  <p [innerHTML]="copyRightTextMobile"></p>
  <img
    [src]="'assets/logos/logo-bh-white.svg'"
    [alt]="'Bright Horizons'"
  />
</ng-template>

<ng-template #privacyLinkTemplate>
  <bh-footer-legal-link [linkInfo]="privacyNotice" (click) = "onPrivacyClick()"></bh-footer-legal-link>
</ng-template>

<ng-template #cookieNoticeTemplate>
  <bh-footer-legal-link [linkInfo]="cookieNotice" (click) = "onCookieNoticeClick()"></bh-footer-legal-link>
</ng-template>

<ng-template #usePolicyTemplate>
  <bh-footer-legal-link [linkInfo]="acceptableUsePolicy" (click) = "onAcceptableUsePolicyClick()"></bh-footer-legal-link>
</ng-template>

<ng-template #trademarksTemplate>
  <bh-footer-legal-link [linkInfo]="trademarks" (click) = "onTrademarksClick()"></bh-footer-legal-link>
</ng-template>
