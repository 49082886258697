import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { ConfigActions } from '../../../core/config/config.actions';
import { ContentService } from '../../../core/content/content.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bh-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: [ './timeout-popup.component.scss' ],
})
export class TimeoutPopupComponent {
  constructor(private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly userActivityService: ContentService,
  ) { }

  public onYes(): void {
    this.dialog.closeAll();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.store.dispatch(ConfigActions.fetchClientToken());
    this.store.dispatch(ConfigActions.saveTimeoutButtonClick({ clickType: 'yes' }));
    this.userActivityService.onYesClick.next(false);
  }

  public onNo(): void {
    this.dialog.closeAll();
    this.store.dispatch(ConfigActions.saveTimeoutButtonClick({ clickType: 'no' }));
    this.userActivityService.onYesClick.next(true);
  }
}
