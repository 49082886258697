import { BhlError, IForgotPasswordState, IForgotUsernameState, IMFAUserEmailAndMobileState, IMFAUserEmailAndMobileStatus, IValidateUserState, IValidateUserStatus } from 'src/app/models';
import { createSelector } from '@ngrx/store';
import { selectForgotPasswordState, selectForgotUsernameState, selectValidateUserState, selectMfaUserEmailAndMobileState } from './credentials.reducers';

export const selectChangePasswordStatus =
  createSelector(selectForgotPasswordState, (state: IForgotPasswordState): boolean | null => state.data);
export const selectForgotPasswordLoaded = createSelector(selectForgotPasswordState, (state: IForgotPasswordState): boolean => state.loaded);
export const selectForgotPasswordLoading = createSelector(selectForgotPasswordState, (state: IForgotPasswordState): boolean => state.loading);
export const selectForgotPasswordError =
createSelector(selectForgotPasswordState, (state: IForgotPasswordState): BhlError => state.error);

export const selectChangeUsernameStatus =
  createSelector(selectForgotUsernameState, (state: IForgotUsernameState): boolean | null => state.data);
export const selectForgotUsernameLoaded = createSelector(selectForgotPasswordState, (state: IForgotUsernameState): boolean => state.loaded);
export const selectForgotUsernameLoading = createSelector(selectForgotPasswordState, (state: IForgotUsernameState): boolean => state.loading);
export const selectForgotUsernameError =
createSelector(selectForgotUsernameState, (state: IForgotUsernameState): BhlError => state.error);

export const selectValidateUserStatus =
  createSelector(selectValidateUserState, (state: IValidateUserState): IValidateUserStatus | null => state.validateUserStatus);
export const selectValidateUserLoaded = createSelector(selectValidateUserState, (state: IValidateUserState): boolean => state.loaded);
export const selectValidateUserLoading = createSelector(selectValidateUserState, (state: IValidateUserState): boolean => state.loading);
export const selectValidateUserError =
createSelector(selectValidateUserState, (state: IValidateUserState): BhlError => state.error);

export const selectMfaUserEmailAndMobileStatus =
  createSelector(selectMfaUserEmailAndMobileState, (state: IMFAUserEmailAndMobileState):
  Array<IMFAUserEmailAndMobileStatus> | null => state.mfaUserEmailAndMobileStatus);
export const selectMfaUserEmailAndMobileLoaded =
  createSelector(selectMfaUserEmailAndMobileState, (state: IMFAUserEmailAndMobileState): boolean => state.loaded);
export const selectMfaUserEmailAndMobileLoading =
  createSelector(selectMfaUserEmailAndMobileState, (state: IMFAUserEmailAndMobileState): boolean => state.loading);
export const selectMfaUserEmailAndMobileError =
  createSelector(selectMfaUserEmailAndMobileState, (state: IMFAUserEmailAndMobileState): BhlError => state.error);
