import { createSelector } from '@ngrx/store';
import { BhlError, IEmployer, IMasterListStatus } from 'src/app/models';
import {
  IClientsState,
  IHelpDeskStatus,
  IHelpState,
  IStateListState,
  ISupportHelpsState,
  ISupportServicesState } from '../../../models/global';
import { selectEmployers, selectHelpDeskSupport, selectStateList, selectSupportHelps, selectSupportServices } from './global.reducers';

export const selectEmployersList = createSelector(selectEmployers, (state: IClientsState): Array<IEmployer> | null => state.list);
export const selectEmployersLoaded = createSelector(selectEmployers, (state: IClientsState): boolean => state.loaded);
export const selectEmployersLoading = createSelector(selectEmployers, (state: IClientsState): boolean => state.loading);
export const selectEmployersError = createSelector(selectEmployers, (state: IClientsState): BhlError => state.error);

export const selectSupportHelpsList = createSelector(selectSupportHelps, (state: ISupportHelpsState): Array<IMasterListStatus> | null => state.list);
export const selectSupportHelpsLoaded = createSelector(selectSupportHelps, (state: ISupportHelpsState): boolean => state.loaded);
export const selectSupportHelpsLoading = createSelector(selectSupportHelps, (state: ISupportHelpsState): boolean => state.loading);
export const selectSupportHelpsError = createSelector(selectSupportHelps, (state: ISupportHelpsState): BhlError => state.error);

export const selectSupportServicesList =
createSelector(selectSupportServices, (state: ISupportServicesState): Array<IMasterListStatus> | null => state.list);
export const selectSupportServicesLoaded = createSelector(selectSupportServices, (state: ISupportServicesState): boolean => state.loaded);
export const selectSupportServicesLoading = createSelector(selectSupportServices, (state: ISupportServicesState): boolean => state.loading);
export const selectSupportServicesError = createSelector(selectSupportServices, (state: ISupportServicesState): BhlError => state.error);

export const selectHelpDeskSupportDetails =
createSelector(selectHelpDeskSupport, (state: IHelpState): IHelpDeskStatus | null => state.helpDeskSupportStatus);
export const selectHelpDeskSupportLoaded = createSelector(selectHelpDeskSupport, (state: IHelpState): boolean => state.loaded);
export const selectHelpDeskSupportLoading = createSelector(selectHelpDeskSupport, (state: IHelpState): boolean => state.loading);
export const selectHelpDeskSupportError = createSelector(selectHelpDeskSupport, (state: IHelpState): BhlError => state.error);

export const selectStateListDetails =
createSelector(selectStateList, (state: IStateListState): Array<IMasterListStatus> | null => state.list);
export const selectStateListLoaded = createSelector(selectStateList, (state: IStateListState): boolean => state.loaded);
export const selectStateListLoading = createSelector(selectStateList, (state: IStateListState): boolean => state.loading);
export const selectStateListError = createSelector(selectStateList, (state: IStateListState): BhlError => state.error);
