import { ActionReducer, createFeature, createReducer, on } from '@ngrx/store';
import { ForgotType, ILoginState } from 'src/app/models/login';
import { LoginActions } from './login.actions';

export const initialState: ILoginState = {
  forgotType: null,
};

export const loginReducer: ActionReducer<ILoginState> = createReducer(
  initialState,
  on(
    LoginActions.setForgotType,
    (state: ILoginState, { forgotType }: { forgotType: ForgotType }): ILoginState => ({
      ...state,
      forgotType,
    }),
  ),
);

export const loginFeatureReducer = createFeature({
  name: 'login',
  reducer: loginReducer,
});

export const {
  name,
  selectForgotType,
} = loginFeatureReducer;
