import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take } from 'rxjs';

import { ConfigActions } from '../../../core/config/config.actions';
import { selectBhPropertiesURL } from '../../../core/config/config.reducers';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { SignupActions } from '../../../core/signup/signup.actions';
import { selectPersonInfoState } from '../../../core/signup/signup.reducers';
import {
  BhQueryParams,
  IPersonInfoState,
  LocalStorageKey,
} from '../../../models';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class HelpGuard {
  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
  ) {}

  public canActivate(): true | Observable<true> {
    let userInfoId: string | null = this.localStorageService.getItem(LocalStorageKey.userInfoId);

    this.store.select(selectBhPropertiesURL)
      .pipe(
        take(1),
      )
      .subscribe((params: BhQueryParams): void => {
        if (params.userinfoid) {
          userInfoId = params.userinfoid;
        } else if (params.uid) {
          userInfoId = params.uid;
        }
      });

    if (userInfoId) {
      this.store.dispatch(ConfigActions.loading({ loading: true }));
      this.store.dispatch(SignupActions.loadPersonInfo({ input: userInfoId }));

      return this.store.select(selectPersonInfoState)
        .pipe(
          filter((personalInfoState: IPersonInfoState): boolean => personalInfoState.loaded || personalInfoState.error !== null),
          take(1),
          // eslint-disable-next-line @ngrx/avoid-mapping-selectors
          map((): true => true),
        );
    }

    return true;
  }
}

export const helpGuard: CanActivateFn = (): true | Observable<true> => inject(HelpGuard).canActivate();
