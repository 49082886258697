import { bhQueryParamsSet } from '../constants/query-params';
import { HttpError, IAppToken } from './http';
import { SourceId } from './source-id';

export type BhlError = string | HttpError | null;

export interface ISharedState {

  /**
   * Whether the data was loaded. Please note that when an error
   * occurs, any data loaded gets wiped.
   */
  loaded: boolean;

  /**
   * An indicator for when we are waiting for the API to respond.
   */
  loading: boolean;

  /**
   * An error that was thrown by the backend. Please note that some
   * errors get returned wrapped in a 200 status code, and we set these
   * manually to this error property.
   */
  error: BhlError;
}

/**
 * A type representing the flows a user can take within the application.
 */
export type FlowType = 'join' | 'login';

/**
 * A type representing the display types we will be working with based
 * on the breakpoint(s) defined by the design team.
 */
export type DisplayType = 'desktop' | 'mobile';

export type LoginType = 'password' | 'otp';

/**
 * A type to represent the supported cultures/locales. This is important
 * for the content retrieval.
 */
export type Locale = 'en-US';

/**
 * An enum to capture the benefit IDs the application should expect and be
 * able to handle.
 */
export enum BenefitId {
  Default = '0',
  MyBH = '-1',
  CollegeCoach = '1',
  EdAssist = '2',
  BUC = '3', // Backup Care
  Community = '4',
  FIC = '5', // Family Information Center a.k.a. Full Service, a.k.a. Early Education and Preschool
  SLP = '6', // Student Load Repayment
  TPM = '7', // Tuition Program Management
  OW = '8', // Operator Owner a.k.a. Central
  Crisiscare = '9',
  CCSS = '10', // Child Care and School Support
  WFS = '11',
  WFS_IRL = '12',
  ElderCare = '13',
  SpecialNeeds = '14',
  Thrive = '15',
  SC = '16', // Sittercity
  CCR = '17', // College Coach Retail
  EFS = '18',
  EPTB = '19', // External Partner Test Benefit
  GPS = '20', // GP Strategies
  SFBH = '21', // Salesforce-brighthorizons
}
export enum BenefitName {
  Default = '0',
  MyBH = 'MyBH',
  CollegeCoach = 'College Coach',
  EdAssist = 'EdAssist Solutions',
  BUC = 'Back-Up Care', // Backup Care
  Community = 'Community',
  FIC = 'Early Education and Preschool', // Family Information Center a.k.a. Full Service, a.k.a. Early Education and Preschool
  SLP = 'Student Loan Repayment ', // Student Load Repayment
  TPM = 'Tuition Program Management', // Tuition Program Management
  OW = 'Central', // Operator Owner a.k.a. Central
  Crisiscare = 'Crisiscare',
  CCSS = 'Child Care and School Support', // Child Care and School Support
  WFS = 'WFS',
  WFS_IRL = 'WFS_IRL',
  ElderCare = 'Elder Care',
  SpecialNeeds = 'Special Needs',
  Thrive = 'Thrive',
  SC = 'Sittercity', // Sittercity
  CCR = 'College Coach Retail', // College Coach Retail
  EFS = 'EFS',
  EPTB = 'External Partner Test Benefit', // External Partner Test Benefit
  GPS = 'GP Strategies', // GP Strategies
  SFBH = 'Salesforce-brighthorizons', // Salesforce-brighthorizons
}

export enum ModeType {
  Registration = 'Registration',
  Login = 'login',
}

export enum UserType {
  FederateNonEmployee = 2,
}

export enum UserStatus {
  InActive = 0,
  Active = 1,
}

export enum SourceOfRegistration {
  BHLoginNonFederatedRegistration = 'BHLoginNonFederatedRegistration',
  OptInRegistration = 'OptInRegistration',
  BHLoginFederatedRegistration = 'BHLoginFederatedRegistration',
}

export enum ContactType {
  email = 1,
  mobile = 2,
}

export const MobileContactType = 'mobile';
export const EmailContactType = 'email';

export const FsRetailClientGuid = 'FC2ADC71-EDBA-EA11-A843-005056994C8E';

/**
 * An interface to represent the LOB logo information.
 */
export interface IBenefitLogo {

  /**
   * The unique ID of the benefit/LOB
   */
  id: BenefitId;

  /**
   * The URL of the home page of the benefit ID/LOB.
   */
  url: string;

  /**
   * The path to the BH logo matching the benefit ID/LOB.
   */
  logoUrl: string;

  /**
   * An assistive technology friendly label for the logo (img).
   */
  altText: string;

  /**
   * The name of the benefit ID/LOB (e.g., EdAssist).
   */
  lob?: string;

  /**
   * The name of the application of the corresponding LOB (e.g., BUCA for Backup Care).
   */
  applicationName: string;
}

/**
 * A type to generate an interface for the entire list of benefit IDs.
 */
export type BenefitIdRecord = Record<BenefitId, IBenefitLogo>;

/**
 * A constant for the LOB logos and their associated information per LOB.
 */
export const benefitLogo: Readonly<BenefitIdRecord> = {
  [BenefitId.Default]: {
    id: BenefitId.Default,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'BH',
  },
  [BenefitId.MyBH]: {
    id: BenefitId.MyBH,
    url: 'https://my.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'MyBH',
  },
  [BenefitId.CollegeCoach]: {
    id: BenefitId.CollegeCoach,
    url: 'https://www.brighthorizons.com/family-solutions/college-coach',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'College Coach',
    applicationName: 'CC',
  },
  [BenefitId.EdAssist]: {
    id: BenefitId.EdAssist,
    url: 'https://www.brighthorizons.com/edassist-solutions',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'EdAssist Solutions',
    applicationName: 'EA',
  },
  [BenefitId.BUC]: {
    id: BenefitId.BUC,
    url: 'https://backup.brighthorizons.com',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Back-Up Care',
    applicationName: 'BUCA',
  },
  [BenefitId.Community]: {
    id: BenefitId.Community,
    url: 'https://backup.brighthorizons.com',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Community',
    applicationName: 'Community',
  },
  [BenefitId.FIC]: {
    id: BenefitId.FIC,
    url: 'https://familyinfocenter.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Family Information Center',
    applicationName: 'FIC',
  },
  [BenefitId.SLP]: {
    id: BenefitId.SLP,
    url: 'https://familyinfocenter.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Student Load Repayment',
    applicationName: 'SLP',
  },
  [BenefitId.TPM]: {
    id: BenefitId.TPM,
    url: 'https://familyinfocenter.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Tuition Program Management',
    applicationName: 'TPM',
  },
  [BenefitId.OW]: {
    id: BenefitId.OW,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'OW',
  },
  [BenefitId.Crisiscare]: {
    id: BenefitId.Crisiscare,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'Crisiscare',
  },
  [BenefitId.CCSS]: {
    id: BenefitId.CCSS,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'CCSS',
  },
  [BenefitId.WFS]: {
    id: BenefitId.WFS,
    url: 'https://www.brighthorizons.com/family-solutions',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'WFS',
  },
  [BenefitId.WFS_IRL]: {
    id: BenefitId.WFS_IRL,
    url: 'https://solutions.brighthorizons.co.uk/our-solutions',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'WFSIRL',
  },
  [BenefitId.ElderCare]: {
    id: BenefitId.ElderCare,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Elder Care',
    applicationName: 'ElderCare',
  },
  [BenefitId.SpecialNeeds]: {
    id: BenefitId.SpecialNeeds,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Special Needs',
    applicationName: 'SpecialNeeds',
  },
  [BenefitId.Thrive]: {
    id: BenefitId.Thrive,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'Thrive',
    applicationName: 'Thrive',
  },
  [BenefitId.SC]: {
    id: BenefitId.SC,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'SC',
  },
  [BenefitId.CCR]: {
    id: BenefitId.CCR,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    lob: 'College Coach Retail',
    applicationName: 'CCR',
  },
  [BenefitId.EFS]: {
    id: BenefitId.EFS,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'EFS',
  },
  [BenefitId.EPTB]: {
    id: BenefitId.EPTB,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'EPTB',
  },
  [BenefitId.GPS]: {
    id: BenefitId.GPS,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'GPS',
  },
  [BenefitId.SFBH]: {
    id: BenefitId.SFBH,
    url: 'https://www.brighthorizons.com/',
    logoUrl: 'assets/logos/bh-logo.svg',
    altText: 'Bright Horizons Logo',
    applicationName: 'SFBH',
  },
};

/**
 * An interface to represent the API authorization token info stored
 * in the NgRx store.
 */
export interface IAppJwt {

  /**
   * The token we should use for API authorization.
   */
  token: string;
  userInfoId: string | null;
  expiresAt: number;

  /**
   * The user access token we get during the login flow and create registration.
   */
  userAccessToken?: string | null;
}

export interface TokenParam {
  clientId: string;
  clientSecret: string;
}

export type QueryParamsSet = typeof bhQueryParamsSet[number];
export type BhQueryParams = Partial<Record<QueryParamsSet, string | SourceId | null>>;

export interface IApiTokenRefreshState extends ISharedState {
  apiTokenRefresh: IAppToken | null;
}

export type ClickType = 'yes' | 'no';

export enum ClickTypeValue {
  YES = 'yes',
  NO = 'no',
}

/**
 * An interface to represent the NgRx data structure for the app global
 * state/information like the API authorization token, display type, etc.
 */
export interface IConfigState {

  /**
   * The flow the user is in (login vs registration).
   */
  flowType: FlowType;

  /**
   * An indicator to be set and used to show/hide the spinner. It can be
   * set by any page to display the global spinner we have defined in
   * app.component.ts/html
   */
  loading: boolean;

  /**
   * The benefit ID/LOB information that was mapped to the benefit ID inferred
   * from the "benefitid" query param when we loaded the app.
   */
  benefitId: IBenefitLogo;

  /**
   * The display mode we are in based on the breakpoint(s) (e.g., desktop).
   */
  displayType: DisplayType;

  loginType: LoginType;

  /**
   * The token info that we use to authenticate the app against the API.
   */
  apiToken: IAppJwt | null;

  bhPropertiesURL: BhQueryParams;

  jsonDfp: string | null;
  locale: Locale;

  /**
   * The current host url of the application
   */
  origin: string;

  /**
   * whether the client guid was derived from the `clientGuid` URL query param.
   */
  isClientGuidFromQueryParam: boolean;
  apiTokenRefresh: IApiTokenRefreshState;
  clickType: ClickType;
}
