<ng-container *ngIf="(content | async)?.loaded">
    <article class="signup-confirmation" *ngIf="{ page: (content | async)?.content, account: (accountContent | async)?.content } as pageContent">
      <div class="success-icon">
        <img
          [src]="'assets/success-icon.svg'"
          [alt]="'Success'"
        />
      </div>
      <h1 class="bh--heading">
        {{ pageContent.page?.BHL_MobilePWReset_MBD_Header }}
      </h1>
      <p class="explore-message">
        {{ pageContent.page?.BHL_MobilePWReset_MBD_Body }}
      </p>
  
      <bh-already-have-account [content]="pageContent.account" [showLogin]="false"></bh-already-have-account>
    </article>
  </ng-container>
  